<uni-label [required]="true">
    {{'ci.common.toDate' | translate}}
</uni-label>
<uni-datepicker [datepicker]="toDatepicker">
    <input
        [ngModel]="toDate"
        (ngModelChange)="changeToDate($event)"
        [placeholder]="'label.chooseDate' | translate"
        [matDatepicker]="toDatepicker"
        (click)="toDatepicker.open()"
        autocomplete="off"
        uniInput
        readonly
    >
    <mat-datepicker #toDatepicker></mat-datepicker>
</uni-datepicker>