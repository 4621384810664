import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { Observable } from 'rxjs';
import { FormDateRangeSelectorStore } from './form-date-range-selector.store';

@Component({
  selector: 'app-form-date-range-selector',
  templateUrl: './form-date-range-selector.component.html',
  styleUrls: ['./form-date-range-selector.component.scss'],
  providers: [FormDateRangeSelectorStore]
})
export class FormDateRangeSelectorComponent implements OnInit {

  constructor(
    private store: FormDateRangeSelectorStore,
    private fb: FormBuilder
  ) { }

  @Input() set from(from: string){
    this.store.externalSetFrom(from)
    this.form.get('from').setValue(moment(from))
  }

  @Input() set to(to:string){
    this.store.externalSetTo(to)
    this.form.get('to').setValue(moment(to))
  }

  @Output() dateChange: Observable<any> = this.store.eventEmitter$

  vm$ = this.store.vm$

  form: FormGroup = this.fb.group({
    to: [],
    from: []
  })

  ngOnInit(): void {}

  changeEndDate(event: any){
    if(event.target.value){
        this.store.internalSetDateRange({
          from: this.form.value.from.toISOString(),
          to: this.form.value.to.toISOString()
        })
    }
  }



}
