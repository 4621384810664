import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';


import { UniAuthFacade } from 'unifonic-spa-common';

@Injectable({ providedIn: 'root' })
export class FeatureFlagNewMenuGuard implements CanActivate, CanActivateChild {

  readonly newMenu$ = this.authFacade.featureFlags$.pipe(
    filter(flags => !!flags),
      tap((flags) => {
        
          const newMenu = flags['2022-q-4-ci-new-menu'];
          if(!newMenu){  
              this.router.navigate(['/', '404'])
          }
      }), 
      map((flags) => {
          return flags && flags['2022-q-4-ci-new-menu']
      })
  )

  constructor(
    private router: Router,
    private authFacade: UniAuthFacade
  ) { }



  canActivate(): Observable<boolean> {
    return this.newMenu$
  }


  canActivateChild(): Observable<boolean>  {
    return this.newMenu$
  }

 
}
