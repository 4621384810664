import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { CustomerStatus } from '../../../shared/store-mocks/status.store';
import { AdvancedFiltersFormStatusStore } from './advanced-filters-form-status.store';

@Component({
  selector: 'app-advanced-filters-form-status',
  templateUrl: './advanced-filters-form-status.component.html',
  styleUrls: ['./advanced-filters-form-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AdvancedFiltersFormStatusStore]
})
export class AdvancedFiltersFormStatusComponent implements OnInit, OnDestroy, ControlValueAccessor {

  onChange: any = () => {}
  onTouch: any = () => {}


  
  statusCurrentValue$ = this.store.statusValue$.pipe(map(
    (value: any) => {
      return value.length ? value.split(',').filter(s => s !== CustomerStatus.NONE) : []
    }
  ))
  statusEntities$ = this.store.entities$

  constructor(
    public ngControl: NgControl,
    private store: AdvancedFiltersFormStatusStore

    ) {
      ngControl.valueAccessor = this;
    }

  ngOnInit(): void {}

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  statusValueChange(option: string[]) {
    this.saveValue(option.join(','))
  }


  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
