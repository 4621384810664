export const createWidgetTooltip = (source: any) => {

    const pointId = source.point.options.id;
    const childrenPoints = source.series.data.filter((s: any) => s.options.from === pointId)

    //@ts-ignore
    const totalItems = childrenPoints.reduce((acc, curr) => acc + curr.options.size, 0)
    const childrenOptions = childrenPoints.map((cp) => {
      return {
        //@ts-ignore
        size: cp.options.size,
        
        //@ts-ignore
        type: cp.options.type,
        
        //@ts-ignore
        percent: (cp.options.size / totalItems).toFixed(2) * 100
      }
    })

    const nameStyles = `
      font-size: 12px;
      padding: 12px 5px; 
      margin: 0;
      color: #979797;
      min-width: 180px;
      text-transform: uppercase;
    `

    const itemRowStyles = `
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 5px;
      border-top: 1px solid #979797;
    `

    const nametHtml = `<h6 style="${nameStyles}">${source.point.name}</h6>`;
    const totalItemsHtml = `<li style="${itemRowStyles}"><span>Total Items</span><span>${totalItems}</span></li>`
    const itemByTypeHtml = `<ul style="padding: 0; margin: 0; list-style: none;">
        ${totalItemsHtml}
        ${childrenOptions.map(co => `<li style="${itemRowStyles}"><span>${co.type}</span><span style="text-align:right;"><strong>${co.percent}%</strong><br />${co.size}</span></li>`).join('')}
    </ul>`
    return `<div>
      ${nametHtml}
      ${childrenOptions.length ? itemByTypeHtml : ''}
    </div>`

}


export const createLinkTooltip = (source: any) => {
    //@ts-ignore
    const fromNode = source.point.fromNode
    //@ts-ignore
    const toNode = source.point.toNode
    
    const nameStyles = `
      font-size: 12px;
      padding: 12px 5px; 
      margin: 0;
      color: #979797;
      min-width: 180px;
      text-transform: uppercase;
    `;
    const nameHtml = `<div style="${nameStyles}">${fromNode.name} <i class="fa fa-arrow-right"></i> ${toNode.name}</div>`
    
    const totalItemsStyle = `
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 5px;
      border-top: 1px solid #979797;
    `
    //@ts-ignore
    const totalItemsHtml = `<div style="${totalItemsStyle}"><span>Total Items</span><span>${source.point.size}</span></div>`

    return `<div>
      ${nameHtml} 
      ${totalItemsHtml}
    </div>`
} 