import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { MonthDaysMockStore } from "src/app/shared/store-mocks/month-days.store";
import { WeekDaysMockStore } from "src/app/shared/store-mocks/week-days.store";



type State = {
    dateSelectorsVisibility: boolean
}

const initialState: State = {
    dateSelectorsVisibility: false
}

@Injectable()
export class CreateFormFrequencyMonthDayStore extends ComponentStore<State>{
    constructor(
        private monthDaysMockStore: MonthDaysMockStore,
    ){
        super({...initialState})
    }

    //selectors

    readonly entities$ = this.monthDaysMockStore.entities$

    readonly dateSelectorsVisibility$ = this.select(state => state.dateSelectorsVisibility)

    readonly vm$ = this.select(
        this.entities$,
        this.dateSelectorsVisibility$,
        (entities, dateSelectorsVisibility) => {
            return {
               entities,
               dateSelectorsVisibility,
               value: ''
            }
        }, {debounce: true}
    )

    //updaters


    // effects

}
