export enum FeatureFlagKeys {
  store_Q1_2020 = '2020-q-1-store',
  languages_Q3_2020 = '2020-q-3-languages',
  maker_checker_2021_q_2 = '2021-q-2-maker-checker',
  otp_campaign_2021_q_2 = '2021-q-2-otp-campaign',
  spa_custom_routing_rules_2021_q_3 = '2021-q-3-spa-custom-routing-rules',
  scheduled_reports_2021_q_3 = '2021-q-3-scheduled-reports',
  impersonation_history_2021_q_3 = '2021-q-3-impersonation-history',
  routing_checker_2021_q_3 = '2021-q-3-routing-checker',
  whatsapp_embedded_flow = '2021-q-4-whatsapp-embedded-signup-flow',
  whatsapp_manual_flow = '2023-q-1-whatsapp-manual-signup-flow',
  whatsapp_campaign_2021_q_4 = '2021-q-4-whatsapp-campaign',
  new_design_2022_q_1 = '2022-q-1-new-design',
  add_package_2022_q_1 = '2022-q-1-add-package',
  self_serve_2022_q_1 = '2022-q-1-self-serve-mvp',
  change_campaign_status_2022_q_1 = '2022-q-1-change-campaign-status',
  cr_trail_2022_q_1 = '2022-q-1-cr-trial',
  userpilot_2022_q_1 = '2022-q-1-userpilot',
  uc_dashboard_user_widget_2022_q_1 = '2022-q-1-uc-dashboard-user-widget',
  uc_client_mnp_2022_q_1 = '2022-q-1-uc-client-mnp',
  new_menu_2022_q_1 = '2022-q-1-new-menu', // @deprecated
  channels_whatsapp_2022_q_1 = '2022-q-1-channels-whatsapp',
  mixpanel_analytics_2022_q_2 = '2022-q-2-mixpanel-analytics',
  payment_module_wrapper_2022_q2 = '2022-q2-payment-module-wrapper',
  appsid_otp_2022_q2 = '2022-q2-appsid-otp',
  voice_appsid_otp_2022_q2 = '2022-q2-voice-appsid-otp',
  conversations_report_2022_q_3 = '2022-q-3-conversations-report',
  channels_webwidget_2022_q_4 = '2022-q-4-channels-webwidget',
  webwidget_powered_by_2023_q_1 = '2023-q-1-webwidget-powered-by-branding',
  campaign_block_words_2022_q_4 = '2022-q-4-campaign-block-words',
  campaign_low_balance_alert_2022_q_4 = '2022-q-4-campaign-low-balance-alert',
  campaign_stats_2022_q_4 = '2022-q4-campaign-stats',
  api_keys_2022_q_4 = '2022-q-4-api-keys',
  ci_old_menu_2022_q_4 = '2022-q-4-ci-old-menu',
  ci_new_menu_2022_q_4 = '2022-q-4-ci-new-menu',
  channels_instagram_2022_q_4 = '2022-q-4-channels-instagram',
  otp_for_profile_2023_q_1 = '2023-q-1-otp-for-profile-edit',
  survicate_2023_q_1 = '2023-q-1-survicate',
  flow_studio_campaigns_2022_q_3 = '2022-q-3-flow-studio-campaigns',
  sms_pro_2022_q_3 = '2022-q-3-sms-pro',
  selling_rates_2023_q_1 = '2023-q-1-selling-rates',
  integrations_2023_q_1 = '2023-q-1-integrations',
  data_protection_2023_q_1 = '2023-q-1-data-protection', // @deprecated
  data_delete_request_2023_q_1 = '2023-q-1-dpl-data-delete_request',
  data_copy_request_2023_q_1 = '2023-q-1-data-copy_request',
  user_roles_single_select_2023_q_1 = '2023-q-1-user-roles-single-select',
  fs_campaign_2023_q_1 = '2023-q-1-fs-campaign',
  sender_name_geo_permissions_2023_q_1 = '2023-q-1-sender-name-geo-permissions',
  ramadan_campaign_timing_note_2023_q_1 = '2023-q-1-ramadan-campaign-timings-note',
  contact_channels_2023_q_1 = '2023-q-1-contact-channels',
  dashboard_pricing_widget_2023_q_2 = '2023-q-2-dashboard-pricing-widget',
  ksa_sid_renewal_2023_q_2 = '2023-Q2-ksa-sid-renewal',
  conv_botbuilder_language_2023_q_2 = '2023-q-2-bot-builder-language',
  admin_console_callerId_settings_2023_q_2 = '2023-q-2-admin-console-callerId-settings',
  ksa_sid_renewal_payment_credit_card_2023_q_2 = '2023-Q2-ksa-sid-renewal-payment-credit-card',
  ksa_sid_renewal_payment_balance_2023_q_2 = '2023-Q2-ksa-sid-renewal-payment-balance',
  ksa_sid_renewal_payment_bank_transfer_2023_q_2 = '2023-Q2-ksa-sid-renewal-payment-bank-transfer',
  admin_console_account_impersonation_2023_q_2 = '2023-Q2-admin-console-account-impersonation',
  applications_2023_q_2 = '2023-q-2-applications',
  sms_traffic_report_2023_q_2 = '2023-q-2-sms-traffic-report',
  ci_enrolment_forward_2023_q_2 = '2023-q-2-ci-enrolment-forward',
  ci_enrolment_backward_2023_q_2 = '2023-q-2-ci-enrolment-backward',
  ci_whatsapp_2023_q_2 = '2023-q-2-ci-whatsapp',
  ci_financial_report_2023_q_1 = '2023-q-1-ci-financial-report',
  channel_twitter_2020_q_1 = '2020-q-1-twitter-channel',
  channel_abc_2021_q_1 = '2021-q-1-abc-channel',
  security_ip_whitelisting_2023_q_3 = '2023-q-3-security-ip-whitelisting',
  trusted_domains_2023_q_3 = '2023-q-4-trusted-domains',
  audio_library_tts_engine_2023_q_3 = '2023-q-3-audio-library-tts-engine',
  voice_tts_sestek_engine_2023_q_3 = '2023-q-3-voice-tts-sestek-engine',
  message_template_marketing_category_2023_q_3 = '2023-q-3-tw-message-template-marketing-category',
  otp_for_email_change_2023_q_3 = '2023-q-3-otp-for-email-change',
  voice_apps_business_hours_controls_2023_q_3 = '2023-q-3-voice-apps-business-hours_controls',
  package_management_2023_q_2 = '2023-Q2-package-management',
  package_filtering_2023_q_4 = '2023-q-4-package-filtering',
  quick_sms_message_type_2023_q_3 = '2023-q-3-quick-sms-message-type',  // @deprecated
  account_manager_role_restriction_2023_q_3 = '2023-q-3-account-manager-role-restriction',
  campaign_content_subscription_2023_q3 = '2023-q-3-campaign-content-subscription',
  campaign_content_flow_studio_enable_ulink_2023_q4 = '2023-q-4-flow-studio-ulink',
  campaign_content_sms_enable_ulink_2023_q4 = '2023-q-4-sms-ulink',
  audiences_2023_q_3 = '2023-q-3-audiences',
  byoc_trunk_2023_q_4 = '2023-q-4-byoc-trunk',
  whatsapp_name_upadte_2023_q_4 = '2023-q-4-whatsapp-name-update',
  voice_blacklisting_settings_2023_q_4 = '2023-q-4-voice-blacklisting-settings',
  units_management_2023_q_4 = '2023-q-4-units-management',
  packages_consumption_column_2023_q_4 = '2023-q-4-packages-consumption-column',
  sms_service_trials_2024_q_1 = '2024-q-1-sms-service-trials',
  call_retry_barred_for_business_day_2024_q_1 = '2024-q-1-call-retry-barred-for-business-day',
  wab_carousel_message_template_2024_q_1 = '2024-q1-wab-carousel-message-template',
  conv_settings_encrypted_2024_q_1 = '2024-q-1-conv-settings-encrypted',
  conv_api_key_seen_one_time = '2024-q-2-conv-api-key-seen-one-time',
  sender_id_edit_view_2024_q_2 = '2024-q-2-sender-id-edit-view',
  mcc_campaign_settings_under_uc_account_2024_q_2 = '2024-q-2-mcc-campaign-settings-under-uc-account',
  mcc_campaign_audience_segments_2024_q2 = '2024-q-2-mcc-campaign-audience-segments',
  audiences_ads_performance_2024_q2 = '2024-q-2-audiences-ads-performance'
}

export type FeatureFlags = Record<FeatureFlagKeys, boolean>;

export interface FeatureFlag {
  name?: string;
  trackEvents?: boolean;
  value?: boolean;
  variation?: number;
  version?: number;
}

export interface FeatureFlagsUser {
  key: string;
  name?: string;
  anonymous: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  custom?: {
    admin: boolean;
    account: string;
    accountId: string;
    whiteLabel: boolean;
  };
}

export enum FeatureFlagOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface FeatureFlagGuard {
  operator: FeatureFlagOperator;
  featureFlags: FeatureFlagKeys[];
}
