import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartCircleMultiComponent } from './chart-circle-multi.component';
@NgModule({
  declarations: [ChartCircleMultiComponent],
  exports: [ChartCircleMultiComponent],
  imports: [
    HighchartsChartModule
  ]
})
export class ChartCircMultileModule { }
