
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";
import { UniAuthFacade } from "unifonic-spa-common/src";
import { CreateFormStore } from "../create-form/create-form.store";

export type EntityItem = {
    value: string,
    label: string,
    description: string
    disabled?: boolean
}

type State = {
    entities: EntityItem[],
    selectedValue: any
}

const initialState: State = {
    entities: [],
    selectedValue: ''
}

@Injectable()
export class CreateFormTemplatesStore extends ComponentStore<State>{
    constructor(
        private translateService: TranslateService,
        private createFormStore: CreateFormStore,
        private authFacade: UniAuthFacade
    ){
        super({...initialState})
    }

    //selectors
    readonly entities$ = this.select((state) => state.entities)
    readonly selectedValue$ = this.select((state) => state.selectedValue)

    readonly viewEntities$ = this.select(
        this.entities$,
        this.selectedValue$,
        (entities, selectedValue) => entities.map(e => {
            return {
                ...e,
                label: this.translateService.instant(e.label),
                description: this.translateService.instant(e.description),
                active: e.value === selectedValue
            }

        })
    )

    readonly defaultSelectedTemplate$ = this.select(
        this.viewEntities$,
        (entities) => entities[0]?.value
    )



    readonly vm$ = this.select(
        this.viewEntities$,
        this.selectedValue$,
        (viewEntities, selectedValue) => {
            return {
                viewEntities,
                selectedValue
            }
        }
    )


}
