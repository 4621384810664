
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Products, ProductsMockStore } from "src/app/shared/store-mocks/products.store";

type State = {
    selectedItems: string[]
    disabled: string[]
}

const initialState: State = {
    selectedItems: [Products.ALL],
    disabled: [Products.CHATBOT, Products.FLOW_STUDIO, Products.AGENT_CONSOLE]
}

@Injectable()
export class CreateFormProductStore extends ComponentStore<State>{
    constructor(
        private productsMockStore: ProductsMockStore
    ){
        super({...initialState})
    }

    //selectors
    readonly selectedItems$ = this.select((state) => state.selectedItems)
    readonly disabled$ = this.select((state) => state.disabled)

    readonly vm$ = this.select(
        this.productsMockStore.viewEntities$,
        this.selectedItems$,
        this.disabled$,
        (entities, selectedItems,disabled) => {
            return {
                entities,
                selectedItems,
                disabled
            }
        } 
    )

    //updaters
    readonly setSelectedItems = this.updater((state, selectedItems: string[]) => {
        return {...state, selectedItems: [...selectedItems]}
    })

}
