<div (uniClickOutside)="onDiscard()">
  <uni-table-filter-header
    *ngIf="!isInputField"
    [heading]="heading"
    [hasFilters]="getControlKey()"
    (open)="onOpen()"
  ></uni-table-filter-header>
  <div *ngIf="isInputField">
    <uni-form-field [margin]="false">
      <input
        [class.uni-table-filter--disabled]="isDisabled"
        type="text"
        [formControl]="filterInputValue"
        [label]="heading"
        [placeholder]="placeholder"
        [disabled]="!data || isDisabled"
        readonly
        uniInput
      >
      <uni-icon
        [disabled]="!data || isDisabled"
        [name]="data ? 'angle-down' : 'spinner fa-spin'"
        uniSuffix
      ></uni-icon>
    </uni-form-field>
  </div>
  <uni-table-filter-wrapper
    *ngIf="isOpened"
    [tableLeft]="tableLeft"
    [thead]="thead"
    [width]="width"
    [alignRight]="alignRight"
  >
    <uni-form-multi-autocomplete
      [control]="control"
      [thead]="thead"
      [heading]="heading"
      [filterKey]="filterKey"
      [filterValue]="filterValue"
      [isSearch]="isSearch"
      [multi]="isMulti"
      [data]="parsedData"
      [addLrmChar]="addLrmChar"
      [filteredDataOnly]="filteredDataOnly"
      (apply)="onApply()"
      (discard)="onDiscard()"
      (loadMore)="onLoadMore()"
      (search)="search.emit($event)"
      [isLoading]="isLoading"
      [isLazyLoading]="isLazyLoading"
      [scrollCallback]="scrollCallback"
    >
    </uni-form-multi-autocomplete>
  </uni-table-filter-wrapper>
</div>
