import { Injectable } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store"
import { map } from "rxjs/operators"
import { UniAuthFacade, momentTimezone } from "unifonic-spa-common/src"


export type MarginUnit = 'h' | 'm' | 's'

type State = {
    queryParams: any,
    lastUpdate: string,
    timeMargin: number,
    marginUnit: MarginUnit,
    disableRedirection: boolean
}

const initialState: State = {
    queryParams: {},
    lastUpdate: '',
    timeMargin: 0,
    marginUnit: 'h',
    disableRedirection: false
}


@Injectable()
export class LastTimeRealTimeInfoStore extends ComponentStore<State>{

    constructor(
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }


    //selectors
    queryParams$ = this.select(state => state.queryParams)
    lastUpdate$ = this.select(state => state.lastUpdate)
    timeMargin$ = this.select(state => state.timeMargin)
    marginUnit$ = this.select(state => state.marginUnit)
    disableRedirection$ = this.select(state => state.disableRedirection)


    timezone$ = this.authFacade.userMe$.pipe(
        map((user: any) => user.user.timezone)
    )


    lastUpdateWithMargin$ = this.select(
        this.timezone$,
        this.lastUpdate$,
        this.timeMargin$,
        this.marginUnit$,
        (timezone, lastUpdate, timeMargin, marginUnit) => {
            return lastUpdate ? momentTimezone(lastUpdate).tz(timezone).subtract(timeMargin, marginUnit).format('hh:mm A') : ''
        }
    )



    vm$ = this.select(
        this.queryParams$,
        this.lastUpdateWithMargin$,
        this.disableRedirection$,
        (   
            queryParams, lastUpdate, disableRedirection
        ) => {
            return {
                queryParams,
                lastUpdate,
                disableRedirection
            }
        }
    )


}


