import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-circle-chart-stats',
  templateUrl: './card-circle-chart-stats.component.html',
  styleUrls: ['./card-circle-chart-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCircleChartStatsComponent implements OnInit {

  @Input() loading: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}
