import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { UniAuthFacade } from "@unifonic/common";
import { omitBy, without } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { mapDatePickerValuesToDateRanges } from "../../shared/utils/map-date-picker-values-to-date-ranges";
import { ApiMessageLogsListParams, SortingDirection } from "../log-analyser.models";
import { mapSortingOptionsToFilterParams } from "../utils/map-sorting-options-to-filter-params";
import { MessageLogsFetchStore } from './message-logs-fetch.store';


type State = {
    autoload: boolean
    status: string
    channels: string
    products: string
    dateRange: string
    messageType: string
    accountId: string
    senderName: string
    recipients: string
    page: string
    limit: string
    sortBy: string
    sortDir: SortingDirection
    campaignStatus: string
    campaignId: string
    senderNumber: string
    customerReason: string
    templates: string
    buttons: string,
    links: string,
    locations: string,
}

const initialState: State = {
    autoload: false,
    status: '',
    channels: '',
    products: '',
    dateRange: '',
    messageType: '',
    accountId: '',
    senderName: '',
    recipients: '',
    page: '1',
    limit: '15',
    sortBy: '',
    sortDir: '',
    campaignStatus: '',
    campaignId: '',
    senderNumber: '',
    customerReason: '',
    templates: '',
    buttons: '',
    links: '',
    locations: '',
}

type SubscriptionFn = (value: string) => void
@Injectable()
export class MessageLogsFiltersStore extends ComponentStore<State>{
    constructor(
        private messageLogsFetchStore: MessageLogsFetchStore,
        private uniAuthFacade: UniAuthFacade,
    ){
        super(initialState)
    }

    //auth selectors
    readonly selectUserTimezone$ = this.select(
        this.uniAuthFacade.userMe$,
        userMe => userMe?.user?.timezone
    )

    //selectors
    readonly status$ = this.select(state => state.status)

    readonly channels$ = this.select(state => state.channels)
    readonly channelsAsArray$ = this.select(this.channels$, (values) => values.split(','))

    readonly products$ = this.select(state => state.products)
    readonly productsAsArray$ = this.select(this.products$, (values) => values.split(','))

    readonly dateRange$ = this.select(state => state.dateRange)

    readonly messageType$ = this.select(state => state.messageType)

    readonly accountId$ = this.select(state => state.accountId)

    readonly senderName$ = this.select(state => state.senderName)

    readonly recipients$ = this.select(state => state.recipients)

    readonly page$ = this.select(state => state.page)
    readonly limit$ = this.select(state => state.limit)

    readonly sortBy$ = this.select(state => state.sortBy)
    readonly sortDir$ = this.select(state => state.sortDir)
    readonly autoload$ = this.select(state => state.autoload)

    readonly campaignId$ = this.select(state => state.campaignId)
    readonly campaignStatus$ = this.select(state => state.campaignStatus)

    readonly senderNumber$ = this.select(state => state.senderNumber)
    readonly customerReason$ = this.select(state => state.customerReason)

    readonly templates$ = this.select(state => state.templates)
    readonly buttons$ = this.select(state => state.buttons)
    readonly links$ = this.select(state => state.links)
    readonly locations$ = this.select(state => state.locations)
    
    //selectors - modifiers
    readonly sortData$ = this.select(
        this.sortBy$,
        this.sortDir$,
        (sortBy, sortDir) => {
            return {sortBy, sortDir}
        }
    )

    readonly allFilters$ = this.select(state => {
        return {
            status: state.status,
            channels: state.channels,
            products: state.products,
            dateRange: state.dateRange,
            messageType: state.messageType,
            accountId: state.accountId,
            senderName: state.senderName,
            recipients: state.recipients,
            page: state.page,
            limit: state.limit,
            sortBy: state.sortBy,
            sortDir: state.sortDir,
            campaignId: state.campaignId,
            campaignStatus: state.campaignStatus,
            senderNumber: state.senderNumber,
            customerReason: state.customerReason,
            templates: state.templates,
            buttons: state.buttons,
            links: state.links,
            locations: state.locations,
        }
    }, {debounce: true})

    readonly queryParams$: Observable<Partial<ApiMessageLogsListParams>> = this.state$.pipe(
        withLatestFrom(this.selectUserTimezone$),
        map(([state, timezone]) => this.prepareQueryParams(state, timezone))
    )

    readonly trackParams$: Observable<any> = this.select(
        this.allFilters$,
        this.uniAuthFacade.userMe$,
        this.selectUserTimezone$,
        (allFilters, userMe, timezone) => {
            const dates = mapDatePickerValuesToDateRanges(allFilters.dateRange, timezone)
            return {
                account_name: userMe?.user?.account?.name,
                start_date: dates.startDate,
                end_date: dates.endDate,
                channels: allFilters.channels,
                products: allFilters.products,
                source_status: allFilters.status,
                sender_name: allFilters.senderName,
                page: +allFilters.page,
                is_recipient_number_used: allFilters.recipients.length,
                is_admin: userMe?.user?.isAdminLevel

            }
        }
    )


    //updaters
    readonly sortingChange = this.updater((state, value: {sortBy: string, sortDir: SortingDirection}) => {
        return {...state, ...value, page: '1'}
    } )

    readonly sortingChangeWithReload = this.updater((state, value: {sortBy: string, sortDir: SortingDirection}) => {
        return {...state, ...value, page: '1'}
    } )

    readonly clearAdvancedFilters = this.updater((state) => {
        return {...state, senderName: '', senderNumber: '', status: '', customerReason: ''}
    })

    //effects
    readonly reload = this.effect((trigger$) => {
        return trigger$.pipe(
            // tap((sortData: any) => this.patchState({reloadType: 'immidiate'}))
        )
    })

    readonly reloadOnQueryParamChange = this.effect((trigger$) => {
        return this.allFilters$.pipe(
            withLatestFrom(
                this.selectUserTimezone$,
                this.autoload$,
                this.trackParams$,
            ),
            filter(([_1,_2, autoload]) => autoload),
            tap(([filters, timezone, _, trackParams]) => {
                this.patchState({autoload: false})
                this.messageLogsFetchStore.fetchMessageLogs({
                    query: this.prepareQueryParams(filters, timezone),
                    track: trackParams
                })
            })
        )
    })

    private prepareQueryParams(filters, timezone: string): ApiMessageLogsListParams {
        const limit = Number(filters.limit)
        const page = Number(filters.page)
        const offset = (page - 1) * limit
        return {
            offset,
            limit,
            ...mapDatePickerValuesToDateRanges(filters.dateRange, timezone),
            accountId: filters.accountId,
            ...omitBy({
                campaignId: filters.campaignId,
                campaignStatus: filters.campaignStatus,
                customerStatus: filters.status,
                channels: filters.channels,
                products: filters.products,
                messageType: filters.messageType, // TODO: currently only null is possible, in future -> state.messageType,
                senderName: filters.senderName,
                senderNumber: filters.senderNumber,
                recipient: filters.recipients,
                customerReason: filters.customerReason,
                templates: filters.templates,
                buttons: filters.buttons,
                links: filters.links,
                locations: without(filters.locations.split('__'), ""),
                ...mapSortingOptionsToFilterParams(filters.sortBy, filters.sortDir),
            }, (v) => v === null || v === undefined || v === '' || v.length === 0)
        }

    }
}
