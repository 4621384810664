import { graphql } from 'msw'



function r(){
    return Number((Math.random() * 10).toFixed())
}

function prepareXAxis(date){
  if(date === 'hour_6'){
    return ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00']
  }
  if(date === 'hour_12'){
    return ['7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',  '16:00', '17:00', '18:00']
  }
  if(date === 'week_1'){
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  }

  if(date === 'month_1'){
      return ['Week 1', 'Week 2', 'Week 3', 'Week 4']
  } 

  return []
}

function prepareData(xAxis){
  return xAxis.map((xa, idx) => {
    return  {x: idx, y: r()}
  })
}

export const getDashboardDataGQL = graphql.query('GetDashboardData', (req, res, ctx) => {

  const date = req.variables.date
  const xAxis = prepareXAxis(date)

  return res(
    ctx.data(
      {
      data: [{
          name: 'SMS', 
          data: prepareData(xAxis)
        },
        {
          name: 'Whatsapp',
          data: prepareData(xAxis)
        }, {
          name: 'Voice', 
          data: prepareData(xAxis)
        }, {
          name: 'Facebook', 
          data: prepareData(xAxis)
        }, {
          name: 'Twitter', 
          data: prepareData(xAxis)
        }, {
          name: 'Push notification', 
          data: prepareData(xAxis)
        }
      ],
      xAxis: xAxis
      }
    )
  )
})