import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CreateFromRecipientsStore } from './create-form-recipients.store';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';

@Component({
  selector: 'app-create-form-recipients',
  templateUrl: './create-form-recipients.component.html',
  styleUrls: ['./create-form-recipients.component.scss'],
  providers: [CreateFromRecipientsStore,
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [188,32]
      }
    }
  ]
})
export class CreateFormRecipientsComponent implements OnInit, ControlValueAccessor {
  onChange: any = () => {}
  onTouch: any = () => {}
  value: string = ''

  constructor(
    public ngControl: NgControl,
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {

  }

  writeValue(value: string | undefined){
    if(value){
      this.value = value
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onRecipientChange(event: any){
    if(!this.ngControl.touched){
      this.onTouch()
    }
    this.onChange(event.join(','))
  }


  
}
