import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageDownloadStore } from './page-download.store';

@Component({
  template: '',
  styles: [],
  providers: [PageDownloadStore]
})
export class PageDownloadComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private store: PageDownloadStore

  ) { }

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams

    this.store.downloadReport(params)
  }

}
