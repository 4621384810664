
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {

}

const initialState: State = {
}

@Injectable()
export class GeneralFiltersFormStore extends ComponentStore<State>{
    constructor(
        private formStore: FiltersFormStore
    ){
        super(initialState)
    }

    vm$ = this.select(
        this.formStore.channels$,
        this.formStore.products$,
        this.formStore.accountId$,
        this.formStore.dateRange$,
        this.formStore.recipients$,
        (channels, products, accountId, dateRange, recipients) => {
            return {
                channels,
                products,
                accountId,
                dateRange,
                recipients

            }
        }
            
        
    )
    
}