import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { QueryPayload } from "../data-access.model";
import { DEFAULT_OUTPUT } from "./customer-reason.graphql";
import { CustomerReasonRepository } from "./customer-reason.repository";


type State = {
    loading: boolean
    loaded: boolean
    entities: any[]
    count: number
    lastFetchTimestamp: number
}

const initialState: State = {
    loading: false,
    loaded: false,
    entities: [],
    count: 0,
    lastFetchTimestamp: 0
}

type QueryParams = {
}

@Injectable()
export class CustomerReasonFetchStore extends ComponentStore<State>{
    constructor(
        private repository: CustomerReasonRepository,
    ){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)
    count$ = this.select(state => state.count)
    lastFetchTimestamp$ = this.select(state => state.lastFetchTimestamp)


    //effect
    readonly fetch = this.effect((payload$: Observable<QueryPayload<QueryParams>>) => {
        return payload$.pipe(
            tap((v) => {
                this.patchState({loading: true})
            }),
            switchMap(({variables, output}) => this.repository.gqlQueryCustomerReason(variables, output || DEFAULT_OUTPUT).pipe(
                tapResponse(
                    (response) => {
                        this.patchState({
                            entities: [...response.entries],
                            loading: false,
                            count: response.count
                        })
                    },
                    () => {}
                )
            ))
        )
    })
}


