export type CreateReportBody = {
    name: string
    products: string
    channels: string
    reportType: string
    date: string
    accountId: string
    notification: string
    campaignId?: string
    search?: string
    recipient?: string
    recurringType?: string,
    sheduledReport?: string
}

export type CreateReportApiParams = {
    TimeFrom: string
    TimeTo: string
    ReportName: string
    AccountUUID: string
    Products?: string
    Channels?: string
    UserID?: string
    NotifyBySms?: string
    NotifyByEmail?: string
    CampaignId?: string
    ReportType: string
    Search?: string
    Recipient?: string
    RecurringType?: string
    ScheduledReport?: string
}

export type ApiAggregateReports = {
    channels: string,
    createOn: null,
    products: string,
    reportFileName: string,
    reportId: string,
    reportName: string,
    reportParams: string,
    status: string,
    extraConfigSet: {key: string, value: string}[]
}

export type ViewAggregateReports = ApiAggregateReports & {
    productsNumber: number,
    channelsNumber: number
}

export type ApiAggregateReportsParams = {
    orderBy?: {
        field: string
        direction: 'ASC' | 'DESC'
    }
}

export enum ReportType {
    TRAFFIC = 'TRAFFIC',
    CAMAPIGN = 'CAMPAIGN'
}
