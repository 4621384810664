import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { delay, distinctUntilChanged, map, skip, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { Products } from "src/app/shared/store-mocks/products.store";
import { UniAnalyticsService, UniAuthFacade, UniSnackbarFacade, UserMe, momentTimezone } from "unifonic-spa-common";
import { ReportsRepository } from "../../data-access/reports.repository";
import { CreateReportBody } from "../../reports.models";
import { mapCreateFormBodyToRequestParams } from "../../utils/map-create-form-body-to-request-params";
import { Channels } from "src/app/shared/store-mocks/channels.store";
import { MixpanelEvents } from "src/app/app.models";



type State = {
    templates: {value: string, label: string, description:string}[]
    loading: boolean

    formName: string,
    formChannels: string
    formProducts: string
    formTemplate: string
    formDate: string
    formAccountId: string
    formCampaignId: string
    formNotification: string
    formKewords: string
    formRecipients: string
    formScheduledReport: boolean
    formRecurringType: number
    selectedCampaignName: string
    formDeliveryDate: string
    formDeliveryTime: string,
    formRecurringDateRange: string
    formReportFrequency: string
    formReportFrequencyMonth: string
    formReportFrequencyWeekDay: string
    formReportFrequencyMonthDay: string
    formReportFrequencyTime: string
    formReportOneTimeDateRange: string
    formReportOneTimeDateRangeFrom: string
    formReportOneTimeDateRangeTo: string
}

const initialState: State = {
    templates: [{
        value: 'TRAFFIC',
        label: 'ci.common.trafficReport',
        description: 'ci.common.trafficReportDescription',
    },{
        value: 'CAMPAIGN',
        label: 'ci.common.campaignsReport',
        description: 'ci.common.campaignsReportDescription',
    },{
        value: 'FINANCIAL',
        label: 'ci.common.financialReport',
        description: 'ci.common.financialReportDescription',
    },{
        value: 'BALANCE',
        label: 'ci.common.balanceReport',
        description: 'ci.common.balanceReportDescription',
    },{
        value: 'CONVERSATION',
        label: 'ci.common.whatsappReport',
        description: 'ci.common.whatsappReportDescription',
    }],
    loading: false,

    formName: '',
    formChannels: 'SMS',
    formProducts: 'ALL',
    formTemplate: '',
    formDate: '',
    formAccountId: '',
    formCampaignId: '',
    formNotification: '',
    selectedCampaignName: '',
    formKewords: '',
    formRecipients: '',
    formScheduledReport: false,
    formRecurringType: 1,
    formDeliveryDate: '',
    formDeliveryTime: '',
    formRecurringDateRange: '',
    formReportFrequency: '',
    formReportFrequencyMonth: '',
    formReportFrequencyWeekDay: '',
    formReportFrequencyMonthDay: '',
    formReportFrequencyTime: '',
    formReportOneTimeDateRange: '',
    formReportOneTimeDateRangeFrom: '',
    formReportOneTimeDateRangeTo: ''

}

@Injectable()
export class CreateFormStore extends ComponentStore<State>{
    constructor(
        private reportsRepository: ReportsRepository,
        private authFacade: UniAuthFacade,
        private router: Router,
        private snackbar: UniSnackbarFacade,
        private translate: TranslateService,
        private analytics: UniAnalyticsService
    ){
        super({...initialState})
    }

    //selectors
    readonly loading$ = this.select((state) => state.loading)
    readonly selectedCampaignName$ = this.select(state => state.selectedCampaignName)

    readonly formName$ = this.select(state => state.formName)
    readonly formChannels$ = this.select(state => state.formChannels)
    readonly formProducts$ = this.select(state => state.formProducts)
    readonly formTemplate$ = this.select(state => state.formTemplate)
    readonly formDate$ = this.select(state => state.formDate)
    readonly formAccountId$ = this.select(state => state.formAccountId)
    readonly formNotification$ = this.select(state => state.formNotification)
    readonly formCampaignId$ = this.select(state => state.formCampaignId)
    readonly templates$ = this.select(state => state.templates)
    readonly formKeywords$ = this.select(state => state.formKewords)
    readonly formRecipients$ = this.select(state => state.formRecipients)
    readonly formScheduledReport$ = this.select(state => state.formScheduledReport)
    readonly formRecurringType$ = this.select(state => state.formRecurringType)
    readonly formDeliveryDate$ = this.select(state => state.formDeliveryDate)
    readonly formDeliveryTime$ = this.select(state => state.formDeliveryTime)
    readonly formRecurringDateRange$ = this.select(state => state.formRecurringDateRange)
    readonly formReportFrequency$ = this.select(state => state.formReportFrequency)
    readonly formReportFrequencyMonth$ = this.select(state => state.formReportFrequencyMonth)
    readonly formReportFrequencyWeekDay$ = this.select(state => state.formReportFrequencyWeekDay)
    readonly formReportFrequencyMonthDay$ = this.select(state => state.formReportFrequencyMonthDay)
    readonly formReportFrequencyTime$ = this.select(state => state.formReportFrequencyTime)
    readonly formReportOneTimeDateRange$ = this.select(state => state.formReportOneTimeDateRange)
    readonly formReportOneTimeDateRangeFrom$ = this.select(state => state.formReportOneTimeDateRangeFrom)
    readonly formReportOneTimeDateRangeTo$ = this.select(state => state.formReportOneTimeDateRangeTo)


    readonly userInfo$ = this.select(
        this.authFacade.userMe$,
        (userMe) => {
            return {
                timezone: userMe?.user?.timezone,
                userId: userMe?.user?.id,
                accountId: userMe?.user?.account?.id,
                isAdmin: userMe?.user.isAdminLevel,
                accountName: userMe?.user?.account?.name,

            }
        }
    )

    readonly campaignExtensionVisibility$ = this.select(
        this.formProducts$,
        (product) => product === Products.CAMPAIGNS
    )

    readonly availableTemplates$ = this.select(
        this.templates$,
        this.formChannels$,
        this.formProducts$,
        this.authFacade.featureFlags$,
        (templates, channels, products, featureFlags) => {
            return templates.filter((ve: any) => {
                switch(ve.value){
                    case "TRAFFIC":
                        return (channels === Channels.SMS || channels === Channels.ALL)
                            && (products == Products.ALL || products === Products.API_SMPP)

                    case "CAMPAIGN":
                        return products === Products.CAMPAIGNS
                            && (channels === Channels.SMS || channels === Channels.ALL || channels === Channels.WHATSAPP)

                    case "FINANCIAL":
                        return featureFlags['2023-q-1-ci-financial-report']
                            && products === Products.ALL
                            && (channels === Channels.SMS || channels === Channels.ALL)

                    case "BALANCE":
                        return featureFlags['2024-q-1-ci-balance-report']
                            && products === Products.ALL
                            && (channels === Channels.SMS || channels === Channels.ALL)

                    case "CONVERSATION":
                        return channels === Channels.WHATSAPP
                            && products === Products.ALL
                }
                return true
            })
        }
    )

    readonly textSearchVisibility$ = this.select(
        this.authFacade.featureFlags$,
        this.formTemplate$,
        (featureFlags, formTemplate) => {
            return formTemplate === "TRAFFIC" && featureFlags['2024-q-2-ci-reports-text-search']
        }
    )

    readonly scheduledReportsVisibility$ = this.select(
        this.authFacade.featureFlags$,
        (featureFlags) => {
            return featureFlags['2024-q-2-ci-reports-scheduled-reports']
        }
    )

    readonly vm$ = this.select(
        this.loading$,
        this.campaignExtensionVisibility$,
        this.formName$,
        this.formChannels$,
        this.formProducts$,
        this.formTemplate$,
        this.formDate$,
        this.formAccountId$,
        this.formCampaignId$,
        this.formNotification$,
        this.userInfo$,
        this.availableTemplates$,
        this.formKeywords$,
        this.formRecipients$,
        this.textSearchVisibility$,
        this.scheduledReportsVisibility$,
        this.formScheduledReport$,
        this.formRecurringType$,
        this.formDeliveryDate$,
        this.formDeliveryTime$,
        this.formRecurringDateRange$,
        this.formReportFrequency$,
        this.formReportFrequencyMonth$,
        this.formReportFrequencyWeekDay$,
        this.formReportFrequencyMonthDay$,
        this.formReportFrequencyTime$,
        this.formReportOneTimeDateRange$,
        this.formReportOneTimeDateRangeFrom$,
        this.formReportOneTimeDateRangeTo$,
        (
            loading,
            campaignExtensionVisibility,
            formName,
            formChannels,
            formProducts,
            formTemplate,
            formDate,
            formAccountId,
            formCampaignId,
            formNotification,
            userInfo,
            availableTemplates,
            formKeywords,
            formRecipients,
            textSearchVisibility,
            scheduledReportsVisibility,
            formScheduledReport,
            formRecurringType,
            formDeliveryDate,
            formDeliveryTime,
            formRecurringDateRange,
            formReportFrequency,
            formReportFrequencyMonth,
            formReportFrequencyWeekDay,
            formReportFrequencyMonthDay,
            formReportFrequencyTime,
            formReportOneTimeDateRange,
            formReportOneTimeDateRangeFrom,
            formReportOneTimeDateRangeTo
        ) => {
            return {
                loading,
                campaignExtensionVisibility,
                dateRangeVisibility: !campaignExtensionVisibility,
                formName,
                formChannels,
                formProducts,
                formTemplate,
                formDate,
                formAccountId,
                formCampaignId,
                formNotification,
                userInfo,
                availableTemplates,
                formKeywords,
                formRecipients,
                textSearchVisibility,
                formScheduledReport,
                formRecurringType,
                scheduledReportsVisibility,
                formDeliveryDate,
                formDeliveryTime,
                formRecurringDateRange,
                formReportFrequency,
                formReportFrequencyMonth,
                formReportFrequencyWeekDay,
                formReportFrequencyMonthDay,
                formReportFrequencyTime,
                formReportOneTimeDateRange,
                formReportOneTimeDateRangeFrom,
                formReportOneTimeDateRangeTo
            }
        }, {debounce: true}
    )

    //updaters
    readonly setLoading = this.updater((state, loading: boolean) => {
        return {...state, loading}
    })


    // effects
    readonly createReport = this.effect( (data$: Observable<CreateReportBody>) => {
        return data$.pipe(
            tap((data) => {
                this.patchState({loading: true})
            }),
            withLatestFrom(
                this.userInfo$,
                this.selectedCampaignName$

            ),

            tap(([data, userInfo, campaignName]) => {

                const params = mapCreateFormBodyToRequestParams(data, userInfo.userId, userInfo.timezone, campaignName)

                this.analytics.mixpanelTrack(MixpanelEvents.reportCreation, {
                    is_admin: userInfo.isAdmin,
                    timezone: userInfo.timezone,
                    account_name: userInfo.accountName,
                    products: params.Products,
                    channels: params.Channels,
                    ci_campaign_id: params.CampaignId,
                    report_type: params.ReportType,
                    search: params.Search,
                    recurring_type: params.RecurringType === '1' ? 'onetime' : 'recurring',
                    scheduled_reports: params.ScheduledReport === 'true' ? true : false
                })
            }),
            map(([data, userInfo, campaignName]) => ({
                data: mapCreateFormBodyToRequestParams(data, userInfo.userId, userInfo.timezone, campaignName),
                userInfo,
                campaignName
            }) ),
            switchMap(({data, userInfo}) => this.reportsRepository.createReport(data, userInfo.timezone).pipe(
                tapResponse(
                    () => {
                        this.patchState({loading: false})
                        this.snackbar.show('success', this.translate.instant('ci.common.reportCreationSuccessMessage'))
                        this.router.navigate(['/', 'insights', 'reports'])
                    },
                    ()=> {
                        this.patchState({loading: false})
                        this.snackbar.show('error', this.translate.instant('ci.common.httpRequestErrorMessage'))
                    }
                )
            ))
    )})


    readonly setDefaultDate = this.effect( (trigger$: Observable<any>) => {
        return trigger$.pipe(
            withLatestFrom(this.userInfo$.pipe(map(ui => ui.timezone))),
            tap(([fn, timezone]) => {
                fn(timezone)
            }))
    } )


    readonly resetReportType = this.effect( (fn$: Observable<any>) => {
        return this.campaignExtensionVisibility$.pipe(
            withLatestFrom(fn$),
            tap(([_, fn]) => {
                fn()
            }))
    } )


    readonly setDefaultFormValues = this.effect((trigger$: Observable<void>) => {
        return trigger$.pipe(
            withLatestFrom(this.authFacade.userMe$),
            tap(([_, userMe]: [any, UserMe]) => {
                const timezone = userMe.user.timezone

                const timezoneBase = momentTimezone.tz(timezone).format('YYYY-MM-DDTHH:mm:ss') + 'Z'

                const from = momentTimezone.tz(timezoneBase, 'utc').startOf('month').startOf('day').format()
                const to = momentTimezone.tz(timezoneBase, 'utc').startOf('day').format()

                this.patchState({
                    formAccountId: userMe?.user?.account?.id,
                    formName: this.translate.instant('ci.common.reportForDate', {
                        date: momentTimezone.tz(timezone).format('DD-MM-YYYY')
                      }),
                    formDate: `${from}_${to}`,
                })
            })
        )
    })


    readonly setFirstTemplate = this.effect(() => {
        return this.availableTemplates$.pipe(
                tap((templates: any[]) => {
                    this.patchState({
                        formTemplate: templates[0]?.value || ''
                    })
                })
            )
    })
}
