import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createCampaignListQuery } from './campaign-list.graphql';
import { CampaignListApiResponse } from './campaign-list.model';

@Injectable()
export class CampaignListRepository {

    constructor(
        private apollo: Apollo
    ){}

    gqlQueryCampaignList(variables, output: string): Observable<CampaignListApiResponse['findAllCampaignsByAccountID']> {
      return this.apollo.query<CampaignListApiResponse>({
        query: createCampaignListQuery(
            output
        ),
        variables
      })
      .pipe(
          map((response) => {
            return response.data.findAllCampaignsByAccountID
          }) 
      )
    }
}
