import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormPeriodDatePickerPeriodSelectorStore } from './form-period-date-picker-period-selector.store';

@Component({
  selector: 'app-form-period-date-picker-period-selector',
  templateUrl: './form-period-date-picker-period-selector.component.html',
  styleUrls: ['./form-period-date-picker-period-selector.component.scss'],
  providers: [FormPeriodDatePickerPeriodSelectorStore]
})
export class FormPeriodDatePickerPeriodSelectorComponent implements OnInit {

  @Input() currentValue: string

  @Input() set entities(entities: any[]){
    this.store.setEntities(entities)
  }

  @Output() changeDate: EventEmitter<string> = new EventEmitter()

  onChange: any = () => {}
  onTouch: any = () => {}

  vm$ = this.store.vm$

  constructor(
    private store: FormPeriodDatePickerPeriodSelectorStore
  ) {
  }

  ngOnInit(): void {
  }


  dateRangeOptionChange(value: string){
    this.store.setValue(value)
    if(value === '_selectDate'){
      this.store.switchToDateSelector()
    } else {
    }
  }
}
