import { gql } from "apollo-angular"

export const DEFAULT_OUTPUT = `
  accountId
  campaignId
  templateId
  totalMessages
  messagesSent
  messagesRead
  messagesFailed
  messagesDeliveryNotConfirmed
  messagesReceived
  messagesRejected
  messagesSubmittedToWhatsApp
  uLinksClickSummary{
    linkId
    linkName
    percentage
    numClicks
    linkType
    ulinkDomain
    clickStatistics{
        countryCode
        numClicks
        percentage
    }

  }
`

export const createTemplateSummaryQuery = (output = DEFAULT_OUTPUT) => {
    return gql`
        query GetWhatsappCampaignsSummaryByCampaignIdForULink(
            $accountId : String!
            $campaignId : String!
            $templateId : String
            $includeULinkClickSummary : Boolean
        ){
            getWhatsappCampaignsSummaryByCampaignIdForULink(
                accountId : $accountId
                campaignId : $campaignId
                templateId : $templateId
                includeULinkClickSummary : $includeULinkClickSummary
            ){
                success, message, count, errorCode, entries {
                    ${output}
                }
            }
        }
    `
}
