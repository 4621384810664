import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { CampaignStatusMockStore } from '../../../shared/store-mocks/campaign-status.store';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';

@Component({
  selector: 'app-advanced-filters-form-campaign-status',
  templateUrl: './advanced-filters-form-campaign-status.component.html',
  styleUrls: ['./advanced-filters-form-campaign-status.component.scss'],
  providers: [CampaignStatusMockStore]
})
export class AdvancedFiltersFormCampaignStatusComponent implements OnInit, OnDestroy, ControlValueAccessor {

  onChange: any = () => {}
  onTouch: any = () => {}

  campaignStatusValue$ = this.filtersFormStore.campaignStatus$.pipe(map(
    (value: any) => value.length ? value.split(',') : []
  ))
  statusEntities$ = this.campaignStatusMockStore.viewEntities$

  constructor(
    public ngControl: NgControl,
    private filtersFormStore: FiltersFormStore,
    private campaignStatusMockStore: CampaignStatusMockStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  statusValueChange(option: string[]) {
    this.saveValue(option.join(','))
  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
