<uni-label [required]="true">
    {{'ci.common.reportName' | translate}}
</uni-label>
<input
    type="text"
    [(ngModel)]="value"
    (ngModelChange)="onInputChange($event)"
    uniInput
    placeholder="Report Name"
/>
<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    <ng-container *ngIf="ngControl.errors.required">
        {{'ci.common.fieldRequired' | translate}}
    </ng-container>

    <ng-container *ngIf="ngControl.errors.maxlength">
        {{'ci.common.maxLength' | translate}}
    </ng-container>
</uni-form-field-error>
