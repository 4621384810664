
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {
  value: string
}

const initialState: State = {
  value: ''
}

@Injectable()
export class AdvancedFiltersFormCustomerReasonStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
    ){
        super(initialState)
    }

    readonly value$ = this.select(state => state.value)

    readonly onHide = this.effect(() => {
        return this.advancedFiltersFormStore.customerReasonVisibility$.pipe(
            filter(v => !v),
            tap((v) => this.patchState({value: ''}))
        )
    })

    readonly onFormValueChange = this.effect(() => {
        return this.filtersFormStore.customerReason$.pipe(
            tap((v: string) => this.patchState({value: v}))
        )
    })
}
