import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { FrequenciesMockStore } from "src/app/shared/store-mocks/frequencies.store";
import { TimesMockStore } from "src/app/shared/store-mocks/times.store";



type State = {
    timeSelectorVisibility: boolean
}

const initialState: State = {
    timeSelectorVisibility: false
}

@Injectable()
export class CreateFormDeliveryTimeStore extends ComponentStore<State>{
    constructor(
        private timesMockStore: TimesMockStore,
    ){
        super({...initialState})
    }

    //selectors

    readonly entities$ = this.timesMockStore.viewEntities$

    readonly vm$ = this.select(
        this.entities$,
        (entities) => {
            return {
               entities,
               value: ''
            }
        }, {debounce: true}
    )

    //updaters


    // effects

}
