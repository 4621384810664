import { ApiAggregateReportsParams } from "../reports.models"


export const mapSortingOptionsToFilterParams = (sortBy: string, sortDir: 'ASC' | 'DESC' | ''): {orderBy?: ApiAggregateReportsParams['orderBy']} => {
    if(!sortBy || !sortDir){
        return {}
    }

    return {
        orderBy: {
            field: sortBy,
            direction: sortDir
        }
    }
}