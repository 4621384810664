import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map, tap } from "rxjs/operators";
import { DateRangeMockStore } from "src/app/shared/store-mocks/date-range.store";
import { UniAuthFacade } from "@unifonic/common";


type State = {
}

const initialState: State = {
 
}

@Injectable()
export class GeneralFiltersFormDateStore extends ComponentStore<State>{
    constructor(
        private authFacade: UniAuthFacade,
        private dateRangeMockStore: DateRangeMockStore,
    ){
        super(initialState)
    }

    //selectors

    readonly timezone$ = this.authFacade.userMe$.pipe(
        map((user: any) => user.user.timezone)
    )

    readonly entities$ = this.select(
        this.dateRangeMockStore.today$,
        this.dateRangeMockStore.last7d$,
        this.dateRangeMockStore.last30d$,
        this.dateRangeMockStore.this1w$,
        this.dateRangeMockStore.this1m$,
        this.dateRangeMockStore.last1m$,
        this.dateRangeMockStore.selectDate$,
        (...entities) => {
            return [
                ...entities
            ]
        } 
    )

    readonly vm$ = this.select(
        this.timezone$,
        this.entities$,
        (timezone, periods) => {
            return {
                timezone,
                periods
            }
        }
    )

    //updaters

    //effects

    
}

