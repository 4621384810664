import { momentTimezone } from "unifonic-spa-common/src"

export const getVariablesForSearchByPhrase = (phrase, timezone: string, accountId: string) => {

    const additionalVariables = phrase.length <= 2 ? {
        startDate: momentTimezone.tz(timezone).subtract(1, 'month'),
        endDate: momentTimezone.tz(timezone),
    } : {}

    return {
        campaignNamePhrase: phrase,
        accountId: accountId,
        ...additionalVariables
    }
}
