<uni-label>
    {{'ci.common.senderName' | translate}}
</uni-label>

<app-autocomplete-multiselect
  [currentValue]="value"
  [options]="senderNames$ | async"
  [loading]="senderNamesLoading$ | async"
  [noResoultsLabel]="'ci.common.noResults' | translate"
  [placeholder]="'ci.common.senderName' | translate"
  [searchPlaceholder]="'search for sender names'"
  (valueChange)="onValueChange($event)"
></app-autocomplete-multiselect>

<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>
