import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map } from "rxjs/operators";
import { AccountsFacade } from "unifonic-spa-common/src";

type State = {}

const initialState: State = {}

@Injectable()
export class AccountListStore extends ComponentStore<State>{
    constructor(
        private accountsFacade: AccountsFacade
    ){
        super(initialState)
    }

    //selectors
    accounts$ = this.accountsFacade.accounts$.pipe(
        map((accounts => accounts || [])),
        map((accounts) => {
          return accounts.map(a => ({label: a.name, value: a.id}) )
        })
    )

    loading$ = this.accountsFacade.isLoading$
}