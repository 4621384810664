import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormPeriodDatePickerComponent } from './form-period-date-picker.component';
import { FormPeriodDatePickerPeriodSelectorComponent } from './form-period-date-picker-period-selector/form-period-date-picker-period-selector.component';
import { FormPeriodDatePickerDateSelectorComponent } from './form-period-date-picker-date-selector/form-period-date-picker-date-selector.component';
import { UniCommonModule } from 'unifonic-spa-common/src';
import { FormSingleSelectModule } from '../form-single-select/form-single-select.module';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';


@NgModule({
  declarations: [
    FormPeriodDatePickerComponent,
    FormPeriodDatePickerPeriodSelectorComponent,
    FormPeriodDatePickerDateSelectorComponent],
  exports: [FormPeriodDatePickerComponent],
  imports: [
    CommonModule,
    UniCommonModule,
    FormSingleSelectModule,
    MatMomentDateModule
  ],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ]
})
export class FormPeriodDatePickerModule { }
