<uni-label>
    {{'ci.common.messageType' | translate}}
</uni-label>
<app-form-single-select
    (valueChange)="messageTypeOptionChange($event)"
    [options]="messageTypeEntities$ | async"
    [value]="messageTypeValue$ | async"
    [placeholder]="'ci.common.messageType' | translate"
></app-form-single-select>
<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>