<ng-container *ngIf="vm$ | async as vm">
    <uni-label [required]="true">
        {{'ci.common.selectMonth' | translate}}
    </uni-label>
    <app-form-single-select
        (valueChange)="frequencyMonthChange($event)"
        [value]="vm.value"
        [options]="vm.entities"
        [canClear]="false"
        [placeholder]="'ci.common.selectMonth' | translate"
    ></app-form-single-select>
</ng-container>