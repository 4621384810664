import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { PageIndexComponent } from './page-index/page-index.component';
import { CreateFormComponent } from './containers/create-form/create-form.component';
import { CreateFormNameComponent } from './containers/create-form-name/create-form-name.component';
import { CreateFormChannelsComponent } from './containers/create-form-channels/create-form-channels.component';
import { CreateFormProductsComponent } from './containers/create-form-products/create-form-products.component';
import { CreateFormTemplatesComponent } from './containers/create-form-templates/create-form-templates.component';
import { CreateFormDateRangeComponent } from './containers/create-form-date-range/create-form-date-range.component';
import { CreateFormAccountIdComponent } from './containers/create-form-account-id/create-form-account-id.component';
import { CreateFormNotificationComponent } from './containers/create-form-notification/create-form-notification.component';
import { UniCommonModule } from 'unifonic-spa-common/src';
import { PageReportsListComponent } from './page-reports-list/page-reports-list.component';
import { ReportsDataTableComponent } from './containers/reports-data-table/reports-data-table.component';
import { FormCardSelectorModule } from '../shared/form-card-selector/form-card-selector.module';
import { CreateFormForewordComponent } from './containers/create-form-foreword/create-form-foreword.component';
import { ReportsFiltersStore } from './store/reports-filters.store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsRepository } from './data-access/reports.repository';
import { ReportsDateTableDetailsComponent } from './containers/reports-date-table-details/reports-date-table-details.component';
import { ReportsDataTableDeleteComponent } from './containers/reports-data-table-delete/reports-data-table-delete.component';
import { ReportsDataTableDownloadComponent } from './containers/reports-data-table-download/reports-data-table-download.component';
import { ReportsDataTableOpenComponent } from './containers/reports-data-table-open/reports-data-table-open.component';
import {MatMenuModule} from '@angular/material/menu';
import {OverlayModule} from '@angular/cdk/overlay';
import { CreateFormCampaignIdComponent } from './containers/create-form-campaign-id/create-form-campaign-id.component';
import { PageDownloadComponent } from './page-download/page-download.component';
import { ChannelProductCombinationValidator } from '../shared/utils/custom-validators';
import { CreateFormKeywordsComponent } from './containers/create-form-keywords/create-form-keywords.component';
import { CreateFormRecipientsComponent } from './containers/create-form-recipients/create-form-recipients.component';
import { CreateFormScheduleToggleComponent } from './containers/create-form-schedule-toggle/create-form-schedule-toggle.component';
import { CreateFormRecurringTypeComponent } from './containers/create-form-recurring-type/create-form-recurring-type.component';
import { CreateFormDateRangeSelectorComponent } from './containers/create-form-date-range-selector/create-form-date-range-selector.component';
import { CreateFormDateRangeRecurringComponent } from './containers/create-form-date-range-recurring/create-form-date-range-recurring.component';
import { CreateFormDateRangeRecurringNDaysComponent } from './containers/create-form-date-range-recurring-n-days/create-form-date-range-recurring-n-days.component';
import { CreateFormFrequencyComponent } from './containers/create-form-frequency/create-form-frequency.component';
import { CreateFormFrequencyMonthComponent } from './containers/create-form-frequency-month/create-form-frequency-month.component';
import { CreateFormFrequencyTimeComponent } from './containers/create-form-frequency-time/create-form-frequency-time.component';
import { CreateFormFrequencyWeekDayComponent } from './containers/create-form-frequency-week-day/create-form-frequency-week-day.component';
import { CreateFormFrequencyMonthDayComponent } from './containers/create-form-frequency-month-day/create-form-frequency-month-day.component';
import { CreateFormDeliveryDateComponent } from './containers/create-form-delivery-date/create-form-delivery-date.component';
import { CreateFormDeliveryTimeComponent } from './containers/create-form-delivery-time/create-form-delivery-time.component';
import { CreateFormDateRangeSelectorFromComponent } from './containers/create-form-date-range-selector-from/create-form-date-range-selector-from.component';
import { CreateFormDateRangeSelectorToComponent } from './containers/create-form-date-range-selector-to/create-form-date-range-selector-to.component';

@NgModule({
  declarations: [
    PageIndexComponent,
    CreateFormComponent,
    CreateFormNameComponent,
    CreateFormChannelsComponent,
    CreateFormProductsComponent,
    CreateFormTemplatesComponent,
    CreateFormDateRangeComponent,
    CreateFormAccountIdComponent,
    CreateFormNotificationComponent,
    PageReportsListComponent,
    ReportsDataTableComponent,
    CreateFormForewordComponent,
    ReportsDateTableDetailsComponent,
    ReportsDataTableDeleteComponent,
    ReportsDataTableDownloadComponent,
    ReportsDataTableOpenComponent,
    CreateFormCampaignIdComponent,
    PageDownloadComponent,
    ChannelProductCombinationValidator,
    CreateFormKeywordsComponent,
    CreateFormRecipientsComponent,
    CreateFormScheduleToggleComponent,
    CreateFormRecurringTypeComponent,
    CreateFormDateRangeSelectorComponent,
    CreateFormDateRangeRecurringComponent,
    CreateFormDateRangeRecurringNDaysComponent,
    CreateFormFrequencyComponent,
    CreateFormFrequencyMonthComponent,
    CreateFormFrequencyTimeComponent,
    CreateFormFrequencyWeekDayComponent,
    CreateFormFrequencyMonthDayComponent,
    CreateFormDeliveryDateComponent,
    CreateFormDeliveryTimeComponent,
    CreateFormDateRangeSelectorFromComponent,
    CreateFormDateRangeSelectorToComponent
  ],
  imports: [
    CommonModule,
    UniCommonModule,
    ReportsRoutingModule,
    FormCardSelectorModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    OverlayModule,
  ],
  providers: [
    ReportsFiltersStore,
    ReportsRepository,
    ChannelProductCombinationValidator
  ]
})
export class ReportsModule { }
