import { Component, OnInit } from '@angular/core';
import { CreateFormStore } from './create-form.store';
import { omitBy } from 'lodash';


@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss'],
  providers: [CreateFormStore]
})
export class CreateFormComponent implements OnInit {

  constructor(
    private store: CreateFormStore
  ) { }

  vm$ = this.store.vm$.pipe()


  ngOnInit(): void {
      this.store.setDefaultFormValues()
  }

  generateReport(form: any){
    if(form.valid){
      const value = form.value;

      const data = {
        channels: value.combination.channels,
        products: value.combination.products,
        accountId: value.accountId,
        date: value.date || '',
        name: value.name,
        notification: value.notification,
        reportType: value.template,
        recipient: value.recipients,
        search: value.keywords
      }

      if(value.campaignId){
        data['campaignId'] = value.campaignId
      }

      const scheduledReport = omitBy({
        recurringType: value.recurringType,
        sheduledReport: value.sheduledReport
      }, v => typeof v === 'undefined')

      this.store.createReport({...data, ...scheduledReport})
    }
  }


  selectCampaign(campaign: any){
    this.store.patchState({selectedCampaignName: campaign.label})
  }

  selectAccountId(accountId: any){
    this.store.patchState({formAccountId: accountId})
  }

  changeChannel(value: string){
    this.store.patchState({formChannels: value})
  }

  changeProducts(value: string){
    this.store.patchState({formProducts: value})
  }

  changeTemplate(value: string){
    this.store.patchState({formTemplate: value})
  }

  changeRecurringType(value: number){
    this.store.patchState({formRecurringType: value})
  }

  changeScheduledReport(value: boolean){
    this.store.patchState({formScheduledReport: value})
  }

  changeDeliveryDate(value: string){
    this.store.patchState({formDeliveryDate: value})
  }

  changeDeliveryTime(value:string){
    this.store.patchState({formDeliveryTime: value})
  }


  changeRecurringDateRange(value: string){
    this.store.patchState({
      formRecurringDateRange: value
    })
  }

  changeFrequency(value: string){
    this.store.patchState({
      formReportFrequency: value
    })

  }
  changeFrequencyMonth(value: string){
    this.store.patchState({
      formReportFrequencyMonth: value
    })
  }

  changeFrequencyWeekDay(value: string){
    this.store.patchState({
      formReportFrequencyWeekDay: value
    })
  }

  changeFrequencyMonthDay(value: string){
    this.store.patchState({
      formReportFrequencyMonthDay: value
    })
  }

  changeFrequencyTime(value: string){
    this.store.patchState({
      formReportFrequencyTime: value
    })
  }


  changeOneTimeDateRange(value: string){
    this.store.patchState({
      formReportOneTimeDateRange: value
    })
  }
  changeOneTimeDateRangeFrom(value: string){
    this.store.patchState({
      formReportOneTimeDateRangeFrom: value
    })
  }
  changeOneTimeDateRangeTo(value: string){
    this.store.patchState({
      formReportOneTimeDateRangeTo: value
    })
  }

}
