<ng-container *ngIf="vm$ | async as vm">
    <div class="row">
      <div class="col-12">
        <app-general-filters-form-channels [ngModel]="vm.channels" required name="channels" (ngModelChange)="changeChannels($event)"></app-general-filters-form-channels>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-general-filters-form-products [ngModel]="vm.products" required name="products" (ngModelChange)="changeProducts($event)"></app-general-filters-form-products>
      </div>
    </div>
    <div class="row">
      <div class="col-4 col-sm-12">
        <app-general-filters-form-account [ngModel]="vm.accountId" required name="accountId" (ngModelChange)="changeAccountId($event)"></app-general-filters-form-account>
      </div>
      <div class="col-4 col-sm-12">
        <app-general-filters-form-date [ngModel]="vm.dateRange" required name="dateRange" (ngModelChange)="changeDateRange($event)"></app-general-filters-form-date>
      </div>
      <div class="col-4 col-sm-12">
        <app-general-filters-form-recipients [ngModel]="vm.recipients" name="recipients" (ngModelChange)="changeRecipients($event)"></app-general-filters-form-recipients>

      </div>
    </div>
  </ng-container>
