<ng-container *ngIf="vm$ | async as vm">
    <div class="datepicker">
        <uni-icon
            name="arrow-left"
            isBig
            (click)="selectFromList()"
        ></uni-icon>
        <uni-datepicker [datepicker]="datepicker">
            <mat-date-range-input
                [rangePicker]="datepicker"
                [formGroup]="dateForm"
            >
                <input
                    [placeholder]="vm.dateRangeLabel"
                    formControlName="from"
                    autocomplete="off"
                    uniInput
                    matStartDate
                    readonly
                >
                <input
                    formControlName="to"
                    autocomplete="off"
                    uniInput
                    matEndDate
                    readonly
                    (dateChange)="changeEndDate($event)"
                >
            </mat-date-range-input>

            <mat-date-range-picker #datepicker [maxRange]="30"></mat-date-range-picker>
        </uni-datepicker>
    </div>
</ng-container>