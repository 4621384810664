import { Component, Input, OnInit } from '@angular/core';
import { MessageLogsTableDetailsWhatsappStore } from './message-logs-table-details-whatsapp.store';

@Component({
  selector: 'app-message-logs-table-details-whatsapp',
  templateUrl: './message-logs-table-details-whatsapp.component.html',
  styleUrls: ['./message-logs-table-details-whatsapp.component.scss'],
  providers: [MessageLogsTableDetailsWhatsappStore]
})
export class MessageLogsTableDetailsWhatsappComponent implements OnInit {

  @Input() set messageId(messageId: string){
    this.store.patchState({messageId})
  }

  vm$ = this.store.vm$

  constructor(
    private store: MessageLogsTableDetailsWhatsappStore
  ) { }

  ngOnInit(): void {
    this.store.fetchData()
  }
}
