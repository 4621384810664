<ng-container *ngIf="vm$ | async as vm">
    <app-raised-button
        (click)="openOptions()"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
    >
        <i class="far fa-download" *ngIf="!vm.downloading"></i>
        <i class="far fa-spin fa-spinner" *ngIf="vm.downloading"></i>
    </app-raised-button>


    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="closeOptions()"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="vm.open"
    >
        <ul class="type-list">
            <li
                *ngFor="let type of vm.types"
            >
                <button (click)="downloadReport(type.value)">
                    <app-span>{{type.translationKey | translate}}</app-span>
                </button>
            </li>
        </ul>
  </ng-template>
</ng-container>
