import { omitBy } from "lodash"
import moment from "moment"
import { Products } from "src/app/shared/store-mocks/products.store"
import { mapDatepickerValueToTimzoneFormattedValue } from "src/app/shared/utils/map-date-picker-values-to-date-ranges"
import { CreateReportApiParams, CreateReportBody, ReportType } from "../reports.models"

/**
TODO: Things to fix on the backend
1. Post Request with query params without any reason
2. params name starts from capital letter
3. params name with white spaces
4. userID is an int32 on backend, while string is returned in the userMe query (commented for now)
 */
export const mapCreateFormBodyToRequestParams = (body: CreateReportBody, userId: string, timezone: string, campaignName: string = ''): Partial<CreateReportApiParams> => {
    const {startDate: TimeFrom, endDate: TimeTo} = mapDatepickerValueToTimzoneFormattedValue(body.date, timezone)
    const reportBasicName = body.name.replace(/\//g, "-")
    const reportName = !body.campaignId ? reportBasicName : reportBasicName + '_' + campaignName.replace(/\//g, "-")
    return omitBy({
        TimeFrom: body.products === Products.CAMPAIGNS ? '' : TimeFrom,
        TimeTo: body.products === Products.CAMPAIGNS ? '' :  TimeTo,
        ReportName: reportName,
        AccountUUID: body.accountId,
        Products: body.products,
        Channels: body.channels,
        UserID: userId,
        NotifyBySms: (body.notification.indexOf('sms') !== -1).toString(),
        NotifyByEmail: (body.notification.indexOf('email') !== -1).toString(),
        CampaignId: body.campaignId,
        ReportType: body.reportType,
        Search: body.search,
        Recipient: body.recipient,
        
        //@TODO: we still dont know the names of the properties below
        RecurringType: ''+body.recurringType,
        SheduledReport: ''+body.sheduledReport
    },  (v) => v === null || v === undefined || v === '')
}
