import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartCircleMultiStackedComponent } from './chart-circle-multi-stacked.component';
@NgModule({
  declarations: [ChartCircleMultiStackedComponent],
  exports: [ChartCircleMultiStackedComponent],
  imports: [
    HighchartsChartModule
  ]
})
export class ChartCircMultiStackedModule { }
