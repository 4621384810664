import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { MessageLogsResponseType } from "../../log-analyser.models";
import { MessageLogsFetchStore } from "../../store/message-logs-fetch.store";

type State = {
    messageId: string
    elMessageId: string
    isRealTime: boolean
    submitDate: string
}

const initialState: State = {
    messageId: '',
    elMessageId: '',
    isRealTime: false,
    submitDate: ''
}

@Injectable()
export class MessageLogsTableDetailsStore extends ComponentStore<State>{

    constructor(
        private messageLogsFetchStore: MessageLogsFetchStore
    ){
        super(initialState)
    }

    //selectors
    readonly messageId$ = this.select(state => state.messageId)
    readonly elMessageId$ = this.select(state => state.elMessageId)
    readonly isRealTime$ = this.select(state => state.isRealTime)
    readonly submitDate$ = this.select(state => state.submitDate)
    
    readonly vm$ = this.select(
        this.messageId$,
        this.elMessageId$,
        this.isRealTime$,
        this.submitDate$,
        this.messageLogsFetchStore.responseType$,
        (messageId, elMessageId, isRealTime, submitDate, type) => {
            return {
                messageId,
                elMessageId,
                isRealTime,
                submitDate,
                type: {
                    sms: type === MessageLogsResponseType.Sms,
                    whatsapp: type === MessageLogsResponseType.Whatsapp
                }
            }
        }
    )
}