import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';

@Component({
  selector: 'app-general-filters-form-recipients',
  templateUrl: './general-filters-form-recipients.component.html',
  styleUrls: ['./general-filters-form-recipients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class GeneralFiltersFormRecipientsComponent implements OnInit, ControlValueAccessor {

  selectedItems$ = this.filtersFormStore.recipients$.pipe(
    tap((entities) => {
        this.saveValue(entities)
    }),
    map((entities) => entities.length ? entities.split(',') : [])
  )

  constructor(
    public ngControl: NgControl,
    private filtersFormStore: FiltersFormStore
  ) {
      ngControl.valueAccessor = this;
    }


  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(): void {}

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onRecipientChange(value: string[]){
    this.saveValue(value.join(','))
  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }
}
