import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import moment from 'moment';



export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-create-form-date-range-selector-from',
  templateUrl: './create-form-date-range-selector-from.component.html',
  styleUrls: ['./create-form-date-range-selector-from.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class CreateFormDateRangeSelectorFromComponent implements OnInit, ControlValueAccessor {

  constructor(
    public ngControl: NgControl,
  
  ) { 
    ngControl.valueAccessor = this;
  }

  value: any = ''

  onChange: any = () => {}
  onTouch: any = () => {}

  fromDate = moment().subtract(1, 'month')

  ngOnInit(): void {


  }

  writeValue(value: string | undefined){
    if(value){
      this.value = value
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }



  changeFromDate(value: any){
    this.modelChange(value.toISOString())
  }

  modelChange(value: string){
    if(!this.ngControl.touched){
      this.onTouch()
    }

    this.onChange(value)
  }

}
