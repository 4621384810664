
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, tap } from "rxjs/operators";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";
import { combineLatest } from "rxjs";
import { MccCampaignsFetchStore } from "../../store/mcc-campaigns-fetch.store";
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {
    value: string
}

const initialState: State = {
    value: "",
}

@Injectable()
export class AdvancedFiltersFormButtonStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
        private mccCampaignsFetchStore: MccCampaignsFetchStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors

    readonly buttonOptions$ = this.select(
        this.mccCampaignsFetchStore.templateStats$,
        (template: any) => {
          return template?.uLinksClickSummary?.filter(opt => opt.linkType === 'BUTTON')
              .map(opt => ({
              label: opt.linkName,
              value: opt.linkName,
              numberOfRecipients: opt.numClicks
          })) || []
         
        }
      )

      vm$ = this.select(
        this.buttonOptions$,
        this.mccCampaignsFetchStore.templateStatsLoading$,
        (buttonOptions, templateStatsLoading) => {
            return {
                buttonOptions,
                templateStatsLoading
            }
        }
      )
      



    readonly params$ = this.select(
        this.filtersFormStore.campaignId$,
        this.filtersFormStore.templates$,
        this.authFacade.account$.pipe(filter(account => !!account), map((account:any) => account.id)),
        (campaignId, templates, accountId) => {
            return {
                campaignId, templateId: templates, accountId,
                includeULinkClickSummary: true
            }
        }
    )


    fetchStats = this.effect(() => {
        return this.params$.pipe(
            tap((params: any) => {
                //Copy of AMMAMAMAZMMFILE 1|

                if(params.campaignId.length && params.templateId.length && params.accountId.length){
                    this.mccCampaignsFetchStore.fetchWhatsappCampaignStats(params)
                }
                
            })
        )
    })

    // MccCampaignsFetchStore
}