<uni-label>
    {{'ci.common.customerReason' | translate}}
</uni-label>

<app-customer-reason-selector
  [value]="selectedItems$ | async"
  (valueChange)="onValueChange($event)"
></app-customer-reason-selector>

<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>
