import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";

export enum Months {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER',
}

export type MonthsKeys = keyof typeof Months;

type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: string
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [{
        label: 'january',
        value: Months.JANUARY
      },{
        label: 'february',
        value: Months.FEBRUARY
      },{
        label: 'march',
        value: Months.MARCH
      },{
        label: 'april',
        value: Months.APRIL
      },{
        label: 'may',
        value: Months.MAY
      },{
        label: 'june',
        value: Months.JUNE
      },{
        label: 'july',
        value: Months.JULY
      },{
        label: 'august',
        value: Months.AUGUST
      },{
        label: 'september',
        value: Months.SEPTEMBER
      },{
        label: 'october',
        value: Months.OCTOBER
      },{
        label: 'november',
        value: Months.NOVEMBER
      },{
        label: 'december',
        value: Months.DECEMBER
      },
    ]
}

@Injectable()
export class MonthsMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    viewEntities$ = this.select(
      this.entities$,
      (entities) => {
        return entities.map(e => {
          return {
            ...e,
            label: this.translateService.instant('ci.common.' + e.value.toLowerCase())
          }
        })
      }
    )

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}