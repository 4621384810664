<div class="uni-form-multi-autocomplete-controllers">
  <uni-button
    (click)="onApply()"
    [disabled]="isValidationError || isLoading"
  >
    {{ 'action.apply' | translate }}
  </uni-button>
  <uni-button
    class="uni-form-multi-autocomplete-controllers__clear"
    [disabled]="isLoading"
    [style]="'link'"
    (click)="onClear()"
  >
    {{ 'action.clear' | translate }}
  </uni-button>
</div>
