import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { Products, ProductsMockStore } from '../../../shared/store-mocks/products.store';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';

@Component({
  selector: 'app-filter-panel-products',
  templateUrl: './filter-panel-products.component.html',
  styleUrls: ['./filter-panel-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductsMockStore],
})
export class FilterPanelProductsComponent implements OnInit {

  @Output() valueChange: EventEmitter<void> = new EventEmitter()
  
  products$ = this.productsMockStore.viewEntities$.pipe(
    map(products => {
      return products.filter(p => 
        p.value === Products.ALL
        || p.value === Products.CAMPAIGNS
        || p.value === Products.FLOW_STUDIO
        || p.value === Products.API_SMPP) // TODO: temporary, no data available for rest.
    })
  )
  productsCurrentValue$ = this.messageLogsFiltersStore.products$

  constructor(
    private productsMockStore: ProductsMockStore,
    private messageLogsFiltersStore: MessageLogsFiltersStore,
  ) { }

  ngOnInit(): void {
  }

  productValueChange(value: string){
    this.messageLogsFiltersStore.clearAdvancedFilters()
    this.messageLogsFiltersStore.patchState({products: value, autoload: true })
  }
}
