import { gql } from "apollo-angular"

export const DEFAULT_OUTPUT = `campaignName, campaignId`

export const createCampaignListQuery = (output: string = DEFAULT_OUTPUT) => {
    return gql`
            query FindAllCampaignsByAccountID(
                $accountId: String!
                $limit: Int
                $startDate: String
                $endDate: String
                $campaignNamePhrase: String
                $campaignId: String
                $channel: Channel
            ){
                findAllCampaignsByAccountID(
                    accountId: $accountId
                    limit: $limit
                    startDate: $startDate
                    endDate: $endDate
                    campaignNamePhrase: $campaignNamePhrase
                    campaignId: $campaignId
                    channel: $channel
            ){
                success, message, count, errorCode, entries {
                    ${output}
                }

            }
        }
    `
}
