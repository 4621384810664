<ng-container *ngIf="vm$ | async as vm">
    <uni-buttons>
        <uni-button
            type="button"
            [style]="'secondary'"
            [isMargin]="true"
            (click)="downloadReport()"
            [disabled]="vm.disabled"
        >
                {{'ci.logAnalyzer.table.downloadButton' | translate}}
                <uni-icon  *ngIf="!vm.generating" [name]="'download'"></uni-icon>
                <uni-icon  *ngIf="vm.generating" [name]="'spinner fa-spin'"></uni-icon>
        </uni-button>
    </uni-buttons>


      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="vm.popupVisibility"
        [cdkConnectedOverlayWidth]="'50%'"
        [cdkConnectedOverlayMinHeight]="45"
        [cdkConnectedOverlayPanelClass]="'popup-force-center'"
      >
        <div class="info-popup">
            <header>
                <h6>{{'ci.logAnalyzer.infoPopupTitle' | translate}}</h6>
                <i class="fa fa-times" (click)="closePopup()"></i>
            </header>
            <p [innerHTML]="'ci.logAnalyzer.infoPopupContent' | translate: {email: vm.userEmail} "></p>
            <uni-buttons>
                <uni-button
                [isMargin]="true"
                (click)="closePopup()">
                    {{'ci.common.close' | translate}}
                </uni-button>
            </uni-buttons>
        </div>
      </ng-template>


      <div cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="trigger"></div>
</ng-container>
