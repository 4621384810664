<uni-section>
    <uni-title-container>
        <uni-title-item>
            <uni-text-body>
                <a [routerLink]="['/', 'insights', 'reports']"><i class="fa fa-chevron-left"></i></a>
                {{'ci.reports.createReport' | translate}}
            </uni-text-body>
        </uni-title-item>
    </uni-title-container>
</uni-section>
<uni-section>

<app-create-form></app-create-form>