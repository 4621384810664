<uni-label [required]="true">
    {{'ci.common.channels' | translate}}
</uni-label>
<uni-icon
    [isRightSpace]="true"
    name="spinner fa-spin"
    *ngIf="!(channels$ | async).length"
></uni-icon>
<app-form-button-selector
    *ngIf="!!(channels$ | async).length"
    (onDataChange)="onDataChange($event)"
    [data]="channels$ | async"
    [selected]="selectedItems$ | async"
    [mode]="'single'"
    [disabled]="disabled$ | async"
>
</app-form-button-selector>
<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>