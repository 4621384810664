import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { of } from 'rxjs';
import { FormButtonSelectorItem } from 'src/app/shared/form-button-selector/form-button-selector.models';
import { ChannelsMockStore } from 'src/app/shared/store-mocks/channels.store';
import { CreateFormChannelsStore } from './create-form-channels.store';

@Component({
  selector: 'app-create-form-channels',
  templateUrl: './create-form-channels.component.html',
  styleUrls: ['./create-form-channels.component.scss'],
  providers: [ChannelsMockStore, CreateFormChannelsStore]
})
export class CreateFormChannelsComponent implements OnInit, ControlValueAccessor {

  vm$ = this.store.vm$

  constructor(
    public ngControl: NgControl,
    private store: CreateFormChannelsStore
  ) {
    ngControl.valueAccessor = this;
  }

  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(){

  }

  writeValue(value: string | undefined){
    if(value){
      this.store.setSelectedItems(value.split(','))
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onDataChange(data: FormButtonSelectorItem[]){
    if(!this.ngControl.touched){
      this.onTouch()
    }

    const values = data.map(d => d?.value).join(',')
    this.onChange(values)
  }
}

