<ng-container *ngIf="vm$ | async as vm">
    <app-typeahead-select
        [labelValue]="vm.defaultLabel"
        [options]="vm.campaigns"
        [listLoading]="vm.campaignsLoading"
        [noResultMessage]="vm.config.noResultMessage | translate"
        [tooManyItemsMessage]="vm.config.tooManyItemsMessage | translate"
        [placeholder]="vm.config.placeholder | translate"
        [debounceTime]="vm.config.debounceTime"
        [minPhraseLength]="vm.config.minPhraseLength"
        [maxOptionsSize]="vm.maxSize"
        (valueChange)="onValueChange($event)"
        (typeahead)="onTypeaheadEvent($event)"
        (onListClose)="onOptionsClose()"
        >
    </app-typeahead-select>
</ng-container>
  
