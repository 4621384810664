import { Channels } from "../../shared/store-mocks/channels.store"
import { MessageLogsResponseType } from "../log-analyser.models"

export const mapResponseTypeToVisibleColumns = (responseType: MessageLogsResponseType): any  => {
    if (responseType === MessageLogsResponseType.Whatsapp) {
      return [
        'sender',
        'product',
        'recipient',
        'sentAt',
        'status',
        'action'
      ]
    } else {
      return [
        'sender',
        'recipient',
        'date',
        'status',
        'action'
      ]
    }
}
