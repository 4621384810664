import { graphql, rest } from 'msw'

export const getCampaignGQL = graphql.query('GetCampaignSummaryResponse', (req, res, ctx) => {
   
    const mock = () => {
        const random = Math.floor(Math.random() * 1000)
        const ulinkRandom = Math.floor(Math.random() * 100)
        const id =  req.variables.campaignId || Math.ceil(Math.random() * 100 )
        return {

            product: 'product' + id,
            channel: 'channel',
            campaignId: 'campaignId' + id,
            campaignName: 'campaignName' + id,
            status: 'status',
            accountId: 'accountId',
            correlationId: 'correlationId',
            recipient: 'recipient',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia, asperiores labore illo totam ipsam vel accusamus officiis molestias porro magnam doloremque tempore, beatae at veritatis similique dicta ipsa laboriosam ratione exercitationem repellendus? Est, asperiores adipisci autem perferendis accusantium recusandae et.',
            userId: 'userId',
            senderNameId: 'senderNameId',
            name: 'Marcom KSA EID EOSS Campaign ' + id,
            countryCodeId: 'countryCodeId',
            rejectionReason: 'rejectionReason',
            approvalStatus: 'approvalStatus',
            messageType: 'messageType',
            senderName: 'senderName',
            finalStatus: 'finalStatus',
            totalRecipients: 1200,
            totalMessages: 5250 + random,
            totalUnits: 5800 + random,
            messagesDelivered: 3489 + random,
            messagesUndelivered: 1511 + random,
            uLinkClickRate: ulinkRandom,
            startingAt: new Date(),
            createdAt: new Date(),
            updatedAt: new Date(),
            scheduledAt: new Date(),
            sendingCompletedAt: new Date(),
            accountName: 'accountName',
        }
    } 
    return res(
        ctx.data({
            entries:{
                ...mock()
            }
        })
    )

})