
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';

import { merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { MessageLogsFetchStore } from '../../store/message-logs-fetch.store';
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { MessageLogsLayoutStore } from "../../store/message-logs-layout.store";
import { FilterFormVM } from './filter-forms.types';


type State = {
    generalFormValidity: boolean,
    advancedFormValidity: boolean,
    channels: string,
    products: string,
    accountId: string,
    dateRange: string,
    recipients: string,
    status: string,
    messageType: string,
    senderName: string,
    campaignId: string,
    campaignStatus: string,
    senderNumber: string
    customerReason: string
    templates: string
    buttons: string
    links: string
    locations: string
}

const initialState: State = {
    generalFormValidity: false,
    advancedFormValidity: false,
    channels: '',
    products: '',
    accountId: '',
    dateRange: '',
    recipients: '',
    status: '',
    messageType: '',
    senderName: '',
    campaignId: '',
    campaignStatus: '',
    senderNumber: '',
    customerReason: '',
    templates: '',
    buttons: '',
    links: '',
    locations: '',
}

@Injectable()
export class FiltersFormStore extends ComponentStore<State>{

    reset$: Subject<void> = new Subject()

    constructor(
        private messageLogsFetchStore: MessageLogsFetchStore,
        private messageLogsLayoutStore: MessageLogsLayoutStore,
        private messageLogsFiltersStore: MessageLogsFiltersStore
    ){
        super(initialState)
    }

    //selectors
    generalFormValidity$ = this.select(state => state.generalFormValidity)
    advancedFormValidity$ = this.select(state => state.advancedFormValidity)
    accountId$ = this.select(state => state.accountId)
    dateRange$ = this.select(state => state.dateRange)
    messageType$ = this.select(state => state.messageType)
    senderName$ = this.select(state => state.senderName)
    status$ = this.select(state => state.status)
    products$ = this.select(state => state.products)
    channels$ = this.select(state => state.channels)
    campaignId$ = this.select(state => state.campaignId)
    campaignStatus$ = this.select(state => state.campaignStatus)
    senderNumber$ = this.select(state => state.senderNumber)
    recipients$ = this.select(state => state.recipients)
    customerReason$ = this.select(state => state.customerReason)
    templates$ = this.select(state => state.templates)
    buttons$ = this.select(state => state.buttons)
    links$ = this.select(state => state.links)
    locations$ = this.select(state => state.locations)

    readonly clearAdvancedFilters = this.updater((state) => {
        return {...state, senderName: '', senderNumber: '', status: '', customerReason: ''}
    })


    readonly vm$: Observable<FilterFormVM> = this.select(
        this.generalFormValidity$,
        this.messageLogsFetchStore.loading$,
        this.messageLogsFetchStore.loaded$,
        this.messageLogsLayoutStore.advancedFiltersFormVisibility$,
        this.messageLogsLayoutStore.generalFiltersFormVisbility$,
        (   generalFormValidity,
            messageLogsLoading,
            messageLogsLoaded,
            advancedFormVisibility,
            generalFormVisbility
        ) => {
            return {
                messageLogsLoading,
                messageLogsLoaded,
                advancedFormVisibility,
                submitable: generalFormValidity,
                generalFormVisbility
            }
        }, {debounce: true}
    )


    readonly resetForm = this.effect((trigger$) => {
        return trigger$.pipe(
            tap(() => {
                this.patchState({...initialState})
            })
        )
    })

    readonly submitForm = this.effect((trigger$) => {
        return trigger$.pipe(
            switchMap(() => this.state$.pipe(take(1))),
            tap((state: State) => {
                this.messageLogsFiltersStore.patchState({
                    ...state,
                    autoload: true
                })
            })
        )
    })


    readonly syncOnInit = this.effect((trigger$:Observable<void>) => {
        return this.messageLogsFiltersStore.allFilters$.pipe(
            tap((filters: any) => {
                this.patchState({
                    status: filters.status,
                    channels: filters.channels,
                    products: filters.products,
                    dateRange: filters.dateRange,
                    messageType: filters.messageType,
                    accountId: filters.accountId,
                    senderName: filters.senderName,
                    recipients: filters.recipients,
                    campaignId: filters.campaignId,
                    campaignStatus: filters.campaignStatus,
                    senderNumber: filters.senderNumber,
                    customerReason: filters.customerReason,
                    templates: filters.templates,
                    buttons: filters.buttons,
                    links: filters.links,
                    locations: filters.locations,
                })
            })
        )
    })

}
