export const HIGHCHARTS_SERIES_COLUMN_COLORS = ['#82F365', '#5FDFF0', '#6DECBE', '#8F8DFD', '#29DDD2', '#1EC374'];
export const HIGHCHARTS_SERIES_LINE_COLORS = ['#33CD71', '#30ABDF', '#A071EC'];
export const HIGHCHARTS_SERIES_PIE_COLORS = ['#00A838', '#1AD678', '#53E09A', '#8CEABB', '#C5F4DD', '#E9FBF2'];

export const HIGHCHART_COLORS = {
    green: '#53E09A',
    greenlight: '#8ceabb',
    red: '#E65579',
    blue: '#4070A7',
    bluelight: '#1c8ffe',
    gray: '#D9D9D9',
    darkgray: '#404040'
}

export type HighchartsColors = 'green' | 'greenlight' | 'red' | 'blue' | 'bluelight' | 'gray' | 'darkgray'

export const FUNNEL_CHART_COLORS = ['#2CAFFE', '#D568FB', '#2EE0CA', '#FA4B42', '#6B8ABC', '#FEB56A']
export const CLICK_STATUS_LEGEND_COLORS = {
  BUTTON: '#18D678',
  LINK: '#00408A'
}
