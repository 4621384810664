
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, tap } from "rxjs/operators";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";
import { combineLatest } from "rxjs";
import { MccCampaignsFetchStore } from "../../store/mcc-campaigns-fetch.store";
import { UniAuthFacade } from "unifonic-spa-common/src";
import { uniq, uniqBy } from "lodash";

type State = {
    value: string,
}

const initialState: State = {
    value: "",
}

@Injectable()
export class AdvancedFiltersFormLocationStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
        private mccCampaignsFetchStore: MccCampaignsFetchStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors
    readonly params$ = this.select(
        this.filtersFormStore.campaignId$,
        this.filtersFormStore.templates$,
        this.authFacade.account$.pipe(filter(account => !!account), map((account:any) => account.id)),
        (campaignId, templates, accountId) => {
            return {
                campaignId, templateId: templates, accountId,
                includeULinkClickSummary: true
            }
        }
    )
    readonly locationOptions$ = this.select(
        this.filtersFormStore.buttons$,
        this.filtersFormStore.links$,
        this.mccCampaignsFetchStore.templateStats$,
        (buttons, links, templateStats) => {
            const stats = templateStats?.uLinksClickSummary?.filter((ul:any) => buttons.split(',').includes(ul.linkName) || links.split(',').includes(ul.linkName))
            if(stats && stats.length){
                const locations = stats.reduce((acc,curr) => {
                    const clickStats = (curr.clickStatistics || []).map((cs: any) => {
                        return {
                            label: cs.countryCode,
                            value: cs.countryCode
                        }
                    })
                    return [...acc, ...clickStats]
                }, [])

                return uniqBy(locations, 'value')
            }

            return []
        }
    )

    vm$ = this.select(
        this.locationOptions$,
        this.mccCampaignsFetchStore.templateStatsLoading$,
        (locationOptions, templateStatsLoading) => {
            return {
                locationOptions, templateStatsLoading
            }
        }
        
    )

    fetchStats = this.effect(() => {
        return this.params$.pipe(
            tap((params: any) => {
                if(params.campaignId.length && params.templateId.length && params.accountId.length){
                    this.mccCampaignsFetchStore.fetchWhatsappCampaignStats(params)
                }
            })
        )
    })
}