<ng-container *ngIf="vm$ | async as vm">
    <ul>
        <li *ngFor="let entity of vm.entities; trackBy: trackByValue">
            <app-form-card
                [title]="entity.label"
                [value]="entity.value"
                [description]="entity.description"
                [active]="entity.active"
                [disabled]="entity.disabled"
                (select)="onCardSelect($event)"
            ></app-form-card>
        </li>
    </ul>
</ng-container>