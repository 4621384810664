<uni-label>
  {{'ci.common.campaignName' | translate}}
</uni-label>

<app-campaign-id-selector
  [accountId]="accountId$ | async"
  [value]="value$ | async"
  (valueChange)="onValueChange($event)"
></app-campaign-id-selector>

<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
  {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>