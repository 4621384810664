import { ApiAggregateReports, ViewAggregateReports } from "../reports.models"

export const mapReportListToView = (data: ApiAggregateReports[]): ViewAggregateReports[] => {
    return data.map(d => {
        return {
            ...d,
            productsNumber: (d.products || '').split(',').length,
            channelsNumber:  (d.channels || '').split(',').length,
            isSearchable: d.extraConfigSet.find(e => (e.key === "searchable" && e.value === "true")) !== undefined,
        }
    })
}
