<ng-container *ngIf="(vm$ | async) as vm">
    <uni-box [style.height.px]="50" *ngIf="vm.entitiesLoading && !vm.entitiesLoaded">
        <div class="loading-mask">
            <i class="fa fa-spinner fa-spin"></i>
        </div>
    </uni-box>
    <uni-box *ngIf="vm.entitiesLoaded">
        <uni-table [style.overflow]="vm.entitiesLoading && vm.entitiesLoaded ? 'initial' : null">
            <div class="loading-mask" *ngIf="vm.entitiesLoading && vm.entitiesLoaded">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
            <table
                cdk-table
                [dataSource]="vm.entities"
                [trackBy]="trackId"
                multiTemplateDataRows
            >
                <tr
                    cdk-header-row
                    *cdkHeaderRowDef="displayedColumns"
                ></tr>
                <tr
                    cdk-row
                    *cdkRowDef="let row; columns: displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
                    [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
                ></tr>
                <tr
                    cdk-row
                    *cdkRowDef="let row; columns: ['details']; let isOdd = odd; let isEven = even; let isLast = last"
                    [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
                    [hidden]="!row.open"
                ></tr>

                <ng-container [cdkColumnDef]="columns.details">
                    <td
                        [attr.colspan]="5"
                        cdk-cell
                        *cdkCellDef="let element"
                    >
                      <app-reports-date-table-details *ngIf="element.open" [entity]="vm.entity"></app-reports-date-table-details>
                    </td>
                </ng-container>

                <ng-container [cdkColumnDef]="columns.reportName">
                    <th cdk-header-cell *cdkHeaderCellDef #thead>
                        {{'ci.common.reportName' | translate}}
                    </th>
                    <td cdk-cell *cdkCellDef="let element" class="wrap-column">{{ element.reportName }}</td>
                </ng-container>
                <ng-container [cdkColumnDef]="columns.reportId">
                    <th cdk-header-cell *cdkHeaderCellDef #thead>
                        {{'ci.common.reportId' | translate}}
                        <app-overlay-text-filter
                            [currentValue]="vm.filters.reportId"
                            [active]="!!vm.filters.reportId.length"
                            (valueChange)="filterChange($event)"
                            [titleLabel]="'ci.common.reportId' | translate"
                            [placeholderLabel]="'ci.common.searchByReportId' | translate"
                            [submitLabel]="'ci.common.apply' | translate"
                            [clearLabel]="'ci.common.clear' | translate"
                        ></app-overlay-text-filter>

                    </th>
                    <td cdk-cell *cdkCellDef="let element">
                        {{ element.reportId }}
                    </td>
                </ng-container>
                <ng-container [cdkColumnDef]="columns.type">
                    <th cdk-header-cell *cdkHeaderCellDef #thead>
                        {{'ci.common.type' | translate}}
                        <!-- TODO: Backend doesn't support type filtering yet -->
                        <!-- <app-overlay-select-filter
                            [options]="vm.filters.typeOptions"
                            [currentValue]="vm.filters.type"
                            [active]="!!vm.filters.type.length"
                            (valueChange)="filterTypeChange($event)"
                            [titleLabel]="'ci.common.type' | translate"
                            [submitLabel]="'ci.common.apply' | translate"
                            [clearLabel]="'ci.common.clear' | translate"
                        >
                        </app-overlay-select-filter> -->
                    </th>
                    <td cdk-cell *cdkCellDef="let element">
                        {{ element.reportType }}
                        <span *ngIf="element.isSearchable">
                          [{{'label.search' | translate}}]
                        </span>

                    </td>
                </ng-container>
                <ng-container [cdkColumnDef]="columns.services">
                    <th cdk-header-cell *cdkHeaderCellDef #thead>
                        {{'ci.common.services' | translate}}
                    </th>
                    <td cdk-cell *cdkCellDef="let element">
                        {{'ci.common.numberOfProducts' | translate: {amount: element.productsNumber} }},
                        {{'ci.common.numberOfChannels' | translate: {amount: element.channelsNumber} }}
                    </td>
                </ng-container>
                <ng-container [cdkColumnDef]="columns.createOn">
                    <th cdk-header-cell *cdkHeaderCellDef #thead class="sorting-cell">
                        {{'ci.common.createOn' | translate}}
                        <app-data-sort
                            [fieldName]="'startedAt'"
                            [value]="vm.sortStartedAtData"
                            [isActive]="vm.sortByStartedAt"
                            (valueChange)="sort( $event )"
                        ></app-data-sort>
                    </th>
                    <td cdk-cell *cdkCellDef="let element">
                        {{ element.startedAt | dateFormat: 'DD-MM-YYYY HH:mm' }}
                    </td>
                </ng-container>

                <ng-container [cdkColumnDef]="columns.dateRange">
                    <th cdk-header-cell *cdkHeaderCellDef #thead>
                        {{'ci.common.dateRange' | translate}}
                    </th>
                    <td cdk-cell *cdkCellDef="let element">
                        {{ element.startDate | dateFormat: 'DD-MM-YYYY HH:mm' }} -
                        {{ element.endDate | dateFormat: 'DD-MM-YYYY HH:mm' }}
                    </td>
                </ng-container>

                <ng-container [cdkColumnDef]="columns.status">
                    <th cdk-header-cell *cdkHeaderCellDef #thead class="sorting-cell">
                        {{'ci.common.status' | translate}}
                        <app-data-sort
                            [fieldName]="'reportState'"
                            [value]="vm.sortStatusData"
                            [isActive]="vm.sortByStatus"
                            (valueChange)="sort( $event )"
                        ></app-data-sort>
                    </th>
                    <td cdk-cell *cdkCellDef="let element">{{ 'ci.common.brand.' + element.status | lowercase | translate }}</td>
                </ng-container>

                <ng-container [cdkColumnDef]="columns.actions" stickyEnd>
                    <th cdk-header-cell *cdkHeaderCellDef #thead class="sticky-column">
                        {{'ci.common.actions' | translate}}
                    </th>
                    <td cdk-cell *cdkCellDef="let element" [style.textAlign]="'right'" class="sticky-column">
                        <div class="buttons">
                            <app-reports-data-table-delete [reportId]="element.reportId" (deleteReport)="onDelete($event)"></app-reports-data-table-delete>
                            <app-reports-data-table-download [reportId]="element.reportId"></app-reports-data-table-download>
                            <app-reports-data-table-open
                                [reportId]="element.reportId"
                                [loading]="element.detailsLoading"
                                [isOpen]="element.open"
                                (toggle)="toggleDetails($event)"
                            ></app-reports-data-table-open>
                        </div>
                    </td>
                </ng-container>
            </table>
        </uni-table>
    </uni-box>
    <uni-box *ngIf="vm.entitiesLoaded && vm.entities.length === 0">
        <div class="empty-message">
            <i class="far fa-box-open"></i>
            <p>{{'ci.reports.table.emptyInfoLine1' | translate}}</p>
            <p>{{'ci.reports.table.emptyInfoLine2' | translate}}</p>
        </div>
    </uni-box>
    <div class="table-pagination">
        <app-pagination
            [items]="vm.totalCount"
            [itemsPerPage]="vm.limit"
            (pageChange)="onPageChange($event)"
            [valueTracker]="vm.page"
        ></app-pagination>
    </div>
</ng-container>
