import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartPieVariableComponent } from './chart-pie-variable.component';
@NgModule({
  declarations: [ChartPieVariableComponent],
  exports: [ChartPieVariableComponent],
  imports: [
    HighchartsChartModule
  ]
})
export class ChartPieVariableModule { }
