import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-key-value-legend',
  templateUrl: './key-value-legend.component.html',
  styleUrls: ['./key-value-legend.component.scss']
})
export class KeyValueLegendComponent implements OnInit {
  @Input() value?: number;
  @Input() label: string = '';
  @Input() color: string = '#FFFFFF';

  constructor() { }

  ngOnInit(): void {
  }

}
