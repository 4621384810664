<div class="recurring-type">
    <uni-label>
        {{'ci.common.dateRangeAndRecurringReports' | translate}}
    </uni-label>

    <mat-radio-group [(ngModel)]="value" (ngModelChange)="changeRecurringType($event)">
        <mat-radio-button [value]="1">
            {{'ci.common.oneTimeReport' | translate}}
        </mat-radio-button>
        <mat-radio-button [value]="2">
            {{'ci.common.recurringReport' | translate}}
        </mat-radio-button>
    </mat-radio-group>


</div>
