

<ng-container *ngIf="vm$ | async as vm">

    <uni-label [required]="true">
        {{'ci.common.dateRange' | translate}}
    </uni-label>
    <app-form-single-select
        (valueChange)="dateRangeOptionChange($event)"
        [value]="vm.value"
        [options]="vm.entities"
        [canClear]="false"
        [placeholder]="'ci.common.dateRange' | translate"
    ></app-form-single-select>

</ng-container>