import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";

export enum Frequencies {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
    PREVIOUS_N_DAYS = '_PREVIOUS_N_DAYS'
}

export type FrequenciesKeys = keyof typeof Frequencies;

type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: string
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [{
        label: 'daily',
        value: Frequencies.DAILY
      },{
        label: 'weekly',
        value: Frequencies.WEEKLY
      },{
        label: 'monthly',
        value: Frequencies.MONTHLY
      },{
        label: 'yearly',
        value: Frequencies.YEARLY
      },{
        label: 'previous n days',
        value: Frequencies.PREVIOUS_N_DAYS
      }
    ]
}

@Injectable()
export class FrequenciesMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    viewEntities$ = this.select(
      this.entities$,
      (entities) => {
        return entities.map(e => {
          return {
            ...e,
            label: this.translateService.instant('ci.common.' + e.value.toLowerCase())
          }
        })
      }
    )

    readonly daily$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === Frequencies.DAILY)
    )

    readonly weekly$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === Frequencies.WEEKLY)
    )

    readonly monthly$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === Frequencies.MONTHLY)
    )

    readonly yearly$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === Frequencies.YEARLY)
    )


    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}