import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-data-key-value',
  templateUrl: './data-key-value.component.html',
  styleUrls: ['./data-key-value.component.scss']
})
export class DataKeyValueComponent implements OnInit {

  @Input() key: string = ''
  @Input() value: string = ''
  @Input() icon: string = ''
  @Input() headerTemplate!: TemplateRef<{}>
  @Input() contentTemplate!: TemplateRef<{}>
  @Input() footerTemplate!: TemplateRef<{}>

  constructor() { }

  ngOnInit(): void {
  }

}
