import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-tiles-item',
  templateUrl: './tiles-item.component.html',
  styleUrls: ['./tiles-item.component.scss']
})
export class TilesItemComponent implements OnInit {

  @Input() title: string
  @Input() titleTemplate: TemplateRef<{}>
  @Input() subtitle: string
  @Input() content: string = ''
  @Input() loading: boolean
  @Input() progressBarTemplate: TemplateRef<{}>

  constructor() { }

  ngOnInit(): void {
  }

}
