import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ChannelsMockStore } from '../../../shared/store-mocks/channels.store';
import { FilterPanelChannelsStore } from './filter-panel-channels.store';

@Component({
  selector: 'app-filter-panel-channels',
  templateUrl: './filter-panel-channels.component.html',
  styleUrls: ['./filter-panel-channels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChannelsMockStore, FilterPanelChannelsStore]
})
export class FilterPanelChannelsComponent implements OnInit {

  @Output() valueChange: EventEmitter<void> = new EventEmitter()
  
  channels$ = this.store.channels$
  channelsCurrentValue$ = this.store.channelsCurrentValue$

  constructor(
    private store: FilterPanelChannelsStore
  ) { }

  ngOnInit(): void {}

  channelValueChange(value: string){
    this.store.valueChange(value)
  }

}
