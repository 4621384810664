import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerReasonSelectorComponent } from './customer-reason-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormMultiSelectModule } from '../../form-multi-select/form-multi-select.module';
import { AutocompleteMultiSelectModule } from '../../autocomplete-multiselect/autocomplete-multiselect.module';

@NgModule({
  declarations: [CustomerReasonSelectorComponent],
  exports: [CustomerReasonSelectorComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormMultiSelectModule,
    AutocompleteMultiSelectModule
  ]
})
export class CustomerReasonSelectorModule { }
