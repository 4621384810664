import { Component, Input, OnInit, Output } from '@angular/core';
import { PaginationStore } from './pagination.store';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  providers:[ PaginationStore ]
})
export class PaginationComponent implements OnInit {

  vm$ = this.store.vm$

  @Input() set items(items: number){
    this.store.setItems(items)
  }

  @Input() set valueTracker(value: number){
    this.store.setValue([Number(value), 'external'])
  }

  @Input() set itemsPerPage(itemsPerPage: number){
    this.store.setItemsPerPage(itemsPerPage)
  }

  // @Output() pageChange: EventEmitter<number> = new EventEmitter()
  @Output() pageChange = this.store.eventEmitter$

  constructor(private store: PaginationStore) { }

  ngOnInit(): void {}


  selectPage(page: any) {
    if(typeof page === 'number'){
      this.store.setValue([page, 'internal'])
    }
  }

  changePageByOne(dir: 'next' | 'prev'){
    if(dir === 'next'){
      this.store.nextPage()
    }

    if(dir === 'prev'){
      this.store.prevPage()
    }
  }
}
