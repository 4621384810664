<div
    *ngIf="vm$ | async as vm"
    class="data-sort"
>
    <!-- <uni-icon
        name="sort-up"
        (click)="setValue(vm.fieldName, 'ASC')"
        [isSuccess]="vm.ascActive"
    ></uni-icon>
    <uni-icon
        name="sort-down"
        (click)="setValue(vm.fieldName, 'DESC')"
        [isSuccess]="vm.descActive"
    ></uni-icon> -->
    <div class="data-sort-icons">
        <i
            class="far fa-sort-up"
            (click)="setValue(vm.fieldName, 'ASC')"
            [attr.active]="vm.ascActive || null"
        ></i>
        <i
            class="far fa-sort-down"
            (click)="setValue(vm.fieldName, 'DESC')"
            [attr.active]="vm.descActive || null"
        ></i>

    </div>
</div>