import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageIndexComponent } from './page-index/page-index.component';

export const routes: Routes = [
  {
    path: '',
    component: PageIndexComponent,
    data: {
      titleKey: 'ci.pageTitle.logAnalyzer'
    }
 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LogAnalyserRoutingModule { }
