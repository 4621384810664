
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { Observable, combineLatest, from, of } from "rxjs";
import { map, mergeMap, switchMap, tap, toArray, withLatestFrom } from "rxjs/operators";
import { CampaignDetailsRepository } from "src/app/shared/data-access/campaign-details/campaign-details.repository";
import { UniAuthFacade } from "unifonic-spa-common/src";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";
import { StatusMockStore } from "src/app/shared/store-mocks/status.store";

type State = {
    options: any[],
}

const initialState: State = {
    options: []
}

@Injectable()
export class FilterPanelWhatsappStatusStore extends ComponentStore<State>{

 
    constructor(
        private filtersFormStore: MessageLogsFiltersStore,
        private messageLogsStatusMockStore: StatusMockStore,
    ){
        super(initialState)
    }

    //selectors
    readonly statusValue$ = this.select(
        this.filtersFormStore.status$,
        (status) => {
            return status
        })
       
        
    readonly entities$ = this.select(
        this.messageLogsStatusMockStore.whatsappStatuses$,
        (statuses) => statuses
    )


}