
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { filter, map, mapTo, skip, switchMap, tap, withLatestFrom } from "rxjs/operators";

type State = {
    reportId: string,
    open: boolean
    loading: boolean
}

const initialState: State = {
    reportId: '',
    open: false,
    loading: false
}

@Injectable()
export class ReportsDataTableOpenStore extends ComponentStore<State>{
    constructor(
    ){
        super({...initialState})
    }

    //selectors
    readonly reportId$ = this.select((state) => state.reportId)
    readonly open$ = this.select((state) => state.open)
    readonly loading$ = this.select((state) => state.loading)


    readonly isOpen$ = this.open$.pipe(
        filter(open => open),
        switchMap(() => this.reportId$)
    )

    readonly isClose$ = this.open$.pipe(
        skip(1),
        filter(open => !open),
        switchMap(() => this.reportId$)
    )

    readonly vm$ = this.select(
        this.reportId$,
        this.open$,
        this.loading$,

        (reportId, open, loading ) => {
            return {
                reportId,
                open,
                loading
            }
        } 
    )

    //updaters
    readonly setReportId = this.updater((state, reportId: string) => {
        return {...state, reportId}
    })

    readonly setLoading = this.updater((state, loading: boolean) => {
        return {...state, loading}
    })

    readonly setOpen = this.updater((state, open: boolean) => {
        return {...state, open}
    })

    //effects
    readonly toggleReport = this.effect((reportId$: Observable<any>) => {

        return reportId$.pipe(
            withLatestFrom(this.open$),
            tap(([reportId, open]) => {
                this.patchState({
                    open: !open
                })
            }),
        
        )
    })
}
