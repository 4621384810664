import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UniSeparatorComponent } from './components';

const components = [
  UniSeparatorComponent,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class UniSeparatorModule { }
