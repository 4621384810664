
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { delay, filter, map, skip, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { BlobUtils, UniAuthFacade } from "@unifonic/common";
import { ReportsRepository } from "../../data-access/reports.repository";
import { ViewAggregateReports } from "../../reports.models";
import { ReportsFiltersStore } from "../../store/reports-filters.store";
import { mapReportListToView } from "../../utils/map-report-list-to-view";


export type EntityItem = {
    value: string,
    label: string,
    description: string
    disabled?: boolean
}

type State = {
    entity: any
}

const initialState: State = {
    entity: {}
}

@Injectable()
export class ReportDataTableDetailsStore extends ComponentStore<State>{
    constructor(

    ){
        super({...initialState})
    }

    //selectors
    readonly entity$ = this.select((state) => state.entity)

    readonly vm$ = this.select(
        this.entity$,
        (entity) => {
            return {
                entity
            }
        }
    )

    //updaters
    readonly setEntity = this.updater((state, entity: any) => {
        return {...state, entity}
    })


}
