import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { QueryPayload } from "../data-access.model";
import { DEFAULT_OUTPUT } from "./campaign-list.graphql";
import { CampaignListRepository } from "./campaign-list.repository";


type State = {
    loading: boolean
    loaded: boolean
    entities: any[]
    totalCount: number
    lastFetchTimestamp: number
}

const initialState: State = {
    loading: false,
    loaded: false,
    entities: [],
    totalCount: 0,
    lastFetchTimestamp: 0
}

type QueryParams = {
    accountId?: string,
    campaignNamePhrase?: string
}

@Injectable()
export class CampaignListFetchStore extends ComponentStore<State>{
    constructor(
        private repository: CampaignListRepository,
    ){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)
    totalCount$ = this.select(state => state.totalCount)
    lastFetchTimestamp$ = this.select(state => state.lastFetchTimestamp)

    
    //effect
    readonly fetch = this.effect((payload$: Observable<QueryPayload<QueryParams>>) => {
        return payload$.pipe(
            tap((v) => {
                this.patchState({loading: true})
            }),
            switchMap(({variables, output}) => this.repository.gqlQueryCampaignList(variables, output || DEFAULT_OUTPUT).pipe(
                tapResponse(
                    (response) => {
                        this.patchState({
                            entities: [...response.entries],
                            loading: false,
                            totalCount: response.count
                        })
                    },
                    () => {}
                )
            ))
        )
    })
}


