import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-icon',
  templateUrl: './uni-icon.component.html',
  styleUrls: ['./uni-icon.component.scss'],
})
export class UniIconComponent {
  @Input() name: string;
  @Input() style = 'far';

  @HostBinding('class.is-disabled') @Input() disabled = false;
  @HostBinding('class.is-left-space') @Input() isLeftSpace = false;
  @HostBinding('class.is-right-space') @Input() isRightSpace = false;
  @HostBinding('class.is-action') @Input() isAction = false;
  @HostBinding('class.is-bullet-icon') @Input() isBullet = false;
  @HostBinding('class.is-inherit') @Input() isInherit = false;
  @HostBinding('class.is-success') @Input() isSuccess = false;
  @HostBinding('class.is-alert') @Input() isAlert = false;
  @HostBinding('class.is-error') @Input() isError= false;
  @HostBinding('class.is-header-icon') @Input() isHeaderIcon = false;
  @HostBinding('class.is-body-icon') @Input() isBodyIcon = false;
  @HostBinding('class.is-big') @Input() isBig = false;
  @HostBinding('class.is-arrow-icon-success') @Input() isArrowIconSuccess = false;
}
