import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-circle-chart',
  templateUrl: './card-circle-chart.component.html',
  styleUrls: ['./card-circle-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCircleChartComponent implements OnInit {

  @Input() value!: number
  @Input() title!: string
  @Input() label: string
  @Input() loading: boolean = false
  
  constructor() { }

  ngOnInit(): void {
  }

}