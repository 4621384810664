import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CreateFormAccountIdStore } from './create-form-account-id.store';

@Component({
  selector: 'app-create-form-account-id',
  templateUrl: './create-form-account-id.component.html',
  styleUrls: ['./create-form-account-id.component.scss'],
  providers: [CreateFormAccountIdStore]
})
export class CreateFormAccountIdComponent implements OnInit, ControlValueAccessor {


  onChange: any = () => {}
  onTouch: any = () => {}
  value: string =''

  constructor(
    public ngControl: NgControl,
    private store: CreateFormAccountIdStore
  ) {
    ngControl.valueAccessor = this;
  }

  vm$ = this.store.vm$


  ngOnInit(): void {
      this.store.setDefaultValue((userAccoundId) => {
        if(!this.value || this.value === ''){
          this.onChange(userAccoundId || '')
          this.value = userAccoundId // TODO could be more reactive
        }
      })
  }

  writeValue(value: string | undefined){
    if(value){
      this.value = value
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(event: any){
    if(!this.ngControl.touched){
      this.onTouch()
    }
    
    this.onChange(event)
  }


}
