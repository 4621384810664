import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormPeriodDatePickerStore } from './form-period-date-picker.store';

@Component({
  selector: 'app-form-period-date-picker',
  templateUrl: './form-period-date-picker.component.html',
  styleUrls: ['./form-period-date-picker.component.scss'],
  providers: [FormPeriodDatePickerStore]
})
export class FormPeriodDatePickerComponent implements OnInit {

  @Input() set value( dateValue: string){
    this.store.setCurrentValue([dateValue, 'external'])
  }
  @Input() set dateRangeLabel( label: string ) {
    this.store.setDateRangeLabel(label)
  }

  @Input() set periodPlaceholder( placeholder: string ) {
    this.store.patchState({periodPlaceholder: placeholder})
  }

  @Input() set blacklist( list: string[] ){
    this.store.setBlacklist(list)
  }
  @Input() set clearable( clearable: boolean ){
    this.store.setClearable(clearable)
  }

  @Input() set defaultDateRange(range: string){
    this.store.setDefaultDateRange(range)
  }

  @Input() set defaultTimePeriod(period: string){
    this.store.setDefaultTimePeriod(period)
  }

  @Input() set timezone(tz: string){
    this.store.setTimezone(tz || 'UTC')
  }

  @Input() set periodEntities(list: any[]){
    this.store.setPeriodEntities(list)
  }

  // @Output() dateSelect = new EventEmitter()
  @Output() dateSelect = this.store.emitValue$

  onChange: any = () => {}
  onTouch: any = () => {}


  vm$ = this.store.vm$

  constructor(
    private store: FormPeriodDatePickerStore
  ) {
  }

  ngOnInit(): void {
  }
}
