import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";

export enum Times {
}

export type TimesKeys = keyof typeof Times;

type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: string
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: Array.from({length: 24}, (_,i) => i).reduce((r,hour) => {
      r.push({value: moment({hour, minute: 0}), label: moment({hour, minute: 0}).format('h:mm A')});
      r.push({value: moment({hour, minute: 30}), label: moment({hour, minute: 30}).format('h:mm A')});;
      return r;
    }, [])
}

@Injectable()
export class TimesMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    viewEntities$ = this.select(
      this.entities$,
      (entities) => {
        return entities.map(e => {
          return {
            ...e
          }
        })
      }
    )

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}
