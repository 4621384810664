import { state } from "@angular/animations";
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { delay, filter, switchMap, tap } from 'rxjs/operators';
import { MessageLogsResponseType } from "../log-analyser.models";
import { mapApiMessageLogsToAppMessageLogs } from "../utils/map-api-message-logs-app-message-logs";
import { mapQueryParamsToResponseType } from "../utils/map-query-params-to-response-type";
import { MessageLogsRepository } from './../data-access/message-logs.repository';
import { MessageLogsLayoutStore } from "./message-logs-layout.store";
import { UniAnalyticsService } from "unifonic-spa-common/src";
import { MixpanelEvents } from "src/app/app.models";


type State = {
    loading: boolean
    loaded: boolean
    entities: any[]
    totalCount: number
    lastFetchTimestamp: number
    fetchOnInit: boolean
    responseType: MessageLogsResponseType
}

const initialState: State = {
    loading: false,
    loaded: false,
    entities: [],
    totalCount: 0,
    lastFetchTimestamp: 0,
    fetchOnInit: false,
    responseType: MessageLogsResponseType.None
}

@Injectable()
export class MessageLogsFetchStore extends ComponentStore<State>{
    constructor(
        private repository: MessageLogsRepository,
        private messageLogsLayoutStore: MessageLogsLayoutStore,
        private analytics: UniAnalyticsService,
    ){
        super(initialState)
    }

    //selectors

    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)
    totalCount$ = this.select(state => state.totalCount)
    lastFetchTimestamp$ = this.select(state => state.lastFetchTimestamp)
    fetchOnInit$ = this.select(state => state.fetchOnInit)
    responseType$ = this.select(state => state.responseType)


    //updaters
    setFetchOnInit = this.updater((state, fetchOnInit: boolean) => ({...state, fetchOnInit }))

    //effect
    readonly fetchMessageLogs = this.effect((params$: Observable<any>) => {
        return params$.pipe(
            tap((params)=>{
                this.patchState({loading: true})
            }),
            switchMap((params) => this.fetchData(params))
        )
    })

    readonly fetchMessageLogsWithParam = this.effect<any>((value$: Observable<any>) => {
        return value$.pipe()
    })

    readonly resetMessageLogs = this.effect<void>((_) => {
        return _.pipe(tap(() => {
            this.patchState({loading: false, loaded: false, entities: [], fetchOnInit: false})
        }))
    })

    
    //helpers
    readonly fetchData = (params) => {
        const performanceStart = new Date().getTime()

        return this.repository.gqlQueryMessageLogs(params.query).pipe(
            tapResponse(
                (response: any) => {
                    this.patchState({
                        loading: false,
                        loaded: true,
                        fetchOnInit: false,
                        entities: mapApiMessageLogsToAppMessageLogs(response.entries),
                        totalCount: response.totalCount,
                        lastFetchTimestamp: (new Date()).getTime(),
                        responseType: mapQueryParamsToResponseType(params.query)
                    })

                    this.analytics.mixpanelTrack(MixpanelEvents.logAnalyzerSearch, {
                        ...params.track,
                        result_count: response.totalCount,
                        load_data_duration: new Date().getTime() - performanceStart
                    })
    
                    this.messageLogsLayoutStore.setFilterPanelVisibility(true)
                    this.messageLogsLayoutStore.setGeneralFiltersFormVisbility(false)
                },
                (err) => {
                    this.patchState({
                        loading: false,
                        loaded: false,
                        fetchOnInit: false,
                        responseType: MessageLogsResponseType.None
                    })
                }
            )
        )
    }
   

}


