import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { skip } from 'rxjs/operators';
import { FormMultiSelectOption, FormMultiSelectStore } from './form-multi-select.store';

@Component({
  selector: 'app-form-multi-select',
  templateUrl: './form-multi-select.component.html',
  styleUrls: ['./form-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormMultiSelectStore]
})
export class FormMultiSelectComponent implements OnInit {

  @Input() set options(options: FormMultiSelectOption[]){
    this.store.setOptions(options)
  }

  @Input() set value(value: string[]){
    this.store.setSelectedExternal(value)
  }

  @Input() set clearable(value: boolean){
    this.store.setClearable(value)
  }

  @Input() set defer(value: number){
    this.store.setDefer(value)
  }
  @Input() placeholder: string = 'Select'

  @Output() valueChange = this.store.selectedInternally$.pipe(skip(1))

  vm$ = this.store.vm$

  constructor(private store: FormMultiSelectStore) { }

  ngOnInit(): void {}

  selectionChange(change: MatSelectChange){
    this.store.selectOptionInternally(change.value)
  }

  clearOptions(){
    this.store.clearSelected()
  }

}
