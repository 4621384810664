import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniCommonModule } from './../../../../unifonic-spa-common/src/uni-common.module';
import { FormButtonSelectorComponent } from './form-button-selector.component';



@NgModule({
  declarations: [FormButtonSelectorComponent],
  imports: [
    CommonModule,
    UniCommonModule
  ],
  exports: [FormButtonSelectorComponent]
})
export class FormButtonSelectorModule { }
