<ng-container *ngIf="vm$ | async as vm">
    <uni-label [required]="true">
        {{'ci.common.templates' | translate}}
    </uni-label>
    <ul>
        <li *ngFor="let entity of vm.viewEntities; trackBy: trackByValue">
            <app-form-card
                [title]="entity.label"
                [value]="entity.value"
                [description]="entity.description"
                [active]="entity.active"
                [disabled]="entity.disabled"
                (select)="onCardSelect($event)"
            ></app-form-card>
        </li>
    </ul>
    <uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
        {{'ci.common.fieldRequired' | translate}}
    </uni-form-field-error>
</ng-container>
