import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniCommonModule } from './../../../../unifonic-spa-common/src/uni-common.module';
import { OverlayTextFilterComponent } from './overlay-text-filter.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [OverlayTextFilterComponent],
  imports: [
    CommonModule,
    UniCommonModule,
    OverlayModule,
    FormsModule
  ],
  exports: [OverlayTextFilterComponent]
})
export class OverlayTextFilterModule { }
