import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageLogsReportGeneratorStore } from './message-logs-report-generator.store';
import { ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { UniAuthFacade } from 'unifonic-spa-common/src/modules/uni-auth/shared/uni-auth.facade';

@Component({
  selector: 'app-message-logs-report-generator',
  templateUrl: './message-logs-report-generator.component.html',
  styleUrls: ['./message-logs-report-generator.component.scss'],
  providers: [MessageLogsReportGeneratorStore]
})
export class MessageLogsReportGeneratorComponent implements OnInit {

  vm$ = this.store.vm$

  positions: ConnectedPosition[] =[{
    originX: 'center',
    originY: 'center',
    overlayX: 'center',
    overlayY: 'center',
  }]

  constructor(
    private store: MessageLogsReportGeneratorStore,
    protected uniAuthFacade: UniAuthFacade,
  ) { }

  ngOnInit(): void {
  }

  downloadReport(){
    this.store.generateReport()
  }

  closePopup(){
    this.store.patchState({infoPopupVisibility: false})
  }

  get isImpresonationSession(): boolean {
    return this.uniAuthFacade.isImpresonationSession();
  }
}
