import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AdvancedFiltersFormSenderNumberStore } from './advanced-filters-form-sender-number.store';

@Component({
  selector: 'app-advanced-filters-form-sender-number',
  templateUrl: './advanced-filters-form-sender-number.component.html',
  styleUrls: ['./advanced-filters-form-sender-number.component.scss'],
  providers: [AdvancedFiltersFormSenderNumberStore],
})
export class AdvancedFiltersFormSenderNumberComponent implements OnInit, ControlValueAccessor {

  selectedItems$ = this.store.value$.pipe(
    map((entities) => entities.length ? entities.split(',') : [])
  )

  constructor(
    public ngControl: NgControl,
    private store: AdvancedFiltersFormSenderNumberStore
  ) {
      ngControl.valueAccessor = this;
    }


  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(): void {}

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  addSender(value: string[]){
    this.saveValue(value.join(','))
  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }
}