<ng-container [ngTemplateOutlet]="headerTemplate || header"></ng-container>
<ng-container [ngTemplateOutlet]="contentTemplate || content"></ng-container>
<ng-container [ngTemplateOutlet]="footerTemplate || footer"></ng-container>

<ng-template #header>
    <header>
        <i class="fa" [ngClass]="icon" *ngIf="icon.length"></i>
        <strong>{{key}}</strong>
    </header>
</ng-template>
<ng-template #content>
    <div class="content">
        <p>{{value}}</p>
    </div>
</ng-template>

<ng-template #footer>
    <footer></footer>
</ng-template>
