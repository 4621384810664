import { gql } from "apollo-angular"

export const DEFAULT_OUTPUT = ``

export const createCustomerReasonQuery = (output: string = DEFAULT_OUTPUT) => {
    return gql`
            query GetAllCustomerReasons{
                getAllCustomerReasons{
                success, message, count, errorCode, entries
            }
        }
    `
}
