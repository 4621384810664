<uni-label>
    {{'ci.reports.form.notifyWhenReady' | translate}}
</uni-label>

<div class="row">
    <div class="col-6 col-sm-12">
        <uni-checkbox>
            <mat-checkbox [checked]="value.indexOf('email') !== -1" (change)="onToggleCheckbox($event, 'email')">
                {{'ci.reports.form.byEmail' | translate}}
                <br>
                <small>
                    {{'ci.reports.form.byEmailTip' | translate: {email: email$ | async} }}
                </small>
            </mat-checkbox>
        </uni-checkbox>
    </div>
</div>


<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>