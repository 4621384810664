import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import moment from "moment";
import { Observable } from "rxjs";
import { map, tap, withLatestFrom } from "rxjs/operators";
import { momentTimezone } from "unifonic-spa-common/src";


type State = {
    overlayWidth: number,
    open: boolean,
    format: 12 | 24,
    selectedHour: number,
    selectedMinutes: number,
    selectedDayPart: 'PM' | 'AM' | '',
    timezone: string,
    initial: boolean
}

const initialState: State = {
    overlayWidth: 0,
    open: false,
    format: 12,
    selectedHour: 0,
    selectedMinutes: 0,
    selectedDayPart: 'AM',
    timezone: 'Asia/Riyadh',
    initial: true

}

@Injectable()
export class FormTimepickerStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    open$ = this.select(state => state.open)
    overlayWidth$ = this.select(state => state.overlayWidth)
    format$ = this.select(state => state.format)
    selectedHour$ = this.select(state => state.selectedHour)
    selectedMinutes$ = this.select(state => state.selectedMinutes)
    selectedDayPart$ = this.select(state => state.selectedDayPart)
    timezone$ = this.select(state => state.timezone)
    initial$ = this.select(state => state.initial)

    hours$ = this.select(
        this.format$,
        (format) => {
            return Array(format).fill(0).map((i,index) => index+1)
        }
    )

    minutes$ = this.select(
        () => {
            return [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
        }
    )

    dayparts$ = this.select(
        () => {
            return ['PM', 'AM']
        }
    )

    selectedTime$ = this.select(
        this.selectedHour$,
        this.selectedMinutes$,
        this.selectedDayPart$,
        this.timezone$,
        this.format$,
        (hour, minutes, daypart, timezone, format) => {

            return momentTimezone
                .tz(timezone)
                .set({h: daypart === 'AM' ? hour : hour + 12, m: minutes})
        }
    )

    emittedTime$ = this.select(
        this.selectedTime$,
        (selectedTime) => {
            return selectedTime?.utc()
        }
    )

    vm$ = this.select(
        this.open$,
        this.overlayWidth$,
        this.hours$,
        this.minutes$,
        this.dayparts$,
        this.selectedHour$,
        this.selectedMinutes$,
        this.selectedDayPart$,
        this.selectedTime$,
        this.emittedTime$,
        (
            open, 
            overlayWidth,
            hours,
            minutes,
            dayparts, 
            selectedHour,
            selectedMinutes,
            selectedDayPart,
            selectedTime,
            emittedTime
        
        ) => {
            return {
                open,
                overlayWidth,
                hours,
                minutes,
                dayparts,
                selectedHour,
                selectedMinutes,
                selectedDayPart,
                selectedTime: selectedTime.format('hh:mm A'),
                momentTime: emittedTime,
                
            }
        }
    )

    //selectors

 


    //updaters

    readonly setInitialTime = this.effect((trigger$: Observable<void>) => {
        return trigger$.pipe(
            withLatestFrom(
                this.timezone$,
            ),
            tap(([_, timezone]) => {
                const now = momentTimezone.tz(timezone);
                this.patchState({
                    selectedHour: +now.format('hh'),
                    selectedMinutes: Math.round(+now.format('mm') / 5) * 5,
                    selectedDayPart: now.format('A') as "PM" | "AM"
                })

            }),
            
        )
    })

}