<div class="uni-form-box__header">
  <uni-text-caption>
    {{ boxHeader }}
  </uni-text-caption>
  <uni-icon
    name="times"
    [isInherit]="true"
    class="uni-form-box__remove-icon"
    (click)="removeControl()"
  ></uni-icon>
</div>
<ng-content></ng-content>
