import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";

export enum Days {
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
}

export type DaysKeys = keyof typeof Days;

type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: string
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [{
        label: 'saturday',
        value: Days.SATURDAY
      },{
        label: 'sunday',
        value: Days.SUNDAY
      },{
        label: 'monday',
        value: Days.MONDAY
      },{
        label: 'tuesday',
        value: Days.TUESDAY
      },{
        label: 'wednesday',
        value: Days.WEDNESDAY
      },{
        label: 'thursday',
        value: Days.THURSDAY
      },{
        label: 'friday',
        value: Days.FRIDAY
      }
    ]
}

@Injectable()
export class WeekDaysMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    viewEntities$ = this.select(
      this.entities$,
      (entities) => {
        return entities.map(e => {
          return {
            ...e,
            label: this.translateService.instant('ci.common.' + e.value.toLowerCase())
          }
        })
      }
    )

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}