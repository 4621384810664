<uni-label [required]="true">
    {{'ci.common.selectAccount' | translate}}
</uni-label>

<app-autocomplete-select
    [currentValue]="accountIdValue$ | async"
    [options]="accounts$ | async"
    [loading]="accountsLoading$ | async"
    [noResoultsLabel]="'ci.common.noResults' | translate"
    [placeholder]="'ci.common.selectAccount' | translate"
    (valueChange)="onValueChange($event)"
></app-autocomplete-select>


<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>
