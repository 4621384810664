import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardStatisticMessageComponent } from './card-statistic-message.component';
import { UniBoxModule } from '@unifonic/common';
import { LoadingMaskModule } from '../loading-mask/loading-mask.module';


@NgModule({
  declarations: [CardStatisticMessageComponent],
  exports: [CardStatisticMessageComponent],
  imports: [
    CommonModule,
    UniBoxModule,
    LoadingMaskModule
  ]
})
export class CardStatisticMessageModule { }
