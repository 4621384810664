
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, tap } from "rxjs/operators";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {
    accountId: string
    value: string
}

const initialState: State = {
    accountId: "",
    value: "",
}

@Injectable()
export class AdvancedFiltersFormCampaignIdStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
    ){
        super(initialState)
    }

    readonly value$ = this.select(state => state.value)
    readonly accountId$ = this.select(state => state.accountId)

    readonly onHide = this.effect(() => {
        return this.advancedFiltersFormStore.campaignIdVisibility$.pipe(
            filter(v => !v),
            tap((v) => this.patchState({value: ''}))
        )
    })

    readonly onFormValueChange = this.effect(() => {
        return this.filtersFormStore.campaignId$.pipe(
            tap((v: string) => this.patchState({value: v}))
        )
    })
}