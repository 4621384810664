import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardValueMessageComponent } from './card-value-message.component';
import { UniBoxModule } from '@unifonic/common';
import { LoadingMaskModule } from '../loading-mask/loading-mask.module';

@NgModule({
  declarations: [CardValueMessageComponent],
  imports: [
    CommonModule,
    UniBoxModule,
    LoadingMaskModule
  ],
  exports: [CardValueMessageComponent]
})
export class CardValueMessageModule { }
