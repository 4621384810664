import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniCommonModule } from 'unifonic-spa-common/src';
import { TranslateModule } from '@ngx-translate/core';
import { SecurityUnishieldRoutingModule } from './security-unishield-routing.module';

import { PageIndexComponent } from './page-index/page-index.component';

@NgModule({
  declarations: [
    PageIndexComponent,
  ],
  imports: [
    CommonModule,
    UniCommonModule,
    TranslateModule,
    SecurityUnishieldRoutingModule,
  ],
  providers: [
  ]
})
export class SecurityUnishieldModule { }
