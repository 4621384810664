<ng-container *ngIf="vm$ | async as vm">
    <div class="autocomplete-select">
        <i class="fa-regular fa-clock"
            (click)="onFocus($event)"
        ></i>
        <input type="text"
            #trigger="cdkOverlayOrigin"
            readonly
            uniInput
            cdkOverlayOrigin
            autocomplete="off"
            [placeholder]="vm.placeholder"
            [attr.ariaLabel]="vm.placeholder"
            [value]="vm.selectedTime"
            (focus)="onFocus($event)"
            (change)="onInputChange($event)"
        >
        <!-- <span class="nav-icon" *ngIf="vm.loading">
            <i class="fa fa-spin fa-spinner"></i>
        </span>
        <span class="nav-icon" *ngIf="!vm.loading">
            <i class="fa" [ngClass]="vm.open ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </span>
        <span class="nav-icon nav-icon-reset" *ngIf="!vm.loading && vm.isValueSelected && !vm.open" (click)="clear()">
            <i class="fa fa-times"></i>
        </span> -->
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="closeOptions(vm.momentTime)"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="vm.open"
        [cdkConnectedOverlayWidth]="vm.overlayWidth"
        (overlayKeydown)="keydown($event, vm.momentTime)"
    >
        <div class="timepicker">
            <div class="timepicker-selector">
                <div class="timepicker-hours">
                    <ul>
                        <li [attr.active]="vm.selectedHour === h || null" class="pointer" *ngFor="let h of vm.hours" (click)="selectHour(h)">{{h}}</li>
                    </ul>
                </div>
                <div class="timepicker-minutes">
                    <ul>
                        <li [attr.active]="vm.selectedMinutes === m || null" class="pointer" *ngFor="let m of vm.minutes" (click)="selectMinute(m)">{{m}}</li>
                    </ul>
                </div>
                <div class="timepicker-daypart">
                    <ul>
                        <li [attr.active]="vm.selectedDayPart === d || null" class="pointer" *ngFor="let d of vm.dayparts" (click)="selectDayPart(d)">{{d}}</li>
                    </ul>

                </div>
            </div>
            <div class="timepicker-footer">
                <uni-button [autoWidth]="true" [isMargin]="false" [style]="'link'" (click)="selectTime(vm.momentTime)">OK</uni-button>
            </div>
        </div>
    </ng-template>
</ng-container>
