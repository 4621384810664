import { InjectionToken } from "@angular/core";
import * as Highcharts from "highcharts";
import { HighchartsItem } from "./highcharts.types";
import ChartModuleMore from 'highcharts/highcharts-more';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import HCSankey from 'highcharts/modules/sankey'

export const HCToken = new InjectionToken<HighchartsItem>('HC', {
    providedIn: 'root',
    factory: () => {
        ChartModuleMore(Highcharts);
        HCSoldGauge(Highcharts);
        HCSankey(Highcharts);
        return Highcharts
    }
  })
