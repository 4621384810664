<div class="tile">
    <header class="tile-title" [ngClass]="{'success': type === 'success', 'danger': type === 'danger'}">
        {{value}}
    </header>
    <footer>
        <span class="tile-desc">
            {{label}}
        </span>
        <span class="tile-link">
            <a [routerLink]="['/', 'insights', 'log-analyser']" [queryParams]="queryParams">{{btnLabel}}</a>
        </span>
    </footer>
</div>