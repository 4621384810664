import { Component, Input, OnInit, Output } from '@angular/core';
import { OverlaySelectFilterStore } from './overlay-select-filter.store';
import { Subject, Observable } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-overlay-select-filter',
  templateUrl: './overlay-select-filter.component.html',
  styleUrls: ['./overlay-select-filter.component.scss'],
  providers: [OverlaySelectFilterStore]
})
export class OverlaySelectFilterComponent implements OnInit {

  @Input() set options(options: any[]){
    this.store.patchState({options})
  }

  @Input() set currentValue(value: string){
    const val = value.length ? value.split(',') : []
    this.store.patchState({value: [...val], internalValue: [...val]})
  }

  @Input() set active(active: boolean){
    this.store.patchState({active})
  }

  @Input() set titleLabel(titleLabel: string){
    this.store.patchState({titleLabel})
  }

  @Input() set submitLabel(submitLabel: string){
    this.store.patchState({submitLabel})
  }

  @Input() set clearLabel(clearLabel: string){
    this.store.patchState({clearLabel})
  }


  @Output() valueChange: Subject<string> = this.store.emitter$
  @Output() openChange: Observable<boolean> = this.store.open$

  constructor(private store: OverlaySelectFilterStore) { }

  vm$ = this.store.vm$

  ngOnInit(): void {}

  closeOverlay(){
    this.store.patchState({open: false})
  }

  openOverlay(){
    this.store.patchState({open: true})
  }

  submit(){
    this.store.changeValue()
    this.store.patchState({open: false})
  }

  changeCheckbox(event: MatCheckboxChange, value){
    this.store.setInternalValue({value, checked: event.checked})
  }
}
