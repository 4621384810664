import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'uni-form-box',
  templateUrl: './uni-form-box.component.html',
  styleUrls: ['./uni-form-box.component.scss'],
})
export class UniFormBoxComponent {
  @Input() boxHeader = '';
  @Output() removeItem = new EventEmitter();

  removeControl() {
    this.removeItem.emit();
  }
}
