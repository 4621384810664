 <ng-container *ngIf="vm$ | async as vm">
    <div class="overlay-trigger">
        <i 
            class="far fa-filter overlay-action-icon"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            (click)="openOverlay()"
        ></i>
        <span class="overlay-active-indicator" [attr.active]="vm.active || null"></span>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="closeOverlay()"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="true"
        [cdkConnectedOverlayOpen]="vm.open"
    >
        <div class="overlay-filter">
            <form action="" #form="ngForm" (ngSubmit)="submit()">
                <header>
                    <h6>{{vm.labels.titleLabel}}</h6>
                    <i class="fa fa-times overlay-action-icon" (click)="closeOverlay()"></i>
                </header>
                <div>
                    <div class="overlay-input">
                        <input 
                            [placeholder]="vm.labels.placeholderLabel"
                            uniInput
                            autocomplete="off"
                            name="inputValue"
                            type="text"
                            [ngModel]="vm.inputValue"
                            (ngModelChange)="store.setInputValue($event)">
                    </div>
                </div>
                <footer>
                    <uni-button type="submit">{{vm.labels.submitLabel}}</uni-button>
                    <uni-button [style]="'secondary'" type="button" (click)="form.reset(); reset()">{{vm.labels.clearLabel}}</uni-button>
                </footer>
            </form>
        </div>
    </ng-template> 
</ng-container>
