import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';


/**
 * This store keeps data required to display layout correctly.
 * Most layout states (loading spinner, open detailed view etc) are the result of data flow which can be calculated for specific view.
 * Those data which is the result of pure user action (like click on specific button to open specific component) are stored here.
 */

type State = {
    generalFiltersFormVisbility: boolean,
    advancedFiltersFormVisibility: boolean,
    filterPanelVisibility: boolean,
    multiCampaignMode: boolean
}

const initialState: State = {
    generalFiltersFormVisbility: true,
    advancedFiltersFormVisibility: true,
    filterPanelVisibility: false,
    multiCampaignMode: false
}

@Injectable()
export class MessageLogsLayoutStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    // selectors
    readonly generalFiltersFormVisbility$ = this.select(state => state.generalFiltersFormVisbility)
    readonly advancedFiltersFormVisibility$ = this.select(state => state.advancedFiltersFormVisibility)
    readonly filterPanelVisibility$ = this.select(state => state.filterPanelVisibility)
    readonly multiCampaignMode$ = this.select(state => state.multiCampaignMode)

    // updaters
    readonly setGeneralFiltersFormVisbility = this.updater((state, generalFiltersFormVisbility: boolean) => {
        return {...state, generalFiltersFormVisbility}
    })

    readonly setAdvancedFiltersFormVisibility = this.updater((state, advancedFiltersFormVisibility: boolean) => {
        return {...state, advancedFiltersFormVisibility}
    })

    readonly setFilterPanelVisibility = this.updater((state, filterPanelVisibility: boolean) => {
        return {...state, filterPanelVisibility}
    })

    readonly toggleAdvancedFiltersFormVisibility =  this.updater((state) => {
        return {...state, advancedFiltersFormVisibility: !state.advancedFiltersFormVisibility}
    })
}
