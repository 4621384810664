import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartMultiColumnComponent } from './chart-multi-column.component';
@NgModule({
  declarations: [ChartMultiColumnComponent],
  exports: [ChartMultiColumnComponent],
  imports: [
    HighchartsChartModule
  ]
})
export class ChartMultiColumnModule { }
