import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';

type UpdateSource = 'internal' | 'external' | 'initial'
export type PaginationValue = [number, UpdateSource]

type State = {
    items: number,
    itemsPerPage: number,
    value: PaginationValue
}

@Injectable()
export class PaginationStore extends ComponentStore<State>{

    constructor(){
        super({
            items: 0,
            itemsPerPage: 0,
            value: [-1, 'initial']
        })
    }
    // selectors
    readonly items$ = this.select(state => state.items)
    readonly itemsPerPage$ = this.select(state => state.itemsPerPage)
    readonly value$ = this.select(state => state.value, {debounce: true})

    //selectors modifiers
    readonly numberOfPages$ = this.select(
        this.items$,
        this.itemsPerPage$,
        (items, itemsPerPage) => {
            return Math.ceil(items / itemsPerPage)
        }
    )

    readonly pagesNav$ = this.select(
        this.numberOfPages$,
        this.value$,
        (numOfPages, [pageIndex, _]) => {

            const firstPage = 1;
            const lastPage = numOfPages

            if(numOfPages <= 5) {
                return new Array(numOfPages).fill(0).map((i,idx)=>idx+1)
            }
            
            const pages: any[] = [pageIndex-1, pageIndex, pageIndex + 1]
            if(pageIndex === firstPage){
                pages.shift()
                pages.push(pageIndex + 2)
            }

            if(pageIndex === firstPage + 3){
                pages.unshift(pageIndex - 2)
            }

            if(pageIndex === lastPage - 3){
                pages.push(pageIndex + 2)
            }

            if(pageIndex === lastPage){
                pages.unshift(pageIndex - 2)
                pages.pop()
            }

            if(pages[0] - firstPage > 1){
                pages.unshift('...')
            }

            if(lastPage - pages[pages.length - 1] > 1){
                pages.push('...')
            }
            
            if(pages[0] !== firstPage){
                pages.unshift(1)
            }

            if(pages[pages.length - 1] !== lastPage){
                pages.push(lastPage)
            }
  
            return pages
        }    
    )

    readonly eventEmitter$ = this.value$.pipe(
        distinctUntilChanged(([prevValue, _], [currValue, __]) => prevValue === currValue),
        filter(([_, source]) => source === 'internal'),
        map(([value, _]) => value)
    )

    readonly vm$ = this.select(
        this.items$,
        this.itemsPerPage$,
        this.numberOfPages$,
        this.pagesNav$,
        this.value$,
        (items, itemsPerPage,numberOfPages, pagesNav, value) => {
            return {
                items,
                itemsPerPage,
                numberOfPages,
                pagesNav,
                currentPage: value[0]
            }
        }
    )

    readonly setItems = this.updater((state, items: number) => {
        return {...state, items}
    })

    readonly setItemsPerPage = this.updater((state, itemsPerPage: number) => {
        return {...state, itemsPerPage}
    })

    readonly setPageIndex = this.updater((state, pageIndex: number) => {
        return {...state, pageIndex}
    })

    readonly setValue = this.updater( (state, value: [number, UpdateSource]) => {
        return {...state, value: [...value]}
    })


    //effects
    readonly nextPage = this.effect((trigger$) => {
        return trigger$.pipe(
            withLatestFrom(this.value$, this.numberOfPages$),
            tap(([_, [page, __], lastPage]) => {
                this.setValue([page < lastPage ? page + 1 : page, 'internal'])
            })
        )
    })

    readonly prevPage = this.effect((trigger$) => {
        return trigger$.pipe(
            withLatestFrom(this.value$),
            tap(([_, [page, __]]) => {
                this.setValue([page > 1 ? page -1 : page, 'internal'])
            })
        )
    })
}