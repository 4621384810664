import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateRangeMockStore } from 'src/app/shared/store-mocks/date-range.store';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';
import { FilterPanelDateStore } from './filter-panel-date.store';

@Component({
  selector: 'app-filter-panel-date-range',
  templateUrl: './filter-panel-date-range.component.html',
  styleUrls: ['./filter-panel-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterPanelDateStore, DateRangeMockStore]
})
export class FilterPanelDateRangeComponent implements OnInit {

  @Output() valueChange: EventEmitter<void> = new EventEmitter()
  
  vm$ = this.store.vm$

  constructor(
    private messageLogsFiltersStore: MessageLogsFiltersStore,
    private store: FilterPanelDateStore
  ) { }

  ngOnInit(): void {
  }

  dateRangeValueChange(value: string){
    if(value.length){
      this.messageLogsFiltersStore.patchState({dateRange: value, autoload: true})
    }
  }
}
