import { Component, ElementRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MultiSelectOption, AutocompleteMultiSelectStore } from './autocomplete-multiselect.store';

@Component({
  selector: 'app-autocomplete-multiselect',
  templateUrl: './autocomplete-multiselect.component.html',
  styleUrls: ['./autocomplete-multiselect.component.scss'],
  providers: [AutocompleteMultiSelectStore]
})
export class AutocompleteMultiSelectComponent {
  constructor(private store: AutocompleteMultiSelectStore) { }

  /**
   * The only usage of this is to read the width of the element for adjusting overlay
   */
  @ViewChild('trigger', {read: ElementRef}) trigger!: ElementRef


  @Input() optionTemplate!: TemplateRef<{}>
  /**
   * Current value imposing from application (parent component)
   * There are two source of currentValue for the component: external and internal
   * External is the one from other components
   * Internal is the one set inside the component
   * Notice that component emits only internally set values (otherwise infinite loop would happen)
   */
  @Input() set currentValue(value: string){
    this.store.setCurrentValue([value, 'external'])
  }

  /**
   * List of options available in dropdown
   */
  @Input() set options(options: MultiSelectOption[] ){
    this.store.patchState({options: [...(options || [])]})
  }

  /**
   * Boolean value to indicate whether the options are available.
   * If options are fetched in async request it provide info about waiting for options to fetch.
   */
  @Input() set loading(loading: boolean){
    this.store.patchState({loading})
  }

  /**
   * Time between end typing in the search field and filtering options accordingly
   */
  @Input() set debounceTime(debounceTime: number){
    this.store.patchState({debounceTime})
  }

  @Input() set noResoultsLabel(noResultsLabel: string){
    this.store.patchState({noResultsLabel})
  }

  @Input() set placeholder(placeholder: string){
    this.store.patchState({placeholder})
  }

  @Input() set searchPlaceholder(searchPlaceholder: string){
    this.store.patchState({searchPlaceholder})
  }

  @Input() set separator(separator: string){
    this.store.patchState({separator: separator || ','})
  }

  /**
   * Emit internal value change. Only values set from the component can be emitted
   */
  @Output() valueChange = this.store.eventEmitter$

  /**
   * View model with all calculated values for the view
   */
  vm$ = this.store.vm$

  /**
   * Click on backdrop invokes it.
   * We checked if the input value match to any option if yes we set value for component
   * if no we clear the input and reset value of components
   */
  closeOptions(){
    this.store.patchState({open: false})
    this.store.updateInputValue()
  }

  /**
   * We check the ovelay origin width to adjust ovelay
   * Then we open overlay
   */
  onFocus(event: any){
    this.store.patchState({
      overlayWidth: this.trigger.nativeElement.offsetWidth,
      open: true,
      inputValue: ''
    })
  }

  /**
   * On item select action
   * We set input value to the one from selected item
   * Then validate input (which obviously set the current value for complement)
   * Close the overlay
   */
  selectItem(item: MultiSelectOption){
    this.store.updateSelection(item.value);
  }

  /**
   * Sync the store value with the one in the input
   */
  onInputChange(value: string){
    this.store.patchState({inputValue: value.split(this.separator).join(',')})
  }

  /**
   * Handle Esc button click, close and blur input on esc press
   * Esc button by default closes the backdrop
   */
  keydown(event: KeyboardEvent){
    if(event.code === 'Escape'){
      this.closeOptions()
      this.trigger.nativeElement.blur()
    }
  }

  /**
   * Clear value by clicking on icon
   */
  clear(){
    this.store.setCurrentValue(['', 'internal'])
  }
}
