import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TilesComponent } from './tiles.component';
import { TilesItemComponent } from './tiles-item/tiles-item.component';
import { UniBoxModule } from '@unifonic/common';



@NgModule({
  declarations: [TilesComponent, TilesItemComponent],
  exports: [TilesComponent, TilesItemComponent],
  imports: [
    CommonModule,
    UniBoxModule
  ]
})
export class TilesModule { }
