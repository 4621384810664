import { delay, distinctUntilChanged, filter, map, skip, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from "@angular/core";

import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable, of } from "rxjs";

/**
 * Describes where the value comes from.
 * Typing - user is typing in the input field
 * Selecting - value is set after option selection
 * Initial - default value set on init.
 */
export enum InputValueSource {
    Typing = 'typing',
    Selecting = 'selecting',
    Initial = 'initial'
}

/**
 * The structure of InputValue is as follow:
 * [phrase from input, assosiate value (if exist) for phrase from input, additional data which can be passed, InputValueSource]
 */
export type InputValue = [string, string, any, InputValueSource]

export type AccountOption = {
    label: string,
    value: string,
    data?: any
}

type State = {

    /**
     * Value visible in the input. Typescript Tuple to store not only the value but also the source
     * The value can be set by typing inside the input - InputValueSource.Typing
     * The value can be set by selecting item from list - InputValueSource.Selecting
     */
    inputValue: InputValue

    /**
     * Options available in the dropdown list.
     */
    options: AccountOption[]

    /**
     * Loading is set from external resources while options are collected
     */
    listLoading: boolean

    /**
     * Loading is set from external resources while label is collected
     */
    labelLoading: boolean

    /**
     * Open the value used to show / hide options list
     */
    open: boolean

    /**
     * Time between end of typing and emitting the event with typed phrase
     */
    debounceTime: number

    /**
     * The width of overlay
     * TODO: It sould be integrated with CDK Breakpoint
     */
    overlayWidth: number

    /**
     * Mininimal length of phrase to emit typeahead event
     */
    minPhraseLength: number

    /**
     * Max size of results which can be displayed as a list. Otherwise tooManyItemsMessage is displayed.
     */
    maxOptionsSize: number

    /**
     * Input Placeholder
     */
    placeholder: string

    /**
     * Message displayed if no results are collected from specific phrase
     */
    noResultMessage: string

    /**
     * Message displayed if too many items is passed through options
     */
    tooManyItemsMessage: string

    /**
     * Value set (most likely) right after component initialization. It should display the label of already selected option
     */
    labelValue: string
}

const initialState: State = {
    inputValue: ['', '', {}, InputValueSource.Initial],
    options: [],
    listLoading: false,
    labelLoading: false,
    open: false,
    debounceTime: 300,
    overlayWidth: 300,
    noResultMessage: 'No Results',
    placeholder: 'Select Option',
    minPhraseLength: 2,
    maxOptionsSize: 50,
    tooManyItemsMessage: 'Too many items were found. Please specify your query',
    labelValue: ''
}

@Injectable()
export class TypeaheadSelectStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    //Selectors - Values are taken directly from the store
    readonly options$ = this.select(state => state.options)
    readonly listLoading$ = this.select(state => state.listLoading)
    readonly labelLoading$ = this.select(state => state.labelLoading)
    readonly open$ = this.select(state => state.open)
    readonly inputValue$ = this.select(state => state.inputValue)
    readonly debounceTime$ = this.select(state => state.debounceTime)
    readonly overlayWidth$ = this.select(state => state.overlayWidth)
    readonly noResultMessage$ = this.select(state => state.noResultMessage)
    readonly placeholder$ = this.select(state => state.placeholder)
    readonly minPhraseLength$ = this.select(state => state.minPhraseLength)
    readonly maxOptionsSize$ = this.select(state => state.maxOptionsSize)
    readonly tooManyItemsMessage$ = this.select(state => state.tooManyItemsMessage)
    readonly labelValue$ = this.select(state => state.labelValue)


    //Selectors - Values are calculated from other selectors

    /**
     * Calculate whether the input value is valid agains min length requirement
     */
    readonly inputValueMinLengthValidity$ = this.select(
        this.inputValue$,
        this.minPhraseLength$,
        ([inputValue, _1, _2], minLength) => inputValue.length >= minLength
    )

    /**
     * SearchingValue is a "delayed" inputValue
     * It is used to emit searching phrase after time specified in debounceTime property
     */
    readonly searchingValue$ = this.inputValue$.pipe(
        withLatestFrom(
            this.debounceTime$,
            this.minPhraseLength$
        ),
        switchMap(([[phrase, value, data, source], debounceTime, minPhraseLength]) => {
            return phrase.length < minPhraseLength || source !== InputValueSource.Typing
                ? of([phrase, value, data, source])
                : of([phrase, value, data, source]).pipe(delay(debounceTime))
        })
    )

    /**
     * Mapping searchingValue to string, regardless of source.
     * Start with empty string is neccessary to emit at least one event at the begining and generate view model stream
     */
    readonly searchingPhrase$ = this.searchingValue$.pipe(
        map(([phrase, _1, _2]) => phrase),
        startWith('')
    )

    readonly visibleOptions$ = this.select(
        this.options$,
        this.searchingPhrase$,
        (options, searchingPhrase) => {
            return options.filter(o => o.label.toLowerCase().indexOf(searchingPhrase.toLowerCase()) !== -1)
        }
    )


    //View Model
    /**
     * Calculates all used by view values to keep html templates clean
     */
    readonly vm$ = this.select(
        this.visibleOptions$,
        this.listLoading$,
        this.labelLoading$,
        this.open$,
        this.inputValue$,
        this.overlayWidth$,
        this.noResultMessage$,
        this.placeholder$,
        this.maxOptionsSize$,
        this.tooManyItemsMessage$,
        this.searchingPhrase$,
        (
            options,
            listLoading,
            labelLoading,
            open,
            [inputValue, _, inputSource],
            overlayWidth,
            noResultMessage,
            placeholder,
            maxOptionsSize,
            tooManyItemsMessage,
            searchingPhrase
        ) => {
            const typing = inputValue !== searchingPhrase && inputSource === InputValueSource.Typing
            const tooManyItemsMessageVisibility = options.length > maxOptionsSize && !listLoading && !typing
            const listReloading = typing || listLoading
            const listVisibility = options.length <= maxOptionsSize && !listLoading && !typing
            const valueSelection = !!inputValue.length && !typing
            const resetIconVisibility = !listLoading && valueSelection && !open && !labelLoading
            const chevronIconVisibility = !listLoading && !valueSelection && !typing && !labelLoading
            const inputDeactivity = !listLoading && valueSelection && !open && !labelLoading
            const labelLoadingIconVisibility = listReloading
            const noResultsMessageVisibility = !options.length && !listLoading && !typing

            return {
                options,
                open,
                inputValue,
                typing,
                overlayWidth,
                noResultMessage,
                placeholder,
                maxOptionsSize,
                tooManyItemsMessage,
                searchingPhrase,
                tooManyItemsMessageVisibility,
                listReloading,
                listVisibility,
                resetIconVisibility,
                chevronIconVisibility,
                inputDeactivity,
                labelLoadingIconVisibility,
                listLoading,
                noResultsMessageVisibility

            }
        }, {debounce: true}
    )

    /**
     * Emit value to external resources - parent component
     * Only internal changes can be emitted outside (otherwise infinite loop happens)
     */
    readonly eventEmitter$ = this.inputValue$.pipe(
        filter(([_1, _2, _3, source]) => source === InputValueSource.Selecting),
        map(([label, value, data, _4]) => ({label, value, data})),
        distinctUntilChanged(),
    )

    /**
     * Emit phrase to external resources - parent component
     * Only values which are a result of typing (InputValueSource.Typing) can be emitted
     * Prevent stream processing when the same value occur two times in the row for the same input source
     */
    readonly phraseEmitter$ = this.searchingValue$.pipe(
        distinctUntilChanged(([prevPhrase, _1, _2, prevSource], [currPhrase, _3, _4, currSource]) => {
            return prevPhrase === currPhrase && prevSource === currSource
        }),
        filter(([_1, _2, _3, source]) => source === InputValueSource.Typing),
        map(([phrase, _1, _2, _3]) =>  phrase),

        withLatestFrom(
            this.minPhraseLength$,
        ),
        filter( ([phrase, length]) => phrase.length >= length),
        map(([phrase, _]) => phrase)
    )

    readonly closeOptionsEmitter$ = this.open$.pipe(
        filter(open => !open),
    )

    //Value updaters

    /**
     * Clear options whenever inputValue is not validate correctly agains min length.
     */
    readonly clearOptions = this.effect(() => {
        return this.inputValueMinLengthValidity$.pipe(
            filter( (validity) => !validity)
        )
    })

}
