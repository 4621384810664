import { Component, Input, OnInit, Output } from '@angular/core';
import { ReportsDataTableDeleteStore } from './reports-data-table-delete.store';

@Component({
  selector: 'app-reports-data-table-delete',
  templateUrl: './reports-data-table-delete.component.html',
  styleUrls: ['./reports-data-table-delete.component.scss'],
  providers: [ReportsDataTableDeleteStore]
})
export class ReportsDataTableDeleteComponent implements OnInit {

  @Input() set reportId(value: string){
      this.store.setReportId(value)
  }

  @Output() deleteReport = this.store.isDeleted$

  vm$ = this.store.vm$

  constructor(
    private store: ReportsDataTableDeleteStore
  ) { }

  ngOnInit(): void {
  }

  selectReport(id: string){
    this.store.deleteReport(id)
  }
}
