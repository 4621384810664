import { from, Observable } from 'rxjs';

import { TranslateLoader } from '@ngx-translate/core';

export class LazyTranslateLoader implements TranslateLoader {
  constructor(){
  }
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}
