<uni-box>
  <app-loading-mask *ngIf="loading"></app-loading-mask>

  <div class="row">
      <div class="col-6">
          <div class="content">
              <h6>{{ title }}</h6>
              <p>{{ label }}</p>
          </div>
      </div>
      <div class="col-6">
          <div class="content">
              <ng-content select="[content]"></ng-content>
          </div>
      </div>
  </div>
</uni-box>
