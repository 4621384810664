import { Component, Input, OnInit, Output } from '@angular/core';
import { SortByOptions, SortOptions } from './data-sort.models';
import { DataSortStore } from './data-sort.store';

@Component({
  selector: 'app-data-sort',
  templateUrl: './data-sort.component.html',
  styleUrls: ['./data-sort.component.scss'],
  providers: [DataSortStore],
})
export class DataSortComponent implements OnInit {

  @Input() set fieldName(value: string){
    this.store.setFieldName(value)
  }

  @Input() set value(value: any){
    const {sortBy, sortDir} = value || {}
    // this.store.setValue([sortBy || '', sortDir || '', 'external'])
    
    this.store.updateByExternalValue([sortBy || '', sortDir || '', 'external'])
  }

  @Input() set isActive(value : boolean){
    this.store.setIsActive(value)
  }

  // @Output() valueChange: EventEmitter<any> = new EventEmitter()
  @Output() valueChange = this.store.eventEmitter$

  vm$ = this.store.vm$

  constructor(private store: DataSortStore) {}



  ngOnInit(): void {
  }

  setValue(sortBy: SortByOptions, sortDir: SortOptions){

    this.store.setValue([sortBy, sortDir, 'internal'])
  }

}
