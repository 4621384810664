<uni-label [required]="true">
    {{'ci.common.fromDate' | translate}}
</uni-label>
<uni-datepicker [datepicker]="fromDatepicker">
    <input
        [ngModel]="fromDate"
        (ngModelChange)="changeFromDate($event)"
        [placeholder]="'label.chooseDate' | translate"
        [matDatepicker]="fromDatepicker"
        (click)="fromDatepicker.open()"
        autocomplete="off"
        uniInput
        readonly
        
    >
    <mat-datepicker #fromDatepicker></mat-datepicker>
</uni-datepicker>