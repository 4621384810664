<uni-label>
    {{'ci.common.campaignStatus' | translate}}
  </uni-label>
  
  <app-form-multi-select
    [options]="statusEntities$ | async"
    [value]="campaignStatusValue$ | async"
    (valueChange)="statusValueChange($event)"
    [defer]="0"
    [placeholder]="'ci.common.campaignStatus' | translate"
  ></app-form-multi-select>
  <small>{{'ci.common.selectOneOrMoreCampaigns' | translate}}</small>
  <uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
      {{'ci.common.fieldRequired' | translate}}
  </uni-form-field-error>