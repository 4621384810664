import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormTimepickerStore } from './form-timepicker.store';
import { momentTimezone } from 'unifonic-spa-common/src';

@Component({
  selector: 'app-form-timepicker',
  templateUrl: './form-timepicker.component.html',
  styleUrls: ['./form-timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormTimepickerStore]
})
export class FormTimepickerComponent implements OnInit{

  @Input() set timezone(timezone: string){
    this.store.patchState({timezone})
  }

  @ViewChild('trigger', {read: ElementRef}) trigger!: ElementRef

  @Output() onTimeSelect: EventEmitter<string> = new EventEmitter<string>()

  constructor(private store: FormTimepickerStore){
    
  }


  vm$ = this.store.vm$;

  ngOnInit(): void {
    this.store.setInitialTime()
  }


  closeOptions(time: string){
    this.store.patchState({open: false})
    this.onTimeSelect.emit(time)
  }

  onFocus(event: any){
    this.store.patchState({
      overlayWidth: 152,
      open: true
    })
  }


  onInputChange(value: string){
    this.onTimeSelect.emit(value)
  }


  keydown(event: KeyboardEvent, time:string){
    if(event.code === 'Escape'){
      this.trigger.nativeElement.blur()
    }
    this.closeOptions(time)
  }


  /**
   * Clear value by clicking on icon
   */
  clear(){
    // this.store.setCurrentValue(['', 'internal'])
  }


  selectHour(hour: number){
    this.store.patchState({
      selectedHour: hour
    })
  }

  selectMinute(minutes: number){
    this.store.patchState({
      selectedMinutes: minutes
    })
  }

  selectDayPart(daypart: 'PM' | 'AM' | ''){
    this.store.patchState({
      selectedDayPart: daypart
    })
  }


  selectTime(time: string){
    this.store.patchState({open:false})
    this.onTimeSelect.emit(time)
  }
}
