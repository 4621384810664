import { Component, Input, OnInit, Output } from '@angular/core';
import { EntityItem, FormCardSelectorStore, SelectorMode } from './form-card-selector.store';

@Component({
  selector: 'app-form-card-selector',
  templateUrl: './form-card-selector.component.html',
  styleUrls: ['./form-card-selector.component.scss'],
  providers: [FormCardSelectorStore]
})
export class FormCardSelectorComponent implements OnInit {

  @Input() set selectedValues(values: string[]){
    this.store.retriveSelectedValues(values)
  }

  @Input() set entities(entities: EntityItem[]){
    this.store.setEntities(entities)
  }

  @Input() set mode(mode: SelectorMode){
    this.store.setMode(mode)
  }

  @Output() selectCard = this.store.valueChange$

  vm$ = this.store.vm$

  constructor(private store: FormCardSelectorStore) { }

  ngOnInit(): void {
    this.store.configurationSync()
  }

  onCardSelect(value: string){
      this.store.toggleSelectedEntities(value)
  }

  public trackByValue(index: number, item: EntityItem) {
    return item.value;
  }
}
