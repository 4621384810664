import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniCommonModule } from '@unifonic/common';
import { FormAutocompleteSelectComponent } from './form-autocomplete-select.component';



@NgModule({
  declarations: [FormAutocompleteSelectComponent],
  imports: [
    CommonModule,
    UniCommonModule
  ],
  exports: [FormAutocompleteSelectComponent]
})
export class FormAutocompleteSelectModule { }
