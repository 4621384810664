<uni-section>
    <div class="grid">
        <app-filter-panel-channels></app-filter-panel-channels>
        <app-filter-panel-products></app-filter-panel-products>
        <ng-container *ngIf="!(multiCampaignMode$ | async)">
            <app-filter-panel-account-id></app-filter-panel-account-id>
        </ng-container>
        <ng-container *ngIf="!(multiCampaignMode$ | async)">
            <app-filter-panel-date-range></app-filter-panel-date-range>
        </ng-container>
        <ng-container *ngIf="multiCampaignMode$ | async">
            <app-filter-panel-whatsapp-status></app-filter-panel-whatsapp-status>
        </ng-container>
        <ng-container *ngIf="multiCampaignMode$ | async">
            <app-filter-panel-multi-campaign-ids></app-filter-panel-multi-campaign-ids>
        </ng-container>
        <ng-container *ngIf="!(multiCampaignMode$ | async)">
            <div class="more-filters">
                <uni-buttons>
                    <uni-button
                        type="button"
                        [style]="'secondary'"
                        [isMargin]="true"
                        [disabled]="loading$ | async"
                        (click)="showMoreFilters()"
                    >
                        <span class="button-content">
                            {{'ci.logAnalyzer.filters.moreButton' | translate}}
                        </span>
                    </uni-button>
                </uni-buttons>
            </div>
        </ng-container>

    </div>
</uni-section>
