import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignIdsSelectorComponent } from './campaign-ids-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadSelectModule } from '../../typeahead-select/typeahead-select.module';
import { AutocompleteMultiSelectModule } from '../../autocomplete-multiselect/autocomplete-multiselect.module';



@NgModule({
  declarations: [CampaignIdsSelectorComponent],
  exports: [CampaignIdsSelectorComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TypeaheadSelectModule,
    AutocompleteMultiSelectModule
  ]
})
export class CampaignIdsSelectorModule { }
