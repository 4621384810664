import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Products, ProductsMockStore } from 'src/app/shared/store-mocks/products.store';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';
import { FormButtonSelectorItem } from './../../../shared/form-button-selector/form-button-selector.models';

@Component({
  selector: 'app-general-filters-form-products',
  templateUrl: './general-filters-form-products.component.html',
  styleUrls: ['./general-filters-form-products.component.scss'],
  providers: [ProductsMockStore],
})
export class GeneralFiltersFormProductsComponent implements OnInit, ControlValueAccessor {

  products$ = this.productsMockStore.viewEntities$
  selectedItems$ = this.filtersFormStore.products$.pipe(
    map((channel => channel.split(','))),
  )
  
  disabled: string[] = [
    Products.CHATBOT,
    Products.AGENT_CONSOLE
  ]

  constructor(
    public ngControl: NgControl,
    private productsMockStore: ProductsMockStore,
    private filtersFormStore: FiltersFormStore
  ) {
    ngControl.valueAccessor = this;
  }

  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(): void {}

  writeValue(value: any){
    if(value){
      this.onTouch()
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onDataChange(data: FormButtonSelectorItem[]){
    this.filtersFormStore.clearAdvancedFilters()
    const values = data.map(d => d?.value).join(',')
    this.saveValue(values.length ? values : '')
  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }
}
