import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { UniButtonComponent } from './components/uni-button/uni-button.component';
import { UniButtonsComponent } from './components/uni-buttons/uni-buttons.component';

const components = [
  UniButtonComponent,
  UniButtonsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniButtonModule { }
