import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createCustomerReasonQuery } from './customer-reason.graphql';
import { CustomerReasonApiResponse } from './customer-reason.model';

@Injectable()
export class CustomerReasonRepository {

    constructor(
        private apollo: Apollo
    ){}

    gqlQueryCustomerReason(variables, output: string): Observable<CustomerReasonApiResponse['getAllCustomerReasons']> {
      return this.apollo.query<CustomerReasonApiResponse>({
        query: createCustomerReasonQuery(
            output
        ),
        variables
      })
      .pipe(
          map((response) => {
            return response.data.getAllCustomerReasons
          })
      )
    }
}
