import { Component, OnInit } from '@angular/core';
import { FormChipsAutocompleteStore } from './form-chips-autocomplete.store';

@Component({
  selector: 'app-form-chips-autocomplete',
  templateUrl: './form-chips-autocomplete.component.html',
  styleUrls: ['./form-chips-autocomplete.component.scss'],
  providers: [FormChipsAutocompleteStore]
})
export class FormChipsAutocompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
