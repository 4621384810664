<ng-container [formGroup]="form">
  <uni-form-container [isWide]="true">
    <uni-label *ngIf="label" [required]="required">
      {{ label | translate }}
      <uni-tooltip uniLabelTip *ngIf="tip">
        {{ tip | translate }}
      </uni-tooltip>
    </uni-label>
    <uni-form-field [isButton]="true" [margin]="false">
      <input
        [placeholder]="placeholder | translate"
        [formControlName]="fileName"
        (click)="uploadFromFile()"
        uniInput
        readonly
      >
      <uni-button
        *ngIf="!fileControl.value && isEditable"
        [style]="'primary'"
        (click)="uploadFromFile()"
        [disabled]="fileControl.disabled || form.disabled"
      >
        {{ 'action.upload' | translate }}
      </uni-button>
      <uni-button
        *ngIf="fileControl.value && isEditable"
        [style]="'primary'"
        (click)="deleteFile()"
        [disabled]="fileControl.disabled || form.disabled"
      >
        {{ 'action.delete' | translate }}
      </uni-button>
    </uni-form-field>
  </uni-form-container>
</ng-container>

<input
  type="file"
  #file
  [accept]="accept"
  style="display: none"
  (change)="onFilesAdded()"
/>
