import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { cutTimezoneFromDate } from '../../utils/date-format';
import { FormPeriodDatePickerStore } from '../form-period-date-picker.store';

@Component({
  selector: 'app-form-period-date-picker-date-selector',
  templateUrl: './form-period-date-picker-date-selector.component.html',
  styleUrls: ['./form-period-date-picker-date-selector.component.scss']
})
export class FormPeriodDatePickerDateSelectorComponent implements OnInit, OnDestroy {

  @Input() set currentValue(value){
    if(value && value !== '_selectPeriod' && value !== '_selectDate'){
      const from = value.split('_')[0]
      const to = value.split('_')[1]
      this.dateForm.get('from').setValue(from)
      this.dateForm.get('to').setValue(to)
    }

  }

  @Output() changeDate: EventEmitter<string> = new EventEmitter()
  
  dateChangeSubscription!: Subscription;
  
  vm$ = this.formPeriodDatePickerStore.vm$

  dateForm = this.fb.group({
    from: ['', Validators.required],
    to: ['', Validators.required]
  })

  constructor(
    private formPeriodDatePickerStore: FormPeriodDatePickerStore,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {

  }

  selectFromList(){
    this.formPeriodDatePickerStore.setCurrentValueToDefaultTimePeriod()
    // this.formPeriodDatePickerStore.selectType('period')
  }

  changeEndDate(event: any){
    if(event.target.value){
        this.formPeriodDatePickerStore.changeCurrentValue({
          ...this.dateForm.value
        })
    }
  }

  ngOnDestroy(): void {
    if(this.dateChangeSubscription){
      this.dateChangeSubscription.unsubscribe()
    }
  }

}
