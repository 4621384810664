import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import momentTimezone from "moment-timezone";
import { UniAuthFacade } from "@unifonic/common";
import { TranslateService } from "@ngx-translate/core";

type State = {
    loading: boolean
    loaded: boolean
    entities: any[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [
        {
          value: 'rt_360',
          label: 'Last 6 hrs',
        },{
          value: 'rt_720',
          label: 'Last 12 hrs',
        },
        {
          value: 'rt_1440',
          label: 'Real Time',
        },
        {
          value: 'rt_today',
          label: 'Today',
        },
        {
          value: 'last24h',
          label: 'Last 24 hrs',
        },
        {
          value: 'today',
          label: 'Today',
        },
        {
          value: 'last7d',
          label: 'Last 7 days',
        },
        {
          value: 'last30d',
          label: 'Last 30 days',
        },
        {
          value: 'last2w',
          label: 'Last 2 weeks',
        },
        {
          value: 'last1m',
          label: 'Last month',
        },
        {
          value: 'last2m',
          label: 'Last month',
        },
        {
          value: 'last3m',
          label: 'Last 3 months',
        },
        {
          value: 'last1y',
          label: 'Last year',
        },
        {
          value: 'this1w',
          label: 'This week'
        },
        {
          value: 'this1m',
          label: 'This month'
        },
        {
          value: 'this1y',
          label: 'This year'
        },
        {
          value: '_custom',
          label: 'Custom',
        },
        {
          value: '_previousNDays',
          label: 'Previous N Days',
        },
        {
          value: '_selectDate',
          label: 'Select Date Range',
        }
    ]
}


@Injectable()
export class DateRangeMockStore extends ComponentStore<State>{
    constructor(
      private uniAuthFacade: UniAuthFacade,
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    // auth facade selectors
    readonly selectUserTimezone$ = this.select(
      this.uniAuthFacade.userMe$,
      userMe => userMe?.user?.timezone
    )


    //selectors

    readonly loading$ = this.select(state => state.loading)
    readonly loaded$ = this.select(state => state.loaded)
    readonly entities$ = this.select(state => state.entities)


    viewEntities$ = this.select(
      this.entities$,
      (entities) => {
        return entities.map(e => {
          return {
            ...e,
            label: this.translateService.instant('ci.common.brand.' + e.value.toLowerCase())
          }
        })
      }
    )
    readonly rt360$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'rt_360')
    )
    readonly rt720$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'rt_720')
    )

    readonly rt1440$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'rt_1440')
    )

    readonly rtToday$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'rt_today')
    )

    readonly last24h$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'last24h')
    )

    readonly today$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'today')
    )

    readonly last7d$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'last7d')
    )

    readonly last30d$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'last30d')
    )

    readonly last2w$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'last2w')
    )

    readonly last1m$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'last1m')
    )

    readonly last2m$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'last2m')
    )

    readonly last3m$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'last3m')
    )

    readonly last1y$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'last1y')
    )

    readonly this1w$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'this1w')
    )

    readonly this1m$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'this1m')
    )

    readonly this1y$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === 'this1y')
    )

    readonly custom$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === '_custom')
    )

    readonly previousNDays$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === '_previousNDays')
    )


    readonly selectDate$ = this.select(
      this.viewEntities$, (entities) => entities.find(e => e.value === '_selectDate')
    )


    //Caluclate default date range according to specific timezone
    readonly defaultDateRange$ = this.select(
      this.selectUserTimezone$,
      (timezone) => {

        const start = momentTimezone.tz(timezone).subtract(6, 'days').startOf('day').utc().format()
        const end = momentTimezone.tz(timezone).subtract(0, 'days').endOf('day').utc().format()

        return `${start}_${end}`

      }
    )

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}
