<ng-container *ngIf="vm$ | async as vm">
  <uni-label>
      {{'ci.common.template' | translate}}
  </uni-label>

  <app-autocomplete-select
    [currentValue]="value"
    [options]="vm.templates"
    [loading]="vm.templatesLoading"
    [noResoultsLabel]="'ci.common.noResults' | translate"
    [placeholder]="'ci.common.template' | translate"
    (valueChange)="onValueChange($event)"
></app-autocomplete-select>

  <uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
      {{'ci.common.fieldRequired' | translate}}
  </uni-form-field-error>

</ng-container>