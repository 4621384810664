import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-create-form-schedule-toggle',
  templateUrl: './create-form-schedule-toggle.component.html',
  styleUrls: ['./create-form-schedule-toggle.component.scss']
})
export class CreateFormScheduleToggleComponent implements OnInit, ControlValueAccessor {

  constructor(public ngControl: NgControl) { 
    ngControl.valueAccessor = this;
  }

  value = false

  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(): void {
  }

  writeValue(value: string | undefined){
    if(value){
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  toggle(value: boolean){
    if(!this.ngControl.touched){
      this.onTouch()
    }

    this.onChange(value)
  }



}
