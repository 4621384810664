import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss'],
  providers: [
  ]
})
export class PageIndexComponent implements OnInit {

  constructor(

  ) { }

  
  ngOnInit(): void {
 
  }
}
