import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteSelectComponent } from './autocomplete-select.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { OverlayModule } from '@angular/cdk/overlay'
import { UniCommonModule } from 'unifonic-spa-common/src';
import { SpanModule } from '../span/span.module';


@NgModule({
  declarations: [AutocompleteSelectComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    UniCommonModule,
    OverlayModule,
    FormsModule,
    SpanModule
  ],
  exports: [AutocompleteSelectComponent]
})
export class AutocompleteSelectModule { }
