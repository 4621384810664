import { Component, OnInit } from '@angular/core';
import { FilterPanelWhatsappStatusStore } from './fitler-panel-whatsapp-status.store';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';
import { CustomerStatus } from 'src/app/shared/store-mocks/status.store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-filter-panel-whatsapp-status',
  templateUrl: './filter-panel-whatsapp-status.component.html',
  styleUrls: ['./filter-panel-whatsapp-status.component.scss'],
  providers: [FilterPanelWhatsappStatusStore]
})
export class FilterPanelWhatsappStatusComponent implements OnInit {


  
  statusCurrentValue$ = this.store.statusValue$.pipe(map(
    (value) => {
      return value.length ? value.split(',').filter(s => s !== CustomerStatus.NONE) : []
    }
  ))
  statusEntities$ = this.store.entities$

  constructor(
    private store: FilterPanelWhatsappStatusStore,
    private messageLogsFiltersStore: MessageLogsFiltersStore
  ) {
  }

  ngOnInit(): void {
    
  }

  statusValueChange(option: string[]) {
    this.messageLogsFiltersStore.patchState({status: option.join(','), autoload: true})
  }

}
