import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardCampaignsRoutingModule } from './dashboard-campaigns-routing.module';
import { PageIndexComponent } from './page-index/page-index.component';

@NgModule({
  declarations: [PageIndexComponent],
  imports: [
    CommonModule,
    DashboardCampaignsRoutingModule
  ]
})
export class DashboardCampaignsModule { }
