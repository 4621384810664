import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";

export enum Channels {
  ALL = 'ALL',
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  VOICE = 'VOICE',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER'
}

export type ChannelKeys = keyof typeof Channels;

type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: string
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [
      {
        label: 'all',
        value: Channels.ALL
      },{
        label: 'sms',
        value: Channels.SMS
      },{
        label: 'whatsapp',
        value: Channels.WHATSAPP
      },{
        label: 'voice',
        value: Channels.VOICE
      },{
        label: 'push notification',
        value: Channels.PUSH_NOTIFICATION
      },{
        label: 'email',
        value: Channels.EMAIL
      },{
        label: 'facebook',
        value: Channels.FACEBOOK
      },{
        label: 'twitter',
        value: Channels.TWITTER
      }
    ]
}

@Injectable()
export class ChannelsMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors

    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    viewEntities$ = this.select(
      this.entities$,
      (entities) => {
        return entities.map(e => {
          return {
            ...e,
            label: this.translateService.instant('ci.common.brand.' + e.value.toLowerCase())
          }
        })
      }
    )

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}