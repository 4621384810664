import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeaheadSelectComponent } from './typeahead-select.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UniCommonModule } from 'unifonic-spa-common/src';
import { SpanModule } from '../span/span.module';
import { TypeaheadSelectDirective } from './typeahead-select.directive';

@NgModule({
  declarations: [TypeaheadSelectComponent, TypeaheadSelectDirective],
  exports: [TypeaheadSelectComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    UniCommonModule,
    OverlayModule,
    FormsModule,
    SpanModule
  ]
})
export class TypeaheadSelectModule { }
