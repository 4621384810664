import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-statistic-message',
  templateUrl: './card-statistic-message.component.html',
  styleUrls: ['./card-statistic-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardStatisticMessageComponent implements OnInit {

  @Input() value: string
  @Input() message: string
  @Input() loading: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
