<ng-container *ngIf="vm$ | async as vm">
    <app-raised-button
        (onClick)="toggleReport(vm.reportId)"
    >
        <i class="fa fa-chevron-down"
            *ngIf="!vm.open && !vm.loading"
        ></i>
        <i class="fa fa-chevron-up"
            *ngIf="vm.open && !vm.loading"
        ></i>
        <i class="far fa-spin fa-spinner"
            *ngIf="vm.loading"
        ></i>
    </app-raised-button>
</ng-container>