import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartMultiLineComponent } from './chart-multi-line.component';
@NgModule({
  declarations: [ChartMultiLineComponent],
  exports: [ChartMultiLineComponent],
  imports: [
    HighchartsChartModule
  ]
})
export class ChartMultiLineModule { }
