import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataKeyValueComponent } from './data-key-value.component';

@NgModule({
  declarations: [DataKeyValueComponent],
  imports: [
    CommonModule
  ],
  exports: [DataKeyValueComponent]
})
export class DataKeyValueModule { }
