import { NgModule } from '@angular/core';
import { CardCircleChartStatsComponent } from './card-circle-chart-stats.component';
import { UniBoxModule } from '@unifonic/common';
import { UniLayoutModule } from "@unifonic/common";
import { UniTextModule } from "@unifonic/common";
import { LoadingMaskModule } from '../loading-mask/loading-mask.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [CardCircleChartStatsComponent],
    exports: [CardCircleChartStatsComponent],
    imports: [
        CommonModule,
        UniBoxModule,
        UniLayoutModule,
        UniTextModule,
        LoadingMaskModule
    ]
})
export class CardCircleChartStatsModule { }
