<ng-container *ngIf="vm$ | async as vm">
    <div class="autocomplete-select">
    <input type="text"
        #trigger="cdkOverlayOrigin"
        uniInput
        cdkOverlayOrigin
        autocomplete="off"
        [placeholder]="vm.placeholder"
        [attr.ariaLabel]="vm.placeholder"
        [ngModel]="vm.inputValue"
        (focus)="onFocus($event)"
        (ngModelChange)="onInputChange($event)"
        [disabled]="vm.inputDeactivity"
    >
        <span class="nav-icon" *ngIf="vm.chevronIconVisibility">
            <i class="fa" [ngClass]="vm.open ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </span>

        <span class="nav-icon" *ngIf="vm.labelLoadingIconVisibility">
            <i class="fa fa-spin fa-spinner"></i>
        </span>

        <span class="nav-icon nav-icon-reset" *ngIf="vm.resetIconVisibility" (click)="clear()">
            <i class="fa fa-times"></i>
        </span>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="closeOptions()"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="vm.open"
        [cdkConnectedOverlayWidth]="vm.overlayWidth"
        [cdkConnectedOverlayMinHeight]="45"
        (overlayKeydown)="keydown($event)"
    >
        <ul class="type-list" *ngIf="vm.listVisibility">
            <li *ngFor="let opt of vm.options" [value]="opt" (click)="selectItem(opt)">
                <app-span>
                    <span appTypeaheadSelect 
                        [value]="opt.label"
                        [phrase]="vm.inputValue">
                    </span>
                </app-span>
            </li>
            <li *ngIf="vm.noResultsMessageVisibility">
                {{vm.noResultMessage}}
            </li>
        </ul>
        <ul class="type-list" *ngIf="vm.tooManyItemsMessageVisibility">
            <li>{{vm.tooManyItemsMessage}}</li>
        </ul>
        <ul *ngIf="vm.listReloading">
            <li>&nbsp;</li>
        </ul>
        <div class="loading-mask" *ngIf="vm.listReloading">
            <i class="fa fa-spinner fa-spin"></i>
        </div>
  </ng-template>

</ng-container>
