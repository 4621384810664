import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CampaignIdSelectorComponent } from 'src/app/shared/containers/campaign-id-selector/campaign-id-selector.component';
import { CreateFromCampaignIdStore } from './create-form-campaign-id.store';

@Component({
  selector: 'app-create-form-campaign-id',
  templateUrl: './create-form-campaign-id.component.html',
  styleUrls: ['./create-form-campaign-id.component.scss'],
  providers: [CreateFromCampaignIdStore]
})
export class CreateFormCampaignIdComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() set accountId(accountId: string){
    this.store.patchState({accountId})
    this.saveValue('')
  }

  @Output() selectCampaign: EventEmitter<any> = new EventEmitter<any>()

  accountId$ = this.store.accountId$
  
  onChange: any = () => {}
  onTouch: any = () => {}

  constructor(
    public ngControl: NgControl,
    private store: CreateFromCampaignIdStore
  ) { 
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(event: any){
    this.saveValue(event.value)
    this.selectCampaign.emit(event)
  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
