import { Component, Input, OnInit } from '@angular/core';
import { ReportDataTableDetailsStore } from './reports-date-table-details.store';

@Component({
  selector: 'app-reports-date-table-details',
  templateUrl: './reports-date-table-details.component.html',
  styleUrls: ['./reports-date-table-details.component.scss'],
  providers: [ReportDataTableDetailsStore]
})
export class ReportsDateTableDetailsComponent implements OnInit {

  @Input() set entity(data: any) {
    this.store.setEntity(data)
  }

  vm$ = this.store.vm$

  constructor(private store: ReportDataTableDetailsStore) { }

  ngOnInit(): void {
  }

}
