import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from "@angular/material/icon";
import { UniCommonModule } from '@unifonic/common';
import { FormChipsComponent } from './form-chips.component';
@NgModule({
  declarations: [FormChipsComponent],
  exports: [FormChipsComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    UniCommonModule,
    MatIconModule
  ]
})
export class FormChipsModule { }
