import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDateRangeSelectorComponent } from './form-date-range-selector.component';
import { UniCommonModule } from '@unifonic/common';



@NgModule({
  declarations: [FormDateRangeSelectorComponent],
  imports: [
    CommonModule,
    UniCommonModule,
  ],
  exports: [
    FormDateRangeSelectorComponent
  ]
})
export class FormDateRangeSelectorModule { }
