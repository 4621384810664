
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { UniAuthFacade } from "unifonic-spa-common/src";
import { MccCampaignsFetchStore } from "../../store/mcc-campaigns-fetch.store";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {
    value: string
}

const initialState: State = {
    value: "",
}

@Injectable()
export class AdvancedFiltersFormLinkStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
        private formStore: FiltersFormStore,
        private mccCampaignsFetchStore: MccCampaignsFetchStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors

    readonly compaignId$ = this.select(
        this.formStore.campaignId$,
        (campaignId) => campaignId
    )

    readonly senderNameValue$ = combineLatest([
        this.filtersFormStore.senderName$,
        this.advancedFiltersFormStore.senderNameVisibility$,
        
    ]).pipe(
        map(([value, visibility]) => visibility ? value : '')
    )


     
    readonly linkOptions$ = this.select(
        this.mccCampaignsFetchStore.templateStats$,
        (template: any) => {
          return template?.uLinksClickSummary?.filter(opt => opt.linkType === 'LINK')
              .map(opt => ({
                  label: opt.linkName,
                  value: opt.linkName,
                  numberOfRecipients: opt.numClicks
              })) || []
         
        }
      )


    vm$ = this.select(
        this.compaignId$,
        this.mccCampaignsFetchStore.templateStatsLoading$,
        this.linkOptions$,
        (campaignId, templateStatLoading, linkOptions) => {
    
            return {
                campaignId,
                templateStatLoading,
                linkOptions
            }
        }
    )

    readonly params$ = this.select(
        this.filtersFormStore.campaignId$,
        this.filtersFormStore.templates$,
        this.authFacade.account$.pipe(filter(account => !!account), map((account:any) => account.id)),
        (campaignId, templates, accountId) => {
            return {
                campaignId, templateId: templates, accountId,
                includeULinkClickSummary: true
            }
        }
    )


    fetchStats = this.effect(() => {
        return this.params$.pipe(
            tap((params:any) => {
                if(params.campaignId.length && params.templateId.length && params.accountId.length){
                    this.mccCampaignsFetchStore.fetchWhatsappCampaignStats(params)
                }
            })
        )
    })


}