

<ng-container *ngIf="vm$ | async as vm">
    <app-message-logs-table-details-sms
        *ngIf="vm.type.sms"
        [messageId]="vm.messageId"
        [elMessageId]="vm.elMessageId"
        [isRealTime]="vm.isRealTime"
        [submitDate]="vm.submitDate"
    ></app-message-logs-table-details-sms>

    <app-message-logs-table-details-whatsapp
        *ngIf="vm.type.whatsapp"
        [messageId]="vm.messageId"
    >

    </app-message-logs-table-details-whatsapp>
</ng-container>
