import { Component, HostBinding, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonSize, ButtonStyle, ButtonType } from './uni-button.model';

@Component({
  selector: 'uni-button',
  templateUrl: './uni-button.component.html',
  styleUrls: ['./uni-button.component.scss'],
})
export class UniButtonComponent {
  @Input() routerLink = '';
  @Input() path: string;
  @Input() queryParams = {};
  @Input() class = '';
  @Input() style: ButtonStyle = 'primary';
  @Input() type: ButtonType = 'button';
  @Input() size: ButtonSize = 'default';
  @Input() isMargin = false;
  @Input() isMarginVertical = false;
  @Input() isMarginHorizontal = false;
  @Input() fit = false;
  @Input() fitContent = false;
  @Input() noVerticalSpace = false;
  @Input() noHorizontalSpace = false;
  @Input() disabled = false;
  @Input() isSuffix = false;
  @Input() autoWidth = false;
  @Input() target = '_self';
  @Input() isRelativePath = false;
  @Input() isInputRelated = false;
  @Input() onClick: () => void = () => {
    if (this.disabled) {
      return;
    }

    if (!!this.routerLink) {
      this.router.navigate([this.routerLink], {
        queryParams: this.queryParams,
        relativeTo: this.isRelativePath ? this.activatedRoute : undefined
      });
    }
  };

  @HostBinding('class') get getClasses(): string {
    return [
      this.class,
      this.disabled ? 'is-disabled' : '',
      this.fit ? 'uni-button--fit' : '',
      this.isSuffix ? 'uni-button--suffix' : '',
      this.isMargin ? 'uni-button--margin' : '',
      this.isMarginVertical ? 'uni-button--margin-vertical' : '',
      this.isMarginHorizontal ? 'uni-button--margin-horizontal' : '',
      this.isInputRelated ? 'uni-button--input-size' : '',
      this.fitContent ? 'uni-button--fit-content' : '',
      `uni-button--${this.size}`,
    ].join(' ');
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}
}
