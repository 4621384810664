import { gql } from "apollo-angular"

export const createReportsDataQuery = (output: string) => {
    return gql`
        query (
            $limit: Int!,
            $offset: Int!
            $userId: String
            $reportId: String
            $orderBy: OrderBy
        ){
        getReportsDataOrByID(
            limit: $limit,
            offset: $offset,
            userId: $userId,
            reportId: $reportId,
            orderBy: $orderBy
        ){
            success, message, count, errorCode, totalCount, entries {
                ${output}
            }
        }
    }
    `

}
