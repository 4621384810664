<uni-box>
    <div class="tiles-item">
        <div class="tiles-item-title">
            <ng-container [ngTemplateOutlet]="titleTemplate ? titleTemplate : defaultTitleTemplate"></ng-container>
        </div>
        
        <h6>{{subtitle}}</h6>
        <p *ngIf="content.length">{{content}}</p>  

        <ng-container [ngTemplateOutlet]="progressBarTemplate ? progressBarTemplate : ''"></ng-container>
    </div>

    <div class="loading-mask" *ngIf="loading">
        <i class="fa fa-spinner fa-spin"></i>
    </div>



</uni-box>


<ng-template #defaultTitleTemplate>
        <h3>{{title}}</h3>
</ng-template>