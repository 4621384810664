
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, map } from "rxjs/operators";

type State = {
    entities: string[]
    source: 'internal' | 'external' | 'initial',
    placeholder: string,
}

const initialState: State = {
    entities: [],
    source: 'initial',
    placeholder: ''
}

@Injectable()
export class FormChipsStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    // Selectors
    readonly entities$ = this.select(state => state.entities)
    readonly source$ = this.select(state => state.source)
    readonly placeholder$ = this.select(state => state.placeholder)


        
    emittedValue$ = this.select(
        this.entities$,
        this.source$,
        (entities, source) => [entities, source],
        {debounce: true}
    ).pipe(
        filter(([_ , source]) => source === 'internal'),
        map(([value, _]) => {
            return value
        })
    )



    vm$ = this.select(
        this.entities$,
        this.placeholder$,
        (entities, placeholder) => {
            return {entities, placeholder}
        }
    )


    // Updaters
    readonly setEntitiesInternally = this.updater((state, entities: string[]) => {
        return {...state, entities: [...entities], source: 'internal'}
    })

    readonly setEntitiesExternally = this.updater((state, entities: string[]) => {
        return {...state, entities: [...entities], source: 'external'}
    })

    readonly addEntity = this.updater((state, entity: string) => {
        return {...state, entities: [...state.entities, entity], source:'internal'}
    })

    readonly removeEntity = this.updater((state, entity: string) => {
        return {...state, entities: [...state.entities.filter(e => e !== entity)], source: 'internal'}
    })

    

}