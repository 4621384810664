import { Component, OnInit, OnChanges, SimpleChanges, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import { HighchartsColors, HIGHCHART_COLORS } from '../highcharts/highcharts.colors';
import { HCToken } from '../highcharts/highcharts.token';
import { HighchartsInstance, HighchartsItem, HighchartsOptions } from '../highcharts/highcharts.types';

@Component({
  selector: 'app-chart-circle-multi',
  templateUrl: './chart-circle-multi.component.html',
  styleUrls: ['./chart-circle-multi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartCircleMultiComponent implements OnInit, OnChanges {

  @Input() backgroundColor: HighchartsColors = 'gray'
  @Input() rotationAngle: number = 0;
  @Input() outerRadius: string = '112%';
  @Input() innerRadius: string = '88%';

  @Input() dataMulti: any[] = [];

  @Input() startAngle: number = 0;
  @Input() endAngle: number = 360;

  @Input() percentage: number;

  constructor( @Inject(HCToken) public hc: HighchartsItem) {}

  cInstance!: HighchartsInstance;

  chartOptions: HighchartsOptions = {
    credits:{
      enabled: false,
    },
    chart:{
      type: 'solidgauge',
      height: 170,
      animation: false,
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: []
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: true,
          borderWidth: 0,
          format: '{point.custom.label}%',
          y: -20,
          style: {
            fontSize: '28px',
            fontFamily: 'Inter, Gotham Rounded',
            fontWeight: "500"
          }
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true
      }
    },
    series: []
  }

  ngOnInit(): void {
    this.startAngle = this.startAngle + this.rotationAngle;
    this.endAngle = this.endAngle + this.rotationAngle;
    this.chartOptions.pane = {
      size: '90%',
      startAngle: this.startAngle,
      endAngle: this.endAngle,
      background: [{
        outerRadius: this.outerRadius,
        innerRadius: this.innerRadius,
        backgroundColor: HIGHCHART_COLORS[this.backgroundColor],
        borderWidth: 0
      }]
    };
    this.chartOptions = { ...this.chartOptions };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataMulti && !changes.dataMulti.firstChange){
      this.redrawChart()
    }
  }

  chartInit(chart: Highcharts.Chart) {
    this.cInstance = chart;
    this.redrawChart()
  }

  redrawChart(){
    if(this.cInstance){
      while(this.cInstance.series.length){
        this.cInstance.series[0].remove()
      }

      this.cInstance.addSeries({
        name: '',
        type: 'solidgauge',
        data: this.dataMulti.map((item, index) => {
          return {
            color: item.color || 'rgba(0,0,0,0)',
            radius: this.outerRadius,
            innerRadius: this.innerRadius,
            y: item.forcedRatio || item.ratio,
            custom: {
              label: this.percentage || item.ratio
            }
          }
        })
      });
      this.cInstance.redraw(true);
    }
  }
}
