import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CreateFormTemplatesStore } from './create-form-templates.store';

@Component({
  selector: 'app-create-form-templates',
  templateUrl: './create-form-templates.component.html',
  styleUrls: ['./create-form-templates.component.scss'],
  providers: [CreateFormTemplatesStore]
})
export class CreateFormTemplatesComponent implements OnInit, ControlValueAccessor {

  @Input() set templates(templates: any[]){
    this.store.patchState({entities: templates})
  }

  vm$ = this.store.vm$

  onChange: any = () => {}
  onTouch: any = () => {}
  value: string = ''

  constructor(
    public ngControl: NgControl,
    private store: CreateFormTemplatesStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
  }

  writeValue(value: string | undefined){
    this.store.patchState({selectedValue: value || ''})
    if(!!value){
      this.value = value
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onCardSelect(selectedValue: any){
    if(!this.ngControl.touched){
      this.onTouch()
    }
    this.store.patchState({selectedValue })
    this.onChange(selectedValue)
  }

}
