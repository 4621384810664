import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MessageLogsRepository } from "../../data-access/message-logs.repository";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";

type State = {
    messageId: string
    loading: boolean
    loaded: boolean
    entity: any
}

const initialState: State = {
    messageId: '',
    loading: false,
    loaded: false,
    entity: {},
}

@Injectable()
export class MessageLogsTableDetailsWhatsappStore extends ComponentStore<State>{

    constructor(
        private messageLogsRepository: MessageLogsRepository,
        private messageLogsFiltersStore: MessageLogsFiltersStore
    ){
        super(initialState)
    }

    //selectors
    readonly messageId$ = this.select(state => state.messageId)
    readonly loading$ = this.select(state => state.loading)
    readonly loaded$ = this.select(state => state.loaded)
    readonly entity$ = this.select(state => state.entity)

    readonly isCommInbound$ = this.select(
        this.entity$,
        (entity) => entity.commDirection === 'INBOUND'
    )


    readonly vm$ = this.select(
        this.messageId$,
        this.loading$,
        this.loaded$,
        this.entity$,
        this.isCommInbound$,
        (id, loading, loaded, entity, isCommInbound) => {
            return {
                id,
                loading,
                loaded,
                entity: {
                    ...entity,
                    messageId: entity.messageId === "-1" ? entity.elMessageId : entity.messageId // According to business requirement. Replace messageId if -1
                },
                isCommInbound
            }
        }
    )

    //effects
    readonly fetchData = this.effect((trigger$) => {
        return trigger$.pipe(
            tap(() => {
                this.patchState({loading: true})
            }),
            withLatestFrom(
                this.messageId$,
                this.messageLogsFiltersStore.accountId$
            ),
            switchMap(([_, messageId, accountId]) => this.messageLogsRepository.qplQueryMessageLogsWhatsappDetails({
                messageId,
                accountId,
            }).pipe(
                tapResponse(
                    (entity) => {
                        this.patchState({loading: false, loaded: true, entity: {...entity}})
                    },
                    () => {
                        this.patchState({loading: false, loaded: false, entity: {}})
                    }

                )
            ))
        )
    })

}
