 <ng-container *ngIf="vm$ | async as vm">
    <div class="autocomplete-select">

    <input type="text"
        #trigger="cdkOverlayOrigin"
        uniInput
        cdkOverlayOrigin
        autocomplete="off"
        [placeholder]="vm.placeholder"
        [attr.ariaLabel]="vm.placeholder"
        [ngModel]="vm.inputValue"
        (focus)="onFocus($event)"
        (ngModelChange)="onInputChange($event)"   
    >
        <span class="nav-icon" *ngIf="vm.loading">
            <i class="fa fa-spin fa-spinner"></i>
        </span>
        <span class="nav-icon" *ngIf="!vm.loading">
            <i class="fa" [ngClass]="vm.open ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </span>
        <span class="nav-icon nav-icon-reset" *ngIf="!vm.loading && vm.isValueSelected && !vm.open" (click)="clear()">
            <i class="fa fa-times"></i>
        </span>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="closeOptions()"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="vm.open"
        [cdkConnectedOverlayWidth]="vm.overlayWidth"
        (overlayKeydown)="keydown($event)"
     
        [cdkConnectedOverlayPositions]="[{
            originX: 'start',
            originY:'bottom',
            overlayX: 'start',
            overlayY: 'top'
        }]"
    >
        <div class="type-list">
            <cdk-virtual-scroll-viewport
                appendOnly
                [itemSize]="vm.dropdownItemHeight"
                [style.height.px]="vm.dropdownHeight"
            >
                <div class="type-list-item" *cdkVirtualFor="let opt of vm.options" (click)="selectItem(opt)">
                    <app-span>{{opt.label}}</app-span>
                </div>
                <div class="type-list-item" *ngIf="!vm.options.length">
                    {{vm.noResultsLabel}}
                </div>
              </cdk-virtual-scroll-viewport>
        </div>
        <div class="loading-mask" *ngIf="vm.optionsLoading">
            <i class="fa fa-spinner fa-spin"></i>
        </div>
  </ng-template>
</ng-container>
