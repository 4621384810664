import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CampaignDetailsRepository } from '../../data-access/campaign-details/campaign-details.repository';
import { CampaignListRepository } from '../../data-access/campaign-list/campaign-list.repository';
import { CampaignListFetchStore } from '../../data-access/campaign-list/campaign-list.store';
import { CampaignIdsSelectorStore } from './campaign-ids-selector.store';
import { TypeaheadSelectComponent } from '../../typeahead-select/typeahead-select.component';

@Component({
  selector: 'app-campaign-ids-selector',
  templateUrl: './campaign-ids-selector.component.html',
  providers: [
    CampaignIdsSelectorStore,
    CampaignListFetchStore,
    CampaignDetailsRepository,
    CampaignListRepository
  ]
})
export class CampaignIdsSelectorComponent implements OnInit {

  constructor(
    private store: CampaignIdsSelectorStore
  ) { }

  @ViewChild('selector') typeahead: TypeaheadSelectComponent 

  @Input() set accountId(accountId: string){
    this.store.patchState({accountId})
  }

  @Input() set typeaheadConfiguration(config: any){
    this.store.patchState({config})
  }

  @Input() set value(label: string){
    this.store.patchState({label})
    this.store.patchState({defaultValue: ''})
  }

  @Input() set channels(channels: string){
    this.store.patchState({channels})
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter()

  vm$ = this.store.vm$

  ngOnInit(): void {
    this.store.fetchLatest()
  }

  onValueChange(event: any){
    if(event.value.length){
      this.valueChange.emit(event)
      this.typeahead?.clear()
    }
  }

  onTypeaheadEvent(phrase){
    this.store.fetchCampaignsByPhrase(phrase)
  }

  reset(){
    this.store.patchState({label: ''})
  }

  onOptionsClose(){
    this.store.patchState({entities: []})
  }
}
