<ng-container *ngIf="vm$ | async as vm">
    <h4>{{'ci.reports.details.reportSummary' | translate}}</h4>
    <div class="table-container">
        <table>
            <tr>
                <td class="label">{{'ci.reports.details.reportId' | translate}}</td>
                <td>{{vm.entity.id}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.reportName' | translate}}</td>
                <td>{{vm.entity.reportName}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.requestedOn' | translate}}</td>
                <td>{{vm.entity.requestOn | dateFormat: 'DD-MM-YYYY HH:mm'}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.generatedOn' | translate}}</td>
                <td>{{vm.entity.generatedOn | dateFormat: 'DD-MM-YYYY HH:mm'}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.status' | translate}}</td>
                <td>{{vm.entity.reportState}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.dateRange' | translate}}</td>
                <td>
                    {{vm.entity.startDate | dateFormat: 'DD-MM-YYYY HH:mm'}} - {{vm.entity.endDate | dateFormat: 'DD-MM-YYYY HH:mm'}}
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <td class="label">{{'ci.reports.details.accountName' | translate}}</td>
                <td>{{vm.entity.accountName}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.user' | translate}}</td>
                <td>{{vm.entity.userId || '-'}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.product' | translate}}</td>
                <td>{{vm.entity.products}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.channel' | translate}}</td>
                <td>{{vm.entity.channels}}</td>
            </tr>
            <tr>
                <td class="label">{{'ci.reports.details.numberOfRecords' | translate}}</td>
                <td>{{vm.entity.totalRecords}}</td>
            </tr>
        </table>
    </div>
</ng-container>