import { ApiMessageLogsListParams, SortingDirection } from "../log-analyser.models"

export const mapSortingOptionsToFilterParams = (sortBy: string, sortDir: SortingDirection): {orderBy?: ApiMessageLogsListParams['orderBy']} => {
    if(!sortBy || !sortDir){
        return {}
    }

    return {
        orderBy: {
            field: sortBy,
            direction: sortDir
        }
    }
}