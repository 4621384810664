import { Component, HostBinding, Input } from '@angular/core';
import { UniSeparatorOrientation, UniSeparatorSpace } from './uni-separator.model';

@Component({
  selector: 'uni-separator',
  templateUrl: './uni-separator.component.html',
  styleUrls: ['./uni-separator.component.scss'],
})
export class UniSeparatorComponent {
  @Input() orientation: UniSeparatorOrientation = 'horizontal';
  @Input() class = '';
  @Input() space: UniSeparatorSpace = '';

  @HostBinding('class') get getClasses(): string {
    return `${this.class} uni-separator--${this.orientation} is-space--${this.space}`;
  }
  @HostBinding('class.is-space') @Input() isSpace = true;
}
