<ng-container *ngIf="vm$ | async as vm">
<div class="refresh-control">
    <span class="refresh-control-timestamp">{{'ci.logAnalyzer.table.lastUpdatedAt' | translate: {date: (vm.timestamp | dateFormat:'hh:mm:ss' )} }}</span>
    <uni-buttons>
        <uni-button
            type="button"
            [style]="'secondary'"
            [isMargin]="true"
            [disabled]="vm.loading"
            (click)="refreshMessageLogs()"
        >
                {{'ci.logAnalyzer.table.refreshButton' | translate}}
                
                <uni-icon [name]="'sync'" *ngIf="!vm.loading"></uni-icon>
                <uni-icon [name]="'spinner fa-spin'" *ngIf="vm.loading"></uni-icon>
        </uni-button>

    </uni-buttons>
</div>
</ng-container>