import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { divideTextByToken } from './typeahead-select.utils';


/**
 * TypeaheadSelectDirective
 * TODO: Extract as separate directive not related to typeahead component
 * TODO: Refactor
 * Use to underline specific phrase in the text. 
 */
@Directive({
  selector: '[appTypeaheadSelect]'
})
export class TypeaheadSelectDirective {

  @Input() color: string = 'inherit'
  @Input() fontWeight: string | number = 'inherit'
  @Input() backgroundColor: string = '#c5f4dd'

  @Input() value: string = ''

  @Input() set phrase(phrase: string){
    if(this.value && !this.value.length){
      return;
    }

    this.el.nativeElement.innerHTML = ''
    const pharsesArray = divideTextByToken(phrase, this.value)

    /**
     * Transform each element of the array from string to html element
     * If string matchs to phrase, special style is added.
     * In the end we need to detect changes to update the colors.
     */
    pharsesArray.forEach(fa => {
      const element = this.transformToSpan(fa, phrase.toLowerCase() === fa.toLowerCase())
      this.el.nativeElement.appendChild(element)
    })
    this.cd.detectChanges()
  }
  
  constructor(private el: ElementRef, private renderer: Renderer2, private cd: ChangeDetectorRef) {}

  private transformToSpan(innerText: string, highlighted: boolean) {
    const element = this.renderer.createElement('span')
    element.innerText = innerText;
  
    if(highlighted){
      element.style.color = this.color
      element.style.backgroundColor = this.backgroundColor
      element.style.fontWeight = this.fontWeight
    }

    return element
  }
}

