
import { rest } from 'msw'

export const generateReport = rest.post('http://0.0.0.0:4200/app/api/v2/message-logs/generate-report', async (req, res, ctx) => {
    var pdf = {}
    return res(
        ctx.set('Content-Type', 'application/pdf'),
        ctx.set('Content-Disposition', 'inline;filename="downloaded.pdf"'),
        ctx.json(pdf)
    )
})

