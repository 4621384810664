<ng-container *ngIf="vm$ | async as vm">
    <form #form="ngForm">
        <div
            [hidden]="!vm.generalFormVisbility"
            class="form-container"
        >
            <uni-box>
                <uni-box-content>
                    <uni-box-content-header>
                        <uni-box-content-header-title>
                            <uni-text-caption>
                                {{'ci.logAnalyzer.forms.general.title' | translate}}
                            </uni-text-caption>
                            <div class="close-btn">
                                <uni-button
                                    type="button"
                                    [style]="'link'"
                                    [isMargin]="false"
                                    [noHorizontalSpace]="true"
                                    [noVerticalSpace]="true"
                                    (click)="close()"
                                    *ngIf="vm.messageLogsLoaded"
                                >
                                    <i class="fa fa-times"></i>
                                </uni-button>
                            </div>
                        </uni-box-content-header-title>
                        <uni-box-content-header-item>
                            {{'ci.logAnalyzer.forms.general.subtitle' | translate}}
                        </uni-box-content-header-item>
                    </uni-box-content-header>

                    <app-general-filters-form></app-general-filters-form>
                    <app-advanced-filters-form></app-advanced-filters-form>

                    <uni-buttons>
                        <uni-button
                            type="button"
                            [style]="'link'"
                            [isMargin]="true"
                            (click)="reset()"
                        >
                            <uni-icon
                                [isRightSpace]="true"
                                name="spinner fa-spin"
                                *ngIf="vm.loading"
                            ></uni-icon>
                            {{'ci.logAnalyzer.forms.resetButton' | translate}}
                        </uni-button>

                        <uni-button
                            type="button"
                            [style]="'primary'"
                            [isMargin]="true"
                            [disabled]="form.invalid || vm.messageLogsLoading"
                            (click)="generate()"
                        >
                            <uni-icon
                                [isRightSpace]="true"
                                name="spinner fa-spin"
                                *ngIf="vm.messageLogsLoading"
                            ></uni-icon>
                            {{'ci.logAnalyzer.forms.submitButton' | translate}}
                        </uni-button>
                    </uni-buttons>
                </uni-box-content>
            </uni-box>
        </div>
    </form>
</ng-container>
