import { Component, ElementRef, Input, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'uni-form-phone',
  templateUrl: './uni-form-phone.component.html',
})
export class UniFormPhoneComponent implements OnInit {
  @ViewChild('phoneRef', { static: false }) phoneRef: ElementRef;
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() tip: string;
  @Input() required = false;
  @Input() isLabel = true;
  @Input() label = '';
  @Input() verified = false;
  @Input() margin = true;
  @Input() disabled = false;
  @Input() error: string;
  @Input() set newPhone(value: boolean) {
    if (!!value) {
      this.instance.setNumber(value);
    }
  }
  phoneValidationLibUrl = 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.17/js/utils.js';

  instance;

  get inputLabel(): string {
    if (!this.isLabel && !this.label) {
      return '';
    }

    return this.isLabel
      ? 'label.phone'
      : this.label;
  }

  ngOnInit() {
    this.control.valueChanges.subscribe(() => this.changeFlag());
  }

  initPhone(instance): void {
    this.instance = instance;
    if (!instance.telInput) {
      return;
    }

    if (this.control.value && this.control.value.length) {
      instance.telInput.value = this.control.value;
      instance.setNumber(this.control.value);
      return;
    }

    instance.setCountry('sa');
    this.control.setValue('+966');
    this.changeFlag();
  }

  onPhoneError(isValid: boolean): void {
    this.control.updateValueAndValidity();

    if (isValid === false) {
      this.control.setErrors({ validPhoneNumber: true });
    }
  }

  onCountryChange(change): void {
    const dialCode = change.dialCode;

    if (!this.control.value.includes('+')) {
      this.control.setValue(`+${ dialCode + this.control.value }`);
    }

    if (!this.phoneRef) {
      return;
    }

    const { value: inputValue } = this.phoneRef.nativeElement;

    if (!!this.phoneRef && !!dialCode && inputValue !== `+${ dialCode }` && inputValue !== '+') {
      this.phoneRef.nativeElement.blur();
    }
  }

  changeFlag() {
    if (!!this.phoneRef) {
      this.phoneRef.nativeElement.dispatchEvent(
        new KeyboardEvent('keyup', { bubbles: true })
      );
    }
  }

  onInputChange(change) {
    this.control.setValue(this.control.value.split(' ').join(''));
  }

  setPlaceholder(selectedCountryPlaceholder) {
    return selectedCountryPlaceholder.replace(/\s/g, '');
  }
}
