import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UniShortNumberService {
  trill = 1000000000000;
  bil   = 1000000000;
  mln   = 1000000;
  thous = 1000;

  constructor(
    private translate: TranslateService,
  ) { }

  formatNumber(price: number): string {
    if (price >= this.trill) {
      return this.translate.instant('format.trillion', {number: Math.floor(price / this.trill)});
    }

    if (price >= this.bil) {
      return this.translate.instant('format.billion', {number: Math.floor(price / this.bil)});
    }

    if (price >= this.mln) {
      return this.translate.instant('format.million', {number: Math.floor(price / this.mln)});
    }

    if (price >= this.thous) {
      return this.translate.instant('format.thousand', {number: Math.floor(price / this.thous)});
    }

    return String(Math.floor(price));
  }
}
