import { get } from 'lodash';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ProductsService } from '../../../shared/products/products.service';
import { KeycloakCookies, UserMe } from './uni-auth.model';
import { DOCUMENT } from '@angular/common';
import { Environment } from '../../../utils/environment.utils';
import mixpanel from 'mixpanel-browser';

@Injectable()
export class UniAuthService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private productsService: ProductsService,
    private cookieService: CookieService,
  ) {}

  getToken(): string {
    if (!this.validSessionId()) {
      return null;
    }

    return localStorage.getItem('access_token');
  }

  getRefreshToken(): string {
    if (!this.validSessionId()) {
      return null;
    }

    return localStorage.getItem('refresh_token');
  }

  getUserMe(): string {
    if (!this.validSessionId()) {
      return null;
    }

    return localStorage.getItem('user_me');
  }

  saveTokens(accessToken, refreshToken): void {
    localStorage.setItem('x_session_id', this.cookieService.get('x_session_id'));
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
  }

  saveUserMe(userMe: UserMe): void {
    localStorage.setItem('user_me', JSON.stringify(userMe));
  }

  isCorrectUser(userMe: UserMe): boolean {
    const savedUserMe = this.getUserMe();

    if (!savedUserMe || savedUserMe === 'undefined') {
      return true;
    }

    const userIdPath = 'user.id';
    const parsedUserMe = JSON.parse(savedUserMe);
    return get(userMe, userIdPath) === get(parsedUserMe, userIdPath);
  }

  clearUserData(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_me');
    localStorage.removeItem('ajs_user_traits');
    localStorage.removeItem('ajs_group_properties');
  }

  retryLogin(): void {
    const currentUrl = window.location.href;

    this.document.location.href = `${ Environment.getMainHost() }/login?_target_path=${ currentUrl }`;
  }

  handleForbiddenAccess(): void {
    this.document.location.href = `${ Environment.getMainHost() }/403`;
  }

  logout(): void {
    this.clearUserData();
    mixpanel?.reset();
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.delete(KeycloakCookies.kcJWT);
    this.cookieService.delete(KeycloakCookies.kcRefreshJWT);
    this.productsService.logout();
  }

  validSessionId(): boolean {
    return this.cookieService.get('x_session_id') === localStorage.getItem('x_session_id');
  }
}
