import { Component, OnInit } from '@angular/core';
import { MessageLogsRefreshControlStore } from './message-logs-refresh-control.store';


@Component({
  selector: 'app-message-logs-refresh-control',
  templateUrl: './message-logs-refresh-control.component.html',
  styleUrls: ['./message-logs-refresh-control.component.scss'],
  providers: [MessageLogsRefreshControlStore]
})
export class MessageLogsRefreshControlComponent implements OnInit {

  constructor(
    private store: MessageLogsRefreshControlStore
  ) { }

  vm$ = this.store.vm$

  ngOnInit(): void {
  }

  refreshMessageLogs(){
    this.store.refreshData()
  }

}
