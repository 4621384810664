import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { ReportsRepository } from "../data-access/reports.repository";
import { ReportsFiltersStore } from "../store/reports-filters.store";
import { Injectable } from "@angular/core";
import { switchMap, tap, withLatestFrom } from "rxjs/operators";
import { BlobUtils, UniSnackbarFacade } from "unifonic-spa-common/src";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

type State = {}

const initialState = {}

@Injectable()
export class PageDownloadStore extends ComponentStore<any>{
    constructor(
        private reportRepository: ReportsRepository,
        private reportFiltersStore: ReportsFiltersStore,
        private snackbar: UniSnackbarFacade,
        private translate: TranslateService,
        private router: Router
    ){
        super(initialState)
    }


    //effect
    readonly downloadReport = this.effect((params$: Observable<any>) => {
        return params$.pipe(
            withLatestFrom(
                this.reportFiltersStore.params$,
            ),
            switchMap(([data, params]) => this.reportRepository.downloadReport({
                reportId: data.reportId,
                extension: data.type.toUpperCase(), 
                userId: params.userId
            }).pipe(
                tapResponse((response) => {
                    const blob = new Blob([response.body], {
                        type: 'octet/stream'
                    })

                    BlobUtils.download(blob, 'unifonic-report.zip',response.headers)

                    this.patchState({
                        downloading: false
                    })
                    this.snackbar.show('success', this.translate.instant('ci.common.downloadFileSuccessMessage'))
                },
                ()=>{
                    this.patchState({ downloading: false })
                    this.snackbar.show('error', this.translate.instant('ci.common.downloadFileErrorMessage'))
                }),
                tap(() => {
                    this.router.navigate(['insights', 'reports'])
                })
            ))
        )
    })
}