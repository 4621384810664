import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { SortData } from "./data-sort.models";


type State = {
    fieldName: string,
    value: SortData,
    isActive: boolean
    
}

const initialState: State = {
    fieldName: '',
    value: ['', '', 'initial'],
    isActive: false
}

@Injectable()
export class DataSortStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    //selectors
    readonly fieldName$ = this.select(state => state.fieldName)
    readonly value$ = this.select(state => state.value)
    readonly valueDebounce$ = this.select(state => state.value, {debounce: true})
    readonly isActive$ = this.select(state => state.isActive)

    readonly eventEmitter$ = this.valueDebounce$.pipe(
        filter(([_ , __, source]) => source === 'internal'),
        map(([sortBy, sortDir, _]) => {
            return [sortBy, sortDir]
        })
    )


    readonly vm$ = this.select(
        this.fieldName$,
        this.isActive$,
        this.valueDebounce$,
        (fieldName, isActive, [_1, dir, _2]) => {
            return {
                fieldName,
                ascActive: isActive && dir === 'ASC',
                descActive: isActive && dir === 'DESC',
            }
        }
    )

    //updaters
    readonly setFieldName = this.updater( (state, fieldName: string) => {
        return {...state, fieldName}
    } )
    
    readonly setValue = this.updater( (state, [sortBy, sortDir, source]: SortData) => {
        let dir = sortDir
        if(source === 'internal'){
            const [_1, currSortDir] = state.value        
            dir = currSortDir !== sortDir ? sortDir : ''
        }

        return {...state, value: [sortBy, dir, source]}
    } )

    readonly setIsActive = this.updater( (state, isActive: boolean) => {
        if(!isActive){
            return {...state, isActive, value: ['', '', 'initial']}
        }
        return {...state, isActive}
    } )


    //effects
    readonly updateByExternalValue = this.effect((value$: Observable<SortData>) => {
        return value$.pipe(
            withLatestFrom(this.value$),
            filter(([[_1, nextDir], [_2, currDir]]) => nextDir !== currDir),
            tap(([[sortBy, sortDir], _]) => {
                this.setValue([sortBy, sortDir, 'external'])
            })
        )
    })
}