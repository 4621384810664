import { Component, Input, OnInit, Output } from '@angular/core';
import { ReportsDataTableDownloadStore } from './reports-data-table-download.store';

@Component({
  selector: 'app-reports-data-table-download',
  templateUrl: './reports-data-table-download.component.html',
  styleUrls: ['./reports-data-table-download.component.scss'],
  providers: [ReportsDataTableDownloadStore]
})
export class ReportsDataTableDownloadComponent implements OnInit {
  @Input() set reportId(value: string){
    this.store.setReportId(value)
  }

  vm$ = this.store.vm$

  constructor(private store: ReportsDataTableDownloadStore) { }

  ngOnInit(): void {
  }

  downloadReport(extension){
    this.store.downloadReport(extension)
  }

  openOptions(){
    this.store.setOpen(true)
  }

  closeOptions(){
    this.store.setOpen(false)
  }

}

