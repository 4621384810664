

import { rest } from 'msw'

const COUNTRIES = [
    {name:"KSA",code:"SAU"},
    {name:"Jordan",code:"JOR"},
    {name:"Egypt",code:"EGY"},
    {name:"Afghanistan",code:"AFG"},
    {name:"Albania",code:"ALB"},
    {name:"Algeria",code:"DZA"},
    {name:"American Samoa",code:"ASM"},
    {name:"Andorra",code:"AND"},
    {name:"Angola",code:"AGO"},
    {name:"Argentina",code:"ARG"},
    {name:"Armenia",code:"ARM"},
    {name:"Aruba",code:"ABW"},
    {name:"Australia",code:"AUS"},
    {name:"Austria",code:"AUT"},
    {name:"Azerbaijan",code:"AZE"},
    {name:"Bahrain",code:"BHR"},
    {name:"Bangladesh",code:"BGD"},
    {name:"Belarus",code:"BLR"},
    {name:"Belgium",code:"BEL"},
    {name:"Belize",code:"BLZ"},
    {name:"Benin",code:"BEN"},
    {name:"Bhutan",code:"BTN"},
    {name:"Bolivia",code:"BOL"},
    {name:"Sint Eustatius",code:"BES"},
    {name:"Bosnia-Herzegovina",code:"BIH"},
    {name:"Botswana",code:"BWA"},
    {name:"Brazil",code:"BRA"},
    {name:"British Indian Ocean Territory",code:"IOT"},
    {name:"Brunei",code:"BRN"},
    {name:"Bulgaria",code:"BGR"},
    {name:"Burkina Faso",code:"BFA"},
    {name:"Burundi",code:"BDI"},
    {name:"Cambodia",code:"KHM"},
    {name:"Cameroon",code:"CMR"},
    {name:"Cape Verde",code:"CPV"},
    {name:"Central Africa Republic",code:"CAF"},
    {name:"Chad",code:"TCD"},
    {name:"Chile",code:"CHL"},
    {name:"China",code:"CHN"},
    {name:"Colombia",code:"COL"},
    {name:"Democratic Republic of Congo",code:"COD"},
    {name:"Cook Islands",code:"COK"},
    {name:"Costa Rica",code:"CRI"},
    {name:"Croatia",code:"HRV"},
    {name:"Cuba",code:"CUB"},
    {name:"Cyprus",code:"CYP"},
    {name:"Czech Republic",code:"CZE"},
    {name:"Denmark",code:"DNK"},
    {name:"Djibouti",code:"DJI"},
    {name:"Ecuador",code:"ECU"},
    {name:"El Salvador",code:"SLV"},
    {name:"Equatorial Guinea",code:"GNQ"},
    {name:"Eritrea",code:"ERI"},
    {name:"Estonia",code:"EST"},
    {name:"Ethiopia",code:"ETH"},
    {name:"Falkland Islands Dependencies",code:"FLK"},
    {name:"Faroe Islands",code:"FRO"},
    {name:"Fiji",code:"FJI"},
    {name:"Finland",code:"FIN"},
    {name:"France",code:"FRA"},
    {name:"French Guiana",code:"GUF"},
    {name:"French Polynesia",code:"PYF"},
    {name:"Gabon",code:"GAB"},
    {name:"Gambia",code:"GMB"},
    {name:"Georgia",code:"GEO"},
    {name:"Germany",code:"DEU"},
    {name:"Ghana",code:"GHA"},
    {name:"Gibraltar",code:"GIB"},
    {name:"Greece",code:"GRC"},
    {name:"Greenland",code:"GRL"},
    {name:"Guadeloupe",code:"GLP"},
    {name:"Guatemala",code:"GTM"},
    {name:"Guernsey",code:"GGY"},
    {name:"Guinea",code:"GIN"},
    {name:"Guinea Bissau",code:"GNB"},
    {name:"Guyana",code:"GUY"},
    {name:"Haiti",code:"HTI"},
    {name:"Honduras",code:"HND"},
    {name:"Hong Kong",code:"HKG"},
    {name:"Hungary",code:"HUN"},
    {name:"Iceland",code:"ISL"},
    {name:"India",code:"IND"},
    {name:"Indonesia",code:"IDN"},
    {name:"Iran",code:"IRN"},
    {name:"Iraq",code:"IRQ"},
    {name:"Ireland",code:"IRL"},
    {name:"Occupied Palestinian Territories",code:"ISR"},
    {name:"Italy",code:"ITA"},
    {name:"Ivory Coast",code:"CIV"},
    {name:"Japan",code:"JPN"},
    {name:"Kazakhstan",code:"KAZ"},
    {name:"Kenya",code:"KEN"},
    {name:"Kiribati",code:"KIR"},
    {name:"Kuwait",code:"KWT"},
    {name:"Kyrgyzstan",code:"KGZ"},
    {name:"Laos",code:"LAO"},
    {name:"Latvia",code:"LVA"},
    {name:"Lebanon",code:"LBN"},
    {name:"Lesotho",code:"LSO"},
    {name:"Liberia",code:"LBR"},
    {name:"Libya",code:"LBY"},
    {name:"Liechtenstein",code:"LIE"},
    {name:"Lithuania",code:"LTU"},
    {name:"Luxembourg",code:"LUX"},
    {name:"Macao",code:"MAC"},
    {name:"Macedonia",code:"MKD"},
    {name:"Madagascar",code:"MDG"},
    {name:"Malawi",code:"MWI"},
    {name:"Malaysia",code:"MYS"},
    {name:"Maldives",code:"MDV"},
    {name:"Mali",code:"MLI"},
    {name:"Malta",code:"MLT"},
    {name:"Marshall Islands",code:"MHL"},
    {name:"Martinique",code:"MTQ"},
    {name:"Mauritania",code:"MRT"},
    {name:"Mauritius",code:"MUS"},
    {name:"Mayotte",code:"MYT"},
    {name:"Mexico",code:"MEX"},
    {name:"Federated States of Micronesia",code:"FSM"},
    {name:"Moldova",code:"MDA"},
    {name:"Mongolia",code:"MNG"},
    {name:"Montenegro",code:"MNE"},
    {name:"Morocco",code:"MAR"},
    {name:"Mozambique",code:"MOZ"},
    {name:"Myanmar",code:"MMR"},
    {name:"Namibia",code:"NAM"},
    {name:"Nauru",code:"NRU"},
    {name:"Nepal",code:"NPL"},
    {name:"Netherlands",code:"NLD"},
    {name:"New Caledonia",code:"NCL"},
    {name:"New Zealand",code:"NZL"},
    {name:"Nicaragua",code:"NIC"},
    {name:"Niger",code:"NER"},
    {name:"Nigeria",code:"NGA"},
    {name:"Niue",code:"NIU"},
    {name:"Norfolk Island",code:"NFK"},
    {name:"North Korea",code:"PRK"},
    {name:"Norway",code:"NOR"},
    {name:"Palestine",code:"PSE"},
    {name:"Oman",code:"OMN"},
    {name:"Pakistan",code:"PAK"},
    {name:"Palau",code:"PLW"},
    {name:"Panama",code:"PAN"},
    {name:"Papua New Guinea",code:"PNG"},
    {name:"Paraguay",code:"PRY"},
    {name:"Peru",code:"PER"},
    {name:"Philippines",code:"PHL"},
    {name:"Poland",code:"POL"},
    {name:"Portugal",code:"POR"},
    {name:"Qatar",code:"QAT"},
    {name:"Romania",code:"ROU"},
    {name:"Rwanda",code:"RWA"},
    {name:"Samoa",code:"WSM"},
    {name:"San Marino",code:"SMR"},
    {name:"Sao Tome and Principe",code:"STP"},
    {name:"Senegal",code:"SEN"},
    {name:"Serbia",code:"SRB"},
    {name:"Seychelles",code:"SYC"},
    {name:"Sierra Leone",code:"SLE"},
    {name:"Singapore",code:"SGP"},
    {name:"Slovakia",code:"SVK"},
    {name:"Slovenia",code:"SVN"},
    {name:"Solomon Islands",code:"SLB"},
    {name:"Somalia",code:"SOM"},
    {name:"South Africa",code:"ZAF"},
    {name:"South Korea",code:"KOR"},
    {name:"South Sudan",code:"SSD"},
    {name:"Spain",code:"ESP"},
    {name:"Sri Lanka",code:"LKA"},
    {name:"St Helena",code:"SHN"},
    {name:"St Pierre and Miquelon",code:"SPM"},
    {name:"Sudan",code:"SDN"},
    {name:"Surinam",code:"SUR"},
    {name:"Swaziland",code:"SWZ"},
    {name:"Sweden",code:"SWE"},
    {name:"Switzerland",code:"CHE"},
    {name:"Syrian Arab Republic",code:"SYR"},
    {name:"Taiwan",code:"TWN"},
    {name:"Tajikistan",code:"TJK"},
    {name:"Tanzania",code:"TZA"},
    {name:"Thailand",code:"THA"},
    {name:"Timor-Leste",code:"TLS"},
    {name:"Togo",code:"TGO"},
    {name:"Tonga",code:"TON"},
    {name:"Tunisia",code:"TUN"},
    {name:"Turkey",code:"TUR"},
    {name:"Turkmenistan",code:"TKM"},
    {name:"Tuvalu",code:"TUV"},
    {name:"Uganda",code:"UGA"},
    {name:"Ukraine",code:"UKR"},
    {name:"United Arab Emirates",code:"ARE"},
    {name:"Uruguay",code:"URY"},
    {name:"Uzbekistan",code:"UZB"},
    {name:"Vanuatu",code:"VUT"},
    {name:"Venezuela",code:"VEN"},
    {name:"Vietnam",code:"VNM"},
    {name:"Wallis and Futuna",code:"WLF"},
    {name:"Yemen",code:"YEM"},
    {name:"Zambia",code:"ZMB"},
    {name:"Zimbabwe",code:"ZWE"},
    {name:"Congo Republic",code:"COG"},
    {name:"Principality of Monaco",code:"MCO"}
]
export const getCountriesList = rest.get('http://0.0.0.0:4200/app/api/v2/countries', async (req, res, ctx) => {
  console.log('%c MSW query param string:' + req.url.search, 'font-weight: bold')
  // const numberOfResults = 0

  return res(
    ctx.json(COUNTRIES)
  )
})