
<uni-label [required]="true">
    {{'ci.common.atTime' | translate}}
</uni-label>

<app-autocomplete-select
    [currentValue]="value"
    [options]="times$ | async"
    [noResoultsLabel]="'ci.common.noResults' | translate"
    [placeholder]="'ci.common.selectTime' | translate"
    (valueChange)="onValueChange($event)"
></app-autocomplete-select>
