import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { filter, map, tap, withLatestFrom } from "rxjs/operators";
import momentTimezone from 'moment-timezone';


type FieldType = 'date' | 'period'
export type UpdateSource = 'external' | 'internal' | 'initial'
type FieldValue = [string, UpdateSource]
const nonActionValues = ['_selectDate', '_selectPeriod']

type State = {
    type: FieldType
    currentValue: FieldValue,
    dateRangeLabel: string,
    defaultDateRange: string,
    defaultTimePeriod: string,
    blacklist: string[],
    clearable: boolean,
    timezone: string,
    periodEntities: any[]
    periodPlaceholder: string
}

const initialState: State = {
    type: 'period',
    currentValue: ['', 'initial'],
    dateRangeLabel: '',
    defaultDateRange: '_selectDate',
    defaultTimePeriod: '_selectPeriod',
    blacklist: [],
    clearable: true,
    timezone: 'UTC',
    periodEntities: [],
    periodPlaceholder: ""
}

@Injectable()
export class FormPeriodDatePickerStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    //selectors
    readonly type$ = this.select(state => state.type)
    readonly currentValue$ = this.select(state => state.currentValue)
    readonly dateRangeLabel$ = this.select(state => state.dateRangeLabel)
    readonly blacklist$ = this.select(state => state.blacklist)
    readonly clearable$ = this.select(state => state.clearable)
    readonly timezone$ = this.select(state => state.timezone)
    readonly periodEntities$ = this.select(state => state.periodEntities)
    readonly defaultDateRange$ = this.select(state => state.defaultDateRange)
    readonly periodPlaceholder$ = this.select(state => state.periodPlaceholder)

    readonly periodCurrentValue$ = this.currentValue$.pipe(
        withLatestFrom(this.type$),
        filter(([[value, _], type]) => type === 'period'),
        map(([[value, _], type]) => {
            return value
        })
    )

    readonly dateCurrentValue$ = this.currentValue$.pipe(
        withLatestFrom(this.type$),
        filter(([[value, _], type]) => type === 'date'),
        map(([[value, _], type]) => {
            return value
        })
    )

    readonly vm$ = this.select(
        this.type$,
        this.currentValue$,
        this.dateRangeLabel$,
        this.timezone$,
        this.periodEntities$,
        (type, [value, _] , dateRangeLabel, timezone, periodEntities) => {

            let periodCurrentValue;
            let dateCurrentValue;
            
            if(type === 'date'){
                const from = value.split('_')[0]
                const to = value.split('_')[1]

                const f = momentTimezone.tz(from, timezone).format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                const t = momentTimezone.tz(to, timezone).startOf('d').format('YYYY-MM-DDTHH:mm:ss') + 'Z'

                dateCurrentValue = `${f}_${t}`
            } 

            if(type === 'period'){
                periodCurrentValue = value 
            }
            
            return {
                isDate: type === 'date',
                isPeriod: type === 'period',
                periodCurrentValue,
                dateCurrentValue,
                dateRangeLabel,
                timezone,
                periodEntities
            }
        }
    )

    readonly emitValue$ = this.currentValue$.pipe(
        filter(([_, source]) => source === 'internal'),
        map(([value, _]) => value),
        withLatestFrom(
            this.blacklist$
        ),
        filter(([value, blacklist]) => blacklist.indexOf(value) === -1),
        map(([value, _]) => value),
        map(value => {
            if(nonActionValues.indexOf(value) !== -1){
                return ''
            }
            return value;
        })
    )

    //updaters
    readonly selectType = this.updater((state, type: FieldType) => {
        return {...state, type}
    })

    readonly setCurrentValue = this.updater((state, value: FieldValue) => {
        return {...state, currentValue: [...value]}
    })

    readonly setCurrentValueToDefaultDateRange = this.updater((state) => {
        return {...state, currentValue: [state.defaultDateRange, 'internal']}
    })

    readonly setCurrentValueToDefaultTimePeriod = this.updater((state) => {
        return {...state, currentValue: [state.defaultTimePeriod, 'internal']}
    })

    readonly setDateRangeLabel = this.updater((state, dateRangeLabel: string) => {
        return {...state, dateRangeLabel}
    })

    readonly setBlacklist = this.updater((state, blacklist: string[]) => {
        return {...state, blacklist: [...blacklist]}
    })

    readonly setClearable = this.updater((state, clearable: boolean) => {
        return {...state, clearable}
    })

    readonly setDefaultDateRange = this.updater((state, defaultDateRange: string) => {
        return {...state, defaultDateRange}
    })

    readonly setDefaultTimePeriod = this.updater((state, defaultTimePeriod: string) => {
        return {...state, defaultTimePeriod}
    })

    readonly setTimezone = this.updater((state, timezone: string) => {
        return {...state, timezone}
    })
    
    readonly setPeriodEntities = this.updater((state, periodEntities: any[]) => {
        return {...state, periodEntities: [...periodEntities]}
    })
    


    //effects
    readonly changeCurrentValue = this.effect((dates$: Observable<any>) => {
        return dates$.pipe(
            withLatestFrom(this.timezone$),
            tap(([dates, timezone]) => {
                const from = typeof dates.from === 'string'
                    ? momentTimezone(dates.from).utc().format('YYYY-MM-DDTHH:mm:ss')
                    : dates.from.format('YYYY-MM-DDTHH:mm:ss')

                const to = typeof dates.to === 'string'
                    ? momentTimezone(dates.to).utc().format('YYYY-MM-DDTHH:mm:ss')
                    :dates.to.format('YYYY-MM-DDTHH:mm:ss')
               
                const fromTz = momentTimezone.tz(from, timezone).utc().format()
                const toTz = momentTimezone.tz(to, timezone).endOf('d').utc().format()

                const value = `${fromTz}_${toTz}`

                this.setCurrentValue([value, 'internal'])
            }))
    })

    readonly calculateFieldType = this.effect(() => {
        return this.currentValue$.pipe(
            tap(([value, _]) => {
                if(value === '_selectPeriod'){
                    this.patchState({type: 'period'})
                    return 
                }

                if(value === '_selectDate'){
                    this.patchState({type: 'date'})
                    return
                }

                if(value === ''){
                    this.patchState({type: 'period'})
                    return
                }

                if(value === null){
                    return;
                }

                if(
                    value.indexOf('rt_') !== -1
                    || value.indexOf('last') !== -1 
                    || value.indexOf('this') !== -1 
                    || value.indexOf('today') !== -1 
                    || value === ''
                ){
                    this.patchState({type: 'period'})
                }else{
                    this.patchState({type: 'date'})
                }
            }))
        }
    )
}

