import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageLogsMessageTypeMockStore } from '../../store/message-logs-message-type-mock.store';
import { FiltersFormStore } from './filter-forms.store';
import { MessageLogsLayoutStore } from "../../store/message-logs-layout.store";

@Component({
  selector: 'app-filter-forms',
  templateUrl: './filter-forms.component.html',
  styleUrls: ['./filter-forms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FiltersFormStore,
    MessageLogsMessageTypeMockStore
  ]
})

export class FilterFormsComponent implements OnInit {

  @Output() onSubmit: EventEmitter<void> = new EventEmitter()

  constructor(
    private store: FiltersFormStore,
    private messageLogsLayoutStore: MessageLogsLayoutStore
  ) { }

  vm$ = this.store.vm$

  ngOnInit(): void {
  }

  generate(){
    this.onSubmit.emit()
    this.store.submitForm()
  }

  reset(){
    this.store.resetForm()
  }


  close(){
    this.messageLogsLayoutStore.setGeneralFiltersFormVisbility(false);
    this.messageLogsLayoutStore.setFilterPanelVisibility(true);
  }
}
