import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map } from "rxjs/operators";
import { DateRangeMockStore } from "src/app/shared/store-mocks/date-range.store";
import { UniAuthFacade } from "unifonic-spa-common/src";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";


type State = {
}

const initialState: State = {
 
}

@Injectable()
export class FilterPanelDateStore extends ComponentStore<State>{
    constructor(
        private authFacade: UniAuthFacade,
        private messageLogsFiltersStore: MessageLogsFiltersStore,
        private dateRangeMockStore: DateRangeMockStore,
    ){
        super(initialState)
    }

    //selectors

    timezone$ = this.authFacade.userMe$.pipe(
        map((user: any) => user.user.timezone)
    )

    readonly entities$ = this.select(
        this.dateRangeMockStore.today$,
        this.dateRangeMockStore.last7d$,
        this.dateRangeMockStore.last30d$,
        this.dateRangeMockStore.this1w$,
        this.dateRangeMockStore.this1m$,
        this.dateRangeMockStore.last1m$,
        this.dateRangeMockStore.selectDate$,
    
        (...entities) => {
            return [
                ...entities
            ]
        } 
    )

    vm$ = this.select(
        this.messageLogsFiltersStore.dateRange$,
        this.timezone$,
        this.entities$,
        this.dateRangeMockStore.defaultDateRange$,
        (currentDate, timezone, periods, defaultDateRange) => {
            return {
                currentDate,
                timezone,
                periods,
                defaultDatePeriod: 'last7d',
                defaultDateRange
            }
        }
    )

    //updaters

    //effects

    
}

