import { Injectable } from "@angular/core"
import { ComponentStore, tapResponse } from "@ngrx/component-store"
import { uniqBy } from "lodash"
import { Observable, of } from "rxjs"
import { map, switchMap, tap, withLatestFrom } from "rxjs/operators"
import { UniAuthFacade } from "unifonic-spa-common/src"
import { Channels } from "../../store-mocks/channels.store"
import { CustomerReasonRepository } from "../../data-access/customer-reason/customer-reason.repository"
import { CustomerReasonFetchStore } from "../../data-access/customer-reason/customer-reason.store"


type State = {
    label: string
    loading: boolean
    defaultValue: string
    entities: any[]
}

const initialState: State = {
  label: "",
  loading: false,
  defaultValue: '',
  entities: []
}


@Injectable()
export class CustomerReasonSelectorStore extends ComponentStore<State>{

    constructor(
        private customerReasonFetch: CustomerReasonFetchStore,
        private repository: CustomerReasonRepository,
        private customerReasonRepository: CustomerReasonRepository,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    readonly label$ = this.select(state => state.label)
    readonly defaultValue$ = this.select(state => state.defaultValue)
    readonly entities$ = this.select(state => state.entities)


    readonly customerReasons$ = this.select(
        this.entities$,
        (entities) => uniqBy([...entities], 'value')
    )

    readonly loading$ = this.customerReasonFetch.loading$

    vm$ = this.select(
        this.customerReasons$,
        this.loading$,
        this.label$,
        this.defaultValue$,
        (
            customerReasons,
            loading,
            label,
            defaultValue,
        ) => {
            return {
                customerReasons,
                loading,
                label,
                defaultValue
            }
        }
    )


    readonly onFormValueChange = this.effect((event$: Observable<any>) => {
        return event$.pipe(
            tap((event:any) => {
                this.patchState({label: event.value})
            })
        )
    })

    readonly fetchLatest = this.effect((trigger$: Observable<void>) => {
        return trigger$.pipe(
            tap(() => {
                this.patchState({loading: true})
            }),
            switchMap(() => this.customerReasonRepository.gqlQueryCustomerReason(
                    {},
                    ``
                ).pipe(
                    map((response) => {
                        return response.entries.map(e => ({
                            label: e,
                            value: e
                        }))
                    }),
                    tapResponse(
                        (entities) => {
                            this.patchState({entities: entities})
                            this.patchState({loading: false})
                        },() => {}
                    )
                )
            )
        )
    })
}


