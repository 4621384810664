
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {}

const initialState: State = {}

@Injectable()
export class AdvancedFiltersFormSenderNameStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
    ){
        super(initialState)
    }

    //selectors
    readonly senderNameValue$ = combineLatest([
        this.filtersFormStore.senderName$,
        this.advancedFiltersFormStore.senderNameVisibility$
    ]).pipe(
        map(([value, visibility]) => visibility ? value : '')
    )
}