import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AdvancedFiltersFormSenderNumberStore } from '../advanced-filters-form-sender-number/advanced-filters-form-sender-number.store';
import { AdvancedFiltersFormLinkStore } from './advanced-filters-form-link.store';
import { map } from 'rxjs/operators';
import { SenderNameFacade } from 'unifonic-spa-common/src';
import { MccCampaignsFetchStore } from '../../store/mcc-campaigns-fetch.store';

@Component({
  selector: 'app-advanced-filters-form-link',
  templateUrl: './advanced-filters-form-link.component.html',
  styleUrls: ['./advanced-filters-form-link.component.scss'],
  providers: [AdvancedFiltersFormLinkStore, MccCampaignsFetchStore],
})
export class AdvancedFiltersFormLinkComponent implements OnInit, OnDestroy, ControlValueAccessor {

  senderNames$ = this.senderNameFacade.senderNames$.pipe(
    map((accounts) => accounts ? accounts : []),
    map((accounts) => {
      return accounts.map(a => ({label: a.name, value: a.name}) )
    }),
    map(names => {
      return names.sort((a: any, b: any) => a.label > b.label ? 1 : -1)
    })
  )

  senderNamesLoading$ = this.senderNameFacade.isLoading$
  linkOptions$ = this.store.linkOptions$

  vm$ = this.store.vm$
  

  value = ''

  onChange: any = () => {}
  onTouch: any = () => {}

  constructor(
    public ngControl: NgControl,
    private senderNameFacade: SenderNameFacade,
    private store: AdvancedFiltersFormLinkStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){
    this.saveValue(value)
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(value: string[]){
    this.saveValue(value);
  }

  saveValue(value){
    this.value = value
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
