import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";

type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: string
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities:[{
        label: 'In Progress',
        value: 'IN_PROGRESS'
      },{
        label: 'Completed',
        value: 'COMPLETED'
      }]
}

@Injectable()
export class CampaignStatusMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors

    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)


    viewEntities$ = this.select(
      this.entities$,
      (entities) => entities.map(e => {
        return {
          ...e,
          label: this.translateService.instant('ci.common.brand.' + e.value.toLowerCase()) // TODO: backend takes data with white spaces so we need to reformat
        }
      })
    )

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}