import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UniAuthFacade } from '@unifonic/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-create-form-notification',
  templateUrl: './create-form-notification.component.html',
  styleUrls: ['./create-form-notification.component.scss']
})
export class CreateFormNotificationComponent implements OnInit, ControlValueAccessor {

  onChange: any = () => {}
  onTouch: any = () => {}
  value: string[] = []

  phoneNumber$ = this.authFacade.userMe$.pipe(map(userMe => userMe.user.phone))
  email$ = this.authFacade.userMe$.pipe(map(userMe => userMe.user.email))

  constructor(
    public ngControl: NgControl,
    private authFacade: UniAuthFacade
  ) {
    ngControl.valueAccessor = this;
  }


  ngOnInit(): void {
      
  }

  writeValue(value: string){
    if(value){
      this.value = value.split(',')
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onToggleCheckbox({checked}, type){
    if(!this.ngControl.touched){
      this.onTouch()
    }
    
    if(checked){
      this.value = [...this.value, type]
    }else{
      this.value = this.value.filter(v => v !== type)
    }
    this.onChange(this.value.join(','))
  }

}
