import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-progress-bar',
  templateUrl: './card-progress-bar.component.html',
  styleUrls: ['./card-progress-bar.component.scss']
})
export class CardProgressBarComponent implements OnInit {

  @Input() percent: number = 0

  constructor() { }

  ngOnInit(): void {
  }

}
