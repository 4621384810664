import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AccountOption, InputValueSource, TypeaheadSelectStore } from './typeahead-select.store';

/**
 * Typeahead Select Component
 * 
 * It allows to fetch items from external resources - server by typing the part of the name of searching resource
 * 
 * It should be used to pre-filter substantional amount of data from server for the sake of performance
 * 
 * It emits event whenever user select the option from list
 * It emits event whenever user stop typing in the input value (after debounceTime)
 * 
 * TODO: Split into at least 3 separate components (input field, icons, options list)
 */
@Component({
  selector: 'app-typeahead-select',
  templateUrl: './typeahead-select.component.html',
  styleUrls: ['./typeahead-select.component.scss'],
  providers: [TypeaheadSelectStore]
})
export class TypeaheadSelectComponent {

  @ViewChild('trigger', {read: ElementRef}) trigger!: ElementRef

  @Input() set labelValue(value: string){
      if(typeof value === 'string'){
        this.store.patchState({
          inputValue: [value, '', {}, InputValueSource.Initial],
        })
      }
  }

  @Input() set minPhraseLength(minPhraseLength: number){
    this.store.patchState({minPhraseLength})
  }

  @Input() set maxOptionsSize(maxOptionsSize: number){
    this.store.patchState({maxOptionsSize})
  }

  @Input() set options(options: AccountOption[] ){
    this.store.patchState({options: [...(options || [])]})
  }

  @Input() set listLoading(listLoading: boolean){
    this.store.patchState({listLoading})
  }

  @Input() set labelLoading(labelLoading: boolean){
    this.store.patchState({labelLoading})
  }

  @Input() set debounceTime(debounceTime: number){
    this.store.patchState({debounceTime})
  }

  @Input() set noResultMessage(noResultMessage: string){
    this.store.patchState({noResultMessage})
  }

  @Input() set placeholder(placeholder: string){
    this.store.patchState({placeholder})
  }

  @Input() set tooManyItemsMessage(tooManyItemsMessage: string){
    this.store.patchState({tooManyItemsMessage})
  }

  @Output() valueChange = this.store.eventEmitter$
  @Output() typeahead = this.store.phraseEmitter$
  @Output() onListClose = this.store.closeOptionsEmitter$

  constructor(private store: TypeaheadSelectStore) { }

  vm$ = this.store.vm$

  /**
   * Clicking on backdrop without selection.
   * Reset input value to initial state
   * It sets current value as empty string
   */
  closeOptions(){
    this.store.patchState({
      inputValue: ['', '', {}, InputValueSource.Initial],
      open: false
    })
  }

  /**
   * On text input focus
   * It opens the dropdown / overlay
   * It checks the width of input and set overlay width accordingly
   * TODO: overlayWidth should be integrated with CDK Breakpoint
   */
  onFocus(event: any){
    this.store.patchState({
      overlayWidth: this.trigger.nativeElement.offsetWidth,
      open: true
    })
  }

  /**
   * On user selection
   * It sets input value to the one from selected item
   * It sets the current value (internal change)
   * It closes the overlay
   */
  selectItem(item: AccountOption){
    this.store.patchState({
      inputValue: [item.label, item.value, item.data || {}, InputValueSource.Selecting], 
      open: false
    })
  }

  /**
   * Sync the store value with the one from the input. Done by ngModel directive - ngModelChange
   */
  onInputChange(value: string){
    this.store.patchState({
      inputValue: [value, '', {}, InputValueSource.Typing]
    })
  }

  /**
   * Handle Esc button click, close and blur input on esc press
   * Esc button by default closes the backdrop
   */
  keydown(event: KeyboardEvent){
    if(event.code === 'Escape'){
      this.closeOptions()
      this.trigger.nativeElement.blur()
    }
  }

  /**
   * It clears value
   * It set input value to empty string with initial type
   * It sets current value to empty string internal (which emits event)
   */
  clear(){
    this.store.patchState({
      inputValue: ['', '', {}, InputValueSource.Selecting]
    })
  }
}
