
<div class="tab-header-selector">
    <uni-buttons>
        <ng-container *ngFor="let button of buttons">
            <uni-button
                type="button"
                [style]="active === button.value ? 'primary' : 'link'"
                [isMargin]="true"
                (click)="selectTab(button.value)"
            >
                {{button.label}}
            </uni-button>
        </ng-container>
    </uni-buttons>
</div>