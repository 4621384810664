import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { omitBy } from "lodash";


type State = {
    reportId: string
    type: string
    typeOptions: any[]
}

const initialState: State = {
    reportId: '',
    type: '',
    typeOptions: [{
        label: 'Conversation',
        value: 'CONVERSATION'
    }, {
        label: 'Financial',
        value: 'FINANCIAL'
    }, {
        label: 'Traffic',
        value: 'TRAFFIC'
    }]
}

@Injectable()
export class ReportsDataTableFiltersStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    //selectors
    readonly reportId$ = this.select(state => state.reportId)
    readonly type$ = this.select(state => state.type)
    readonly typeOptions$ = this.select(state => state.typeOptions)

    readonly vm$ = this.select(
        this.reportId$,
        this.type$,
        this.typeOptions$,
        (reportId, type, typeOptions) => {
            return {
                reportId,
                type,
                typeOptions
            }
        }, {debounce: true}
    )

    readonly params$ = this.select(
        this.reportId$, 
        this.type$,
        (reportId, type) => {
            return omitBy({
                reportId,
                type
            }, (v) => v === null || v === undefined || v === '')
        }
    )

}

