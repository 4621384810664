import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-loader',
  templateUrl: './uni-loader.component.html',
  styleUrls: ['./uni-loader.component.scss']
})
export class UniLoaderComponent {
  @HostBinding('class.is-fullscreen') @Input() isFullScreen = false;
  @HostBinding('class.is-absolute') @Input() isAbsolute = false;
  @HostBinding('class.is-grey') @Input() isGrey = false;
}
