import { omitBy } from 'lodash';
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { SortData } from '../../shared/data-sort/data-sort.models';
import { UniAuthFacade } from 'unifonic-spa-common/src';
import { map } from 'rxjs/operators';
import { mapSortingOptionsToFilterParams } from '../utils/map-sorting-options-to-filter-params';



type State = {

    limit: number
    page: number
    sortBy: string,
    sortDir: "ASC" | "DESC" | "",
    userId: string

}

const initialState: State = {

    limit: 15,
    page: 1,
    sortBy: '',
    sortDir: '',
    userId: ''
}

@Injectable()
export class ReportsFiltersStore extends ComponentStore<State>{
    constructor(
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors
    readonly page$ = this.select(state => state.page)
    readonly limit$ = this.select(state => state.limit)

    readonly sortBy$ = this.select(state => state.sortBy)
    readonly sortDir$ = this.select(state => state.sortDir)

    readonly userId$ = this.select(
        this.authFacade.userMe$.pipe(map( (userMe:any) => userMe?.user.id)),
        (userId) => userId
    )


    readonly params$: Observable<any> = this.select(

        this.limit$,
        this.page$,
        this.sortBy$,
        this.sortDir$,
        this.userId$,
        (limit, page, sortBy, sortDir, userId) => {

            return omitBy({
                limit,
                offset: (page - 1) * limit,
                ...mapSortingOptionsToFilterParams(sortBy, sortDir),
                userId
            }, (v) => v === null || v === undefined || v === '')
        }, {debounce: true}
    )




    //updaters
    readonly setPage = this.updater((state, page: number) => {
        return {...state, page}
    })

    readonly setSortingOptions = this.updater((state, sort: SortData) => {
        return {...state, sortBy: sort[0], sortDir: sort[1], page: 1}
    })

    readonly setTemplates = this.updater((state, templates: string) => {
        return {...state, templates}
    })

}

