import { Component, OnInit } from '@angular/core';
import { CreateFormDateRangeRecurringStore } from './create-form-date-range-recurring.store';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DateRangeMockStore } from 'src/app/shared/store-mocks/date-range.store';

@Component({
  selector: 'app-create-form-date-range-recurring',
  templateUrl: './create-form-date-range-recurring.component.html',
  styleUrls: ['./create-form-date-range-recurring.component.scss'],
  providers: [CreateFormDateRangeRecurringStore, DateRangeMockStore]

})
export class CreateFormDateRangeRecurringComponent implements OnInit, ControlValueAccessor {

  constructor(
    public ngControl: NgControl,
    private store: CreateFormDateRangeRecurringStore
  
  ) { 
    ngControl.valueAccessor = this;
  }

  vm$ = this.store.vm$
  value: any = ''

  onChange: any = () => {}
  onTouch: any = () => {}



  ngOnInit(): void {


  }

  writeValue(value: string | undefined){
    if(value){
      this.value = value
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }


  dateRangeOptionChange(value: any){
    this.modelChange(value)
  }


  modelChange(value: string){
    if(!this.ngControl.touched){
      this.onTouch()
    }

    this.onChange(value)
  }

}
