import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl } from '@angular/forms';

@Component({
  selector: 'app-create-form-name',
  templateUrl: './create-form-name.component.html',
  styleUrls: ['./create-form-name.component.scss']
})
export class CreateFormNameComponent implements OnInit, ControlValueAccessor {
  onChange: any = () => {}
  onTouch: any = () => {}
  value: string =''

  constructor(
    public ngControl: NgControl,
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {

  }

  writeValue(value: string | undefined){
    if(value){
      this.value = value
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onInputChange(event: any){
    if(!this.ngControl.touched){
      this.onTouch()
    }
    this.onChange(event)
  }

}
