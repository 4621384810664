import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tile-key-value-indicator',
  templateUrl: './tile-key-value-indicator.component.html',
  styleUrls: ['./tile-key-value-indicator.component.scss']
})
export class TileKeyValueIndicatorComponent implements OnInit {
  @Input() value: number = 0;
  @Input() label: string = '';
  @Input() btnLabel: string = ''
  @Input() type: 'success' | 'danger' | 'none' = 'none'
  @Input() queryParams: any = {}

  constructor() { }

  ngOnInit(): void {
  }

}
