import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";

type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: string
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [{
        label: 'Warning',
        value: 'WARNING'
      },{
        label: 'Educational',
        value: 'EDUCATIONAL'
      },{
        label: 'Services',
        value: 'SERVICE'
      },{
        label: 'Personal',
        value: 'PERSONAL'
      },{
        label: 'Promotional',
        value: 'PROMOTIONAL'
      }
    ]
}

@Injectable()
export class MessageTypeMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors

    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    viewEntities$ = this.select(
      this.entities$,
      (entities) => {
        return entities.map(e => {
          return {
            ...e,
            label: this.translateService.instant('ci.common.brand.' + e.value.toLowerCase())
          }
        })
      }
    )


    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}