import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AdvancedFiltersFormSenderNumberStore } from '../advanced-filters-form-sender-number/advanced-filters-form-sender-number.store';
import { AdvancedFiltersFormButtonStore } from './advanced-filters-form-button.store';
import { map } from 'rxjs/operators';
import { SenderNameFacade } from 'unifonic-spa-common/src';
import { MccCampaignsFetchStore } from '../../store/mcc-campaigns-fetch.store';

@Component({
  selector: 'app-advanced-filters-form-button',
  templateUrl: './advanced-filters-form-button.component.html',
  styleUrls: ['./advanced-filters-form-button.component.scss'],
  providers: [AdvancedFiltersFormButtonStore, MccCampaignsFetchStore],
})
export class AdvancedFiltersFormButtonComponent implements OnInit, OnDestroy, ControlValueAccessor {


  buttonOptions$ = this.store.buttonOptions$

  vm$ = this.store.vm$

  value = ''

  onChange: any = () => {}
  onTouch: any = () => {}

  constructor(
    public ngControl: NgControl,
    private store: AdvancedFiltersFormButtonStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){
    this.saveValue(value)
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(value: string[]){
    this.saveValue(value);
  }

  saveValue(value){
    this.value = value
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
