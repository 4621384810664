import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UniPage404Component, UniPage403Component } from '@unifonic/common';

export const errorRoutes: Routes = [
  {
    path: '403',
    component: UniPage403Component,
    data: {
      titleKey: 'pageTitle.403'
    }
  },
  {
    path: '404',
    component: UniPage404Component,
    data: {
      titleKey: 'pageTitle.404'
    }
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forChild(errorRoutes)],
  exports: [RouterModule],
})
export class ErrorRoutingModule { }
