import { Injectable } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store"


type State = {
    accountId: string
}

const initialState: State = {
    accountId: ''
}

@Injectable()
export class CreateFromCampaignIdStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    readonly accountId$ = this.select(state => state.accountId)
}


