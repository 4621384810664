import { NgModule } from '@angular/core';
import { LoadingMaskModule } from '../loading-mask/loading-mask.module';
import { FormTimepickerComponent } from './form-timepicker.component';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { UniButtonModule } from 'unifonic-spa-common/src';

@NgModule({
    declarations: [FormTimepickerComponent],
    exports: [FormTimepickerComponent],
    imports: [
        CommonModule,
        OverlayModule,
        LoadingMaskModule,
        UniButtonModule
    ]
})
export class FormTimepickerModule { }
