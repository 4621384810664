import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { FormButtonSelectorItem, FormButtonSelectorMode } from './form-button-selector.models';
import { FormButtonSelectorStore } from './form-button-selector.store';

@Component({
  selector: 'app-form-button-selector',
  templateUrl: './form-button-selector.component.html',
  styleUrls: ['./form-button-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormButtonSelectorStore]
})
export class FormButtonSelectorComponent implements OnInit {

  @Input() set data(values){
    this.store.setItems(values);
  }

  @Input() set mode(mode: FormButtonSelectorMode){
    this.store.setMode(mode);
  }

  @Input() set disabled(disabled: string[]){
    this.store.setDisabledItems(disabled)
  }

  @Input() set selected(value: string[]){
    this.store.setSelectedItems(value)
  }

  @Output() onButtonSelect: Observable<string[]> = this.store.selectedItems$.pipe(skip(1))
  @Output() onValueChange: Observable<string[]> = this.store.selectedValues$.pipe(skip(1))
  @Output() onLabelChange: Observable<string[]> = this.store.selectedLabels$.pipe(skip(1))
  @Output() onDataChange: Observable<FormButtonSelectorItem[]> = this.store.selectedData$

  constructor(private store: FormButtonSelectorStore) { }

  vm$ = this.store.vm$
  
  ngOnInit(): void { 
    this.store.checkConfigConflicts()
  }

  selectOption(option: FormButtonSelectorItem){
    this.store.selectOption(option.value)
  }
}
