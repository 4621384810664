<ng-container *ngIf="vm$ | async as vm">
    <header class="page-header">
        <h1>
            <app-span>{{'ci.logAnalyzer.title' | translate}}</app-span>
        </h1>
    </header>


    <uni-section *ngIf="!vm.fetchOnInit && !vm.multiCampaignMode">
        <app-filter-forms (onSubmit)="fetchMessageLogs()"></app-filter-forms>

    </uni-section>

    <uni-section *ngIf="vm.fetchOnInit">
        <i class="fa fa-spin fa-spinner"></i>
    </uni-section>

    <div [hidden]="!vm.filterPanelVisibility">
        <app-filter-panel></app-filter-panel>
    </div>
        
    <uni-section *ngIf="vm.loaded">
        <app-message-logs-table></app-message-logs-table>
    </uni-section>
</ng-container>
