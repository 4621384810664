<app-loading-mask *ngIf="loading"></app-loading-mask>
<header>
    <ng-content select="[header]"></ng-content>
</header>
<div class="content">
    <div class="content-chart">
        <ng-content select="[content]"></ng-content>
    </div>
</div>
<footer>
    <ng-content select="[legend]"></ng-content>
    <div class="footer-link">
        <ng-content select="[link]"></ng-content>
    </div>
</footer>
