import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, map } from "rxjs/operators";



export type UpdateSource = 'external' | 'internal' | 'initial'

type State = {
    from: string,
    to: string,
    lastChangeSource: UpdateSource
}

const initialState: State = {
    from: '',
    to: '',
    lastChangeSource: 'initial'
}

@Injectable()
export class FormDateRangeSelectorStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    //selectors
    readonly from$ = this.select((state) => state.from)
    readonly to$ = this.select((state) => state.to)

    readonly vm$ = this.select(
       this.from$,
       this.to$,
       (from, to) => {
            return {
                from, to 
            }
       }
    )

    readonly eventEmitter$ = this.state$.pipe(
        filter(state => state.lastChangeSource === 'internal'),
        map(({from, to}) => {
            return {from, to} 
        })
    )


    //updaters
    readonly externalSetFrom = this.updater((state, from: string) => {
        return {...state, from, lastChangeSource: 'external'}
    })

    readonly externalSetTo = this.updater((state, to: string) => {
        return {...state, to, lastChangeSource: 'external'}
    })

    readonly internalSetDateRange = this.updater((state, dates: {from: string, to: string}) => {
        return {...state, ...dates, lastChangeSource: 'internal'}
    })

 
}

