import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WeekDaysMockStore } from 'src/app/shared/store-mocks/week-days.store';
import { CreateFormFrequencyWeekDayStore } from './create-form-frequency-week-day.store';

@Component({
  selector: 'app-create-form-frequency-week-day',
  templateUrl: './create-form-frequency-week-day.component.html',
  styleUrls: ['./create-form-frequency-week-day.component.scss'],
  providers: [CreateFormFrequencyWeekDayStore, WeekDaysMockStore]
})
export class CreateFormFrequencyWeekDayComponent implements OnInit, ControlValueAccessor {


  constructor(
    public ngControl: NgControl,
    private store: CreateFormFrequencyWeekDayStore
  ) { 
    ngControl.valueAccessor = this;
  }

  vm$ = this.store.vm$
  value: any = ''

  onChange: any = () => {}
  onTouch: any = () => {}



  ngOnInit(): void {


  }

  writeValue(value: string | undefined){
    if(value){
      this.value = value
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }


  frequencyWeekDayChange(value: any){
    this.modelChange(value)
  }


  modelChange(value: string){
    if(!this.ngControl.touched){
      this.onTouch()
    }

    this.onChange(value)
  }

}
