import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { UniCommonModule } from '@unifonic/common';
import { SpanModule } from '../span/span.module';
import { FormMultiSelectComponent } from './form-multi-select.component';


@NgModule({
  declarations: [FormMultiSelectComponent],
  exports: [FormMultiSelectComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    UniCommonModule,
    SpanModule
  ]
})
export class FormMultiSelectModule { }
