import { Component, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormChipsStore } from './form-chips.store';

@Component({
  selector: 'app-form-chips',
  templateUrl: './form-chips.component.html',
  styleUrls: ['./form-chips.component.scss'],
  providers: [FormChipsStore]
})
export class FormChipsComponent implements OnInit {

  @Input() set value(entities: string[]){
    this.store.setEntitiesExternally(entities || [])
  }

  @Input() set placeholder(value: string){
    this.store.patchState({placeholder: value})
  }

  @Output() valueChange = this.store.emittedValue$

  constructor(private store: FormChipsStore) { }

  vm$ = this.store.vm$

  ngOnInit(): void {}

  addEntityFromInput(event: MatChipInputEvent) {
    if (event.value) {
      event.input.value = ''
      this.store.addEntity(event.value)
    }
  }

  removeKeyword(entity: string){
    this.store.removeEntity(entity)
  }
}
