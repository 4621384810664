export const mapApiMessageLogsToAppMessageLogs = (logs: any) => {
    /**
     * We need unique indentifier for each object
     * We can't rely on any backend value as they are not guarantee uniqness
     * Combining timestamp + index in array produces uniqe value for each fetched page (as we can't fetch 2 pages at the same time)
     */
    const timestamp = new Date().getTime()
    return logs.map((l, i) => {
        return {
            ...l,
            recordId: timestamp + i
        }
    })
}