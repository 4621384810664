import { Component, Input, OnInit } from '@angular/core';
import { MessageLogsTableDetailsStore } from './message-logs-table-details.store';

@Component({
  selector: 'app-message-logs-table-details',
  templateUrl: './message-logs-table-details.component.html',
  styleUrls: ['./message-logs-table-details.component.scss'],
  providers: [MessageLogsTableDetailsStore]
})
export class MessageLogsTableDetailsComponent {


  @Input() set messageId(messageId: string){
    this.store.patchState({messageId})
  }

  @Input() set elMessageId(elMessageId: string){
    this.store.patchState({elMessageId})
  }

  @Input() set isRealTime(isRealTime: boolean){
    this.store.patchState({isRealTime: isRealTime || false})
  }

  @Input() set submitDate(submitDate: string){
    this.store.patchState({submitDate})
  }

  vm$ = this.store.vm$

  constructor(
    private store: MessageLogsTableDetailsStore
  ) { }

}
