
<uni-label>
    {{'ci.common.recipients' | translate}}
</uni-label>
<app-form-chips
    (valueChange)="onRecipientChange($event)"
    [value]="selectedItems$ | async "
    [placeholder]="'ci.common.recipients' | translate"
></app-form-chips>
<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched && ngControl.errors.required">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>
<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched && ngControl.errors.pattern">
    {{'validation.pattern' | translate}}
</uni-form-field-error>