
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";
import { Observable, of, throwError } from "rxjs";
import { delay, filter, map, skip, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { UniSnackbarFacade } from "unifonic-spa-common/src";
import { ReportsRepository } from "../../data-access/reports.repository";



type State = {
    reportId: string,
    deleting: boolean,
    deleted: boolean
}

const initialState: State = {
    reportId: '',
    deleting: false,
    deleted: false
}

@Injectable()
export class ReportsDataTableDeleteStore extends ComponentStore<State>{
    constructor(
        private reportRepository: ReportsRepository,
        private snackbar: UniSnackbarFacade,
        private translate: TranslateService
    ){
        super({...initialState})
    }

    //selectors
    readonly reportId$ = this.select((state) => state.reportId)
    readonly deleting$ = this.select((state) => state.deleting)
    readonly deleted$ = this.select((state) => state.deleted)

    readonly isDeleted$ = this.deleted$.pipe(
        filter(deleted => deleted),
        switchMap((_) => this.reportId$)    
    )

    readonly vm$ = this.select(
        this.reportId$,
        this.deleting$,

        (reportId, deleting ) => {
            return {
                reportId,
                deleting
            }
        } 
    )

    //updaters
    readonly setReportId = this.updater((state, reportId: string) => {
        return {...state, reportId}
    })

    //effects
    readonly deleteReport = this.effect((id$: Observable<any>) => {
        return id$.pipe(
            tap((id) => {
                this.patchState({deleting: true })
            }),
            switchMap((id) => this.reportRepository.deleteReport(id).pipe(
                switchMap((entity: any) => {
                    if(!entity.success){
                        return throwError(entity)
                    }
                    return of(entity)
                }),
                tapResponse(
                    (entity: any) => {
                        this.patchState({
                            deleting: false,
                            deleted: true
                        })
                        this.snackbar.show('success', this.translate.instant('ci.common.reportRemoveMessage'))
                    },
                    (err) => {
                        this.snackbar.show('error', this.translate.instant('ci.common.reportCreationSuccessMessage'))
                        this.patchState({deleting: false, deleted: false})
                    }
                )
            ))
        )
    })



}
