import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Subject } from "rxjs";
import { filter, switchMap, tap, withLatestFrom } from "rxjs/operators";

type State = {
    inputValue: string,
    value: string,
    open: boolean,
    active: boolean,
    titleLabel: string,
    placeholderLabel: string,
    submitLabel: string,
    clearLabel: string
};

const initialState: State = {
    inputValue: "",
    value: "",
    open: false,
    active: false,
    titleLabel: "Search",
    placeholderLabel: "Search",
    submitLabel: "Apply",
    clearLabel: "Clear"
}

@Injectable()
export class OverlayTextFilterStore extends ComponentStore<State>{

    readonly emitter$ = new Subject<string>()

    constructor(){
        super(initialState)
    }

    //selectors
    readonly inputValue$ = this.select(state => state.inputValue)
    readonly value$ = this.select(state => state.value)
    readonly open$ = this.select(state => state.open)
    readonly active$ = this.select(state => state.active)

    readonly labels$ = this.select(state => {
        const { titleLabel, placeholderLabel, submitLabel, clearLabel } = state
        return { titleLabel, placeholderLabel, submitLabel, clearLabel }
    })

    vm$ = this.select(
        this.inputValue$,
        this.value$,
        this.open$,
        this.active$,
        this.labels$,
        (inputValue, value, open, active, labels) => {
            return {
                inputValue,
                value: value || '',
                open,
                active,
                labels
            }
        }
    )

    //updaters
    readonly setInputValue = this.updater((state, inputValue: string) => {
        return {...state, inputValue}
    })

    //effects
    readonly changeValue = this.effect(trigger$ => {
        return trigger$.pipe(
            withLatestFrom(this.inputValue$),
            tap(([_, inputValue]) => this.patchState({value: inputValue})),
            tap(([_, inputValue]) => this.emitter$.next(inputValue || ''))
        )
    })

    readonly toggleFilter = this.effect(() => {
        return this.open$.pipe(
            filter(open => open),
            withLatestFrom(this.value$),
            tap(([_, value]) => {
                this.patchState({inputValue: value})
            })
        )
    })
}