<div class="uni-form-multi-autocomplete" #autocomplete>
  <uni-form-multi-autocomplete-header
    [heading]="heading"
    [checkedItemsLength]="checkedItemsLength()"
    [multi]="multi"
    (clear)="onClear()"
    (close)="onDiscard()"
  ></uni-form-multi-autocomplete-header>

  <uni-form-search
    *ngIf="isSearch"
    controlName="search"
    [form]="searchForm"
    [isLoading]="isLoading"
    [margin]="false"
  ></uni-form-search>

  <div
    class="uni-form-multi-autocomplete__list"
    #listContainer
  >
    <div
      class="uni-form-multi-autocomplete__list-item"
      *ngFor="let item of (isLazyLoading ? data : filteredData)"
      (click)="setState(item)"
    >
      <uni-icon
        class="uni-form-multi-autocomplete__list-item-icon"
        [name]="iconName(item)"
      ></uni-icon>
      {{ addLrmChar ? '&lrm;' : '' }}{{ item.value }}
    </div>
  </div>

  <uni-form-multi-autocomplete-controllers
    [isLoading]="isLoading"
    (apply)="onApply()"
    (clear)="onClear()"
  ></uni-form-multi-autocomplete-controllers>

</div>
