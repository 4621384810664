
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';

import { Observable } from "rxjs";
import { map, tap, withLatestFrom } from "rxjs/operators";
import { Channels, ChannelsMockStore } from "src/app/shared/store-mocks/channels.store";
import { UniAuthFacade } from "unifonic-spa-common/src";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";

type State = {

}

const initialState: State = {
}

@Injectable()
export class FilterPanelChannelsStore extends ComponentStore<State>{
    constructor(
        private authFacade: UniAuthFacade,
        private messageLogsFiltersStore: MessageLogsFiltersStore,
        private channelsMockStore: ChannelsMockStore,
    ){
        super(initialState)
    }

    //selectors
    
    readonly whatsappActivity$ = this.authFacade.featureFlags$.pipe(
        map(ff => ff['2023-q-2-ci-whatsapp'])
    )
    
    readonly channels$ = this.channelsMockStore.viewEntities$.pipe(
        withLatestFrom(this.whatsappActivity$),
        map(([products, whatsappActivity]) => {
          return products.filter(p => p.value === Channels.SMS || (whatsappActivity && p.value === Channels.WHATSAPP))
        })
      )

    readonly channelsCurrentValue$ = this.messageLogsFiltersStore.channels$



    readonly valueChange = this.effect((value$: Observable<string>) => {
        return value$.pipe(
            tap((value: string) => {
                this.messageLogsFiltersStore.clearAdvancedFilters()
                this.messageLogsFiltersStore.patchState({channels: value || '', autoload: true})
            })
        )
    })
}