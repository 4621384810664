
        <uni-label [required]="true">
            {{'ci.common.selectDeliveryDate' | translate}}
        </uni-label>
        <uni-datepicker [datepicker]="scheduleDatepicker">
            <input
                [ngModel]="scheduledDate"
                (ngModelChange)="changeScheduledDate($event)"
                [placeholder]="'label.chooseDate' | translate"
                [matDatepicker]="scheduleDatepicker"
                (click)="scheduleDatepicker.open()"
                autocomplete="off"
                uniInput
                readonly
                
            >
            <mat-datepicker #scheduleDatepicker></mat-datepicker>
        </uni-datepicker>

