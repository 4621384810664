import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FormSingleSelectStore } from './form-single-select.store';

@Component({
  selector: 'app-form-single-select',
  templateUrl: './form-single-select.component.html',
  styleUrls: ['./form-single-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormSingleSelectStore]
})
export class FormSingleSelectComponent implements OnInit {


  @Input() set options(options: any[]){
    if(options){
      this.store.setOptions(options)
    }
  }

  @Input() set value(value: string){
    this.store.setValue([value, 'external'])
  }

  @Input() canClear: boolean = true
  @Input() placeholder: string = 'Select'

  @Output() valueChange = this.store.selectedOptionValue$

  form: FormGroup = this.fb.group({
    control: []
  })

  optionLabels$ = this.store.optionLabels$

  constructor(private fb: FormBuilder, private store: FormSingleSelectStore) {}

  ngOnInit(): void {
    // Save selected label to find selected option to find selected values
    this.store.setValueFormLabel(
      this.form.get('control').valueChanges.pipe(
        map(v => [v, 'internal'])
      )    
    )
    this.store.syncFormControl(this.form.get('control'))
  }
}
