import { Component, OnInit } from '@angular/core';
import { SortData } from 'src/app/shared/data-sort/data-sort.models';
import { ReportsFiltersStore } from '../../store/reports-filters.store';
import { ReportDataTableStore } from './reports-data-table.store';
import { ReportsDataTableFiltersStore } from './reports-data-table-filters.store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reports-data-table',
  templateUrl: './reports-data-table.component.html',
  styleUrls: ['./reports-data-table.component.scss'],
  providers: [ReportDataTableStore, ReportsDataTableFiltersStore]
})
export class ReportsDataTableComponent implements OnInit {


  constructor(
    private store: ReportDataTableStore,
    private reportsFiltersStore: ReportsFiltersStore,
    private internalFiltersStore: ReportsDataTableFiltersStore
  ) { }

  vm$ = combineLatest([
    this.store.vm$,
    this.internalFiltersStore.vm$
  ]).pipe(
    map(([storeVm, filtersVm]) => {
      return {
        ...storeVm,
        filters: {...filtersVm}
      }
    })
  )

  displayedColumns = [
    'reportName',
    'reportId',
    'type',
    'services',
    'createOn',
    'dateRange',
    'status',
    'actions'
  ]

  columns = {
    reportName:'reportName',
    reportId:'reportId',
    type:'type',
    services:'services',
    createOn:'createOn',
    dateRange:'dateRange',
    status:'status',
    actions:'actions',
    details: 'details'
  }

  ngOnInit(): void {
    this.store.fetchReports()
  }

  onPageChange(page: number){
    this.store.changePage(page)
  }

  toggleDetails(id: string){
    this.store.featchReportDetails(id)
  }

  onDelete(id: string){
    this.store.fetchReports()
  }

  sort(event: SortData){
    this.reportsFiltersStore.setSortingOptions(event)
  }

  trackId(_, element: any){
    return element.reportId
  }

  filterChange(reportId: string){
    this.internalFiltersStore.patchState({reportId})
  }

  filterTypeChange(type: string){
    this.internalFiltersStore.patchState({type})
  }
}
