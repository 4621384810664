import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniCommonModule } from './../../../../unifonic-spa-common/src/uni-common.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { OverlaySelectFilterComponent } from './overlay-select-filter.component';

@NgModule({
  declarations: [OverlaySelectFilterComponent],
  imports: [
    CommonModule,
    UniCommonModule,
    OverlayModule,
    FormsModule
  ],
  exports: [OverlaySelectFilterComponent]
})
export class OverlaySelectFilterModule { }
