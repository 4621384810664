import { graphql } from 'msw'

const mock = (numberOfItems) => {
    let result = [];
    for(let x = 0; x < numberOfItems; x++){
        result.push(
            {
                period: Math.round(Math.random() * 12) + '',
                sent: '',
                account_id: 123,
                accountId: 123,
                source_protocol: '',
                sourceProtocol: 'sourceProtocol',
                sender_name: 'sender_name',
                senderName: 'senderName',
                message_count: Math.round(Math.random() * 100),
                messageCount:  Math.round(Math.random() * 100),
                number_of_units:  Math.round(Math.random() * 100),
                numberOfUnits:  Math.round(Math.random() * 100),
                source_status:['NOTDELIVERED', 'DELIVERD', 'NONE','PENDING'][Math.round(Math.random()*3)],
                sourceStatus: ['NOTDELIVERED', 'DELIVERD', 'NONE','PENDING'][Math.round(Math.random()*3)],
                message_status: 'message_status',
                messageStatus: ['NOTDELIVERED', 'DELIVERD', 'NONE','PENDING'][Math.round(Math.random()*3)],
                event_status: 'event_status',
                eventStatus: 'eventStatus',
                message_type: 'message_type',
                messageType: 'messageType',
                channel: 'channel',
                product: 'product',
            }
        )
    }

    return result
}

export const getAggragateTrafficLogs = graphql.query('AggragateTrafficLogs', (req, res, ctx) => {
    return res(
        ctx.data({
            aggregateTrafficLogs: {
                count: 123,
                entries: mock(123)
            }
        })
    )

})