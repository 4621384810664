import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MessageLogsRepository } from "../../data-access/message-logs.repository";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { CustomerStatus } from "src/app/shared/store-mocks/status.store";
import { Observable } from "rxjs";

type State = {
    messageId: string
    elMessageId: string
    loading: boolean
    loaded: boolean
    entity: any
    messageChunkId: number
    isRealTime: boolean
    submitDate: string
}

const initialState: State = {
    messageId: '',
    elMessageId: '',
    loading: false,
    loaded: false,
    entity: {},
    messageChunkId: 1,
    isRealTime: false,
    submitDate: ''
}

@Injectable()
export class MessageLogsTableDetailsSmsStore extends ComponentStore<State>{

    constructor(
        private messageLogsRepository: MessageLogsRepository,
        private messageLogsFiltersStore: MessageLogsFiltersStore
    ){
        super(initialState)
    }

    //selectors
    readonly messageId$ = this.select(state => state.messageId)
    readonly elMessageId$ = this.select(state => state.elMessageId)
    readonly loading$ = this.select(state => state.loading)
    readonly loaded$ = this.select(state => state.loaded)
    readonly isRealTime$ = this.select(state => state.isRealTime)
    readonly submitDate$ = this.select(state => state.submitDate)
    readonly entity$ = this.select(state => state.entity)
    readonly messageChunkId$ = this.select(state => state.messageChunkId)

    readonly entityMessage$ = this.select(this.entity$, (entity) => entity.message || '')
    readonly entityShortMessage$ = this.select(this.entity$, (entity) => entity.shortMessage || '')

    readonly entityMessageAsArray$ = this.select(
        this.entityShortMessage$,
        (message: string) => {
            //splitting algorithm
            if (message) {
              const messagePartLength = 100
              const messageWords = message.split(' ')
              if (messageWords.length > 1) {
                const parts = Math.ceil(messageWords.length / messagePartLength)
                const messageParts = Array(parts).fill('').map((item, idx) => {
                    return [...messageWords].splice(idx * messagePartLength, messagePartLength)
                }).map((words) => words.join(' '))
                return messageParts
              } else {
                return message.match(/.{1,900}/g)
              }
            } else {
              return []
            }
        }
    )

    readonly vm$ = this.select(
        this.messageId$,
        this.loading$,
        this.loaded$,
        this.entity$,
        this.entityMessageAsArray$,
        this.messageChunkId$,
        (id, loading, loaded, entity, messageChunks, messageChunkId) => {
            return {
                id,
                loading,
                loaded,
                entity: {
                    ...entity,
                    deliveryNotConfirmedTooltip: entity.customerStatus === CustomerStatus.DELIVERY_NOT_CONFIRMED,
                    messageId: entity.messageId === "-1" ? entity.elMessageId : entity.messageId // According to business requirement. Replace messageId if -1
                },
                messageChunks,
                messageChunkId,
                messageButtonVisibility: messageChunks.length > 1,
                messagePrevButtonDisabled: messageChunkId === 1,
                messageNextButtonDisabled: messageChunkId === messageChunks.length,
            }
        }
    )


    //effects
    readonly fetchData = this.effect((trigger$) => {
        return trigger$.pipe(
            tap(() => {
                this.patchState({loading: true})
            }),
            withLatestFrom(
                this.messageId$,
                this.elMessageId$,
                this.isRealTime$,
                this.submitDate$,
                this.messageLogsFiltersStore.accountId$
            ),
            switchMap(([_, messageId, elMessageId, isRealTime, submitDate, accountId]) => this.messageLogsRepository.gqlQueryMessageLogsDetails({
                messageId,
                elMessageId,
                isRealTime,
                submitDate,
                accountId: accountId
            }).pipe(
                tapResponse(
                    (entity) => {
                        this.patchState({loading: false, loaded: true, entity: {...entity}})
                    },
                    () => {
                        this.patchState({loading: false, loaded: false, entity: {}})
                    }

                )
            ))
        )
    })

    scrollMessageBackward = this.effect(trigger$ => {
        return trigger$.pipe(
            withLatestFrom(this.messageChunkId$),
            tap(([_, messageChunkId]) => {
                this.patchState({messageChunkId: messageChunkId > 1 ? messageChunkId-1: messageChunkId})
            })
        )
    })

    scrollMessageForward = this.effect(trigger$ => {
        return trigger$.pipe(
            withLatestFrom(this.messageChunkId$, this.entityMessageAsArray$),
            tap(([_, messageChunkId, messageArray]) => {
                this.patchState({messageChunkId: messageChunkId < messageArray.length ? messageChunkId+1: messageChunkId})
            })
        )
    })

    scrollMessageTo = this.effect((messageChunkId$: Observable<number>) => {
        return messageChunkId$.pipe(
            tap((messageChunkId: number) => {
                this.patchState({messageChunkId})
            })
        )
    })

}
