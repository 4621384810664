import { filter, map, tap } from 'rxjs/operators';

import { Injectable } from "@angular/core";
import { AbstractControl } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';

export type FormSingleSelectOption = {
    label: string,
    value: string
}

export type UpdateSource = 'external' | 'internal' | 'initial'
export type FieldValue = [string, UpdateSource]

type State = {
    options: FormSingleSelectOption[],
    value: FieldValue
    
}

const initialState: State = {
    options: [],
    value: ['', 'initial']
}

@Injectable()
export class FormSingleSelectStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    //selectors
    options$ = this.select(state => state.options)
    optionLabels$ = this.select(state => state.options.map(o => o.label))

    value$ = this.select(state => state.value)

    selectedOption$ = this.select(this.options$, this.value$, (options, [value, _]) => {
        return options.find((o) => o.value === value) || {}
    })
    
    selectedOptionValue$ = this.value$.pipe(
        filter(([_ , source]) => source === 'internal'),
        map(([value, _]) => {
            return value
        })
    )




    //updaters

    readonly setValue = this.updater((state, value: FieldValue) => {
        return {...state, value: [...value]}
    })

    readonly setOptions = this.updater((state, options: any[]) => {
        return {...state, options: [...options]}
    })

    readonly setSelectedLabel = this.updater( (state, selectedLabel: string | null) => {
        return {...state, selectedLabel}
    })

    readonly setValueFormLabel = this.updater( (state, labelValue: FieldValue) => {
        const [label, source] = labelValue
        const selectedOption = state.options.find(o => o.label === label)
        return {...state, value: [selectedOption?.value || '', source]}
    })

    readonly setSelectedValue = this.updater( (state, selectedValue: string | null) => {
        return {...state, selectedValue}
    })


    syncFormControl = this.effect<AbstractControl>((fc$: Observable<AbstractControl>) => {
        const stream$ = combineLatest([this.selectedOption$, fc$])
        return stream$.pipe(
            tap(([option, fc]) => {
                if(fc.value !== option.label){
                    fc.setValue(option.label, {emitEvent: false})
                }
            }),
        )
    })
}