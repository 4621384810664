<uni-title-container>
  <uni-title-item>
    <uni-text-body>
      {{ 'campaign.list.title' | translate }}
    </uni-text-body>
  </uni-title-item>
  <uni-title-item
    *ngIf="hasCreatePermission"
    (uniClickOutside)="isDropdownOpen = false"
  >
    <uni-button id="campaign-start-new-campaign-btn" (click)="onToogleDropdown()">
      {{ 'campaign.startNewCampaign' | translate }}
      <uni-icon-action></uni-icon-action>
    </uni-button>
    <uni-dropdown [isActive]="isDropdownOpen">
      <uni-dropdown-item (click)="onAddNewCampaign(type.sms)">
        {{ 'campaign.newSMSCampaign' | translate }}
      </uni-dropdown-item>
      <uni-dropdown-item
        *ngIf="isSMSProEnabled"
        (click)="onAddNewCampaign(type.smsPro)"
      >
        {{ 'campaign.newSMSProCampaign' | translate }}
      </uni-dropdown-item>
      <uni-dropdown-item
        (click)="onAddNewCampaign(type.whatsapp)"
      >
        {{ 'campaign.newWhatsAppCampaign' | translate }}
      </uni-dropdown-item>
      <uni-dropdown-item
        *ngIf="isFSEnabled"
        (click)="onAddNewCampaign(type.flowStudio)"
      >
        {{ 'campaign.newFSCampaign' | translate }}
      </uni-dropdown-item>
      <uni-dropdown-item
        (click)="onAddNewCampaign(type.voice)"
        [isDisabled]="disableVoice"
      >
        {{ 'campaign.newVoiceCampaign' | translate }}
      </uni-dropdown-item>
    </uni-dropdown>
  </uni-title-item>
</uni-title-container>
<uni-separator></uni-separator>
