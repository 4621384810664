<ng-container *ngIf="vm$ | async as vm">

    <uni-label [required]="true">
        {{'ci.logAnalyzer.labels.dateRange' | translate}}
    </uni-label>
    <app-form-period-date-picker
        [value]="currentDate$ | async"
        [dateRangeLabel]="'ci.common.select' | translate"
        [periodPlaceholder]="'ci.common.select' | translate"
        [timezone]="vm.timezone"
        (dateSelect)="onDateSelect($event)"
        [periodEntities]="vm.periods"
    ></app-form-period-date-picker>
    <uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
        {{'ci.common.fieldRequired' | translate}}
    </uni-form-field-error>

</ng-container>