import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, tap, withLatestFrom } from "rxjs/operators";

export type SelectorMode = 'single' | 'multiple'
export type UpdateSource = 'external' | 'internal' | 'initial'
type FieldValue = [string[], UpdateSource]
export type EntityItem = {
    value: string,
    label: string,
    description: string,
    disabled?: boolean,
    active: boolean
}

type State = {
    entities: EntityItem[]
    selectedEntites: FieldValue
    mode: SelectorMode
}

const initialState: State = {
    entities: [],
    selectedEntites: [[], 'initial'],
    mode: 'single'
}

@Injectable()
export class FormCardSelectorStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    //selectors

    readonly entities$ = this.select((state) => state.entities)
    
    readonly selectedEntitiesValue$ = this.select((state) => state.selectedEntites)
    readonly selectedEntities$ = this.select(
        this.selectedEntitiesValue$, 
        (value) => value[0])

    readonly lastChangeSource$ = this.select(
        this.selectedEntitiesValue$, 
        (value) => value[1]
    )

    readonly mode$ = this.select((state) => state.mode)

    readonly vm$ = this.select(
        this.entities$,
        this.selectedEntities$,
        (entities, selectedEntities) => {
            return {
                entities: entities.map(e => {
                    return {...e, active: selectedEntities.indexOf(e.value) !== -1}
                })
            }
        } 
    )

    readonly valueChange$ = this.selectedEntitiesValue$.pipe(
        filter(([_, source]) => source === 'internal'),
        map( ([values, source]) => values)
    )
    

    //updaters
    readonly setValue = this.updater((state, value: FieldValue) => {
        return {...state, value}
    })

    readonly setEntities = this.updater((state, entities: EntityItem[]) => {
        return {...state, entities: [...entities]}
    })

    readonly toggleSelectedEntities = this.updater((state, selectedEntity: string) => {
        const isSelected = state.selectedEntites[0].indexOf(selectedEntity) !== -1;

        if(isSelected){
            return {...state, selectedEntites: [[...state.selectedEntites[0].filter(i => i !== selectedEntity)], 'internal']}
        }

        if(state.mode === 'single'){
            return {...state, selectedEntites: [[selectedEntity], 'internal']}
        }
        
        return {...state,  selectedEntites: [[...state.selectedEntites[0], selectedEntity], 'internal'] } 
    })

    readonly retriveSelectedValues = this.updater((state, values: string[]) => {
        return {
            ...state,
            selectedEntites: [[...values], 'external' ]
        }
    })
    
    readonly setMode = this.updater((state, mode: SelectorMode) => {
        return {...state, mode}
    })

    readonly configurationSync = this.effect((trigger$) => {
        return trigger$.pipe(
            withLatestFrom(
                this.selectedEntities$.pipe(map(en => en.length)),
                this.mode$
            ),
            filter( ([_, size, mode]) => size > 1 && mode === 'single' ),
            tap(
                ([_, size, mode]) => {
                    console.error('FormCardComponent - you are passing multiple selectedValues while mode is single. Pass only one value or switch mode to multiple')
                }
            )
        )
    })
}


