<ng-container *ngIf="vm$ | async as vm">
    <uni-icon
        name="spinner fa-spin"
        *ngIf="vm.loading"
    ></uni-icon>
    <div
        class="details-content"
        *ngIf="vm.loaded"
    >
    <table class="details-content-properties">
        <tr>
            <td><strong>{{'ci.common.messageId' | translate}}</strong></td>
            <td>{{vm.entity.messageId}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.product' | translate}}</strong></td>
            <td>{{vm.entity.product}}</td>
        </tr>

        <!-- TODO: For now, no backend data in the response -->
        <!-- <tr>
            <td><strong>{{'ci.common.productName' | translate}}</strong></td>
            <td>{{vm.entity.productName}}</td>
        </tr> -->

        <!-- TODO: For now, no backend data in the response -->
        <!-- <tr>
            <td><strong>{{'ci.common.executionStatus' | translate}}</strong></td>
            <td>-</td>
        </tr>

        <tr>
            <td><strong>{{'ci.common.executionStart' | translate}}</strong></td>
            <td>-</td>
        </tr>

        <tr>
            <td><strong>{{'ci.common.executionEnd' | translate}}</strong></td>
            <td>-</td>
        </tr>

        <tr>
            <td><strong>{{'ci.common.executionDuration' | translate}}</strong></td>
            <td>-</td>
        </tr> -->

        <tr>
            <td><strong>{{'ci.common.channel' | translate}}</strong></td>
            <td>{{vm.entity.channel}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.accountName' | translate}}</strong></td>
            <td>{{vm.entity.accountName}}</td>
        </tr>

        <!-- TODO: For now, no backend data in the response -->
        <!-- <tr>
            <td><strong>{{'ci.common.userName' | translate}}</strong></td>
            <td>-</td>
        </tr> -->
        <tr>
            <td><strong>{{'ci.common.country' | translate}}</strong></td>
            <td>{{vm.entity.country}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.sender' | translate}}</strong></td>
            <td *ngIf="vm.isCommInbound">{{vm.entity.endCustomerContact}}</td>
            <td *ngIf="!vm.isCommInbound">{{vm.entity.sender}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.recipient' | translate}}</strong></td>
            <td *ngIf="vm.isCommInbound">{{vm.entity.sender}}</td>
            <td *ngIf="!vm.isCommInbound">{{vm.entity.endCustomerContact}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.status' | translate}}</strong></td>
            <td>{{vm.entity.status}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.interaction' | translate}}</strong></td>
            <td>{{vm.entity.commDirection}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.campaignId' | translate}}</strong></td>
            <td> {{vm.entity.campaignId}}</td>
        </tr>

        <!-- TODO: For now, no backend data in the response -->
        <!-- <tr>
            <td><strong>{{'ci.common.dataPayload' | translate}}</strong></td>
            <td>-</td>
        </tr> -->
    </table>

    </div>

</ng-container>

