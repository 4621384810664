<ng-container *ngIf="vm$ | async as vm">

  <uni-label>
      {{'ci.common.link' | translate}}
  </uni-label>

  <app-autocomplete-multiselect
    [currentValue]="value"
    [options]="vm.linkOptions"
    [loading]="vm.templateStatLoading"
    [noResoultsLabel]="'ci.common.noResults' | translate"
    [placeholder]="'ci.common.link' | translate"
    (valueChange)="onValueChange($event)"
  ></app-autocomplete-multiselect>

  <uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
      {{'ci.common.fieldRequired' | translate}}
  </uni-form-field-error>
</ng-container>
