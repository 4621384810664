import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthGuard, hasQueryFailed, hasQueryStatus, QueryStatus, SettingsService, UniAuthFacade } from "@unifonic/common";
import { Observable, of } from "rxjs";
import { catchError, filter, switchMap, tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class AuthRedirectGuard implements CanActivate {
  constructor(
    private uniAuthFacade: UniAuthFacade,
    private authGuard: AuthGuard,
    private settings: SettingsService,
    @Inject(DOCUMENT) private document
  ) { }

  getUserMeData(): Observable<any> {
    return this.uniAuthFacade.userMeQuery$.pipe(
      tap(query => !hasQueryStatus(query) && this.uniAuthFacade.setUserMe()),
      filter(query => query.status && query.status !== QueryStatus.InProgress),
      tap(query => hasQueryFailed(query) && this.redirectToHost()),
      filter(query => !hasQueryFailed(query))
    )
  }

  canActivate(): Observable<boolean> {
      return this.getUserMeData()
      .pipe(
        switchMap(() => this.authGuard.canActivate()),
        catchError(() => of(false)),
      );
  }

  redirectToHost(){
    //@ts-ignore
    this.document.location = this.settings.settings.host
  }
}
