<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<ng-container *ngIf="!path">
  <i *ngIf="icon" [ngClass]="icon" class="uni-link__icon far"></i>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<a
  class="uni-link__path"
  *ngIf="path"
  [href]="path"
  [target]="target"
  [tabindex]="tabindex"
>
  <i *ngIf="icon" [ngClass]="icon" class="uni-link__icon far"></i>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
