import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-tab-header-selector',
  templateUrl: './tab-header-selector.component.html',
  styleUrls: ['./tab-header-selector.component.scss']
})
export class TabHeaderSelectorComponent implements OnInit {

  @Input() buttons!: any[] 
  @Input() active!: string

  @Output() onTabSelect: EventEmitter<string> = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  selectTab(value:string){
    this.onTabSelect.emit(value)
  }
}
