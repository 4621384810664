import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-key-value-invert',
  templateUrl: './card-key-value-invert.component.html',
  styleUrls: ['./card-key-value-invert.component.scss']
})
export class CardKeyValueInvertComponent implements OnInit {

  @Input() value: string
  @Input() message: string
  @Input() loading: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
