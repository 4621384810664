<uni-box>
    <app-loading-mask *ngIf="loading"></app-loading-mask>
    <uni-box-content>
        <uni-box-content-header>
            {{ title }}
        </uni-box-content-header>
        <div class="content">
            <div class="content-chart">
                <ng-content select="[content]"></ng-content>
            </div>
        </div>
        <footer>
            <div class="footer-data">
                <ng-container *ngIf="label.length">
                    <span class="footer-data-value">{{ value }}</span>
                    <span class="footer-data-label">{{ label }}</span>
                </ng-container>
            </div>
            <div class="footer-link">
                <ng-content select="[link]"></ng-content>
            </div>
        </footer>
    </uni-box-content>
</uni-box>
