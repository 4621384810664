<uni-label>
  {{'ci.common.keywordsToSearch' | translate}}
</uni-label>
<input
  type="text"
  [(ngModel)]="value"
  (ngModelChange)="onInputChange($event)"
  uniInput
  [placeholder]="'ci.common.enterKeywords' | translate"
/>
