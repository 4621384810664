import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";


type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: number
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [...Array(31)].map((_, i) => ({
        label: (i+1).toString(),
        value: i+1
    }))
}

@Injectable()
export class MonthDaysMockStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}
