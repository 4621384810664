import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { DateRangeMockStore } from "src/app/shared/store-mocks/date-range.store";



type State = {
    dateSelectorsVisibility: boolean
}

const initialState: State = {
    dateSelectorsVisibility: false
}

@Injectable()
export class CreateFormDateRangeRecurringStore extends ComponentStore<State>{
    constructor(
        private dateRangeMockStore: DateRangeMockStore,
    ){
        super({...initialState})
    }

    //selectors

    readonly entities$ = this.select(
        this.dateRangeMockStore.today$,
        this.dateRangeMockStore.last7d$,
        this.dateRangeMockStore.last30d$,
        this.dateRangeMockStore.this1w$,
        this.dateRangeMockStore.this1m$,
        this.dateRangeMockStore.last1m$,
        this.dateRangeMockStore.previousNDays$,
        (...entities) => {
            return [
                ...entities
            ]
        }
    )

    readonly dateSelectorsVisibility$ = this.select(state => state.dateSelectorsVisibility)

    readonly vm$ = this.select(
        this.entities$,
        this.dateSelectorsVisibility$,
        (entities, dateSelectorsVisibility) => {
            return {
               entities,
               dateSelectorsVisibility,
               value: ''
            }
        }, {debounce: true}
    )

    //updaters


    // effects

}
