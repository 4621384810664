import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { FormButtonSelectorItem, FormButtonSelectorMode, FormButtonSelectorStyle, FormButtonSelectorVM } from "./form-button-selector.models";

type SelectedItemUpdate = [string[], 'internal' | 'external' | 'initial']

type State = {
    items: FormButtonSelectorItem[],
    selectedItems: SelectedItemUpdate,
    disabledItems: string[],
    mode: FormButtonSelectorMode
}

const initialState: State = {
    items: [],
    selectedItems: [[], 'initial'],
    disabledItems: [],
    mode: 'single'
}

@Injectable()
export class FormButtonSelectorStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    // Selectors
    readonly items$ = this.select(state => state.items)
    readonly selectedItemsData$ = this.select(state => state.selectedItems)

    readonly selectedItems$ = this.select(
        this.selectedItemsData$,
        selectedItemsData => selectedItemsData[0]
    )
    readonly selectedItemsSource$ = this.select(
        this.selectedItemsData$,
        selectedItemsData => selectedItemsData[1]
    )

    readonly disabledItems$ = this.select(state => state.disabledItems)
    readonly selectMode$ = this.select(state => state.mode, {debounce: true})
    readonly selectedValues$  = this.select(state => state.selectedItems[0])
    readonly selectedLabels$  = this.select(
        this.items$,
        this.selectedValues$,
        (items, selectedValues) => {
            return selectedValues.map((sv) => {
                const item = items.find((i) => i.value === sv)
                return item.label
            })
        }
    )

    readonly selectedData$ = this.selectedItemsData$.pipe(
        filter(([_, source]) => source === 'internal'),
        map(([items, _]) => items), 
        withLatestFrom(this.items$),
        map(([selectedValues, items])=>{
            return items.filter(i => selectedValues.indexOf(i.value) !== -1)
        })
    )


    readonly vm$: Observable<FormButtonSelectorVM> = this.select(
        this.items$,
        this.selectedItems$,
        this.disabledItems$,
        (items, selectedItems, disabledItems) => {
            return {
                items: items.map((i) => {
                    let disabled = false
                    let style: FormButtonSelectorStyle = 'link'
                    if(selectedItems.indexOf(i.value) !== -1){
                        style = 'secondary'
                    }
                    if(disabledItems.indexOf(i.value) !== -1){
                        disabled = true
                    }
                    return {...i, disabled, style}
                }),
            }
        }
    )


    // Updaters
    readonly setItems = this.updater((state, items:any[]) => {
        return {
            ...state,
            items
        }
    })

    readonly setSelectedItems = this.updater((state, selectedItems: string[]) => {
        const stateSelectedItems = state.selectedItems
        if(stateSelectedItems.join(',') === selectedItems.join(',')){
            return state
        }
        return {...state, selectedItems: [[...selectedItems], 'external']}
    })

    readonly toggleSelectedItem = this.updater((state, item: string) => {
        if(state.selectedItems[0].indexOf(item) !== -1){
            return {...state, selectedItems: 
                [state.selectedItems[0].filter(i => i !== item), 'internal']
            
            }
        }else{
            return {...state, selectedItems: [[item, ...state.selectedItems[0]], 'internal']}
        }
    })

    readonly setOneSelectedItem = this.updater((state, item: string) => {
        if(state.selectedItems[0].indexOf(item) !== -1){
            return {...state, selectedItems:[[], 'internal']}
        }else{
            return {...state, selectedItems: [[item], 'internal']}
        }
    })

    readonly setDisabledItems = this.updater((state, disabledItems:string[]) => {
        return {
            ...state,
            disabledItems
        }
    })

    readonly setMode = this.updater((state, mode: FormButtonSelectorMode) => {
        return {
            ...state, 
            mode
        }
    })

    // Effects
    readonly selectOption = this.effect((id$: Observable<string>) => {
        return id$.pipe(
            withLatestFrom(this.selectMode$),
            tap(([id, mode]) => {
                if(mode === 'single'){
                    this.setOneSelectedItem(id)
                }

                if(mode === 'multiple'){
                    this.toggleSelectedItem(id)
                }
            })
        )
    })


    readonly checkConfigConflicts = this.effect((_) => {
        return this.selectedItems$.pipe(
            withLatestFrom(this.selectMode$),
            filter(([items, _]) => items && !!items.length),
            tap(([items, mode]) => {
                if(mode === 'single' && items.length > 1){
                    throw Error('FormButtonSelector Component: You can\'t initialize component with multiple values if the mode is \'single\'')
                }
            })
        )
    })
    
}