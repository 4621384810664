import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { SenderNameFacade } from 'unifonic-spa-common/src';
import { MccCampaignsFetchStore } from '../../store/mcc-campaigns-fetch.store';
import { AdvancedFiltersFormTemplateStore } from './advanced-filters-form-template.store';

@Component({
  selector: 'app-advanced-filters-form-template',
  templateUrl: './advanced-filters-form-template.component.html',
  styleUrls: ['./advanced-filters-form-template.component.scss'],
    providers: [AdvancedFiltersFormTemplateStore, MccCampaignsFetchStore],
})
export class AdvancedFiltersFormTemplateComponent implements OnInit, OnDestroy, ControlValueAccessor {

  vm$ = this.store.vm$
  value = ''

  onChange: any = () => {}
  onTouch: any = () => {}

  constructor(
    public ngControl: NgControl,
    private senderNameFacade: SenderNameFacade,
    private store: AdvancedFiltersFormTemplateStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){
    this.saveValue(value)
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(value: string[]){
    this.saveValue(value);
  }

  saveValue(value){
    this.value = value
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
