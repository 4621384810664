import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { filter, tap } from 'rxjs/operators';
import { MessageLogsFetchStore } from "../../store/message-logs-fetch.store";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";


type State = {
    generating: boolean
    loading: boolean
}

const initialState: State = {
    generating: false,
    loading: false
}

@Injectable()
export class MessageLogsRefreshControlStore extends ComponentStore<State>{
    constructor(
        private messageLogsFiltersStore: MessageLogsFiltersStore,
        private messageLogsFetchStore: MessageLogsFetchStore
    ){
        super(initialState)
    }

    //selectors
    readonly loading$ = this.select(state => state.loading)

    readonly vm$ = this.select(
        this.loading$,
        this.messageLogsFetchStore.lastFetchTimestamp$,
        (loading, timestamp) => {
            return {
                loading,
                timestamp
            }
        }
    )

    //effect
    readonly refreshData = this.effect((trigger$) => {
        return trigger$.pipe(
            tap(()=>{
                this.patchState({loading: true})
                this.messageLogsFiltersStore.patchState({page: '1', autoload: true})
            })
        )
    })

    readonly stopLoading = this.effect(() => {
        return this.messageLogsFetchStore.loading$.pipe(
            filter(loading => !loading),
            tap(() => {
                this.patchState({loading: false})
            })
        )
    })


}