
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { Observable, from, of } from "rxjs";
import { map, mergeMap, switchMap, tap, toArray, withLatestFrom } from "rxjs/operators";
import { CampaignDetailsRepository } from "src/app/shared/data-access/campaign-details/campaign-details.repository";
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {
    options: any[],
}

const initialState: State = {
    options: []
}

@Injectable()
export class FilterPanelMultiCampaignIdsStore extends ComponentStore<State>{

    constructor(
        private messageLogsFiltersStore: MessageLogsFiltersStore,
        private repository: CampaignDetailsRepository,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors

    readonly options$ = this.select(state => state.options)

    readonly entities$ = this.select(
        this.messageLogsFiltersStore.campaignId$,
        (campaignIds) => {
            console.log('ENTTS', campaignIds)
            return (campaignIds?.split(',') || []).map(id => ({
                label: id,
                value: id
            }))
        }
    )

    readonly value$ = this.select(
        this.messageLogsFiltersStore.campaignId$,
        (campaignIds) => {
            return campaignIds
        }
    )

    

    fetch  = this.effect((trigger$:Observable<void>) => {
        return trigger$.pipe(
            withLatestFrom(this.messageLogsFiltersStore.campaignId$),
            map(([_, campaignIds]) => {
                return campaignIds.split(',')
            }),
            switchMap((campaingIds) => {
                return from(campaingIds).pipe(
                    mergeMap((cId) => this.repository.gqlQueryWhatsappCampaignDetailsData({
                        variables: {
                            accountId: this.authFacade.userMe?.user?.account?.id,
                            campaignId: cId
                        },
                        output: `
                            campaignId
                            campaignName
                        `
                    })),
                    toArray(),
                    tap(response => {
                        this.patchState({
                            options: response.map((o: any) => ({
                                value: o.campaignId,
                                label: o.campaignName

                            }))
                        })
                    })
                )
            })
        )
    })

    


}