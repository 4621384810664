<ng-container *ngIf="vm$ | async as vm">
    <uni-icon
        name="spinner fa-spin"
        *ngIf="vm.loading"
    ></uni-icon>
    <div
        class="details-content"
        *ngIf="vm.loaded"
    >
    <table class="details-content-properties">
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.messageId' | translate}}</strong></td>
            <td>{{vm.entity.messageId}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.product' | translate}}</strong></td>
            <td> {{vm.entity.product}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.executionName' | translate}}</strong></td>
            <td> {{vm.entity.executionName}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.channel' | translate}}</strong></td>
            <td> {{vm.entity.channel}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.source' | translate}}</strong></td>
            <td> {{vm.entity.sourceProtocol}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.messageType' | translate}}</strong></td>
            <td>
                {{vm.entity.messageType}}
                <i
                    [matTooltip]="(vm.entity.messageType ? 'ci.common.tips.generatedByAI' : 'ci.common.inProgress') | translate"
                    matTooltipPosition="above"
                    matTooltipClass="tooltip-green"
                    class="far fa-info-circle">
                </i>
            </td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.accountName' | translate}}</strong></td>
            <td> {{vm.entity.accountName}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.country' | translate}}</strong></td>
            <td> {{vm.entity.countryName}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.operator' | translate}}</strong></td>
            <td> {{vm.entity.operatorName}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.sendername' | translate}}</strong></td>
            <td> {{vm.entity.senderName}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.recipient' | translate}}</strong></td>
            <td> {{vm.entity.receiver}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.status' | translate}}</strong></td>
            <td>
                {{vm.entity.customerStatus}}
                <i
                    *ngIf="vm.entity.deliveryNotConfirmedTooltip"
                    [matTooltip]="'ci.common.tips.deliveryNotConfirmed' | translate"
                    matTooltipPosition="left"
                    class="far fa-info-circle">
                </i>
            </td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.statusReason' | translate}}</strong></td>
            <td> {{vm.entity.customerReason}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.statusDescription' | translate}}</strong></td>
            <td> {{vm.entity.description || '-'}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.sentByClientAt' | translate}}</strong></td>
            <td> {{vm.entity.userSubmitDate | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.sentToOperatorAt' | translate}}</strong></td>
            <td> {{vm.entity.submitDate | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.operatorReportReceivedAt' | translate}}</strong></td>
            <td> {{vm.entity.dlrReceivedDate | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}</td>
        </tr>
    </table>

        <div class="details-content-message">
            <div class="message-container">
                <strong [style.display]="'block'">{{'ci.logAnalyzer.details.labels.message' | translate}}:</strong>
                <div
                    *ngFor="let chunk of vm.messageChunks; let idx = index"
                    [hidden]="!(idx + 1 === vm.messageChunkId || null)"
                >
                    <app-message-bubble [message]="chunk"></app-message-bubble>
                </div>
            </div>
            <div
                class="details-content-message-buttons"
                *ngIf="vm.messageButtonVisibility"
            >
                <uni-button
                    (click)="scrollMessageBackward()"
                    type="button"
                    [style]="'link'"
                    [isMargin]="false"
                    [autoWidth]="true"
                    [noHorizontalSpace]="true"
                    [noVerticalSpace]="true"
                    [disabled]="vm.messagePrevButtonDisabled"
                >
                    <uni-icon name="angle-left"></uni-icon>
                </uni-button>
                <uni-button
                    (click)="scrollMessageForward()"
                    type="button"
                    [style]="'link'"
                    [isMargin]="false"
                    [autoWidth]="true"
                    [noHorizontalSpace]="true"
                    [noVerticalSpace]="true"
                    [disabled]="vm.messageNextButtonDisabled"
                >
                    <uni-icon name="angle-right"></uni-icon>
                </uni-button>
            </div>
            <div
                class="details-content-message-dots"
                *ngIf="vm.messageButtonVisibility"
            >
                <ul class="dots-indicator">
                    <li
                        *ngFor="let chunk of vm.messageChunks; let idx = index"
                        [class.active]="idx + 1 === vm.messageChunkId"
                        (click)="scrollMessageTo(idx + 1)"
                    >
                        <span></span>
                  </li>
                </ul>
            </div>
        </div>
    </div>

</ng-container>
