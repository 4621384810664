import { Component, OnInit, ChangeDetectionStrategy, Inject, SimpleChanges, Input } from '@angular/core';
import { HighchartsColors, HIGHCHART_COLORS } from '../highcharts/highcharts.colors';
import { HCToken } from '../highcharts/highcharts.token';
import { HighchartsInstance, HighchartsItem, HighchartsOptions } from '../highcharts/highcharts.types';
import Highcharts from "highcharts";
import variablePie from "highcharts/modules/variable-pie.js";

variablePie(Highcharts);

@Component({
  selector: 'app-chart-pie-variable',
  templateUrl: './chart-pie-variable.component.html',
  styleUrls: ['./chart-pie-variable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartPieVariableComponent implements OnInit {

  @Input() source!: {name: string, value: number, pieSize: number, color: HighchartsColors}[];
  @Input() innerSize: number = 50;

  constructor( @Inject(HCToken) public hc: HighchartsItem) { }

  cInstance!: HighchartsInstance;

  chartOptions: HighchartsOptions = {
    credits:{
      enabled: false,
    },
    chart:{
      type: 'variablepie',
      height: 170,
      animation: false,
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: false
    },
    legend: {
      enabled: true,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      itemMarginBottom: 10
    },
    plotOptions: {
      variablepie: {
        dataLabels: {
          enabled: true,
          distance: -40,
          format: '{y}%',
          style: {
            fontSize: '12px',
            fontFamily: 'Inter, Gotham Rounded',
            fontWeight: "light",
            textOutline: "0px"
          }
        },
      },
      series: {
        enableMouseTracking: false,
        showInLegend: true,
        states: {
            hover: {
                enabled: false
            }
        },
        events: {
          legendItemClick: function () {
            return false;
          }
        }
      }
    },
    series: []
  }

  ngOnInit(): void {
    this.chartOptions = { ...this.chartOptions };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.source && !changes.source.firstChange){
      this.redrawChart()
    }
  }

  chartInit(chart: Highcharts.Chart) {
    this.cInstance = chart;
    this.redrawChart()
  }

  redrawChart(){
    if(this.cInstance){
      while(this.cInstance.series.length){
        this.cInstance.series[0].remove()
      }
      this.cInstance.addSeries({
        name: '',
        type: 'variablepie',
        innerSize: this.innerSize,
        minPointSize: 30,
        data: this.source.map(function(el) {
          return {
            name: el.name,
            z: el.pieSize,
            y: el.value,
            color: HIGHCHART_COLORS[el.color],
          }
        })
      });
      this.cInstance.redraw(true);
    }
  }
}
