import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpanComponent } from './span.component';



@NgModule({
  declarations: [SpanComponent],
  exports: [SpanComponent],
  imports: [
    CommonModule
  ]
})
export class SpanModule { }
