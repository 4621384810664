import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MessageTypeMockStore } from '../../../shared/store-mocks/message-type.store';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';

@Component({
  selector: 'app-advanced-filters-form-message-type',
  templateUrl: './advanced-filters-form-message-type.component.html',
  styleUrls: ['./advanced-filters-form-message-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageTypeMockStore]
})
export class AdvancedFiltersFormMessageTypeComponent implements OnInit, OnDestroy, ControlValueAccessor {

  onChange: any = () => {}
  onTouch: any = () => {}

  messageTypeValue$ = this.filtersFormStore.messageType$
  messageTypeEntities$ = this.messageTypeMockStore.viewEntities$

  constructor(
    public ngControl: NgControl,
    private filtersFormStore: FiltersFormStore,
    private messageTypeMockStore: MessageTypeMockStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }


  messageTypeOptionChange(type: string) {
    this.saveValue(type)
  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}