<ng-container *ngIf="vm$ | async as vm">
    <ng-container *ngIf="vm.isPeriod">
        <app-form-period-date-picker-period-selector
            [currentValue]="vm.periodCurrentValue"
            [entities]="vm.periodEntities"
        ></app-form-period-date-picker-period-selector>
    </ng-container>
    <ng-container *ngIf="vm.isDate">
        <app-form-period-date-picker-date-selector
            [currentValue]="vm.dateCurrentValue"
        ></app-form-period-date-picker-date-selector>
    </ng-container>
</ng-container>
