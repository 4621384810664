import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, skip, startWith, tap, withLatestFrom } from "rxjs/operators";
import { AccountsFacade, UniAuthFacade } from "@unifonic/common";
import { AccountListStore } from "src/app/shared/store-mocks/account-list.store";


type State = {}

const initialState: State = {}

@Injectable()
export class CreateFormAccountIdStore extends ComponentStore<State>{
    constructor(
        private accountsFacade: AccountsFacade,
        private authFacade: UniAuthFacade,
        private accountListStore: AccountListStore
    ){
        super({...initialState})
    }

    //selectors
    readonly vm$ = this.select(
        this.accountListStore.accounts$,
        this.accountsFacade.isLoading$,
        (entities, loading) => {
            return {
                entities,
                loading
            }
        } 
    )

    //effects
    readonly setDefaultValue = this.effect((trigger$: any) => {
        return trigger$.pipe(
            withLatestFrom(this.authFacade.userMe$),
            tap( ([fn, userMe]) =>  {
                fn(userMe.user.account.id)
            })
        )
    })
}
