import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-create-form-delivery-date',
  templateUrl: './create-form-delivery-date.component.html',
  styleUrls: ['./create-form-delivery-date.component.scss'],
  providers: []
})
export class CreateFormDeliveryDateComponent implements OnInit, ControlValueAccessor {

  constructor(
    public ngControl: NgControl,
  
  ) { 
    ngControl.valueAccessor = this;
  }


  scheduledDate = moment().subtract(1, 'month')
  scheduledTime = moment()

  onChange: any = () => {}
  onTouch: any = () => {}

  writeValue(value: string | undefined){
    if(value){

    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }


  ngOnInit(): void {
    const iso = `${this.scheduledDate.format('YYYY-MM-DD')}T${this.scheduledTime.format('HH:mm')}:00.000Z`;
  
    //@TODO: can be improved, it is temporary solution, will be replaced by better one once we connect to backend.
    setTimeout(() => {
      this.changeModel(iso)
    }, 1)
  }


  changeScheduledDate(value: any){
    this.scheduledDate = value
    const iso = `${this.scheduledDate.format('YYYY-MM-DD')}T${this.scheduledTime.format('HH:mm')}:00.000Z`
    
    this.changeModel(iso)
  }

  changeModel(value: string){
    if(!this.ngControl.touched){
      this.onTouch()
    }
    this.onChange(value)
  }

}
