import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsFiltersStore } from '../store/reports-filters.store';
import { UniAuthFacade } from 'unifonic-spa-common/src/modules/uni-auth/shared/uni-auth.facade';

@Component({
  templateUrl: './page-reports-list.component.html',
  styleUrls: ['./page-reports-list.component.scss']
})
export class PageReportsListComponent implements OnInit {

  constructor(
    protected uniAuthFacade: UniAuthFacade,
    private router: Router,
    private reportsFiltersStore: ReportsFiltersStore
  ) { }

  ngOnInit(): void {
    this.reportsFiltersStore.patchState({sortBy: 'startedAt', sortDir: 'DESC'})
  }

  redirectToCreationForm(){
    this.router.navigate(['/', 'insights', 'reports', 'create'])
  }

  get isImpresonationSession(): boolean {
    return this.uniAuthFacade.isImpresonationSession();
  }

}
