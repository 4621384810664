import { NgModule } from '@angular/core';
import { CardInitiationTypeComponent } from './card-initiation-type.component';
import { UniBoxModule } from '@unifonic/common';
import { UniLayoutModule } from "@unifonic/common";
import { UniTextModule } from "@unifonic/common";
import { LoadingMaskModule } from '../loading-mask/loading-mask.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [CardInitiationTypeComponent],
    exports: [CardInitiationTypeComponent],
    imports: [
        CommonModule,
        UniBoxModule,
        UniLayoutModule,
        UniTextModule,
        LoadingMaskModule
    ]
})
export class CardInitiationTypeModule { }
