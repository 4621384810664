import { delay, filter, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from "@angular/core";

import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable, of } from "rxjs";
import { calculateDropdownHeight } from './autocomplete-select.utils';

type UpdateSource = 'external' | 'internal' | 'initial'
export type FieldValue = [string, UpdateSource]

export type AccountOption = {
    label: string,
    value: string
}

type State = {
    currentValue: FieldValue
    options: AccountOption[],
    loading: boolean,
    inputValue: string
    open: boolean,
    debounceTime: number,
    overlayWidth: number,
    noResultsLabel: string,
    placeholder: string
}

const initialState: State = {
    currentValue: ['', 'initial'],
    options: [],
    loading: false,
    inputValue: '',
    open: false,
    debounceTime: 300,
    overlayWidth: 300,
    noResultsLabel: 'No Results',
    placeholder: 'Select Option'
}

@Injectable()
export class AutocompleteSelectStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    /**
     * Selectors
     * Take values directly from the store
     */
    readonly options$ = this.select(state => state.options)
    readonly loading$ = this.select(state => state.loading)
    readonly currentValue$ = this.select(state => state.currentValue)
    readonly open$ = this.select(state => state.open)
    readonly inputValue$ = this.select(state => state.inputValue)
    readonly debounceTime$ = this.select(state => state.debounceTime)
    readonly overlayWidth$ = this.select(state => state.overlayWidth)
    readonly noResultsLabel$ = this.select(state => state.noResultsLabel)
    readonly placeholder$ = this.select(state => state.placeholder)


    /**
     * Selectors
     * Take values from other selectors and calculate for view purposes
     */
    readonly currentValueValue$ = this.select(
        this.currentValue$,
        ([value, _]) => value)

    readonly currentValueSource$ = this.select(
        this.currentValue$,
        ([_, source]) => source)

    /**
     * SearchingPhrase is delayed version of inputValue
     * We dont want to do expensive loop through the options any type user type the value
     * Instead of this we wait for selected amount of time to emit value
     * We use switchMap + delay instead of debounceTime to easily pass the debounceTime value
     */
    readonly searchingPhrase$ = this.inputValue$.pipe(
        withLatestFrom(this.debounceTime$),
        switchMap(([phrase, debounceTime]) => {
            return of(phrase).pipe(delay(phrase.length === 0 ? 0 : debounceTime))
        })
    )

    /**
     * Options filtered by the phrase provided in searchingPhrase.
     * options$ selector usually emits value only on component init phase
     * searchingPhases emits value if user is not typing for a while (specified in debounceTime @Input) 
     */
    readonly filteredOptions$ = combineLatest([
            this.searchingPhrase$,
            this.options$
        ]).pipe(
            map(([phrase, options]: [string, AccountOption[]]) => {
                const filteredOptions = options.filter((option => option.label.toLowerCase().includes(phrase.toLowerCase())))
                return phrase.length === 0 ? options : filteredOptions
            }),
            startWith([])
    )


    /**
     * Moment between typing and calulating the filtered options
     * Since searchingphrase is basically delayed inputValue, we can assume that
     * whenever those values are different user is waiting for the new filtered options
     * 
     */
    readonly optionsLoading$ = combineLatest([
        this.inputValue$,
        this.searchingPhrase$
    ]).pipe(
        map( ([input, search]) => input !== search),
        startWith(false)
    )  
    
    /**
     * View Model
     * debounce: true as we want emit value only once when all micro-tasks are finished
     */
    readonly vm$ = this.select(
        this.filteredOptions$,
        this.loading$,
        this.open$,
        this.inputValue$,
        this.optionsLoading$,
        this.overlayWidth$,
        this.noResultsLabel$,
        this.placeholder$,
        this.currentValueValue$,
        (options, loading, open, inputValue, optionsLoading, overlayWidth, noResultsLabel, placeholder, currentValue) => {
            return {
                options,
                loading,
                open,
                inputValue,
                optionsLoading,
                overlayWidth,
                noResultsLabel,
                placeholder,
                isValueSelected: !!currentValue.length,
                ...calculateDropdownHeight(options, optionsLoading, loading)
            }
        }, {debounce: true}
    )

    /**
     * Emit value to the application (parent component)
     * Only internal changes can be emitted outside (otherwise infinite loop happens)
     */
    readonly eventEmitter$ = this.currentValue$.pipe(
        filter(([_, source]) => source === 'internal'),
        map(([value, _]) => value)
    )

    /**
     * Value updaters
     */
    readonly setCurrentValue = this.updater( (state, currentValue: FieldValue) => {
        const opt = state.options.find(o => o.value === currentValue[0]);
        return {...state, currentValue: [...currentValue], inputValue: opt ? opt.label : ''}
    })
    

    /**
     * Effects
     * Check if current input value can be found in options
     * If yes, set it
     * If no, reset it
     */
    readonly validateInputValue = this.effect((trigger$) => {
        return trigger$.pipe(
            withLatestFrom(
                this.options$,
                this.inputValue$
            ),
            tap(([_, options, inputValue]) => {
                const option = options.find(opt => opt.label === inputValue)
                this.setCurrentValue( option ? [option.value, 'internal'] : ['', 'internal'])
            })
        )
    })

    /**
     * Whenever options change (most likely it will not happen more then once)
     * Calculate the state. As long as there is no way to update options internally
     * all changes invoked by this stream can be treated as external.
     */
    readonly syncOptions = this.effect((trigger$) => {
        return this.options$.pipe(
            filter(options => !!options.length),
            withLatestFrom(
                this.currentValue$
            ),
            tap(([options, [currentValue]]) => {
                const option = options.find(opt => opt.value === currentValue)
                this.setCurrentValue( option ? [option.value, 'external'] : ['', 'external'])
            })
        )
    })

}
