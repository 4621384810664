import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageIndexComponent } from './page-index/page-index.component';
import { PageReportsListComponent } from './page-reports-list/page-reports-list.component';
import { PageDownloadComponent } from './page-download/page-download.component';

const routes: Routes = [
  {
    path: '',
    component: PageReportsListComponent,
    data: {
      titleKey: "ci.pageTitle.reports"
    },
    children: [{
      path: 'download',
      component: PageDownloadComponent,
      data: {
        titleKey: "ci.pageTitle.reportsDownload"
      },
    }]
  },{
    path: 'create',
    component: PageIndexComponent,
    data: {
      titleKey: "ci.pageTitle.reportsCreate"
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
