<ng-container *ngIf="vm$ | async as vm">
  <mat-form-field appearance="standard">
    <mat-select
      multiple
      [value]="vm.selected"
      (selectionChange)="selectionChange($event)"
      [placeholder]="placeholder"
    >
      <mat-option
        *ngFor="let opt of vm.options"
        [value]="opt.value"
      >
        <app-span>{{opt.label}}</app-span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <uni-icon
    *ngIf="vm.selected.length && vm.clearable"
    name="times"
    (click)="clearOptions()"
  ></uni-icon>
</ng-container>
