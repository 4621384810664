import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { Observable } from "rxjs";
import {  map } from 'rxjs/operators';
import { QueryPayload } from "../data-access.model";
import { createTemplateSummaryQuery } from "./template-summary.graphql";
import { TemplateSummaryApi } from "./template-summary.model";
import { HttpClient } from "@angular/common/http";
import { SettingsService } from "unifonic-spa-common/src";

@Injectable({
    providedIn: 'root'
})
export class TemplateSummaryRepository {

    constructor(
        private apollo: Apollo,
        private http: HttpClient,
        private settings: SettingsService
    ){}

    gqlQueryWhatsappCampaignTemplateDetails({variables, output}: QueryPayload<{campaignId: string, accountId: string, templateId: string, includeULinkClickSummary: boolean}>): Observable<Partial<TemplateSummaryApi>> {
      return this.apollo.query<any>({
        query: createTemplateSummaryQuery(output),
        variables
      })
      .pipe(
          map((response) => {
            return response.data.getWhatsappCampaignsSummaryByCampaignIdForULink.entries || {}
          })
      )
    }


    countNumberOfItemsForExport(data: any){
      //   const headers = new HttpHeaders({
      //     "userTimeZone": timezone,
      //     "Accept-language": locale
      //   });
        
  
        return this.http.post(
          //@ts-ignore
          `${this.settings?.settings?.ciRestReportingUrl}/v2/reporting/mcc/countNumberOfItemForExportCampaignRecipientsFile`,
          data
        )
      }
}

