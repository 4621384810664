<ng-container *ngIf="vm$ | async as vm" >
    <uni-label [required]="true">
        {{'ci.form.common.channels' | translate}}
    </uni-label>
    <uni-icon
        [isRightSpace]="true"
        name="spinner fa-spin"
        *ngIf="!vm.entities.length"
    ></uni-icon>
    <app-form-button-selector
        *ngIf="!!vm.entities.length"
        (onDataChange)="onDataChange($event)"
        [data]="vm.entities"
        [selected]="vm.selectedItems"
        [disabled]="vm.disabled"
        [mode]="'single'"
    >
    </app-form-button-selector>
    <uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
        {{'ci.common.fieldRequired' | translate}}
    </uni-form-field-error>

</ng-container>