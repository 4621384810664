import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { ChannelsMockStore } from 'src/app/shared/store-mocks/channels.store';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';
import { FormButtonSelectorItem } from './../../../shared/form-button-selector/form-button-selector.models';
import { GeneralFiltersFormChannelsStore } from './general-filters-form-channels.store';

@Component({
  selector: 'app-general-filters-form-channels',
  templateUrl: './general-filters-form-channels.component.html',
  styleUrls: ['./general-filters-form-channels.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChannelsMockStore, GeneralFiltersFormChannelsStore]
})
export class GeneralFiltersFormChannelsComponent implements OnInit, ControlValueAccessor {

  channels$ = this.store.channels$
  selectedItems$ = this.store.selectedItems$.pipe(
    map((channel => channel.split(','))),
    tap((selectedItems) => {

      if(selectedItems => this.ngControl.untouched && !!selectedItems.length){
        // this.onTouch()
      }
  
    })
  )

  disabled$ = this.store.disabled$


  constructor(
    public ngControl: NgControl,
    private store: GeneralFiltersFormChannelsStore,
    private filtersFormStore: FiltersFormStore,
  ) {
    ngControl.valueAccessor = this;
  }

  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(){}

  writeValue(value: string | undefined){
    if(value){
      this.store.patchState({selectedItem: value})
      this.onTouch()
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onDataChange(data: FormButtonSelectorItem[]){

    this.filtersFormStore.clearAdvancedFilters()
    const values = data.map(d => d?.value).join(',')
    this.saveValue(values.length ? values : '')

  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }
}
