import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyValueLegendComponent } from './key-value-legend.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [KeyValueLegendComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [KeyValueLegendComponent]
})
export class KeyValueLegendModule { }
