import { Component, Input, HostBinding } from '@angular/core';
import { UniTextWeight, UniTextColor } from '../../shared/uni-text.model';

@Component({
  selector: 'uni-text',
  templateUrl: './uni-text.component.html',
  styleUrls: ['./uni-text.component.scss'],
})
export class UniTextComponent {
  @Input() class = '';
  @Input() weight: UniTextWeight = UniTextWeight.medium;
  @Input() color: UniTextColor = UniTextColor.dark;
  @Input() isUppercase = true;
  @Input() level = '100';
  @Input() isUnderline = false;
  @Input() forToggle = false;

  @HostBinding('class') get getClasses(): string {
    return [
      this.class,
      `uni-text--${this.weight}`,
      `uni-text--${this.color}`,
      `uni-text--${this.level}`,
      this.isUnderline ? 'uni-text--is-underline' : '',
      this.isUppercase ? 'uni-text--is-uppercase' : '',
      this.forToggle ? 'uni-text--for-toggle' : '',
    ].join(' ');
  }
}
