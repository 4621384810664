import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-span',
  templateUrl: './span.component.html',
  styleUrls: ['./span.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class SpanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
