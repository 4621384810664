<ng-container *ngIf="vm$ | async as vm">
  <uni-label [required]="true">
      {{'ci.form.common.selectAccount' | translate}}
    </uni-label>

    <app-autocomplete-select
        [currentValue]="value"
        [options]="vm.entities"
        [loading]="vm.loading"
        [noResoultsLabel]="'ci.common.noResults' | translate"
        [placeholder]="'ci.common.selectAccount' | translate"
        (valueChange)="onValueChange($event)"
    ></app-autocomplete-select>

    <uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
      {{'ci.common.fieldRequired' | translate}}
    </uni-form-field-error>
</ng-container>

