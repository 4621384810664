<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<button
  *ngIf="!path; else link"
  class="uni-button"
  [ngClass]="
    [
      'uni-button--' + style,
      autoWidth ? 'uni-button--auto-width' : '',
      disabled ? 'uni-button--disabled' : '',
      this.noHorizontalSpace ? 'uni-button--no-horizontal-space' : '',
      this.noVerticalSpace ? 'uni-button--no-vertical-space' : ''
    ]"
  [type]="type"
  (click)="onClick()"
  [disabled]="disabled"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
<ng-template #link>
  <a
    class="uni-button"
    [ngClass]="
      [
        'uni-button--' + style,
        disabled ? 'uni-button--disabled' : '',
        noHorizontalSpace ? 'uni-button--no-horizontal-space' : '',
        noVerticalSpace ? 'uni-button--no-vertical-space' : ''
      ]"
    (click)="onClick()"
    [href]="path"
    [target]="target"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>
