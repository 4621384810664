import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormCardSelectorComponent } from './form-card-selector.component';
import { FormCardComponent } from './components/form-card/form-card.component';



@NgModule({
  declarations: [FormCardSelectorComponent, FormCardComponent],
  exports: [FormCardSelectorComponent, FormCardComponent],
  imports: [
    CommonModule
  ]
})
export class FormCardSelectorModule { }
