import { gql } from "apollo-angular"

export const DEFAULT_OUTPUT = `
    campaignId
    accountId
    status
    origin
    campaignName
    channel
    senderNameId
    senderName
    content
    campaignContentVariable
    interactionType
    customFields
    campaignTimezone
    recipient
    totalRecipients
    userId
    createdAt
    createdBy
    updatedAt
    updatedBy
    otpRequestedAt
    otpRequestedBy
    scheduledAt
    scheduledBy
    startingAt
    runningAt
    sendingCompletedAt
    countryCodeId
    approvalStatus
    rejectionReason
    campaignType
    messageType
    totalMessages
    totalUnits
    messagesDelivered
    messagesDeliveryNotConfirmed
    messagesUndelivered
    messagesRejected
    
    totalRecipients
    totalMessages
    totalMessagesSent
    totalMessagesRead
    totalMessagesDelivered
    totalMessagesDeliveryNotConfirmed
    totalMessagesFailed
    totalMessagesSubmittedToWhatsApp
    totalMessagesRejected

    uLinkClickRate: Float
    product
    accountName
    countryName
    createdByName
`

export const createSmsCampaignsSummaryById = (output = DEFAULT_OUTPUT) => {
    return gql`
        query GetCampaignsSummaryByCampaignID(
            $campaignId: String!
            $accountId: String!
        ){
            getCampaignsSummaryByCampaignID(
                campaignId: $campaignId
                accountId: $accountId
            ){
                success, message, count, errorCode, entries {
                    ${output}
                }
            }
        }
    `
}


export const createWhatsappCampaignsSummaryById = (output = DEFAULT_OUTPUT) => {
    return gql`
        query GetWhatsappCampaignsSummaryByCampaignID(
            $campaignId: String!
            $accountId: String!
        ){
            getWhatsappCampaignsSummaryByCampaignID(
                campaignId: $campaignId
                accountId: $accountId
            ){
                success, message, count, errorCode, entries {
                    ${output}
                }
            }
        }
    `
}