import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CreateFormDateRangeSelectorStore } from './create-form-date-range-selector.store';
import { DateRangeMockStore } from 'src/app/shared/store-mocks/date-range.store';
import moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-create-form-date-range-selector-2',
  templateUrl: './create-form-date-range-selector.component.html',
  styleUrls: ['./create-form-date-range-selector.component.scss'],
  providers: [CreateFormDateRangeSelectorStore, DateRangeMockStore,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CreateFormDateRangeSelectorComponent implements OnInit, ControlValueAccessor {

  constructor(
    public ngControl: NgControl,
    private store: CreateFormDateRangeSelectorStore
  
  ) { 
    ngControl.valueAccessor = this;
  }

  vm$ = this.store.vm$
  value: any = ''

  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(): void {


  }

  writeValue(value: string | undefined){
    if(value){
      this.value = value
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }


  dateRangeOptionChange(value: any){
    this.modelChange(value)
  }


  modelChange(value: string){
    if(!this.ngControl.touched){
      this.onTouch()
    }

    this.onChange(value)
  }

}
