import { delay, distinctUntilChanged, skip, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { merge, Observable, of } from 'rxjs';

export type FormMultiSelectOption = {
    label: string
    value: string
}

type State = {
    options: FormMultiSelectOption[],
    selectedExternally: string[]
    selectedInternally: string[]
    clearable: boolean
    defer: number
}

const initialState: State = {
    options: [],
    selectedExternally: [],
    selectedInternally: [],
    clearable: true,
    defer: 0
}

@Injectable()
export class FormChipsAutocompleteStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    // Selectors
    readonly options$ = this.select(state => state.options)
    readonly selectedExternally$ = this.select(state => state.selectedExternally)
    readonly selectedInternally$ = this.select(state => state.selectedInternally)
    readonly clearable$ = this.select(state => state.clearable)
  
    readonly defer$ = this.select(state => state.defer)
  
    readonly selected$ = merge(
      this.selectedExternally$,
      this.selectedInternally$.pipe(skip(1)),
    ).pipe(
        distinctUntilChanged((prev, curr) => prev.sort().join(',') === curr.sort().join(',')),
    )


    vm$ = this.select(
        this.clearable$,
        this.options$,
        this.selected$,
        (clearable, options, selected) => {
            return {clearable, options, selected}
        }
    )


    // Updaters
    readonly setOptions = this.updater((state, options: FormMultiSelectOption[]) => {
        return {...state, options: [...options]}
    })

    readonly setSelectedExternal = this.updater((state, selectedExternally: string[]) => {
        return {...state, selectedExternally: [...selectedExternally]}
    })

    readonly setSelectedInternal = this.updater((state, selectedInternally: string[]) => {
        return {...state, selectedInternally: [...selectedInternally]}
    })

    readonly setClearable = this.updater((state, clearable: boolean) => {
        return {...state, clearable}
    })

    readonly setDefer = this.updater((state, defer: number) => {
        return {...state, defer}
    })

    readonly clearSelected = this.updater((state) => {
        return {...state, selectedInternally: [], selectedExternally: []}
    })

    // Effects
    readonly selectOptionInternally = this.effect( (value$:Observable<string[]>) => {
        return value$.pipe(
            withLatestFrom(this.defer$),
            switchMap(([value, defer]) => {
                return of(value).pipe(delay(defer))
            }),
            tap( (value:string[]) => {
                this.setSelectedInternal(value)
            })
        )
    })

}