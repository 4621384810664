import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { delay, map } from 'rxjs/operators';
import { SettingsService } from 'unifonic-spa-common';
import { CreateReportApiParams, CreateReportBody } from '../reports.models';
import { createReportsDataQuery } from './reports.graphql';

@Injectable()
export class ReportsRepository {

    constructor(
        private http: HttpClient,
        private apollo: Apollo,
        private settings: SettingsService
    ){}


    createReport(body: Partial<CreateReportApiParams>, timezone: string = ''){
        const params = new HttpParams({fromObject: body})
        const headers = new HttpHeaders({
            userTimeZone: timezone
        })
        //@ts-ignore
        return this.http.post(this.settings?.settings?.ciRestReportingUrl + '/v2/report/generate?' + params.toString(), body, {
            headers
        })
    }

    gqlQueryFetchReports(params: any){
        return this.apollo.query({
            query: createReportsDataQuery(`
                reportId:id,
                reportName,
                channels,
                products,
                reportFileName,
                startedAt,
                reportParams,
                status:reportState,
                notifyBySms,
                notifyByEmail,
                userId,
                startDate,
                endDate,
                accountName,
                requestOn,
                reportType,
                extraConfigSet{
                    key,
                    value
                }
            `),
            variables: {
                ...params
            }
        }).pipe(
            map((response: any) => {
                return response.data.getReportsDataOrByID
            })
        )
    }


    gqlQueryFetchReportDetails(reportId, userId) {
        //no reportParams as it is a string ~10kb size
        return this.apollo.query({
            query: createReportsDataQuery(`
                id,
                reportName,
                channels,
                products,
                timeTook,
                totalRecords,
                reportSize,
                userId,
                accountUUId,

                reportHash,
                reportState,
                failureReason,
                isUploaded,
                totalProcessedRecords,
                reportFileName,
                notifyBySms,
                notifyByEmail,
                retryCount,
                cronJob,
                scheduledAt,
                isScheduled,
                requestOn,
                generatedOn,
                reportType,
                startDate,
                endDate,
                accountName
            `),
            variables: {
                limit: 1,
                offset: 1,
                reportId,
                userId
            }
        }).pipe(
            map((response: any) => {
                return response.data.getReportsDataOrByID.entries[0]
            })
        )
    }

    downloadReport({reportId, userId, extension}: any){
        //@ts-ignore
        return this.http.get(`${this.settings?.settings?.ciRestReportingUrl}/v2/report/${reportId}/download`,
            {
                params: {
                    userId,
                    ReportExportType: extension
                },
                observe: 'response',
                responseType: 'blob',
            }
        )
    }

    deleteReport(reportId: string){
        //@ts-ignore
        return this.http.delete(`${this.settings?.settings?.ciRestUrl}/v2/report/${reportId}/remove`)
    }
}
