
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { delay, filter, map, skip, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { UniAnalyticsService, UniAuthFacade } from '@unifonic/common';

import { Observable } from "rxjs";
import { CampaignSummaryApi } from "./campaign-details.model";
import { CampaignDetailsRepository } from "./campaign-details.repository";
import { DEFAULT_OUTPUT } from "./campaign-details.graphql";
import { QueryPayload } from "../data-access.model";
import { MixpanelEvents } from "src/app/app.models";

type State = {
    entity: Partial<CampaignSummaryApi>,
    loading: boolean,
    loaded: boolean,
    lastUpdate: string,
}

const initialState: State = {
    entity: {},
    loading: false,
    loaded: false,
    lastUpdate: ''
}

@Injectable()
export class CampaignDetailsFetchStore extends ComponentStore<State> {


    constructor(
        private repository: CampaignDetailsRepository,
        private analytics: UniAnalyticsService,
        private authFacade: UniAuthFacade

    ){
        super(initialState)
    }

    //selectors
    readonly entity$ = this.select(state => state.entity)
    readonly loading$ = this.select(state => state.loading)
    readonly loaded$ = this.select(state => state.loaded)
    readonly lastUpdate$ = this.select(state => state.lastUpdate)
    
    readonly vm$ = this.select(

        this.entity$,
        this.loading$,
        this.loaded$,
        this.lastUpdate$,
        (entity, loading, loaded, lastUpdate) => {
            return {
                entity,
                loading,
                loaded,
                lastUpdate
            }
        },
        {debounce: true}
    )

    // effect
    readonly fetchSmsCampaignById = this.effect((payload$: Observable<QueryPayload<{campaignId: string, accountId: string, campaignType?: string}>>) => {
        return payload$.pipe(
                tap(() => { 
                    this.patchState({loading: true})
                }),
                switchMap(({variables, output}) => this.repository.gqlQuerySmsCampaignDetailsData({variables, output}).pipe(
                    tapResponse(response => {
                        this.patchState({
                            loading: false,
                            loaded: true,
                            entity: response,
                            lastUpdate: (new Date()).toString()
                        })

                    }, () => {
                            this.patchState({
                                entity: {},
                                loading: false, loaded: false})
                        })
                    )))
    })


    
        readonly fetchWhatsappCampaignById = this.effect((payload$: Observable<QueryPayload<{campaignId: string, accountId: string, campaignType?: string}>>) => {
            let performanceStart
            return payload$.pipe(
                    tap(() => {
                        performanceStart = new Date().getTime()
                        this.patchState({loading: true})
                    }),
                    switchMap(({variables, output}) => this.repository.gqlQueryWhatsappCampaignDetailsData({variables, output}).pipe(
                        tapResponse(response => {
                            this.analytics.mixpanelTrack(MixpanelEvents.campaignWhatsappSearch, {
                                ci_campaign_id: variables?.campaignId,
                                ci_campaign_type: variables?.campaignType,
                                account_id: variables?.accountId,
                                load_data_duration: new Date().getTime() - performanceStart,
                                account_name: this.authFacade.userMe?.user?.account?.name,
                                is_admin: this.authFacade.userMe?.user?.isAdminLevel
                            })

                            this.patchState({
                                loading: false,
                                loaded: true,
                                entity: response,
                                lastUpdate: (new Date()).toString()
                            })
    
                        }, () => {
                                this.patchState({
                                    entity: {},
                                    loading: false, loaded: false})
                            })
                        )))
        })

}