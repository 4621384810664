import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { omitBy } from 'lodash';
import { AccountsFacade, SenderNameFacade, UniAuthFacade, ItemStatus, VerificationStatus, Campaign } from '@unifonic/common';

import { MessageLogsRepository } from '../data-access/message-logs.repository';
import { MessageFiltersUrlParams } from '../log-analyser.models';
import { MessageLogsFetchStore } from '../store/message-logs-fetch.store';
import { MessageLogsFiltersStore } from '../store/message-logs-filters.store';
import { MessageLogsLayoutStore } from '../store/message-logs-layout.store';

import { MessageLogsUrlStore } from '../store/message-logs-url.store';
import { PageIndexStore } from './page-index.store';
import { CampaignDetailsFetchStore } from 'src/app/shared/data-access/campaign-details/campaign-details.store';
import { CampaignListFetchStore } from 'src/app/shared/data-access/campaign-list/campaign-list.store';
import { CampaignDetailsRepository } from 'src/app/shared/data-access/campaign-details/campaign-details.repository';
import { CampaignListRepository } from 'src/app/shared/data-access/campaign-list/campaign-list.repository';
import { TemplateSummaryRepository } from 'src/app/shared/data-access/template-summary/template-summary.repository';
@Component({
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MessageLogsFetchStore,
    MessageLogsRepository,
    MessageLogsFiltersStore,
    MessageLogsUrlStore,
    MessageLogsLayoutStore,
    PageIndexStore,
    CampaignListFetchStore,
    CampaignListRepository,
    CampaignDetailsFetchStore,
    CampaignDetailsRepository,
    TemplateSummaryRepository
  ]
})
export class PageIndexComponent implements OnInit {

  constructor(
    private messageLogsFiltersStore: MessageLogsFiltersStore,

    private route: ActivatedRoute,
    private messageLogsUrlStore: MessageLogsUrlStore,
    private store: PageIndexStore,
    
    private accountsFacade: AccountsFacade,
    private senderNameFacade: SenderNameFacade,
    private authFacede: UniAuthFacade,

  ) { }

  vm$ = this.store.vm$

  ngOnInit(): void {
    this.messageLogsUrlStore.syncUrl()

    const qp = this.route.snapshot.queryParams as MessageFiltersUrlParams
    
    /**
     * Set default fitler state according to the query params
     */
    this.store.setDefaultFilterState(qp)

    /**
     * Fetch sendernames
     * Used in filter form for sender name field
     */
    this.senderNameFacade.setSenderNames(omitBy({
      itemStatus: ItemStatus.active,
      verificationStatus: VerificationStatus.approved,
      limit: 1000,
      account: this.authFacede?.userMe?.user?.account?.id
    }, (v) => v === null || v === undefined || v === ''));

  }

  fetchMessageLogs(){
    this.messageLogsFiltersStore.patchState({page: '1'})
  }

}
