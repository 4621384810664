<ng-container *ngIf="vm$ | async as vm">
    <uni-buttons [isFlexEnd]="false">
        <ng-container *ngFor="let item of vm.items">
            <uni-button
                type="button"
                [style]="item.style"
                [isMargin]="true"
                [disabled]="item.disabled"
                (click)="selectOption(item)"
            >
                {{item.label}}
            </uni-button>
        </ng-container>
    </uni-buttons>
</ng-container>