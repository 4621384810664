import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { BlobUtils, UniAuthFacade, UniSnackbarFacade } from "@unifonic/common";
import { MessageLogsRepository } from '../../data-access/message-logs.repository';
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { mapFilterParamsToDownloadReportPayload } from "../../utils/map-filter-params-to-download-report-payload";
import { TranslateService } from "@ngx-translate/core";
import { MessageLogsFetchStore } from "../../store/message-logs-fetch.store";
import { Overlay } from "@angular/cdk/overlay";


type State = {
    generating: boolean
    maxDownloadSize: number
    infoPopupVisibility: boolean
}

const initialState: State = {
    generating: false,
    maxDownloadSize: 10000,
    infoPopupVisibility: false
}

@Injectable()
export class MessageLogsReportGeneratorStore extends ComponentStore<State>{
    constructor(
        private repository: MessageLogsRepository,
        private messageLogsFiltersStore: MessageLogsFiltersStore,
        private messageLogsFetchStore: MessageLogsFetchStore,
        private authFacade: UniAuthFacade,
        private snackbar: UniSnackbarFacade,
        private translate: TranslateService,
        private overlay: Overlay
    ){
        super(initialState)
    }

    //selectors

    generating$ = this.select(state => state.generating)
    maxDownloadSize$ = this.select(state => state.maxDownloadSize)
    infoPopupVisibility$ = this.select(state => state.infoPopupVisibility)

    notifyByMail$ = this.select(
        this.maxDownloadSize$,
        this.messageLogsFetchStore.totalCount$,
        (downloadSize, totalCount) => totalCount > downloadSize
    )

    userEmail$ = this.authFacade.userMe$.pipe(map(userMe => userMe.user.email))

    vm$ = this.select(
        this.generating$,
        this.messageLogsFetchStore.totalCount$,
        this.infoPopupVisibility$,
        this.userEmail$,
        this.maxDownloadSize$,
        (generating, totalCount, popupVisibility, userEmail, maxDownloadSize) => {
            return {
                generating,
                totalCount,
                popupVisibility,
                userEmail,
                disabled: this.authFacade.isImpresonationSession() && (totalCount > maxDownloadSize)
            }
        }
    )


    //effect
    readonly generateReport = this.effect((trigger$) => {
        return trigger$.pipe(
            tap(()=>{
                this.patchState({generating: true})
            }),
            withLatestFrom(
                this.messageLogsFiltersStore.queryParams$,
                this.authFacade.userMe$.pipe(map(userMe => userMe.user.timezone)),
                this.authFacade.userMe$.pipe(map(userMe => userMe.user.locale || 'en')), // CI-3463 - Backend returns locale as null for some users (unknown reasons)
                this.notifyByMail$
            ),
            switchMap(([_, qp, timezone, locale, notifyByMail]) => this.repository.generateReport(
                mapFilterParamsToDownloadReportPayload(qp, timezone, notifyByMail),
                timezone,
                locale
            ).pipe(
                tapResponse(
                    (response: any) => {
                        if(response.body === "Ok"){
                            this.patchState({
                                generating: false,
                                infoPopupVisibility: true
                            })

                        }else{
                            const blob = new Blob([response.body], {
                                type: 'octet/stream'
                            })

                            BlobUtils.download(blob, 'unifonic-report.zip', response.headers)
                            this.patchState({generating: false})
                        }
                    },
                    (err) => {
                        this.patchState({generating: false})
                        this.snackbar.show('error', this.translate.instant('ci.common.downloadFileErrorMessage'))

                    }
                )
            ))
        )
    })


}

