import { Component, Input, OnInit } from '@angular/core';
import { LastTimeRealTimeInfoStore, MarginUnit } from './last-time-real-time-info.store';

@Component({
  selector: 'app-last-time-real-time-info',
  templateUrl: './last-time-real-time-info.component.html',
  styleUrls: ['./last-time-real-time-info.component.scss'],
  providers: [LastTimeRealTimeInfoStore]
})
export class LastTimeRealTimeInfoComponent implements OnInit {

  // Last update date
  @Input() set lastUpdate(lastUpdate: string){
    this.store.patchState({lastUpdate})
  }

  // Query Params attached to the link to Log Analyzer
  @Input() set queryParams(queryParams: any){
    this.store.patchState({queryParams: {...queryParams}})
  }

  // Time margin (in hours) between last update date and the date displayed on the screen
  // For example: Aggregation happens every 2h in the DB. Subtracking 2h ensures us that DB was updated two or less hours ago. Time margin in this case is 2
  @Input() set timeMargin(timeMargin: number){
    this.store.patchState({timeMargin})
  }

  @Input() set marginUnit(marginUnit: MarginUnit){
    this.store.patchState({marginUnit})
  }

  @Input() set disableRedirection(disableRedirection: boolean){
    this.store.patchState({disableRedirection})
  }

  vm$ = this.store.vm$

  constructor(private store: LastTimeRealTimeInfoStore) { }

  ngOnInit(): void {
  }

}
