import { Component, Input } from '@angular/core';
import { UniCampaignsPermissionsService } from '../../shared/uni-campaign-permissions.service';
import { Campaign, CampaignStatus } from '../../shared/uni-campaign.model';
import { SmsCounter } from '../../../../shared/uni-sms-counter.utils';
import { UniShortNumberService } from '../../../../shared/uni-short-number.service';
import { DateUtils } from '../../../../utils/date.utils';
import { getSendingPercentage } from '../campaign-progress/campaign-progress.helper';

@Component({
  selector: 'cc-campaign-status',
  templateUrl: './campaign-status.component.html',
  styleUrls: ['./campaign-status.component.scss']
})
export class CampaignStatusComponent {
  @Input() campaign: Campaign;
  campaignStatus = CampaignStatus;
  dateUtils = DateUtils;
  isModalActive = false;
  smsCounter = SmsCounter;
  getSendingPercentage = getSendingPercentage;

  constructor(
    public campaignPermissionsService: UniCampaignsPermissionsService,
    private uniShortNumberService: UniShortNumberService,
  ) { }

  openModal(): void {
    this.isModalActive = true;
  }

  canDelete(): boolean {
    return this.campaignPermissionsService.canDeleteScheduled();
  }

  getUnitCost(): string {
    const smsCount = this.smsCounter.init(this.campaign.content).smsLength;
    const unitCost = this.campaign.recipientsCount * smsCount;

    return !unitCost
      ? '0'
      : this.uniShortNumberService.formatNumber(unitCost);
  }

  isRejectionReason(campaign: Campaign): boolean {
    return !!campaign.rejectionReason
      && campaign.status === this.campaignStatus.rejected;
  }
}
