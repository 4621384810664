import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ApolloQueryResult } from '@apollo/client';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { createMessageLogs, createMessageLogsDetails, createMessageLogsWhatsappDetails } from './message-logs.graphql';
import { ApiMessageLogsReportBody, CampaignListApiResponse } from '../log-analyser.models';
import { SettingsService } from 'unifonic-spa-common';

@Injectable()
export class MessageLogsRepository {

    constructor(
        private http: HttpClient,
        private apollo: Apollo,
        private settings: SettingsService
    ){}

    gqlQueryMessageLogs(queryParams: any):Observable<ApolloQueryResult<any>>{
        return this.apollo.query({
          query: createMessageLogs(
            `
            sender:sender
            recipient:recipient
            date:sentByClient
            status:status
            messageId:messageId
            elMessageId:elMessageId
            isRealTime:isRealTime
            product
            `
          ),
          variables: {
            ...queryParams
          }
        })
        .pipe(
            map((response: any) => {
              return response.data.findFactSmsTrafficLogs
            })
        )
    }

    gqlQueryMessageLogsDetails(params: any){
      return this.apollo.watchQuery({
        query: createMessageLogsDetails(
          `
            messageId: message_id
            elMessageId: el_message_id
            product
            channel
            sourceProtocol: source_protocol
            messageType: message_type
            accountName: account_name
            countryName: country_name
            operatorName: operator_name
            senderName: sender_name
            receiver
            customerStatus: customer_status
            customerReason: customer_reason
            submitDate: submit_date
            userSubmitDate: user_submit_date
            dlrReceivedDate: dlr_received_date
            dlrStatus: dlr_status
            deliveredToCustomer: dlr_received_date
            executionName: product_name
            shortMessage: short_message
            description
          `
        ),
        variables: {
          ...params
        }
        }).valueChanges.pipe(
          map((response: any) => {
            return response.data.findMessageById
      }))
    }

    qplQueryMessageLogsWhatsappDetails(params: any){
      return this.apollo.watchQuery({
        query: createMessageLogsWhatsappDetails(),
        variables: {
          ...params
        }
        }).valueChanges.pipe(
          map((response: any) => {
            return response.data.findMessageByIdForWhatsApp
      }))
    }

    generateReport(data: ApiMessageLogsReportBody, timezone: string, locale: string = 'en'){
      const headers = new HttpHeaders({
        "userTimeZone": timezone,
        "Accept-language": locale
      });

      /**
       * We have only one endpoint to handle 2 features. It can be treated as a violation of Single Responsibility rule.
       * However, accoding to Backed, for now, there is no way to have 2 separate endpoints.
       * The functionalities here are:
       * 1. Download message logs directly - in case of less than specific number (MessageLogsReportGeneratorStore.state.maxDownloadSize)
       *    In this case the notifyByMail is set to false
       * 2. Send email with a download link - for more than this specific number of logs. In this case notifyByMail is set to true.
       *    From frontend pespective in this case as a response we will display popup with information
       *
       * In the first case backend returns data which should be treated as a "blob"
       * In the second case backend returns data which should be treated as a "text" (it is response.body === "Ok")
       */
      const responseType = data.notifyByMail ? 'text' : 'blob';

      return this.http.get(
        //@ts-ignore
        `${this.settings?.settings?.ciRestReportingUrl}/v2/sms/download/log/csv`,
        {
          observe: 'response',
          responseType,
          headers,
          params: {
            ...data
          }
        } as any
      )
    }
}
