import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { SenderNameFacade } from './../../../../../unifonic-spa-common/src/shared/uni-data/facade/sender-names.facade';
import { AdvancedFiltersFormSenderNameStore } from './advanced-filters-form-sender-name.store';

@Component({
  selector: 'app-advanced-filters-form-sender-name',
  templateUrl: './advanced-filters-form-sender-name.component.html',
  styleUrls: ['./advanced-filters-form-sender-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    AdvancedFiltersFormSenderNameStore
  ]
})
export class AdvancedFiltersFormSenderNameComponent implements OnInit, OnDestroy, ControlValueAccessor {

  senderNames$ = this.senderNameFacade.senderNames$.pipe(
    map((accounts) => accounts ? accounts : []),
    map((accounts) => {
      return accounts.map(a => ({label: a.name, value: a.name}) )
    }),
    map(names => {
      return names.sort((a: any, b: any) => a.label > b.label ? 1 : -1)
    })
  )

  senderNamesLoading$ = this.senderNameFacade.isLoading$

  value = ''

  onChange: any = () => {}
  onTouch: any = () => {}

  constructor(
    public ngControl: NgControl,
    private senderNameFacade: SenderNameFacade,
    private store: AdvancedFiltersFormSenderNameStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){
    this.saveValue(value)
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(value: string[]){
    this.saveValue(value);
  }

  saveValue(value){
    this.value = value
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
