import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AdvancedFiltersFormCampaignIdStore } from './advanced-filters-form-campaign-id.store';

@Component({
  selector: 'app-advanced-filters-form-campaign-id',
  templateUrl: './advanced-filters-form-campaign-id.component.html',
  styleUrls: ['./advanced-filters-form-campaign-id.component.scss'],
  providers: [
    AdvancedFiltersFormCampaignIdStore,
  ]
})
export class AdvancedFiltersFormCampaignIdComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() set accountId(accountId: string){
    this.saveValue('')
    this.store.patchState({accountId})
  }

  accountId$ = this.store.accountId$
  value$ = this.store.value$

  onChange: any = () => {}
  onTouch: any = () => {}

  constructor(
    public ngControl: NgControl,

    private store: AdvancedFiltersFormCampaignIdStore
  ) { 
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){ }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(event: any){
    this.saveValue(event.value)
  }


  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
