import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, filter, map, skipUntil, tap } from 'rxjs/operators';
import { AccountOption, FormAutocompleteSelectStore } from './form-autocomplete-select.store';

@Component({
  selector: 'app-form-autocomplete-select',
  templateUrl: './form-autocomplete-select.component.html',
  styleUrls: ['./form-autocomplete-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormAutocompleteSelectStore]
})
export class FormAutocompleteSelectComponent implements OnInit {

  @Input() set options(options: AccountOption[]){
    this.store.setOptions(options)
  }

  @Input() set value(value: string){
    this.store.setValue([value, 'external'])
  }

  @Input() set loading(loading: boolean){
    this.store.setLoading(loading)
  }
  @Input() placeholder: string = 'Select'

  @Output() valueChange = this.store.eventEmitter$

  control: FormControl = new FormControl()

  options$ = this.store.options$
  loading$ = this.store.loading$

  constructor(
    private store: FormAutocompleteSelectStore
  ) { }

  ngOnInit(): void {

    this.control.valueChanges.pipe(
      skipUntil(this.options$.pipe(filter(opts => !!opts.length))),
      map((val) => val && val.value ? val.value : ''),
      distinctUntilChanged(),
    ).subscribe((val) => {
      this.store.setValue([val, 'internal'])
    })

    this.store.syncFormControl(this.control)

  }

}
