import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerReasonSelectorStore } from './customer-reason-selector.store';
import { CustomerReasonRepository } from '../../data-access/customer-reason/customer-reason.repository';
import { CustomerReasonFetchStore } from '../../data-access/customer-reason/customer-reason.store';

@Component({
  selector: 'app-customer-reason-selector',
  templateUrl: './customer-reason-selector.component.html',
  styleUrls: ['./customer-reason-selector.component.scss'],
  providers: [
    CustomerReasonSelectorStore,
    CustomerReasonFetchStore,
    CustomerReasonRepository
  ]
})
export class CustomerReasonSelectorComponent implements OnInit {

  constructor(
    private store: CustomerReasonSelectorStore
  ) { }

  @Input() set value(label: string){
    this.store.patchState({label})
    this.store.patchState({defaultValue: label})
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter()

  vm$ = this.store.vm$

  ngOnInit(): void {
    this.store.fetchLatest()
  }

  onValueChange(event: any){
    this.store.onFormValueChange(event)
    this.valueChange.emit(event)
  }

  reset(){
    this.store.patchState({label: ''})
  }

  onOptionsClose(){
    this.store.patchState({entities: []})
  }
}
