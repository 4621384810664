
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, tap } from "rxjs/operators";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";
import { combineLatest } from "rxjs";
import { MccCampaignsFetchStore } from "../../store/mcc-campaigns-fetch.store";
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {
    value: string
}

const initialState: State = {
    value: "",
}

@Injectable()
export class AdvancedFiltersFormTemplateStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
        private formStore: FiltersFormStore,
        private mccCampaignsFetchStore: MccCampaignsFetchStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors

    readonly compaignId$ = this.select(
        this.formStore.campaignId$,
        (campaignId) => campaignId
    )

    readonly templateParams$ = this.select(
        this.formStore.campaignId$,
        this.authFacade.account$.pipe(filter(account => !!account), map((account:any) => account.id)),
        (campaignId, accountId) => ({
            campaignId, accountId
        })

    )


    vm$ = this.select(
        this.compaignId$,
        this.mccCampaignsFetchStore.templates$,
        this.mccCampaignsFetchStore.templatesLoading$,
        (campaignId, templates, templatesLoading) => {
            return {
                campaignId,
                templates,
                templatesLoading
            }
        }
    )


    fetchTemplates = this.effect(() => {
        return this.templateParams$.pipe(
            tap((params: any) => {
                if(params.campaignId.length && params.accountId.length){
                    this.mccCampaignsFetchStore.fetchWhatsappCampaignTemplates(params)
                }
            })
        )
    })

}