

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { FilterPanelMultiCampaignIdsStore } from './filter-panel-multi-campaign-ids.store';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';

@Component({
  selector: 'app-filter-panel-multi-campaign-ids',
  templateUrl: './filter-panel-multi-campaign-ids.component.html',
  styleUrls: ['./filter-panel-multi-campaign-ids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterPanelMultiCampaignIdsStore]
})
export class FilterPanelMultiCampaignIdsComponent implements OnInit {

  onChange: any = () => {}
  onTouch: any = () => {}

  currentValue$ = this.store.value$.pipe(map(
    (value: any) => {
      return value?.split(',') || []
    }
  ))
  options$ = this.store.options$


  constructor(
    private store: FilterPanelMultiCampaignIdsStore,
    private messageLogsFiltersStore: MessageLogsFiltersStore
  ) {
  }

  ngOnInit(): void {
    this.store.fetch()
  }

  statusValueChange(option: string[]) {
  
    this.messageLogsFiltersStore.patchState({campaignId: option.join(','), autoload: true})
  }

}
