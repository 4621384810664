import { graphql, rest } from 'msw'

const faker = {
  datatype: { uuid: () => Math.random() + ''},
  name: {fullName: () => ''},
  phone: {number: () => Math.random() * 100000 + ''},
  date: {past: () => '', recent: () => ''},
  helpers: {arrayElement: () => ''},
  lorem: {words: (x) => ''},
  random: {numeric: () => 1}
}



const messageLogs = {
  generate(number) {
    return Array(number).fill().map((_, i) => ({
      record_id: faker.random.numeric(7),
      receiver: faker.random.numeric(12),
      message_id: faker.random.numeric(11),
      event_status: faker.helpers.arrayElement(['SUCCESS_ESME']),
      cdr_kind: faker.helpers.arrayElement(['FCDR']),
      sender_name: faker.helpers.arrayElement(['Unifonic']),
      submit_date: faker.date.recent(),
      source_protocol: faker.helpers.arrayElement(['HTTP']),
      account_id: faker.random.numeric(3),
      account_name: faker.helpers.arrayElement(['Active Monitoring']),
      short_message: '',
      dlr_reveived_date: faker.date.recent(4),
      dlr_status: faker.helpers.arrayElement(['delivered', 'not-delivered', 'sent', 'received']),
      message_type: faker.helpers.arrayElement([null]),



      //not exactly necessary
      id: faker.datatype.uuid(),
      messageId: faker.datatype.uuid(),
      sender: faker.phone.number(),
      recipient: faker.phone.number(),
      date: faker.date.past(),
      status: faker.helpers.arrayElement(['delivered', 'not-delivered', 'sent', 'received']),
    }))
  }
}

export const getMessageLogs = rest.get('http://0.0.0.0:4200/app/api/v2/message-logs', async (req, res, ctx) => {
  console.log('%c MSW query param string:' + req.url.search, 'font-weight: bold')
  const numberOfResults = 40 || Math.round(Math.random() * (40 - 20) + 20)
  const data = messageLogs.generate(numberOfResults)
  return res(
    ctx.json(data)
  )
})


export const getMessageLogsGQL = graphql.query('MessageLogsFindFactSmsTrafficLogs', (req, res, ctx) => {
  const numberOfResults = Number(req.variables.limit)
  const data = messageLogs.generate(numberOfResults)
  return res(
    ctx.data({
      findFactSmsTrafficLogs: {
        totalCount: numberOfResults * 7,
        count: 0,
        errorCode: 0,
        entries: data,
      }
    })
  )
})