import { delay, distinct, distinctUntilChanged, filter, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from "@angular/core";

import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable, of } from "rxjs";

type UpdateSource = 'external' | 'internal' | 'initial'
export type FieldValue = [string, UpdateSource]

export type MultiSelectOption = {
    label: string,
    value: string,
    selected: boolean;
}

type State = {
    currentValue: FieldValue
    options: MultiSelectOption[],
    loading: boolean,
    inputValue: string
    open: boolean,
    debounceTime: number,
    overlayWidth: number,
    noResultsLabel: string,
    placeholder: string,
    searchPlaceholder: string,
    selectedItems: string[],
    separator: string,
}

const initialState: State = {
    currentValue: ['', 'initial'],
    options: [],
    loading: false,
    inputValue: '',
    open: false,
    debounceTime: 300,
    overlayWidth: 300,
    noResultsLabel: 'No Results',
    placeholder: 'Select Option',
    searchPlaceholder: 'Search for option',
    selectedItems: [],
    separator: ','
}

@Injectable()
export class AutocompleteMultiSelectStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    /**
     * Selectors
     * Take values directly from the store
     */
    readonly options$ = this.select(state => state.options)
    readonly loading$ = this.select(state => state.loading)
    readonly currentValue$ = this.select(state => state.currentValue)
    readonly open$ = this.select(state => state.open)
    readonly inputValue$ = this.select(state => state.inputValue)
    readonly debounceTime$ = this.select(state => state.debounceTime)
    readonly overlayWidth$ = this.select(state => state.overlayWidth)
    readonly noResultsLabel$ = this.select(state => state.noResultsLabel)
    readonly placeholder$ = this.select(state => state.placeholder)
    readonly searchPlaceholder$ = this.select(state => state.searchPlaceholder)
    readonly selectedItems$ = this.select(state => state.selectedItems)
    readonly separator$ = this.select(state => state.separator)

    /**
     * Selectors
     * Take values from other selectors and calculate for view purposes
     */
    readonly currentValueValue$ = this.select(
        this.currentValue$,
        ([value, _]) => value)

    readonly currentValueSource$ = this.select(
        this.currentValue$,
        ([_, source]) => source)

    /**
     * SearchingPhrase is delayed version of inputValue
     * We dont want to do expensive loop through the options any type user type the value
     * Instead of this we wait for selected amount of time to emit value
     * We use switchMap + delay instead of debounceTime to easily pass the debounceTime value
     */
    readonly searchingPhrase$ = this.inputValue$.pipe(
        withLatestFrom(this.debounceTime$),
        switchMap(([phrase, debounceTime]) => {
            return of(phrase).pipe(delay(phrase.length === 0 ? 0 : debounceTime))
        })
    )

    /**
     * Add additional properties needed in view
     */
    readonly calculatedOptions$ = this.select(
        this.options$,
        this.selectedItems$,
        (options, selectedItems) => {
            return options.map((o) => {
                return {
                    ...o,
                    selected: selectedItems.indexOf(o.value) !== -1
                }
            })
        }
    )

    /**
     * Options filtered by the phrase provided in searchingPhrase.
     * options$ selector usually emits value only on component init phase
     * searchingPhases emits value if user is not typing for a while (specified in debounceTime @Input)
     */
    readonly filteredOptions$ = combineLatest([
            this.searchingPhrase$,
            this.calculatedOptions$
        ]).pipe(
            map(([phrase, options]: [string, MultiSelectOption[]]) => {
                const filteredOptions = options.filter((option => option.label.toLowerCase().includes(phrase.toLowerCase())))
                return phrase.length === 0 ? options : filteredOptions
            }),
            startWith([])
    )

    /**
     * Moment between typing and calulating the filtered options
     * Since searchingphrase is basically delayed inputValue, we can assume that
     * whenever those values are different user is waiting for the new filtered options
     *
     */
    readonly optionsLoading$ = combineLatest([
        this.inputValue$,
        this.searchingPhrase$
    ]).pipe(
        map( ([input, search]) => input !== search),
        startWith(false)
    )

    /**
     * View Model
     * debounce: true as we want emit value only once when all micro-tasks are finished
     */
    readonly vm$ = this.select(
        this.filteredOptions$,
        this.loading$,
        this.open$,
        this.inputValue$,
        this.optionsLoading$,
        this.overlayWidth$,
        this.noResultsLabel$,
        this.placeholder$,
        this.currentValueValue$,
        this.searchPlaceholder$,
        this.selectedItems$,
        this.separator$,
        (options, loading, open, inputValue, optionsLoading, overlayWidth, noResultsLabel, placeholder, currentValue, searchPlaceholder, selectedItems, separator) => {
            return {
                options,
                loading,
                open,
                inputValue: inputValue.split(separator).join(', '),
                optionsLoading,
                overlayWidth,
                noResultsLabel,
                placeholder,
                isValueSelected: !!currentValue.length,
                searchPlaceholder,
                selectedItems,
                clearIconVisibility: !!selectedItems.length && !open
            }
        }, {debounce: true}
    )

    /**
     * Emit value to the application (parent component)
     * Only internal changes can be emitted outside (otherwise infinite loop happens)
     */
    readonly eventEmitter$ = this.currentValue$.pipe(
        filter(([_, source]) => source === 'internal'),
        map(([value, _]) => value),
        distinctUntilChanged()
        
    )

    /**
     * Value updaters
     */

    readonly setCurrentValue = this.updater( (state, currentValue: FieldValue) => {
        const value = currentValue[0]
        const selectedOptions = state.options.filter(o => value.split(state.separator).indexOf(o.value) !== -1);
        const inputValue = selectedOptions.map(so => so.label).join(state.separator)
        const selected = selectedOptions.map(so => so.value)

        return {
            ...state, 
            selectedItems: selected,
            inputValue,
            currentValue: [...currentValue]}
    })


    readonly updateSelection = this.updater( (state, item: string) => {
        if(state.selectedItems.indexOf(item) !== -1){
            return  {...state, selectedItems: state.selectedItems.filter(si => si !==item)};
        }
        return  {...state, selectedItems: [...state.selectedItems, item]};
    })

    readonly updateInputValue = this.updater( (state) => {
        const inputValue = state.options
            .filter(o => state.selectedItems.indexOf(o.value) !== -1)
            .map(o => o.label)
            .join(state.separator)
      return {...state, inputValue, currentValue: [state.selectedItems.join(state.separator), 'internal']};
    })

    /**
     * Whenever options change (most likely it will not happen more then once)
     * Calculate the state. As long as there is no way to update options internally
     * all changes invoked by this stream can be treated as external.
     */
    readonly syncOptions = this.effect((trigger$) => {
        return this.options$.pipe(
            filter(options => !!options.length),
            withLatestFrom(
                this.currentValue$
            ),
            tap(([options, [currentValue]]) => {
                const option = options.filter(o => currentValue.indexOf(o.value) !== -1);
                this.setCurrentValue( option.length ? [currentValue, 'external'] : ['', 'external'])
            })
        )
    })
}
