import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UniShortNumberService } from '../../../shared/uni-short-number.service';

@Injectable()
export class UniPriceService {
  constructor(
    private translate: TranslateService,
    private uniShortNumberService: UniShortNumberService,
  ) {}

  getAmount(amount: number, fractionDigits?: number): string {
    if (fractionDigits === undefined) {
      return formatNumber(amount, 'en');
    }

    return formatNumber(amount, 'en', `1.${fractionDigits}-${fractionDigits}`);
  }

  getCurrency(currency: string): string {
    return this.translate.instant(`currency.${currency}`);
  }

  getPrice(amount: number, currency: string, fractionDigits?: number): string {

    return this.translate.instant(this.getKey(currency), {
      currency: this.getCurrency(currency),
      amount: this.getAmount(amount, fractionDigits),
    });
  }

  getKey(currency = 'usd'): string {
    return currency.toLowerCase() === 'usd'
      ? 'format.money'
      : 'format.money2';
  }

  getShortAmount(amount: number): string {
    return this.uniShortNumberService.formatNumber(amount);
  }

  getShortPrice(amount: number, currency: string): string {
    return this.translate.instant(this.getKey(currency), {
      currency: this.getCurrency(currency),
      amount: this.getShortAmount(amount),
    });
  }
}
