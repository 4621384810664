import { Component, Input, OnInit, Output } from '@angular/core';
import { OverlayTextFilterStore } from './overlay-text-filter.store';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-overlay-text-filter',
  templateUrl: './overlay-text-filter.component.html',
  styleUrls: ['./overlay-text-filter.component.scss'],
  providers: [OverlayTextFilterStore]
})
export class OverlayTextFilterComponent implements OnInit {

  @Input() set currentValue(value: string){
      this.store.patchState({value, inputValue: value})
  }

  @Input() set active(active: boolean){
    this.store.patchState({active})
  }

  @Input() set titleLabel(titleLabel: string){
    this.store.patchState({titleLabel})
  }

  @Input() set placeholderLabel(placeholderLabel: string){
    this.store.patchState({placeholderLabel})
  }

  @Input() set submitLabel(submitLabel: string){
    this.store.patchState({submitLabel})
  }

  @Input() set clearLabel(clearLabel: string){
    this.store.patchState({clearLabel})
  }


  @Output() valueChange: Subject<string> = this.store.emitter$
  @Output() openChange: Observable<boolean> = this.store.open$

  constructor(private store: OverlayTextFilterStore) { }

  vm$ = this.store.vm$

  ngOnInit(): void {}

  closeOverlay(){
    this.store.patchState({open: false})
  }

  openOverlay(){
    this.store.patchState({open: true})
  }

  submit(){
    this.store.changeValue()
    this.store.patchState({open: false})
  }

  reset(){
    this.store.changeValue()
    this.store.patchState({open: false})
  }

}
