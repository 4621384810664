<uni-box-tabs>
  <uni-box-tab
    [isActive]="activeTab === tabs.sms"
    (click)="onNavigate(tabs.sms)"
  >
    {{ 'campaign.tab.sms' | translate }}
  </uni-box-tab>
  <uni-box-tab
    *ngIf="isSMSProEnabled"
    [isActive]="activeTab === tabs.smsPro"
    (click)="onNavigate(tabs.smsPro)"
  >
    {{ 'campaign.tab.smsPro' | translate }}
  </uni-box-tab>
  <uni-box-tab
    [isActive]="activeTab === tabs.whatsapp"
    (click)="onNavigate(tabs.whatsapp)"
  >
    {{ 'campaign.tab.whatsapp' | translate }}
  </uni-box-tab>
  <uni-box-tab
    *ngIf="isFSEnabled"
    [isActive]="activeTab === tabs.flowStudio"
    (click)="onNavigate(tabs.flowStudio)"
  >
    {{ 'campaign.tab.flowStudio' | translate }}
  </uni-box-tab>
  <uni-box-tab
    [isActive]="activeTab === tabs.voice"
    [isDisabled]="disableVoice"
    (click)="onNavigate(tabs.voice)"
  >
    {{ 'campaign.tab.voice' | translate }}
  </uni-box-tab>
</uni-box-tabs>
