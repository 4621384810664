import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './tab.component';
import { TabHeaderSelectorComponent } from './tab-header-selector/tab-header-selector.component';
import { UniButtonModule } from 'unifonic-spa-common/src';

@NgModule({
  declarations: [TabComponent, TabHeaderSelectorComponent],
  exports: [TabComponent, TabHeaderSelectorComponent],
  imports: [
    CommonModule,
    UniButtonModule,
  ]
})
export class TabModule { }
