import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MessageLogsFetchStore } from '../../store/message-logs-fetch.store';
import { MessageLogsLayoutStore } from '../../store/message-logs-layout.store';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent implements OnInit {

  loading$ = this.messageLogsFetchStore.loading$

  multiCampaignMode$ = this.messageLogsLayoutStore.multiCampaignMode$

  constructor(
    private messageLogsFetchStore: MessageLogsFetchStore,
    private messageLogsLayoutStore: MessageLogsLayoutStore
  ) { }

  ngOnInit(): void {}

  showMoreFilters(){
    this.messageLogsLayoutStore.setGeneralFiltersFormVisbility(true)
    this.messageLogsLayoutStore.setFilterPanelVisibility(false)
  }
}
