import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignIdSelectorComponent } from './campaign-id-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadSelectModule } from '../../typeahead-select/typeahead-select.module';



@NgModule({
  declarations: [CampaignIdSelectorComponent],
  exports: [CampaignIdSelectorComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TypeaheadSelectModule
  ]
})
export class CampaignIdSelectorModule { }
