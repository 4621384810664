import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { FormPeriodDatePickerStore } from "../form-period-date-picker.store";


export type UpdateSource = 'external' | 'internal' | 'initial'

type State = {
    clearable: boolean
    entities: any[]
}

const initialState: State = {
    clearable: true,
    entities: []
}

@Injectable()
export class FormPeriodDatePickerPeriodSelectorStore extends ComponentStore<State>{
    constructor(
        private formPeriodDatePickerStore: FormPeriodDatePickerStore
    ){
        super(initialState)
    }

    //selectors
    readonly clearable$ = this.select((state) => {
        return state.clearable
    })

    readonly entities$ = this.select((state) => {
        return state.entities
    })

    readonly vm$ = this.select(
        this.entities$,
        this.formPeriodDatePickerStore.currentValue$,
        this.formPeriodDatePickerStore.clearable$,
        this.formPeriodDatePickerStore.periodPlaceholder$,
        (entities, [value, _], clearbale, periodPlaceholder) => {
            return {
                entities,
                value,
                clearbale,
                periodPlaceholder
            }
        }, {debounce: true}
    )


    //updaters
    readonly setEntities = this.updater((state, entities: any[]) => ({...state, entities: [...entities]}))

    //effects
    readonly switchToDateSelector = this.effect((trigger$) => {
        return trigger$.pipe(tap(() => {
            // this.formPeriodDatePickerStore.selectType('date')
            this.formPeriodDatePickerStore.setCurrentValueToDefaultDateRange()
        }))
    })

    readonly setValue = this.effect((value$: Observable<string>) => {
        return value$.pipe(tap((value: string) => {
            this.formPeriodDatePickerStore.setCurrentValue([value, 'internal'])
        }))
    })
    
}

