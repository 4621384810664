import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CampaignDetailsFetchStore } from 'src/app/shared/data-access/campaign-details/campaign-details.store';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { CampaignDetailsRepository } from 'src/app/shared/data-access/campaign-details/campaign-details.repository';
import { CampaignListRepository } from "src/app/shared/data-access/campaign-list/campaign-list.repository";
import { Channels } from "src/app/shared/store-mocks/channels.store";
import { TemplateSummaryFetchStore } from 'src/app/shared/data-access/template-summary/template-summary.store';
import { TemplateSummaryRepository } from "src/app/shared/data-access/template-summary/template-summary.repository";

type State = {
    latestLoading: boolean,
    templates: any[],
    templatesLoading: boolean,
    templateStats: any,
    templateStatsLoading: boolean
}

const initialState: State = {
    latestLoading: false,
    templates: [],
    templatesLoading: false,
    templateStats: {},
    templateStatsLoading: false
}

@Injectable()
export class MccCampaignsFetchStore extends ComponentStore<State>{
    constructor(
        private campaignDetailsFetchStore: CampaignDetailsFetchStore,
        private campaignDetailsRepository: CampaignDetailsRepository,
        private templateSummaryRepository: TemplateSummaryRepository,
        private campaignListRepository: CampaignListRepository,
        // private templateSummaryFetchStore: TemplateSummaryFetchStore,
    ){
        super(initialState)
    }
    
    readonly templates$ = this.select(state => state.templates)
    readonly templatesLoading$ = this.select(state => state.templatesLoading)
    readonly templateStats$ = this.select(state => state.templateStats)
    readonly templateStatsLoading$ = this.select(state => state.templateStatsLoading)

    readonly fetchWhatsappCampaignTemplates = this.effect((params$: Observable<any>) => {
        return params$.pipe(
            switchMap((variables) => {
                this.patchState({templates: [], templatesLoading: true})
                return this.campaignDetailsRepository.gqlQueryWhatsappCampaignDetailsData({
                    variables,
                    output: `
                        templatesData{
                            templateId
                            templateName
                            templateLanguage
                        }
                    `
                }).pipe(
                    tapResponse(response => {
                        this.patchState({templatesLoading: false})
                        this.patchState({templates: response.templatesData.map((td: any) => {
                            return {
                                label: td.templateName,
                                value: td.templateId
                            }
                        })})
                    }, () => {
                        this.patchState({templatesLoading: false})
                    })
                )
            }),
        )
    })


    readonly fetchWhatsappCampaignStats = this.effect((params$: Observable<any>) => {
        return params$.pipe(
            switchMap((variables) => {
                this.patchState({templateStatsLoading: true, templateStats: {}})
                return this.templateSummaryRepository.gqlQueryWhatsappCampaignTemplateDetails({
                    variables,
                    output: `
                        accountId
                        campaignId
                        templateId
                        totalMessages
                        messagesSent
                        messagesRead
                        messagesFailed
                        messagesDeliveryNotConfirmed
                        messagesReceived
                        messagesRejected
                        messagesSubmittedToWhatsApp
                        uLinksClickSummary{
                            linkId
                            linkName
                            percentage
                            numClicks
                            linkType
                            clickStatistics{
                                countryCode
                                numClicks
                                percentage
                            }

                        }
                    `
                }).pipe(
                    tapResponse(response => {
                        this.patchState({templateStatsLoading: false, templateStats: response})


                        // this.patchState({templates: response.templatesData.map((td: any) => {
                        //     return {
                        //         label: td.templateName,
                        //         value: td.templateId
                        //     }
                        // })})
                    }, () => {
                        this.patchState({templateStatsLoading: false})
                    })
                )
            }),
        )
    })


    /**
     * 
     * 
     * 
     *       this.dashboardCampaignFiltersStore.campaignId$,
            this.dashboardCampaignFiltersStore.accountId$,
            this.templateId$

            
     *  readonly fetchWhatsappCampaignTemplateById = this.effect((payload$: Observable<QueryPayload<{campaignId: string, accountId: string, templateId: string, includeULinkClickSummary: boolean}>>) => {
        return payload$.pipe(
                tap(() => {
                    this.patchState({loading: true})
                }),
                switchMap(({variables, output}) => this.repository.gqlQueryWhatsappCampaignTemplateDetails({variables, output}).pipe(
                    tapResponse(response => {
                        this.patchState({
                            loading: false,
                            loaded: true,
                            entity: response,
                            lastUpdate: (new Date()).toString()
                        })

                    }, () => {
                            this.patchState({
                                entity: {},
                                loading: false, loaded: false})
                        })
                    )))
    })


     */

}
