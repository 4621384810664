import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { FrequenciesMockStore } from "src/app/shared/store-mocks/frequencies.store";



type State = {
    dateSelectorsVisibility: boolean
}

const initialState: State = {
    dateSelectorsVisibility: false
}

@Injectable()
export class CreateFormFrequencyStore extends ComponentStore<State>{
    constructor(
        private frequenciesMockStore: FrequenciesMockStore,
    ){
        super({...initialState})
    }

    //selectors

    readonly entities$ = this.select(
        this.frequenciesMockStore.daily$,
        this.frequenciesMockStore.weekly$,
        this.frequenciesMockStore.monthly$,
        this.frequenciesMockStore.yearly$,
        (...entities) => {
            return [
                ...entities
            ]
        }
    )

    readonly dateSelectorsVisibility$ = this.select(state => state.dateSelectorsVisibility)

    readonly vm$ = this.select(
        this.entities$,
        this.dateSelectorsVisibility$,
        (entities, dateSelectorsVisibility) => {
            return {
               entities,
               dateSelectorsVisibility,
               value: ''
            }
        }, {debounce: true}
    )

    //updaters


    // effects

}
