<ng-container *ngIf="vm$ | async as vm">

  <uni-label>
      {{'ci.common.button' | translate}}
  </uni-label>
  
  <app-autocomplete-multiselect
    [currentValue]="value"
    [options]="vm.buttonOptions"
    [loading]="vm.templateStatsLoading"
    [noResoultsLabel]="'ci.common.noResults' | translate"
    [placeholder]="'ci.common.button' | translate"
    (valueChange)="onValueChange($event)"
  ></app-autocomplete-multiselect>

  <uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
      {{'ci.common.fieldRequired' | translate}}
  </uni-form-field-error>

  </ng-container>