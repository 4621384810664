export const calculateDropdownHeight = (options: any[], optionsLoading:boolean, httpLoading) => {
    const dropdownItemHeight = 45;
    let dropdownHeight;
    if(options.length <= 5){
        dropdownHeight = options.length * dropdownItemHeight;
    }
    if(options.length > 5){
        dropdownHeight = 5 * dropdownItemHeight;
    }

    if(dropdownHeight === 0 && (optionsLoading || httpLoading)){
        dropdownHeight = dropdownItemHeight
    }

    return {
        dropdownHeight,
        dropdownItemHeight
    }

}