import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

export type UniLinkType = 'primary' | 'secondary';

@Component({
  selector: 'uni-link',
  templateUrl: './uni-link.component.html',
  styleUrls: ['./uni-link.component.scss'],
})
export class UniLinkComponent {
  @Input() class = '';
  @Input() path: string;
  @Input() target = '_self';
  @Input() routerLink = '';
  @Input() type: UniLinkType = 'primary';
  @Input() icon: string;
  @Input() tabindex = '-1';
  @Input() isDisabled = false;
  @Input() isFlex = false;

  @HostListener('click')
  onClick() {
    if (this.isDisabled) {
      return;
    }

    if (!!this.routerLink) {
      this.router.navigate([this.routerLink]);
    }
 }

 @HostBinding('class') get getClasses() {
  return [
    `${this.class} uni-link--${this.type}`,
    this.isDisabled ? 'is-disabled' : '',
    this.isFlex ? 'is-flex' : ''
  ].join(' ');
 }
  constructor(private router: Router) {}
}
