import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';
import { GeneralFiltersFormStore } from './general-filters-form.store';

@Component({
  selector: 'app-general-filters-form',
  templateUrl: './general-filters-form.component.html',
  styleUrls: ['./general-filters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  providers: [GeneralFiltersFormStore]
})
export class GeneralFiltersFormComponent implements OnInit {
  
  constructor(
    private store: GeneralFiltersFormStore,
    private formStore: FiltersFormStore,
    
  ) {}

  vm$ = this.store.vm$


  ngOnInit(): void {}


  changeChannels(value: any){
    this.formStore.patchState({channels: value})
  }

  changeProducts(value: any){
    this.formStore.patchState({products: value})
  }

  changeAccountId(value: any){
    this.formStore.patchState({accountId: value})
  }

  changeDateRange(value: any){
    this.formStore.patchState({dateRange: value})
  }

  changeRecipients(value: any){
    this.formStore.patchState({recipients: value})
  }
}
