import { graphql, rest } from 'msw'


const faker = {
    datatype: { uuid: () => ''},
    name: {fullName: () => ''},
    phone: {number: () => ''},
    date: {past: () => ''},
    helpers: {arrayElement: () => ''},
    lorem: {words: (x) => ''},
  }

  
export const getMessageLogsDetails = rest.get('http://0.0.0.0:4200/app/api/v2/message-logs/:id', async (req, res, ctx) => {

    return res(
        ctx.json({
            id: req.params.id,
            name: faker.name.fullName(),
            account: faker.datatype.uuid(),
            recipient: faker.phone.number(),
            date: faker.date.past(),
            status: faker.helpers.arrayElement(['delivered', 'not-delivered', 'sent', 'received']),
            message: faker.lorem.words(100 + Math.ceil(Math.random() * 350))
        })
    )
})


export const getMessageLogsDetailsGQL = graphql.query('GetMessageLogsDetails', (req, res, ctx) => {
    return res(
        ctx.data({
            findFactSmsTrafficLogsDetails: {
                id: req.variables.id,
                name: faker.name.fullName(),
                account: faker.datatype.uuid(),
                account_name: faker.name.fullName(),
                recipient: faker.phone.number(),
                date: faker.date.past(),
                status: faker.helpers.arrayElement(['delivered', 'not-delivered', 'sent', 'received']),
                message: faker.lorem.words(100 + Math.ceil(Math.random() * 350))
            }
        })
    )
})



