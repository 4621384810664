<ng-container *ngIf="vm$ | async as vm">
    <div class="autocomplete-multiselect">
        <input type="text" #trigger="cdkOverlayOrigin" uniInput cdkOverlayOrigin autocomplete="off"
            [placeholder]="vm.placeholder" [attr.ariaLabel]="vm.placeholder" [ngModel]="vm.inputValue"
            (focus)="onFocus($event)" (ngModelChange)="onInputChange($event)">
        <span class="nav-icon" *ngIf="vm.loading">
            <i class="fa fa-spin fa-spinner"></i>
        </span>
        <span class="nav-icon" *ngIf="!vm.loading">
            <i class="fa" [ngClass]="vm.open ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
        </span>
        <span class="nav-icon nav-icon-reset" *ngIf="vm.clearIconVisibility" (click)="clear()">
            <i class="fa fa-times"></i>
        </span>
    </div>
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="closeOptions()" [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="vm.open" [cdkConnectedOverlayWidth]="vm.overlayWidth"
        (overlayKeydown)="keydown($event)">
        <ul class="type-list">
            <li *ngFor="let opt of vm.options" [value]="opt" (click)="selectItem(opt)">
                <uni-checkbox>
                    <mat-checkbox [checked]="opt.selected">
                        <ng-container [ngTemplateOutlet]="optionTemplate || option" [ngTemplateOutletContext]="{state:{
                          label: opt.label,
                          object: opt
                        }}"></ng-container>
                    </mat-checkbox>
                </uni-checkbox>
            </li>
            <li *ngIf="!vm.options.length">
                {{vm.noResultsLabel}}
            </li>
        </ul>
        <div class="loading-mask" *ngIf="vm.optionsLoading">
            <i class="fa fa-spinner fa-spin"></i>
        </div>
    </ng-template>
</ng-container>

<ng-template #option let-state="state">
  {{state.label}}
</ng-template>
