import { graphql } from 'msw'

export const getCampaignListGQL = graphql.query('FindAllCampaignsByAccountID', (req, res, ctx) => {
    return res(
        ctx.data({
            entries: [
                {value: '1', label: 'Campaign 1'},
                {value: '2', label: 'Campaign 2'},
                {value: '3', label: 'Campaign 3'},
                {value: '4', label: 'Campaign 4'}
            ]
        })
    )

})