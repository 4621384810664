import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { of } from 'rxjs';
import { FormButtonSelectorItem } from 'src/app/shared/form-button-selector/form-button-selector.models';
import { ProductsMockStore } from 'src/app/shared/store-mocks/products.store';
import { CreateFormProductStore } from './create-form-products.store';

@Component({
  selector: 'app-create-form-products',
  templateUrl: './create-form-products.component.html',
  styleUrls: ['./create-form-products.component.scss'],
  providers: [ProductsMockStore, CreateFormProductStore]
})
export class CreateFormProductsComponent implements OnInit, ControlValueAccessor {
  
  vm$ = this.store.vm$

  constructor(
    public ngControl: NgControl,
    private store: CreateFormProductStore
  ) {
    ngControl.valueAccessor = this;
  }

  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(){

  }

  writeValue(value: string | undefined){
    if(value){
      this.store.setSelectedItems(value.split(','))
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onDataChange(data: FormButtonSelectorItem[]){
    if(!this.ngControl.touched){
      this.onTouch()
    }
    
    const values = data.map(d => d?.value).join(',')
    this.onChange(values)
  }


}
