import { NgModule } from '@angular/core';
import { MessageBubbleComponent } from './message-bubble.component';
import { LoadingMaskModule } from '../loading-mask/loading-mask.module';

@NgModule({
    declarations: [MessageBubbleComponent],
    exports: [MessageBubbleComponent],
    imports: [
        LoadingMaskModule
    ]
})
export class MessageBubbleModule { }
