import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormChipsAutocompleteComponent } from './form-chips-autocomplete.component';



@NgModule({
  declarations: [FormChipsAutocompleteComponent],
  exports: [FormChipsAutocompleteComponent],
  imports: [
    CommonModule
  ]
})
export class FormChipsAutocompleteModule { }
