
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { BlobUtils, UniSnackbarFacade } from "@unifonic/common";
import { ReportsRepository } from "../../data-access/reports.repository";
import { ReportsFiltersStore } from "../../store/reports-filters.store";
import { TranslateService } from "@ngx-translate/core";

export type TypeItem = {
  value: string,
  translationKey: string
}

type State = {
    reportId: string,
    downloading: boolean,
    open: boolean,
    types: TypeItem[]
}

const initialState: State = {
    reportId: '',
    downloading: false,
    open: false,
    types: [
    // {
    //     value: 'PDF',
    //     translationKey: 'ci.common.pdf',
    // },
    // {
    //     value: 'EXCEL',
    //     translationKey: 'ci.common.excel',
    // },
    {
        value: 'CSV',
        translationKey: 'ci.common.csv',
    }
]
}

@Injectable()
export class ReportsDataTableDownloadStore extends ComponentStore<State>{
    constructor(
        private reportRepository: ReportsRepository,
        private reportFiltersStore: ReportsFiltersStore,
        private snackbar: UniSnackbarFacade,
        private translate: TranslateService
    ){
        super({...initialState})
    }

    //selectors
    readonly reportId$ = this.select((state) => state.reportId)
    readonly downloading$ = this.select((state) => state.downloading)
    readonly open$ = this.select((state) => state.open)
    readonly types$ = this.select((state) => state.types)

    readonly vm$ = this.select(
        this.reportId$,
        this.downloading$,
        this.open$,
        this.types$,
        (reportId, downloading, open, types ) => {
            return {
                reportId,
                downloading,
                open,
                types
            }
        }
    )

    //updaters
    readonly setReportId = this.updater((state, reportId: string) => {
        return {...state, reportId}
    })

    readonly setOpen = this.updater((state, open: boolean) => {
        return {...state, open}
    })

    //effects
    readonly downloadReport = this.effect((extension$: Observable<any>) => {

        return extension$.pipe(
            tap(() => {
                this.patchState({
                    downloading: true,
                    open: false
                })
            }),
            withLatestFrom(
                this.reportFiltersStore.params$,
                this.reportId$
            ),
            map(([extension, params, reportId]) => {
                return {reportId, userId: params.userId, extension}
            }),
            switchMap((data) => this.reportRepository.downloadReport(data).pipe(
                tapResponse((response) => {
                    const blob = new Blob([response.body], {
                        type: 'octet/stream'
                    })

                    BlobUtils.download(blob, 'unifonic-report.zip',response.headers)

                    this.patchState({
                        downloading: false
                    })
                },
                ()=>{
                    this.patchState({ downloading: false })
                    this.snackbar.show('error', this.translate.instant('ci.common.downloadFileErrorMessage'))
                })
            ))
        )
    })
}
