import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AdvancedFiltersFormSenderNumberStore } from '../advanced-filters-form-sender-number/advanced-filters-form-sender-number.store';
import { AdvancedFiltersFormLocationStore } from './advanced-filters-form-location.store';
import { map } from 'rxjs/operators';
import { SenderNameFacade } from 'unifonic-spa-common/src';
import { MccCampaignsFetchStore } from '../../store/mcc-campaigns-fetch.store';

@Component({
  selector: 'app-advanced-filters-form-location',
  templateUrl: './advanced-filters-form-location.component.html',
  styleUrls: ['./advanced-filters-form-location.component.scss'],
  providers: [AdvancedFiltersFormLocationStore, 
    MccCampaignsFetchStore],
})
export class AdvancedFiltersFormLocationComponent implements OnInit, OnDestroy, ControlValueAccessor {

  value = ''

  onChange: any = () => {}
  onTouch: any = () => {}


  vm$ = this.store.vm$


  constructor(
    public ngControl: NgControl,
    private senderNameFacade: SenderNameFacade,
    private store: AdvancedFiltersFormLocationStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){
    this.saveValue(value)
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(value: string[]){
    this.saveValue(value);
  }

  saveValue(value){
    this.value = value
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }
}
