import { filter, map, tap } from 'rxjs/operators';

import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable } from "rxjs";

type UpdateSource = 'external' | 'internal' | 'initial'
export type FieldValue = [string, UpdateSource]

export type AccountOption = {
    label: string,
    value: string
}

type State = {
    options: AccountOption[],
    value: FieldValue,
    loading: boolean
}

const initialState: State = {
    options: [],
    value: ['', 'initial'],
    loading: false
}

@Injectable()
export class FormAutocompleteSelectStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    //selectors
    options$ = this.select(state => state.options)
    value$ = this.select(state => state.value)
    loading$ = this.select(state => state.loading)

    selectedOption$ = this.select(this.options$, this.value$,
        (options, [value, _]) => {
            return options.find(o => o.value === value) || {}
        }
    )


    readonly eventEmitter$ = this.value$.pipe(
        filter(([_, source]) => source === 'internal'),
        map(([value, _]) => value)
    )

    //updaters

    readonly setOptions = this.updater((state, opts: AccountOption[]) => {
        const options = opts ? opts : [] 
        return {...state, options}
    })

    readonly setValue = this.updater( (state, value: FieldValue) => {
        return {...state, value: [...value]}
    })

    readonly setLoading = this.updater( (state, loading: boolean) => {
        return {...state, loading}
    })



    syncFormControl = this.effect<FormControl>((fc$: Observable<FormControl>) => {
        const stream$ = combineLatest([this.selectedOption$, fc$])
        return stream$.pipe(
            tap(([value, fc]) => {
                fc.setValue(value.value ? value : null, {emitEvent: false})
            }),
        )
    })
}








