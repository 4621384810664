import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, NgForm } from '@angular/forms';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';
import { AdvancedFiltersFormStore } from './advanced-filters-form.store';
import { MccCampaignsFetchStore } from '../../store/mcc-campaigns-fetch.store';

@Component({
  selector: 'app-advanced-filters-form',
  templateUrl: './advanced-filters-form.component.html',
  styleUrls: ['./advanced-filters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  providers: [
    AdvancedFiltersFormStore
  ]
})
export class AdvancedFiltersFormComponent implements OnInit {

  vm$ = this.store.vm$

  constructor(
    private fb: FormBuilder,
    private store: AdvancedFiltersFormStore,
    private formStore: FiltersFormStore,
  ) { }

  ngOnInit(): void {}

  toggleForm(){
    this.store.toggleVisibility()
  }

  changeSenderName(value: any){
    this.formStore.patchState({senderName: value})
  }

  changeSenderNumber(value: any){
    this.formStore.patchState({senderNumber: value})
  }

  changeCampaignId(value: any){
    this.formStore.patchState({campaignId: value})
  }

  changeSmsStatus(value: string){
    this.formStore.patchState({status: value})
  }

  changeWhatsappStatus(value: string){
    this.formStore.patchState({status: value})
  }

  changeCustomerReason(value: string){
    this.formStore.patchState({customerReason: value})
  }

  changeCampaignTemplate(value: string){
    this.formStore.patchState({templates: value})
  }
  changeCampaignButton(value: string){
    this.formStore.patchState({buttons: value})
  }
  changeCampaignLink(value: string){
    this.formStore.patchState({links: value})
  }
  changeCampaignLocation(value: string){
    this.formStore.patchState({locations: value})
  }
  

}
