<ng-container *ngIf="vm$ | async as vm">
    <mat-form-field appearance="standard">
        <mat-chip-list
            #chipList
            multiple
        >
            <mat-chip
                *ngFor="let keyword of vm.entities"
                [selected]="keyword"
                [value]="keyword"
                (removed)="removeKeyword(keyword)"
            >
                {{keyword}}
                <button matChipRemove>
                    <!-- <mat-icon>cancel</mat-icon> -->
                    <i class="fa fa-times"></i>
                </button>
            </mat-chip>
            <input
                [placeholder]="vm.placeholder"
                [matChipInputFor]="chipList"
                (matChipInputTokenEnd)="addEntityFromInput($event)"
                [matChipInputAddOnBlur]="true"
            >
        </mat-chip-list>
    </mat-form-field>
</ng-container>