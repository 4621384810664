import { Component, OnInit } from '@angular/core';
import { AccountListStore } from 'src/app/shared/store-mocks/account-list.store';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';

@Component({
  selector: 'app-filter-panel-account-id',
  templateUrl: './filter-panel-account-id.component.html',
  styleUrls: ['./filter-panel-account-id.component.scss']
})
export class FilterPanelAccountIdComponent implements OnInit {

  accounts$ = this.accountListStore.accounts$
  accountsLoading$ = this.accountListStore.loading$

  accountIdValue$ = this.messageLogsFiltersStore.accountId$

  constructor(
    private messageLogsFiltersStore: MessageLogsFiltersStore,
    private accountListStore: AccountListStore,
  ) { }

  ngOnInit(): void {}

  onValueChange(accountId: string){
    this.messageLogsFiltersStore.patchState({accountId, autoload: true})
  }
}
