import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportsDataTableOpenStore } from './reports-data-table-open.store';

@Component({
  selector: 'app-reports-data-table-open',
  templateUrl: './reports-data-table-open.component.html',
  styleUrls: ['./reports-data-table-open.component.scss'],
  providers: [ReportsDataTableOpenStore]
})
export class ReportsDataTableOpenComponent implements OnInit {
  
  @Input() set reportId(value: string){
    this.store.setReportId(value)
  }

  @Input() set loading(value: boolean){
    this.store.setLoading(value)
  }

  @Input() set isOpen(value: boolean){
    this.store.setOpen(value)
  }

  @Output() toggle: EventEmitter<string> = new EventEmitter()

  vm$ = this.store.vm$
  constructor(
    private store: ReportsDataTableOpenStore
  ) { }

  ngOnInit(): void {
  }

  toggleReport(id: string){
    this.toggle.emit(id)
  }

}
