import { omitBy } from "lodash";
import { ApiMessageLogsListParams, ApiMessageLogsReportBody } from "../log-analyser.models";

export const mapFilterParamsToDownloadReportPayload = (qp: Partial<ApiMessageLogsListParams>, timezone: string, notifyByMail: boolean = false): ApiMessageLogsReportBody  => {
    return {
        startDate: qp.startDate,
        endDate: qp.endDate,
        accountId: qp.accountId,
        ...omitBy({
            campaignId: qp.campaignId,
            campaignStatus: qp.campaignStatus,
            senderName: qp.senderName,
            eventStatus: "", // TODO: Business inconsitent, no way to set this value from to view
            messageStatus: "", // TODO: no way to set up this value in views
            customerStatus: qp.customerStatus,
            messageType: qp.messageType,
            recipient: qp.recipient,
            channels: qp.channels,
            product: qp.products,
            senderNumber: qp.senderNumber,
            notifyByMail
        },(v) => v === null || v === undefined || v === '')

    }
}