import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { Observable } from "rxjs";
import {  map } from 'rxjs/operators';
import { QueryPayload } from "../data-access.model";
import { createSmsCampaignsSummaryById, createWhatsappCampaignsSummaryById } from "./campaign-details.graphql";
import { CampaignSummaryApi, CampaignSummaryApiResponse, LatestCampaignDetailsApiResponse } from "./campaign-details.model";

@Injectable({
    providedIn: 'root'
})
export class CampaignDetailsRepository {

    constructor(
        private apollo: Apollo
    ){}

    gqlQuerySmsCampaignDetailsData({variables, output}: QueryPayload<{campaignId: string, accountId: string}>): Observable<Partial<CampaignSummaryApi>> {
      return this.apollo.query<CampaignSummaryApiResponse>({
        query: createSmsCampaignsSummaryById(output),
        variables
      })
      .pipe(
          map((response) => {
            return response.data.getCampaignsSummaryByCampaignID.entries || {}
          }) 
      )
    }


    gqlQueryWhatsappCampaignDetailsData({variables, output}: QueryPayload<{campaignId: string, accountId: string}>): Observable<Partial<CampaignSummaryApi>> {
      return this.apollo.query<any>({
        query: createWhatsappCampaignsSummaryById(output),
        variables
      })
      .pipe(
          map((response) => {
            return response.data.getWhatsappCampaignsSummaryByCampaignID.entries || {}
          }) 
      )
    }

}

