import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { UniAuthFacade } from 'unifonic-spa-common/src';

@Component({
  selector: 'app-create-form-date-range',
  templateUrl: './create-form-date-range.component.html',
  styleUrls: ['./create-form-date-range.component.scss'],
})
export class CreateFormDateRangeComponent implements OnInit, ControlValueAccessor {


  onChange: any = () => {}
  onTouch: any = () => {}

  value: any = {
    from: '',
    to: '' 
  }

  timezone$ = this.authFacade.userMe$.pipe(
    map((user: any) => user.user.timezone)
  )

  constructor(
    public ngControl: NgControl,
    private authFacade: UniAuthFacade
  ) {
    ngControl.valueAccessor = this;
  }


  ngOnInit(): void {
      
  }

  writeValue(value: string | undefined){
    if(value){
      this.value = {
        from: value.split('_')[0],
        to: value.split('_')[1]
      }
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }


  onDateSelect(value: any){
    const v = `${value.from}_${value.to}`
    this.onChange(v)
  }

}
