import { HttpClient } from '@angular/common/http';
import { get } from 'lodash';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SettingsService } from '../../../shared';

export function HttpLoaderFactory(http: HttpClient, settings: SettingsService) {
  const buildRandomNumber = get(settings, 'environment.buildRandomNumber');

  return new TranslateHttpLoader(http, '/build/i18n/', '.json?v=' + (buildRandomNumber || (+new Date()).toString()));
}

