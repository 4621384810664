import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniCommonModule } from './../../../../unifonic-spa-common/src/uni-common.module';
import { PaginationComponent } from './pagination.component';


@NgModule({
  declarations: [PaginationComponent],
  imports: [
    CommonModule,
    UniCommonModule
  ],
  exports: [PaginationComponent]
})
export class PaginationModule { }
