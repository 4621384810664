import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { DateRangeMockStore } from 'src/app/shared/store-mocks/date-range.store';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';
import { GeneralFiltersFormDateStore } from './general-filters-form-date.store';

@Component({
  selector: 'app-general-filters-form-date',
  templateUrl: './general-filters-form-date.component.html',
  styleUrls: ['./general-filters-form-date.component.scss'],
  providers: [GeneralFiltersFormDateStore, DateRangeMockStore]
})
export class GeneralFiltersFormDateComponent implements OnInit, ControlValueAccessor {

  onChange: any = () => {}
  onTouch: any = () => {}

  currentDate$ = this.filtersFormStore.dateRange$.pipe(
    tap((value) => this.saveValue(value))
  )

  timezone$ = this.store.timezone$
  vm$ = this.store.vm$

  constructor(
    public ngControl: NgControl,
    private store: GeneralFiltersFormDateStore,
    private filtersFormStore: FiltersFormStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {

  }

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onDateSelect(value: string){
    this.saveValue(value)
  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }
}
