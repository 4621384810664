import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-value-message',
  templateUrl: './card-value-message.component.html',
  styleUrls: ['./card-value-message.component.scss']
})
export class CardValueMessageComponent implements OnInit {
  @Input() value: string
  @Input() message: string
  @Input() loading: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
