import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartSankeyDiagramComponent } from './chart-sankey-diagram.component';
@NgModule({
  declarations: [ChartSankeyDiagramComponent],
  exports: [ChartSankeyDiagramComponent],
  imports: [
    HighchartsChartModule
  ]
})
export class ChartSankeyDiagramModule { }
