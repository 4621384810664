import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniCommonModule } from './../../../../unifonic-spa-common/src/uni-common.module';
import { DataSortComponent } from './data-sort.component';


@NgModule({
  declarations: [DataSortComponent],
  imports: [
    CommonModule,
    UniCommonModule
  ],
  exports: [DataSortComponent]
})
export class DataSortModule { }
