import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Subject } from "rxjs";
import { filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";

type State = {
    options: any[],
    value: string[],
    internalValue: string[],
    open: boolean,
    active: boolean,
    titleLabel: string,
    submitLabel: string,
    clearLabel: string
};

const initialState: State = {
    options: [],
    value: [],
    internalValue: [],
    open: false,
    active: false,
    titleLabel: "Search",
    submitLabel: "Apply",
    clearLabel: "Clear"
}

@Injectable()
export class OverlaySelectFilterStore extends ComponentStore<State>{

    readonly emitter$ = new Subject<string>()

    constructor(){
        super(initialState)
    }

    //selectors
    readonly options$ = this.select(state => state.options)
    readonly value$ = this.select(state => state.value)
    readonly internalValue$ = this.select(state => state.internalValue)
    readonly open$ = this.select(state => state.open)
    readonly active$ = this.select(state => state.active)

    readonly labels$ = this.select(state => {
        const { titleLabel, submitLabel, clearLabel } = state
        return { titleLabel, submitLabel, clearLabel }
    })

    readonly viewOptions$ = this.select(
        this.options$,
        this.internalValue$,
        (options, value) => {
            return options.map(o => ({
                    ...o,
                    checked: value.indexOf(o.value) !== -1
                })
            )
        }
    )

    vm$ = this.select(
        this.viewOptions$,
        this.open$,
        this.active$,
        this.labels$,
        (options, open, active, labels) => {
            return {
                options,
                open,
                active,
                labels
            }
        }, {debounce: true}
    )

    //updaters
    readonly setInputValue = this.updater((state, inputValue: string) => {
        return {...state, inputValue}
    })

    readonly setInternalValue = this.updater((state, payload: {value: string, checked: boolean}) => {
        const newValue = payload.checked ? 
            [...state.internalValue, payload.value] 
            : state.internalValue.filter(v => v !== payload.value)
            
        return {...state, internalValue: newValue}   
    })

    //effects
    readonly changeValue = this.effect(trigger$ => {
        return trigger$.pipe(
            withLatestFrom(this.internalValue$),
            tap(([_, internalValue]) => this.patchState({value: internalValue})),
            tap(([_, internalValue]) => this.emitter$.next(internalValue.join(',') || ''))
        )
    })

    readonly toggleFilter = this.effect(() => {
        return this.open$.pipe(
            filter(open => open),
            withLatestFrom(this.value$),
            tap(([_, value]) => {
                this.patchState({internalValue: value})
            })
        )
    })
}