import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { UniCommonModule } from '@unifonic/common';
import { FormSingleSelectComponent } from './form-single-select.component';


@NgModule({
  declarations: [FormSingleSelectComponent],
  exports: [FormSingleSelectComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    UniCommonModule
  ]
})
export class FormSingleSelectModule { }
