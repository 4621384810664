/**
 * Divide text into array of phrases according to specific phrase
 * 
 * @example
 * "My text for test" devided by "e" phrase is ['My t', 'e', 'xt for t', 'e', 'st']
 */
export const divideTextByToken = (phrase: string, textToDivide: string): string[] => {
    const regexp = new RegExp(phrase, 'i')
    let textPartialsArray = []
    if(!phrase.length){
        return [textToDivide]
    }

    let text = textToDivide
    while(text.length){
      const match = text.match(regexp)
      if(match){
        const arrayElement = text.slice(0, match.index)
        text = text.slice(match.index + match[0].length)
        if(arrayElement.length){
          textPartialsArray.push(arrayElement)
        }
        textPartialsArray.push(match[0])
      } else{
        textPartialsArray.push(text)
        text = ''
      }
    }
    return [...textPartialsArray]
}
