import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardKeyValueInvertComponent } from './card-key-value-invert.component';
import { UniBoxModule } from '@unifonic/common';
import { LoadingMaskModule } from '../loading-mask/loading-mask.module';


@NgModule({
  declarations: [CardKeyValueInvertComponent],
  exports: [CardKeyValueInvertComponent],
  imports: [
    CommonModule,
    UniBoxModule,
    LoadingMaskModule
  ]
})
export class CardKeyValueInvertModule { }
