import { Component, HostBinding, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-message-bubble',
    templateUrl: './message-bubble.component.html',
    styleUrls: ['./message-bubble.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MessageBubbleComponent implements OnInit {

    @Input() message!: string;
    @HostBinding("style.--background-color") @Input() backgroundColor = "#fff";

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        var urlRegex =/(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        if (this.message) {
          this.message = this.message.replace(urlRegex, function(url) {
              return '<span class="link">' + url + '</span>';
          });

          this.message = this.message.replace(/\\n/g, '<br>');
        }
    }
}
