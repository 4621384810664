
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { filter, tap } from "rxjs/operators";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {
    value: string
}

const initialState: State = {
    value: "",
}

@Injectable()
export class AdvancedFiltersFormSenderNumberStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private messageLogsFiltersStore: MessageLogsFiltersStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
    ){
        super(initialState)
    }

    readonly value$ = this.select(state => state.value)

    readonly onHide = this.effect(() => {
        return this.advancedFiltersFormStore.senderNameVisibility$.pipe(
            filter(v => !v),
            tap((v) => this.patchState({value: ''}))
        )
    })

    readonly onFormValueChange = this.effect(() => {
        return this.filtersFormStore.senderNumber$.pipe(
            tap((v: string) => this.patchState({value: v}))
        )
    })
}