import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ComponentStore } from '@ngrx/component-store';

import { map, skip, tap } from "rxjs/operators";
import { MessageLogsFiltersStore } from "./message-logs-filters.store";
import momentTimezone from 'moment-timezone';
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {}

const initialState: State = {}

@Injectable()
export class MessageLogsUrlStore extends ComponentStore<State>{

    constructor(
        private messageLogsFiltersStore: MessageLogsFiltersStore,
        private router: Router,
        private route: ActivatedRoute,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //effects
    readonly syncUrl = this.effect((trigger$) => {

        /**
         * TODO: we don't need empty params here but for now it is the best option to merge it
         * with autoLoad=true param which is the only "non-filter" param
         * in future any custom params should be easy to merge
        */
        return this.messageLogsFiltersStore.allFilters$.pipe(
            skip(1), // skip initial 
            map(params => ({...params}) ),
            tap((params: any) => {
                const [startDate, endDate] = params.dateRange.split('_')
                const timezone = this.authFacade.userMe.user.timezone
                
                delete params.dateRange;
                this.router.navigate([], 
                    {
                      relativeTo: this.route,
                      replaceUrl: true,
                      queryParams: {
                        ...params,
                        startDate: momentTimezone.tz(startDate, timezone).format('YYYY-MM-DD'),
                        endDate: momentTimezone.tz(endDate, timezone).format('YYYY-MM-DD')
                      }, 
                      queryParamsHandling: 'merge',
                    });
            })
        )
    })
    
}

