import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';

type State = {
    loading: boolean
    loaded: boolean
    entities: any[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [
        {
            label: 'Warning',
            value: 'WARNING'
          },{
            label: 'Educational',
            value: 'EDUCATIONAL'
          },{
            label: 'Services',
            value: 'SERVICE'
          },{
            label: 'Personal',
            value: 'PERSONAL'
          },{
            label: 'Promotional',
            value: 'PROMOTIONAL'
          }
    ]
}

@Injectable()
export class MessageLogsMessageTypeMockStore extends ComponentStore<State>{
    constructor(){
        super(initialState)
    }

    //selectors

    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}