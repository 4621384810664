import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AccountListStore } from 'src/app/shared/store-mocks/account-list.store';
import { FiltersFormStore } from '../filter-forms/filter-forms.store';

@Component({
  selector: 'app-general-filters-form-account',
  templateUrl: './general-filters-form-account.component.html',
  styleUrls: ['./general-filters-form-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralFiltersFormAccountComponent implements OnInit, ControlValueAccessor {


  accounts$ = this.accountListStore.accounts$
  accountsLoading$ = this.accountListStore.loading$

  accountIdValue$ = this.filtersFormStore.accountId$

  onChange: any = () => {}
  onTouch: any = () => {}

  constructor(
    public ngControl: NgControl,
    private accountListStore: AccountListStore,
    private filtersFormStore: FiltersFormStore
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {}

  writeValue(value: string){
    if(value){
      this.onTouch()
    }
  }

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  onValueChange(value: string){
    this.saveValue(value)
  }


  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }
}
