import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { HighchartsInstance, HighchartsItem, HighchartsOptions } from '../highcharts/highcharts.types';
import { HCToken } from '../highcharts/highcharts.token';
import { SeriesClickEventObject } from 'highcharts';
import { createLinkTooltip, createWidgetTooltip } from './chart-sankey-diagram.utils';

@Component({
  selector: 'app-chart-sankey-diagram',
  templateUrl: './chart-sankey-diagram.component.html',
  styleUrls: ['./chart-sankey-diagram.component.scss']
})
export class ChartSankeyDiagramComponent implements OnInit {

  @Input() source!: any[]
  @Input() nodes!: any[]
  @Input() xAxis!: string[]

  @Output() widgetClick: EventEmitter<any> = new EventEmitter<any>()
  @Output() linkClick: EventEmitter<string> = new EventEmitter<string>()

  constructor( @Inject(HCToken) public hc: HighchartsItem) {}

  cInstance!: HighchartsInstance;
  chartOptions: HighchartsOptions = {

    credits:{
      enabled: false,
    },
    title: {
      text: ''
    },
    tooltip: {
      backgroundColor: '#ffffff',
      borderColor: 'none',
      borderRadius: 4,
      borderWidth: 0,
      useHTML: true,
      formatter: function(){
        if(this.point.options.className === 'highcharts-node'){
          return createWidgetTooltip(this)
        }
    
        if(!!this.point.options.from && !!this.point.options.to){
          return createLinkTooltip(this)
        }

        return ""
      }
    },
    plotOptions: {
  
      sankey:{
        allowPointSelect: true,
        nodeWidth: 35,
        nodePadding: 30,
        cursor: 'pointer',
        events: {
          click: this.selectItem.bind(this)
        },
        dataLabels: {
          align: 'center',
          position: 'center',
          // useHTML: true
        },
      }
    },
    chart:{
      plotShadow: false,
      type: 'sankey',
      height: 450,
      animation: false,
    },
    // colors: [],

    series: [{
      keys: ['from', 'to', 'weight', 'size'],
      type: 'sankey',
      nodes: [],
      data: []
    }]
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.source && !changes.source.firstChange){
      this.redrawChart()
    }
  }
  
  chartInit(chart: Highcharts.Chart){
    this.cInstance = chart;
    this.cInstance.redraw(true)
  }

  redrawChart(){
    if(this.cInstance){
      while(this.cInstance.series.length){
        this.cInstance.series[0].remove()
      }
    
      this.cInstance.addSeries({
        keys: ['from', 'to', 'weight'],
        type: 'sankey',
        nodes: [...this.nodes],
        data: [...this.source]
      })

      this.cInstance.xAxis[0].setCategories(this.xAxis)
      this.cInstance.redraw(true)
    }
  }


  selectItem(event: SeriesClickEventObject){
    event.preventDefault();
    if(event.point.options.className === 'highcharts-node'){
      const point = event.point as any;
  
      this.widgetClick.emit({widgetId: point.id, widgetName: point.name})
    }

    if(!!event.point.options.from && !!event.point.options.to){
      //link click
    }

  }
}
