import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UniBoxModule } from '../uni-box/uni-box.module';
import { UniSnackbarModule } from '../uni-snackbar';
import { UniTopBarModule } from '../uni-top-bar/uni-top-bar.module';
import { UniContainerComponent } from './components/uni-container/uni-container.component';
import { UniInfoComponent } from './components/uni-info/uni-info.component';
import { UniInfoBoxComponent } from './components/uni-info-box/uni-info-box.component';
import { UniLabelComponent } from './components/uni-label/uni-label.component';
import { UniViewComponent } from './components/uni-view/uni-view.component';
import { UniLayoutContentComponent } from './components/uni-layout-content/uni-layout-content.component';
import { UniLinkComponent } from './components/uni-link/uni-link.component';
import { UniLoaderComponent } from './components/uni-loader/uni-loader.component';
import { UniMainContainerComponent } from './components/uni-main-container/uni-main-container.component';
import { UniMainContentComponent } from './components/uni-main-content/uni-main-content.component';
import { UniMoreListComponent } from './components/uni-more-list/uni-more-list.component';
import { UniOverlayComponent } from './components/uni-overlay/uni-overlay.component';
import { UniPriceBoxComponent } from './components/uni-price-box/uni-price-box.component';
import { UniSectionHeaderComponent } from './components/uni-section-header/uni-section-header.component';
import { UniSectionComponent } from './components/uni-section/uni-section.component';
import { UniStatusComponent } from './components/uni-status/uni-status.component';
import { UniTagComponent } from './components/uni-tag/uni-tag.component';
import { UniTitleContainerComponent } from './components/uni-title-container/uni-title-container.component';
import { UniTitleItemComponent } from './components/uni-title-item/uni-title-item.component';
import { UniValueComponent } from './components/uni-value/uni-value.component';
import { UniLayoutGuestComponent } from './containers/uni-layout-guest/uni-layout-guest.component';
import { UniLayoutLoggedComponent } from './containers/uni-layout-logged/uni-layout-logged.component';
import { LayoutEffects } from './store/uni-layout.effects';
import { layoutReducer } from './store/uni-layout.reducers';
import { UniSpaceContainerComponent } from './components/uni-space-container/uni-space-container.component';
import { UniIconModule } from '../uni-icon/uni-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { HttpQueryModule } from '../../store/http-query/http-query.module';
import { UniNavNewModule } from '../uni-nav-new';
import { UniFooterModule } from '../uni-footer';
import { UniLanguageModule } from '../uni-language';

const components = [
  UniContainerComponent,
  UniSectionComponent,
  UniSectionHeaderComponent,
  UniLoaderComponent,
  UniStatusComponent,
  UniLabelComponent,
  UniTitleContainerComponent,
  UniTitleItemComponent,
  UniPriceBoxComponent,
  UniLinkComponent,
  UniLayoutGuestComponent,
  UniLayoutLoggedComponent,
  UniTagComponent,
  UniMainContainerComponent,
  UniMainContentComponent,
  UniLayoutContentComponent,
  UniOverlayComponent,
  UniMoreListComponent,
  UniValueComponent,
  UniInfoComponent,
  UniInfoBoxComponent,
  UniSpaceContainerComponent,
  UniViewComponent,
];

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    UniBoxModule,
    UniSnackbarModule,
    UniNavNewModule,
    UniTopBarModule,
    UniFooterModule,
    UniIconModule,
    TranslateModule,
    HttpQueryModule,
    UniLanguageModule,
    StoreModule.forFeature('layout', layoutReducer),
    EffectsModule.forFeature([LayoutEffects])
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniLayoutModule { }
