<ng-container *ngIf="vm$ | async as vm">

    <div
        class="uni-pagination"
        *ngIf="vm.numberOfPages > 1"
    >
        <div class="uni-pagination__pages">
            <uni-pagination-nav>
                <ng-container *ngFor="let page of vm.pagesNav">
                    <ng-container *ngIf="page !== '...'">
                        <uni-button
                            [style]="'secondary'"
                            (click)="selectPage(page)"
                            [class.active]="vm.currentPage === page"
                        >
                            {{ page }}
                        </uni-button>
                    </ng-container>
                    <ng-container *ngIf="page === '...'">
                        <div class="pagination-button-separator">{{page}}</div>
                    </ng-container>

                </ng-container>
            </uni-pagination-nav>

            <uni-pagination-controllers
                *ngIf="vm.numberOfPages > 1"
                [currentPage]="vm.currentPage"
                [pagesAmount]="vm.numberOfPages"
                (controllerAction)="changePageByOne($event, vm.currentPage, vm.numberOfPages)"
            ></uni-pagination-controllers>
        </div>

    </div>


</ng-container>