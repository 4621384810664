<ng-container *ngIf="vm$ | async as vm">

    <app-form-period-date-picker
        [value]="vm.currentDate"
        [dateRangeLabel]="'ci.logAnalyzer.labels.select' | translate"
        [clearable]="false"
        [blacklist]="['']"
        [defaultTimePeriod]="vm.defaultDatePeriod"
        [defaultDateRange]="vm.defaultDateRange"
        (dateSelect)="dateRangeValueChange($event)"
        [timezone]="vm.timezone"
        [periodEntities]="vm.periods"
        
    ></app-form-period-date-picker>
</ng-container>