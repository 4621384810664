import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss']
})
export class FormCardComponent implements OnInit {


  @Input() title!: string
  @Input() value!: string
  @Input() description!: string
  @Input() disabled: boolean = false
  @Input() active: boolean = false
  @Output() select: EventEmitter<string> = new EventEmitter()



  constructor() { }

  ngOnInit(): void {
  }


  onSelect(value: string){
    if(!this.disabled){
      this.select.emit(value)
    }
  }
}
