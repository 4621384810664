import momentTimezone from "moment-timezone"

export const mapDatePickerValuesToDateRanges = (dateRangeValue: string, timezone: string): any => {

    const tz = () => momentTimezone().tz(timezone);
    const now = momentTimezone.utc().format()
    const eod = tz().endOf('day').utc().format()

    const result =  {
        // Real time last 6 hours
        "rt_360": {
            isRealTime: true,
            lastMinutes: dateRangeValue.split('_')[1],
            startDate: now,
            endDate: now
        },
        // Real time last 12 hours
        "rt_720": {
            isRealTime: true,
            lastMinutes: dateRangeValue.split('_')[1],
            startDate: now,
            endDate: now
        },
        // Real time last 24 hours
        "rt_1440":{
            isRealTime: true,
            lastMinutes: dateRangeValue.split('_')[1],
            startDate: now,
            endDate: now
        },
        //Minutes from start of day - now
        "rt_today":{
            isRealTime: true,
            lastMinutes: tz().utc().diff(tz().startOf('d').utc(), 'm'),
            startDate: now,
            endDate: now
        },
         // 24 hours ago - now
        "last24h": {
            startDate: tz().subtract(24, 'hours').utc().format(),
            endDate: now
        },
        // today start of day - today end of day
          "today": {
            startDate: tz().startOf('d').utc().format(),
            endDate: tz().endOf('d').utc().format()
        },
        // 7 days ago - now
        "last7d": {
            startDate: tz().subtract(7, 'days').startOf('day').utc().format(),
            endDate: eod
        },
        // 30 days ago - now
        "last30d": {
            startDate: tz().subtract(30, 'days').startOf('day').utc().format(),
            endDate: eod
        },
        //begin of the this week - now
        "this1w": {
            startDate: tz().startOf('week').utc().format(),
            endDate: eod
        },

        //begin of the this week, 2 weeks before - end of the week before
        "this2w": {
            startDate: tz().subtract(2, 'weeks').startOf('week').utc().format(),
            endDate: tz().subtract(1, 'week').endOf('week').utc().format()
        },

        // begin of this month - now
        "this1m": {
            startDate: tz().startOf('month').utc().format(),
            endDate: eod
        },

        //begin of the previous month - end of the previous month
        "last1m": {
            startDate: tz().subtract(1, 'months').startOf('M').utc().format(),
            endDate: tz().subtract(1, 'months').endOf('M').utc().format()
        },
        //begin of the month 2 months before previous month  - end of the previous month
        "last2m": {
            startDate: tz().subtract(2, 'months').startOf('M').utc().format(),
            endDate: tz().subtract(1, 'months').endOf('M').utc().format()
        },
        //begin of the month 2 months before previous month  - end of the previous month
        "last3m": {
            startDate: tz().subtract(3, 'months').startOf('M').utc().format(),
            endDate: tz().subtract(1, 'months').endOf('M').utc().format()
        },
        //begin of the year - now
        "this1y": {
            startDate: tz().startOf('y').utc().format(),
            endDate: eod
        },
        //begin of the previous year - end of the previous year
        "last1y": {
            startDate: tz().subtract(1, 'years').startOf('y').utc().format(),
            endDate: tz().subtract(1, 'years').endOf('y').utc().format()
        }

    }[dateRangeValue] ||
    // if undefined it has to be date range from date picker
    {
        startDate: dateRangeValue.split('_')[0],
        endDate: dateRangeValue.split('_')[1]

    }


    return result;
}


export const mapDatepickerValueToTimzoneFormattedValue = (dateString: string, timezone: string): any => {

    // we need to find specific date (for example begining of the day) in given timezone (for example Asia/Dubai)
    const startString = momentTimezone(dateString.split('_')[0]).utc().format('YYYY-MM-DDTHH:mm:ss')
    const endString = momentTimezone(dateString.split('_')[1]).utc().format('YYYY-MM-DDTHH:mm:ss')

    // begining of the day in Asia/Dubai is (for 1st January 2023) 2023-01-01T00:00:00+04:00 (ISO-8601 format)
    const startStringInTimezone = momentTimezone.tz(startString, timezone).utc().format()
    const endStringInTimezone = momentTimezone.tz(endString, timezone).endOf('day').utc().format()

    return {
        startDate: startStringInTimezone,
        endDate: endStringInTimezone,
    }
}
